import * as t from "io-ts";

import { constants } from "@reactivated/constants";

import { GenericErrorSet } from "./formFields";
import { APR, Months, WebPageURL } from "./nominals";
import {
    DineroFromString,
    codecFromConstMap,
    codecFromEnum,
    nullable,
} from "./utils";

/**
 * BackendType
 */
export enum BackendType {
    FORTIVA = "fortiva",
    SYNCHRONY = "synchrony",
    WELLSFARGO = "wellsfargo",
}
export const TBackendType = codecFromEnum("BackendType", BackendType);

/**
 * FinancingDocumentFormat
 */
export const FinancingDocumentFormat =
    constants["tsicommon.financing.constants.DocumentFormat"];
const TFinancingDocumentFormat = codecFromConstMap(
    "FinancingDocumentFormat",
    FinancingDocumentFormat,
);
export type FinancingDocumentFormat = t.TypeOf<typeof TFinancingDocumentFormat>;

/**
 * FinancingDocumentType
 */
export const FinancingDocumentType =
    constants["tsicommon.financing.constants.DocumentType"];
const TFinancingDocumentType = codecFromEnum(
    "FinancingDocumentType",
    FinancingDocumentType,
);
export type FinancingDocumentType = t.TypeOf<typeof TFinancingDocumentType>;

/**
 * A Financing Value Prop Icon
 */
export const FinancingValuePropIcon = t.type({
    id: t.number,
    title: t.string,
    url: t.string,
});
export type FinancingValuePropIcon = t.TypeOf<typeof FinancingValuePropIcon>;

/**
 * A Financing Value Prop
 */
export const FinancingValueProp = t.type({
    title: t.string,
    subtitle: t.string,
    icon: FinancingValuePropIcon,
});
export type FinancingValueProp = t.TypeOf<typeof FinancingValueProp>;

/**
 * PreQualStatus
 */
export enum PreQualStatus {
    APPROVED = "A",
    REJECTED = "D",
    ERROR = "E",
    DOWN = "M",
    EXISTING_CARDHOLDER = "EC",
}
const TPreQualStatus = codecFromEnum("PreQualStatus", PreQualStatus);

/**
 * PreQualCustomerResponse
 */
export enum PreQualCustomerResponse {
    NONE = "",
    CLOSE = "CLOSE",
    ACCEPT = "YES",
    REJECT = "NO",
    SDKPRESENTED = "SDKPRESENTED",
}
const TPreQualCustomerResponse = codecFromEnum(
    "PreQualCustomerResponse",
    PreQualCustomerResponse,
);

/**
 * Financing Application field Names
 */
export const FinancingApplicationFields = t.keyof({
    // 'application_id': null,
    // 'application_source': null,
    // 'language_preference': null,
    requested_credit_limit: null,
    // 'reservation_number': null,
    // 'salesperson': null,
    // 'transaction_code': null,
    main_applicant__first_name: null,
    main_applicant__middle_initial: null,
    main_applicant__last_name: null,
    main_applicant__annual_income: null,
    main_applicant__date_of_birth: null,
    main_applicant__email_address: null,
    main_applicant__employer_name: null,
    main_applicant__home_phone: null,
    main_applicant__housing_status: null,
    main_applicant__mobile_phone: null,
    main_applicant__ssn: null,
    main_applicant__work_phone: null,
    main_applicant__address__address_line_1: null,
    main_applicant__address__address_line_2: null,
    main_applicant__address__city: null,
    main_applicant__address__postal_code: null,
    main_applicant__address__state_code: null,
    joint_applicant__first_name: null,
    joint_applicant__middle_initial: null,
    joint_applicant__last_name: null,
    joint_applicant__annual_income: null,
    joint_applicant__date_of_birth: null,
    joint_applicant__email_address: null,
    joint_applicant__employer_name: null,
    joint_applicant__home_phone: null,
    joint_applicant__housing_status: null,
    joint_applicant__mobile_phone: null,
    joint_applicant__ssn: null,
    joint_applicant__work_phone: null,
    joint_applicant__address__address_line_1: null,
    joint_applicant__address__address_line_2: null,
    joint_applicant__address__city: null,
    joint_applicant__address__postal_code: null,
    joint_applicant__address__state_code: null,
});
export type FinancingApplicationFields = t.TypeOf<
    typeof FinancingApplicationFields
>;

/**
 * Financing Credit Line Application
 */
export const FinancingApplication = t.record(
    FinancingApplicationFields,
    t.string,
);
export type FinancingApplication = t.TypeOf<typeof FinancingApplication>;

/**
 * Financing Credit Line Application Error Response
 */
export const FinancingApplicationErrorSet = GenericErrorSet(
    FinancingApplicationFields,
);
export type FinancingApplicationErrorSet = t.TypeOf<
    typeof FinancingApplicationErrorSet
>;

/**
 * Interface for submitting data to the Financing Apply API
 */
const FinancingApplicationAddress = t.type({
    address_line_1: t.string,
    address_line_2: nullable(t.string),
    city: t.string,
    state_code: t.string,
    postal_code: t.string,
});

const FinancingApplicationApplicant = t.type({
    first_name: t.string,
    middle_initial: nullable(t.string),
    last_name: t.string,
    date_of_birth: t.string,
    ssn: t.string,
    annual_income: t.number,
    email_address: nullable(t.string),
    home_phone: t.string,
    mobile_phone: nullable(t.string),
    work_phone: nullable(t.string),
    employer_name: nullable(t.string),
    housing_status: nullable(t.string),
    address: FinancingApplicationAddress,
});

export const FinancingApplicationPrecursor = t.intersection([
    t.type({
        backend_type: TBackendType,
    }),
    t.partial({
        reservation_number: nullable(t.string),
        application_id: nullable(t.string),
    }),
]);
export type FinancingApplicationPrecursor = t.TypeOf<
    typeof FinancingApplicationPrecursor
>;

export const FinancingApplicationSubmitData = t.intersection([
    t.type({
        precursors: t.array(FinancingApplicationPrecursor),
        main_applicant: FinancingApplicationApplicant,
        joint_applicant: nullable(FinancingApplicationApplicant),
    }),
    t.partial({
        requested_credit_limit: nullable(t.number),
        language_preference: nullable(t.string),
        salesperson: nullable(t.string),
        application_source: t.string,
    }),
]);
export type FinancingApplicationSubmitData = t.TypeOf<
    typeof FinancingApplicationSubmitData
>;

/**
 * Financing Plan
 */
export const FinancingPlan = t.type({
    id: t.number,
    plan_number: t.number,
    description: t.string,
    fine_print_superscript: t.string,
    allow_credit_application: t.boolean,
    apr: APR,
    term_months: Months,
    product_price_threshold: t.string,
});
export type FinancingPlan = t.TypeOf<typeof FinancingPlan>;

export const FinancingPlans = t.array(FinancingPlan);
export type FinancingPlans = t.TypeOf<typeof FinancingPlans>;

/**
 * Response from the estimated payment API endpoint
 */
export const EstimatedPaymentResponse = t.type({
    plan: FinancingPlan,
    principal: DineroFromString,
    monthly_payment: DineroFromString,
    loan_cost: DineroFromString,
});
export type EstimatedPaymentResponse = t.TypeOf<
    typeof EstimatedPaymentResponse
>;

/**
 * FinancingAccountInquiry
 */
export const FinancingAccountInquiry = t.type({
    account_number: t.string,
    credit_limit: t.string,
    available_credit: t.string,
});
export type FinancingAccountInquiry = t.TypeOf<typeof FinancingAccountInquiry>;

export const MaybeFinancingAccountInquiry = nullable(FinancingAccountInquiry);
export type MaybeFinancingAccountInquiry = t.TypeOf<
    typeof MaybeFinancingAccountInquiry
>;

/**
 * FinancingDocument
 */
export const FinancingDocument = t.type({
    document_type: TFinancingDocumentType,
    formats: t.record(TFinancingDocumentFormat, WebPageURL),
});
export type FinancingDocument = t.TypeOf<typeof FinancingDocument>;

/**
 * FinancingPreQualRequest
 */
export const FinancingPreQualRequest = t.intersection([
    t.type({
        first_name: t.string,
        last_name: t.string,
        line1: t.string,
        city: t.string,
        state: t.string,
        postcode: t.string,
        email: t.string,
        phone: t.string,
    }),
    t.partial({
        middle_initial: t.string,
        line2: t.string,
        customer_initiated: t.boolean,
        last_four_ssn: t.string,
    }),
]);
export type FinancingPreQualRequest = t.TypeOf<typeof FinancingPreQualRequest>;

/**
 * FinancingPreQualResponse
 */
export const FinancingPreQualResponse = t.type({
    id: t.number,
    backend_type: TBackendType,
    status: TPreQualStatus,
    is_approved: t.boolean,
    message: t.string,
    offer_indicator: t.string,
    response_id: t.string,
    credit_limit: t.string,
    apr: APR,
    intro_duration: nullable(Months),
    full_duration: nullable(Months),
    documents: t.array(FinancingDocument),
    created_datetime: t.string,
    modified_datetime: t.string,
    customer_response: TPreQualCustomerResponse,
});
export type FinancingPreQualResponse = t.TypeOf<
    typeof FinancingPreQualResponse
>;

export const MaybeFinancingPreQualResponse = nullable(FinancingPreQualResponse);
export type MaybeFinancingPreQualResponse = t.TypeOf<
    typeof MaybeFinancingPreQualResponse
>;

/**
 * Pre-Qualification request with set of responses
 */
export const FinancingPreQualRequestResponse = t.type({
    id: t.number,
    created_datetime: t.string,
    modified_datetime: t.string,

    first_name: t.string,
    middle_initial: nullable(t.string),
    last_name: t.string,
    line1: t.string,
    line2: nullable(t.string),
    city: t.string,
    state: t.string,
    postcode: t.string,
    email: nullable(t.string),
    phone: t.string,
    ip_address: t.string,
    uuid: t.string,
    entry_point: t.string,

    responses: t.array(FinancingPreQualResponse),

    customer_initiated: t.boolean,
});
export type FinancingPreQualRequestResponse = t.TypeOf<
    typeof FinancingPreQualRequestResponse
>;

/**
 * CreditApplicationSubmissionResult
 */
export const CreditApplicationSubmissionResult = t.type({
    id: t.number,
    backend_type: TBackendType,
    documents: t.array(FinancingDocument),
});
export type CreditApplicationSubmissionResult = t.TypeOf<
    typeof CreditApplicationSubmissionResult
>;

/**
 * FinancingCreditAppSubmitResult_Approved
 */
export const FinancingCreditAppSubmitResult_Approved = t.type({
    _tag: t.literal("CreditAppSubmitResult_Approved"),
    result: CreditApplicationSubmissionResult,
    inquiry: FinancingAccountInquiry,
});
export type FinancingCreditAppSubmitResult_Approved = t.TypeOf<
    typeof FinancingCreditAppSubmitResult_Approved
>;

/**
 * FinancingCreditAppSubmitResult_Denied
 */
export const FinancingCreditAppSubmitResult_Denied = t.type({
    _tag: t.literal("CreditAppSubmitResult_Denied"),
    message: t.string,
    result: CreditApplicationSubmissionResult,
});
export type FinancingCreditAppSubmitResult_Denied = t.TypeOf<
    typeof FinancingCreditAppSubmitResult_Denied
>;

/**
 * FinancingCreditAppSubmitResult_Pending
 */
export const FinancingCreditAppSubmitResult_Pending = t.type({
    _tag: t.literal("CreditAppSubmitResult_Pending"),
    message: t.string,
});
export type FinancingCreditAppSubmitResult_Pending = t.TypeOf<
    typeof FinancingCreditAppSubmitResult_Pending
>;

/**
 * FinancingCreditAppSubmitResult_NextBackend
 */
export const FinancingCreditAppSubmitResult_NextBackend = t.type({
    _tag: t.literal("CreditAppSubmitResult_NextBackend"),
    prequal: FinancingPreQualRequestResponse,
});
export type FinancingCreditAppSubmitResult_NextBackend = t.TypeOf<
    typeof FinancingCreditAppSubmitResult_NextBackend
>;

/**
 * FinancingCreditAppSubmitResult_ExistingCardholder
 */
export const FinancingCreditAppSubmitResult_ExistingCardholder = t.type({
    _tag: t.literal("CreditAppSubmitResult_ExistingCardholder"),
});
export type FinancingCreditAppSubmitResult_ExistingCardholder = t.TypeOf<
    typeof FinancingCreditAppSubmitResult_ExistingCardholder
>;

/**
 * FinancingCreditAppSubmitResult
 */
export const FinancingCreditAppSubmitResult = t.union([
    FinancingCreditAppSubmitResult_Approved,
    FinancingCreditAppSubmitResult_Denied,
    FinancingCreditAppSubmitResult_Pending,
    FinancingCreditAppSubmitResult_NextBackend,
    FinancingCreditAppSubmitResult_ExistingCardholder,
]);
export type FinancingCreditAppSubmitResult = t.TypeOf<
    typeof FinancingCreditAppSubmitResult
>;
