import React from "react";
import { connect } from "react-redux";
import { Key } from "ts-key-enum";

import { TStateMapper } from "../../reducers.interfaces";
import { defaultState } from "../defaults";

interface IOwnProps {
    onLoadMore: () => void;
}

interface IReduxProps {
    hasMore: boolean;
}

interface IProps extends IOwnProps, IReduxProps {}

interface IState {}

class LoadMoreButtonComponent extends React.PureComponent<IProps, IState> {
    protected readonly onKeyPressHandler = (event: React.KeyboardEvent) => {
        if (!this.props.onLoadMore) {
            return null;
        }
        if (event.key === Key.Enter) {
            this.props.onLoadMore();
        }
        return null;
    };

    render() {
        if (!this.props.hasMore) {
            return null;
        }
        return (
            <div
                role="button"
                tabIndex={0}
                className="customer-review__footer"
                onClick={this.props.onLoadMore}
                onKeyPress={this.onKeyPressHandler}
            >
                Load More Reviews
                <span className="customer-review__footer__icon"></span>
            </div>
        );
    }
}

const mapStateToProps: TStateMapper<"reviews", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    const state = rootState.reviews || defaultState;
    return {
        hasMore: state.ui.hasMore,
        ...ownProps,
    };
};

export const LoadMoreButton = connect(mapStateToProps)(LoadMoreButtonComponent);
