import * as t from "io-ts";
import { NumberFromString } from "io-ts-types/lib/NumberFromString";
import { fromNewtype } from "io-ts-types/lib/fromNewtype";
import { Newtype, iso } from "newtype-ts";

// ============================================================================
// Coordinates & Distances
// ============================================================================
export type ILatitude = Newtype<{ readonly ILatitude: unique symbol }, number>;
export const Latitude = fromNewtype<ILatitude>(t.number);
export const LatitudeFromString = fromNewtype<ILatitude>(NumberFromString);
export const isoLatitude = iso<ILatitude>();

export type ILongitude = Newtype<
    { readonly ILongitude: unique symbol },
    number
>;
export const Longitude = fromNewtype<ILongitude>(t.number);
export const LongitudeFromString = fromNewtype<ILongitude>(NumberFromString);
export const isoLongitude = iso<ILongitude>();

export type IMeters = Newtype<{ readonly IMeters: unique symbol }, number>;
export const Meters = fromNewtype<IMeters>(t.number);
export const isoMeters = iso<IMeters>();

export type IMiles = Newtype<{ readonly IMiles: unique symbol }, number>;
export const Miles = fromNewtype<IMiles>(t.number);
export const isoMiles = iso<IMiles>();

export type IMonths = Newtype<{ readonly IMonths: unique symbol }, number>;
export const Months = fromNewtype<IMonths>(t.number);
export const isoMonths = iso<IMonths>();

// ============================================================================
// Financing
// ============================================================================
/**
 * Annual Percentage Rate (APR)
 */
export type IAPR = Newtype<{ readonly IAPR: unique symbol }, string>;
export const APR = fromNewtype<IAPR>(t.string);
export const isoAPR = iso<IAPR>();

// ============================================================================
// Retail Locations
// ============================================================================
export type IRetailLocatorStoreID = Newtype<
    { readonly IRetailLocatorStoreID: unique symbol },
    number
>;
export const RetailLocatorStoreID = fromNewtype<IRetailLocatorStoreID>(
    t.number,
);
export const isoRetailLocatorStoreID = iso<IRetailLocatorStoreID>();

export type ISyncStoreID = Newtype<
    { readonly ISyncStoreID: unique symbol },
    number
>;
export const SyncStoreID = fromNewtype<ISyncStoreID>(t.number);
export const isoSyncStoreID = iso<ISyncStoreID>();

// ============================================================================
// Reviews API
// ============================================================================
export type IReviewsBrandID = Newtype<
    { readonly IReviewsBrandID: unique symbol },
    number
>;
export const ReviewsBrandID = fromNewtype<IReviewsBrandID>(t.number);
export const isoReviewsBrandID = iso<IReviewsBrandID>();

export type IReviewsProductTypeID = Newtype<
    { readonly IReviewsProductTypeID: unique symbol },
    number
>;
export const ReviewsProductTypeID = fromNewtype<IReviewsProductTypeID>(
    t.number,
);
export const isoReviewsProductTypeID = iso<IReviewsProductTypeID>();

export type IReviewsProductID = Newtype<
    { readonly IReviewsProductID: unique symbol },
    number
>;
export const ReviewsProductID = fromNewtype<IReviewsProductID>(t.number);
export const isoReviewsProductID = iso<IReviewsProductID>();

export type IReviewsProductVariantID = Newtype<
    { readonly IReviewsProductVariantID: unique symbol },
    number
>;
export const ReviewsProductVariantID = fromNewtype<IReviewsProductVariantID>(
    t.number,
);
export const isoReviewsProductVariantID = iso<IReviewsProductVariantID>();

// ============================================================================
// Product Catalogue
// ============================================================================
export type IProductID = Newtype<
    { readonly IProductID: unique symbol },
    number
>;
export const ProductID = fromNewtype<IProductID>(t.number);
export const isoProductID = iso<IProductID>();
export const ProductIDs = t.array(ProductID);

export type IProductUUID = Newtype<
    { readonly IProductUUID: unique symbol },
    string
>;
export const ProductUUID = fromNewtype<IProductUUID>(t.string);
export const isoProductUUID = iso<IProductUUID>();

export type IFormUUID = Newtype<{ readonly IFormUUID: unique symbol }, string>;
export const FormUUID = fromNewtype<IFormUUID>(t.string);
export const isoFormUUID = iso<IFormUUID>();

export type IProductUPC = Newtype<
    { readonly IProductUPC: unique symbol },
    string
>;
export const ProductUPC = fromNewtype<IProductUPC>(t.string);
export const isoProductUPC = iso<IProductUPC>();

export type IProductSKU = Newtype<
    { readonly IProductSKU: unique symbol },
    string
>;
export const ProductSKU = fromNewtype<IProductSKU>(t.string);
export const isoProductSKU = iso<IProductSKU>();

export type IProductAPIURL = Newtype<
    { readonly IProductAPIURL: unique symbol },
    string
>;
export const ProductAPIURL = fromNewtype<IProductAPIURL>(t.string);
export const isoProductAPIURL = iso<IProductAPIURL>();

export type IProductClassAPIURL = Newtype<
    { readonly IProductClassAPIURL: unique symbol },
    string
>;

export type IProductListAPIURL = Newtype<
    { readonly IProductListAPIURL: unique symbol },
    string
>;
export const ProductListAPIURL = fromNewtype<IProductListAPIURL>(t.string);
export const isoProductListAPIURL = iso<IProductListAPIURL>();

export const ProductClassAPIURL = fromNewtype<IProductClassAPIURL>(t.string);
export const isoProductClassAPIURL = iso<IProductClassAPIURL>();

export type IProductCategoryID = Newtype<
    { readonly IProductCategoryID: unique symbol },
    number
>;
export const ProductCategoryID = fromNewtype<IProductCategoryID>(t.number);
export const isoProductCategoryID = iso<IProductCategoryID>();

export type IProductCategoryAPIURL = Newtype<
    { readonly IProductCategoryAPIURL: unique symbol },
    string
>;
export const ProductCategoryAPIURL = fromNewtype<IProductCategoryAPIURL>(
    t.string,
);
export const isoProductCategoryAPIURL = iso<IProductCategoryAPIURL>();

export type IProductImageID = Newtype<
    { readonly IProductImageID: unique symbol },
    number
>;
export const ProductImageID = fromNewtype<IProductImageID>(t.number);
export const isoProductImageID = iso<IProductImageID>();

export type IProductBundlesListAPIURL = Newtype<
    { readonly IProductBundlesListAPIURL: unique symbol },
    string
>;
export const ProductBundlesListAPIURL = fromNewtype<IProductBundlesListAPIURL>(
    t.string,
);
export const isoProductBundlesListAPIURL = iso<IProductBundlesListAPIURL>();

// ============================================================================
// Baskets
// ============================================================================
export type IBasketLineAPIURL = Newtype<
    { readonly IBasketLineAPIURL: unique symbol },
    string
>;
export const BasketLineAPIURL = fromNewtype<IBasketLineAPIURL>(t.string);
export const isoBasketLineAPIURL = iso<IBasketLineAPIURL>();

// ============================================================================
// Orders
// ============================================================================
export type IOrderID = Newtype<{ readonly IOrderID: unique symbol }, number>;
export const OrderID = fromNewtype<IOrderID>(t.number);
export const isoOrderID = iso<IOrderID>();

export type IOrderNumber = Newtype<
    { readonly IOrderNumber: unique symbol },
    string
>;
export const OrderNumber = fromNewtype<IOrderNumber>(t.string);
export const isoOrderNumber = iso<IOrderNumber>();

export type IOrderAPIURL = Newtype<
    { readonly IOrderAPIURL: unique symbol },
    string
>;
export const OrderAPIURL = fromNewtype<IOrderAPIURL>(t.string);
export const isoOrderAPIURL = iso<IOrderAPIURL>();

// ============================================================================
// Convey
// ============================================================================
export type IDeliveryDatePredictionID = Newtype<
    { readonly IDeliveryDatePredictionID: unique symbol },
    string
>;
export const DeliveryDatePredictionID = fromNewtype<IDeliveryDatePredictionID>(
    t.string,
);
export const isoDeliveryDatePredictionID = iso<IDeliveryDatePredictionID>();

// ============================================================================
// CMS Pages & Content
// ============================================================================
export type ISafeHTML = Newtype<{ readonly ISafeHTML: unique symbol }, string>;
export const SafeHTML = fromNewtype<ISafeHTML>(t.string);
export const isoSafeHTML = iso<ISafeHTML>();

export type IImageURL = Newtype<{ readonly IImageURL: unique symbol }, string>;
export const ImageURL = fromNewtype<IImageURL>(t.string);
export const isoImageURL = iso<IImageURL>();

export type IWebPageURL = Newtype<
    { readonly IWebPageURL: unique symbol },
    string
>;
export const WebPageURL = fromNewtype<IWebPageURL>(t.string);
export const isoWebPageURL = iso<IWebPageURL>();

export type IWagtailPageID = Newtype<
    { readonly IWagtailPageID: unique symbol },
    number
>;
export const WagtailPageID = fromNewtype<IWagtailPageID>(t.number);
export const isoWagtailPageID = iso<IWagtailPageID>();

export type IWagtailImageID = Newtype<
    { readonly IWagtailImageID: unique symbol },
    number
>;
export const WagtailImageID = fromNewtype<IWagtailImageID>(t.number);
export const isoWagtailImageID = iso<IWagtailImageID>();
