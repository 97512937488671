import classNames from "classnames";
import React from "react";
import { t } from "ttag";

import { interpolate } from "@thelabnyc/thelabui/src/utils/i18n";

import { Flair } from "../../../common/Flair";
import { Image } from "../../../common/Image";
import { Link } from "../../../common/Link";
import { RatingGraphic } from "../../../common/RatingGraphic";
import { RichText } from "../../../common/RichText";
import { ProductCompareTheme } from "../../../constants";
import { IProduct } from "../../../models/catalogue.interfaces";
import { IProductID } from "../../../models/nominals";
import {
    ICompareTile,
    IProductFlair,
} from "../../../models/product-compare.interfaces";
import { getShopNowURL } from "../selectors";

import styles from "./ProductCompareTileHeader.module.scss";

interface IProps {
    data: ICompareTile;
    selectedVariant: IProduct | null;
    pageRootProduct?: IProductID | null;
    theme?: ProductCompareTheme | null;
    showDescription?: boolean;
    showRating?: boolean;
}

interface IState {}

export class ProductCompareTileHeader extends React.Component<IProps, IState> {
    private getVariantTitle() {
        return this.props.selectedVariant
            ? this.props.selectedVariant.title
            : this.props.data.rootProducts[0].title;
    }

    private getVariantURL() {
        return this.props.selectedVariant?.link
            ? this.props.selectedVariant?.link
            : this.props.data.rootProducts[0].link;
    }

    private get numReviews() {
        return this.props.data.rootProducts.reduce(
            (memo, rp) => memo + rp.num_reviews,
            0,
        );
    }

    private get avgRating() {
        const total = this.props.data.rootProducts.reduce(
            (memo, rp) => memo + (rp.rating || 0),
            0,
        );
        const products = this.props.data.rootProducts.filter(
            (rp) => rp.rating !== null,
        );

        return total / products.length;
    }

    private buildRating() {
        if (!this.props.showRating) {
            return null;
        }
        if (!this.props.selectedVariant) {
            return null;
        }
        if (this.numReviews < 1) {
            return null;
        }
        if (this.props.theme === ProductCompareTheme.SELECT) {
            return (
                <div className={styles.reviewLink}>
                    <RatingGraphic
                        cardClass={styles.starRating}
                        cardSize="large"
                        numReviews={this.numReviews}
                        rating={this.avgRating}
                    />
                </div>
            );
        }
        return (
            <a
                className={styles.reviewLink}
                href={`${this.getVariantURL()}#reviews__body`}
                title={t`Read Reviews`}
            >
                <RatingGraphic
                    cardClass={styles.starRating}
                    cardSize="large"
                    numReviews={this.numReviews}
                    rating={this.avgRating}
                />
            </a>
        );
    }

    private renderHeader() {
        const showSelectedFlair =
            this.props.theme === ProductCompareTheme.SELECT
                ? this.props.data.product_compare_tile.product_id ===
                  this.props.pageRootProduct
                : false;
        const selectedFlairData = {
            content: "SELECTED",
            style: "flair--selected",
        };
        const selectedFlair = <Flair flair={selectedFlairData} />;
        if (this.props.theme === ProductCompareTheme.SELECT) {
            return (
                <header className={styles.root}>
                    {showSelectedFlair && selectedFlair}
                    <Image
                        src={this.props.data.product_compare_tile.image.url}
                        alt={this.props.data.product_compare_tile.image.title}
                    />
                    {this.props.data.secondary_flair && (
                        <Flair flair={this.props.data.secondary_flair} />
                    )}
                    <RichText
                        html={this.props.data.product_compare_tile.title}
                        className={`title ${styles.richText}`}
                    />
                </header>
            );
        }
        const rootClasses = classNames({
            [styles.root]: true,
            [this.props.theme || ""]: true,
        });
        const showSecondFlairBelowRating =
            this.props.theme &&
            [
                ProductCompareTheme.STEP_UP,
                ProductCompareTheme.THEME_ONE,
            ].includes(this.props.theme);

        return (
            <header className={rootClasses}>
                <Link
                    href={getShopNowURL(
                        this.props.data,
                        this.props.selectedVariant,
                    )}
                    title={interpolate(t`Learn more about %s`, [
                        this.getVariantTitle(),
                    ])}
                >
                    {showSelectedFlair && selectedFlair}
                    {/* if it is a linked image, set alt attr as it describes the function of the link */}
                    <Image
                        src={this.props.data.product_compare_tile.image.url}
                        alt={interpolate(t`Learn more about %s`, [
                            this.getVariantTitle(),
                        ])}
                    />
                    {this.props.data.secondary_flair &&
                        !showSecondFlairBelowRating && (
                            <div className="secondary-flair">
                                <Flair
                                    flair={this.props.data.secondary_flair}
                                />
                            </div>
                        )}
                    <RichText
                        html={this.props.data.product_compare_tile.title}
                        className={`title ${styles.richText} ${this.props.theme}`}
                    />
                </Link>
                {this.buildRating()}
                {this.props.data.secondary_flair &&
                    showSecondFlairBelowRating && (
                        <div className="secondary-flair">
                            <Flair flair={this.props.data.secondary_flair} />
                        </div>
                    )}
            </header>
        );
    }

    private renderPrimaryFlair(flair: IProductFlair) {
        if (this.props.theme === ProductCompareTheme.STEP_UP) {
            return (
                <Link
                    href={getShopNowURL(
                        this.props.data,
                        this.props.selectedVariant,
                    )}
                    title={interpolate(t`Learn more about %s`, [
                        this.getVariantTitle(),
                    ])}
                >
                    <div className="primary-flair">
                        <Flair flair={flair} />
                    </div>
                </Link>
            );
        }

        return <Flair flair={flair} />;
    }

    render() {
        const descriptionClasses = classNames({
            [styles.description]: true,
            [styles.descriptionThemeStepUp]:
                this.props.theme === ProductCompareTheme.STEP_UP,
            [styles.descriptionThemeSelect]:
                this.props.theme === ProductCompareTheme.SELECT,
            [styles.descriptionThemeOne]:
                this.props.theme === ProductCompareTheme.THEME_ONE,
        });

        return (
            <>
                {this.props.data.flair &&
                    this.renderPrimaryFlair(this.props.data.flair)}
                {this.renderHeader()}
                {this.props.showDescription && (
                    <RichText
                        className={descriptionClasses}
                        html={
                            this.props.data.product_compare_tile
                                .short_description
                        }
                        inline={true}
                    />
                )}
            </>
        );
    }
}
