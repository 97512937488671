import * as t from "io-ts";

import { BasketLineAPIURL } from "./nominals";
import { Price } from "./prices";

export const ShippingMethodType = t.keyof({
    "small-package": null,
    "freight": null,
});

export const ShippingMethodLine = t.interface({
    charge_order: t.string,
    charge_total: t.string,
    method: t.interface({
        id: t.number,
        name: t.string,
        description: t.string,
        type: ShippingMethodType,
    }),
    line_url: BasketLineAPIURL,
    charge_line: t.string,
});

export const ShippingMethod = t.interface({
    code: t.string,
    name: t.string,
    selected: t.boolean,
    price: Price,
    price_before_discount: Price,
    lines: t.array(ShippingMethodLine),
});
export const ShippingMethods = t.array(ShippingMethod);

export const ProductShippingMethod = t.interface({
    name: t.string,
    description: t.string,
    type: ShippingMethodType,
});
export const ProductShippingMethods = t.array(ProductShippingMethod);
