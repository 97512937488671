import React from "react";

import {
    IOptionCode,
    IOptionSingleValue,
} from "../models/catalogue.interfaces";
import { SelectOptions } from "./SelectOptions";

interface IProps {
    code: IOptionCode;
    value: IOptionSingleValue;
    options: IOptionSingleValue[];
    prefixNameCapped: string;
    configuratorClass: string;
    prefixClass: string;
    selectClass: string;
    onChange: (event: React.FormEvent<HTMLSelectElement>) => void;
}

interface IState {}

export class ProductOptionClassicSelector extends React.PureComponent<
    IProps,
    IState
> {
    render() {
        if (this.props.options.length === 1) {
            return (
                <div
                    key={this.props.code}
                    className={this.props.configuratorClass}
                >
                    <span>
                        {this.props.prefixNameCapped}: {this.props.value}
                    </span>
                </div>
            );
        }
        return (
            <div key={this.props.code} className={this.props.configuratorClass}>
                <label
                    htmlFor={this.props.code}
                    className={this.props.prefixClass}
                >
                    {this.props.prefixNameCapped}
                </label>
                <select
                    value={this.props.value}
                    name={this.props.code}
                    onChange={this.props.onChange}
                    className={this.props.selectClass}
                >
                    <optgroup label={this.props.prefixNameCapped}>
                        <SelectOptions options={this.props.options} />
                    </optgroup>
                </select>
            </div>
        );
    }
}
