import React from "react";
import { t } from "ttag";

import {
    IAddon,
    IConcreteBundle,
    IProduct,
} from "../../../models/catalogue.interfaces";
import { IProductID } from "../../../models/nominals";
import { getUpgradeID } from "../../configurator/selectors";

interface IProps {
    product: IProduct;
    selectedUpgrades: IAddon[];
    isSelected: boolean;
    pageRootProduct: IProductID | null;
    onSelectionChange: (
        updates: Array<{ productID: IProductID; quantity: number }>,
    ) => void;
    upgradeBundles: IConcreteBundle[];
}

interface IState {}

export class ProductCompareUpgradeTile extends React.Component<IProps, IState> {
    private readonly onButtonClick = (
        product: IProduct,
        event: React.MouseEvent<HTMLButtonElement>,
    ) => {
        event.preventDefault();
        const upgradeID = getUpgradeID(product);

        // Select / deselect this product
        const updates = [
            {
                productID: upgradeID,
                quantity: this.props.selectedUpgrades.length > 0 ? 0 : 1,
            },
        ];

        // Send change batch
        this.props.onSelectionChange(updates);
    };

    private buildUpgradeButton(bundle: IConcreteBundle) {
        return bundle.suggested_products.map((product) => {
            const buttonText = this.props.isSelected
                ? t`Remove Upgrade`
                : t`Add Upgrade`;
            return (
                <button
                    key={`${bundle.id}-${product.id}`}
                    onClick={this.onButtonClick.bind(this, product)}
                >
                    {buttonText}
                </button>
            );
        });
    }

    render() {
        const isRootProduct =
            this.props.product.id === this.props.pageRootProduct;
        if (!isRootProduct) {
            return null;
        }
        return (
            <>
                {this.props.upgradeBundles.map((bundle) => (
                    <React.Fragment key={bundle.id}>
                        {this.buildUpgradeButton(bundle)}
                    </React.Fragment>
                ))}
            </>
        );
    }
}
