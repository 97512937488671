import { ResizeObserver } from "@juggle/resize-observer";
import React from "react";

export type OnResizeHandler = () => void;

interface IProps {
    onResize: OnResizeHandler;
    children?: React.ReactNode;
}

interface IState {}

export class ChildrenResizeSensor extends React.Component<IProps, IState> {
    private _wrapperElem: HTMLDivElement | null = null;

    private readonly resizeObserver = new ResizeObserver((_entries) => {
        this.props.onResize();
    });

    private set wrapperElem(elem: HTMLDivElement | null) {
        if (this._wrapperElem) {
            this.resizeObserver.unobserve(this._wrapperElem);
        }
        if (elem) {
            this.resizeObserver.observe(elem);
        }
        this._wrapperElem = elem;
    }

    private get wrapperElem() {
        return this._wrapperElem;
    }

    render() {
        return (
            <div
                ref={(elem) => {
                    this.wrapperElem = elem;
                }}
            >
                {this.props.children}
            </div>
        );
    }
}
