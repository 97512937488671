import classNames from "classnames";
import React from "react";
import { connect } from "react-redux";

import {
    IReviewsProductTypeID,
    isoReviewsProductTypeID,
} from "../../../models/nominals";
import {
    IReviewQueryFacets,
    ISearchFacetOption,
} from "../../../models/reviews.interfaces";
import { TDispatchMapper, TStateMapper } from "../../reducers.interfaces";
import { defaultState } from "../defaults";
import { Dispatchers } from "../dispatchers";
import { FilterOptionCollection } from "./FilterOptionCollection";

import styles from "./ProductTypeFilter.module.scss";

export interface IFacetCollection {
    name: string | null;
    options: ISearchFacetOption[];
}

interface IOwnProps {
    facetType: IReviewQueryFacets;
    productTypeID: IReviewsProductTypeID;
    name: string;
    collections: IFacetCollection[];
    isCarouselComponentType: boolean;
    isCollapsedView?: boolean;
    isCondensedView?: boolean;
}

interface IReduxProps {
    isOpen: boolean;
}

interface IDispatchProps {
    updateProductTypes: Dispatchers["updateProductTypes"];
}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

interface IState {}

class ProductTypeFilterComponent extends React.PureComponent<IProps, IState> {
    private readonly onChange = (e: React.FormEvent<HTMLInputElement>) => {
        this.props.updateProductTypes(
            this.props.productTypeID,
            e.currentTarget.checked,
        );
    };

    render() {
        const parentClasses = classNames({
            [styles.filterTitle]: true,
            [styles.filterTitleParent]: true,
            [styles.filterTitleParentOpen]: this.props.isOpen,
            [styles.filterTitleCollapsed]: this.props.isCollapsedView,
            "customer-review__side-filters__filter-title--collapsed":
                this.props.isCollapsedView,
        });
        const inputID =
            `product-type-${this.props.productTypeID}`.toLowerCase();
        return (
            <div>
                {!this.props.isCarouselComponentType && (
                    <h3 className={parentClasses}>
                        {!this.props.isCollapsedView && (
                            <input
                                id={inputID}
                                type="checkbox"
                                className={styles.checkbox}
                                value={isoReviewsProductTypeID.unwrap(
                                    this.props.productTypeID,
                                )}
                                checked={this.props.isOpen}
                                onChange={this.onChange}
                            />
                        )}
                        <label htmlFor={inputID}>{this.props.name}</label>
                    </h3>
                )}
                {this.props.collections.map((collection, i) => (
                    <FilterOptionCollection
                        key={i}
                        facetType={this.props.facetType}
                        name={collection.name}
                        options={collection.options}
                        isOpen={this.props.isOpen}
                        isCarouselComponentType={
                            this.props.isCarouselComponentType
                        }
                        isCollapsedView={this.props.isCollapsedView}
                        isCondensedView={this.props.isCondensedView}
                    />
                ))}
            </div>
        );
    }
}

const mapStateToProps: TStateMapper<"reviews", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    const state = rootState.reviews || defaultState;
    return {
        isOpen: state.ui.productTypeIDs.includes(ownProps.productTypeID),
        ...ownProps,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const dispatchers = new Dispatchers(dispatch);
    return {
        updateProductTypes: dispatchers.updateProductTypes,
    };
};

export const ProductTypeFilter = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ProductTypeFilterComponent);
