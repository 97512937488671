import * as Popover from "@radix-ui/react-popover";
import classNames from "classnames";
import React from "react";
import SVG from "react-inlinesvg";
import { t } from "ttag";

import iconXClose from "../../img/icons/x-close.svg";
import { IBaseComponent } from "../models/formFields.interfaces";
import { notEmpty } from "../utils/functional";

import styles from "./AbstractFormComponent.module.scss";

export interface IFormComponentState {}

export abstract class AbstractFormComponent<
    IProps extends IBaseComponent,
    IState extends IFormComponentState,
> extends React.Component<IProps, IState> {
    protected showValidationErrors() {
        const hasErrors = this.props.errors && this.props.errors.length > 0;
        const showErrors =
            this.props.showErrorMessages ||
            this.props.showErrorMessages === undefined;
        return hasErrors && showErrors;
    }

    protected getErrorListElemID() {
        return `${this.props.id}_errors`;
    }

    protected getDescribedByElemID() {
        return `${this.props.id}_describedby`;
    }

    protected buildHelpText() {
        if (!this.props.helpText) {
            return;
        }
        const helpTextClasses = classNames({
            "form__help-text": true,
            [`${this.props.helpTextClass}`]: !!this.props.helpTextClass,
        });

        return (
            <p
                className={helpTextClasses}
                id={this.getDescribedByElemID()}
                dangerouslySetInnerHTML={{ __html: this.props.helpText }}
            ></p>
        );
    }

    protected buildTooltip() {
        if (!this.props.tooltipText) {
            return;
        }
        const tooltipTriggerClasses = classNames({
            [styles.trigger]: true,
            [`${this.props.tooltipClass}`]: true,
        });
        return (
            <Popover.Root modal={true}>
                <Popover.Trigger asChild>
                    <button
                        aria-label={this.props.tooltipLink}
                        className={tooltipTriggerClasses}
                    >
                        {this.props.tooltipLink}
                    </button>
                </Popover.Trigger>
                <Popover.Portal>
                    <Popover.Content
                        className={styles.container}
                        sideOffset={5}
                        style={{ zIndex: "30000" }}
                    >
                        <span className={styles.content}>
                            {this.props.tooltipText}
                        </span>
                        <Popover.Close
                            className={styles.closeButton}
                            aria-label={t`Close`}
                        >
                            <SVG src={iconXClose} aria-hidden="true" />
                        </Popover.Close>
                        <Popover.Arrow />
                    </Popover.Content>
                </Popover.Portal>
            </Popover.Root>
        );
    }

    protected buildContent(): JSX.Element | null {
        return null;
    }

    protected buildValidationError() {
        if (!this.props.errors || !this.showValidationErrors()) {
            return;
        }
        const errors = this.props.errors.filter(notEmpty).map((msg) => {
            return (
                <li
                    className="form__error"
                    key={msg}
                    dangerouslySetInnerHTML={{ __html: msg }}
                ></li>
            );
        });
        return (
            <ul id={this.getErrorListElemID()} className="form__error-list">
                {errors}
            </ul>
        );
    }

    protected buildWarning() {
        if (!this.props.warningTitle || !this.props.warningText) {
            return;
        }
        return (
            <div className={styles.warning}>
                <p className={styles.warningCopy}>
                    <strong>{this.props.warningTitle}</strong>
                    <br />
                    {this.props.warningText}
                </p>
            </div>
        );
    }

    public render() {
        const classes = classNames({
            "form__field": true,
            [`form__field--${this.props.name}`]: !!this.props.name,
            "form__field--has-errors": this.showValidationErrors(),
        });
        return (
            <div className={classes}>
                {this.buildTooltip()}
                {this.buildContent()}
                {this.buildValidationError()}
                {this.buildHelpText()}
                {this.buildWarning()}
            </div>
        );
    }
}
