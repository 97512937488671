import * as t from "io-ts";
import { fromNewtype } from "io-ts-types/lib/fromNewtype";
import { Newtype, iso } from "newtype-ts";

import { ProductCompareTheme } from "../constants";
import { Product } from "./catalogue";
import { ISafeHTML, ProductCategoryID, ProductID, SafeHTML } from "./nominals";
import {
    BackgroundStylesSubBlock,
    ImageChooserBlock,
    PageChooserBlock,
    SpacingSubBlock,
} from "./streamfield-blocks";
import { codecFromEnum, nullable } from "./utils";

export type ITileID = Newtype<{ readonly ITileID: unique symbol }, number>;
export const TileID = fromNewtype<ITileID>(t.number);
export const isoTileID = iso<ITileID>();

export const AttributeOptionGroup = t.interface({
    name: t.string,
    options: t.array(t.string),
});

export const AttributeOption = t.interface({
    group_id: t.number,
    id: t.number,
    option: t.string,
});

export const AttributeDescription = t.interface({
    description: t.string,
    icon: nullable(ImageChooserBlock),
});

export const ProductFlair = t.partial({
    content: t.union([nullable(t.string), nullable(SafeHTML)]),
    style: nullable(t.string),
});

export const ChildFilter = t.interface({
    option_group: t.interface({
        id: t.number,
        code: t.string,
    }),
    behavior: t.string,
    variants: t.array(
        t.interface({
            id: t.number,
            name: t.string,
            group_id: t.number,
        }),
    ),
});

export const ProductTileAttribute = t.interface({
    description: t.string,
    icon: nullable(ImageChooserBlock),
    name: t.string,
    show_attribute: t.boolean,
});

export const ProductCompareRowTypeSlug = t.keyof({
    "richtext": null,
    "attribute-grid": null,
    "attribute-option-list": null,
    "shop-now": null,
    "microconfigurator": null,
    "product-select": null,
    "upgrade": null,
    "dynamic-price": null,
    "price-cta": null,
});

export const ProductCompareRowType = t.interface({
    id: t.number,
    row_type: ProductCompareRowTypeSlug,
    title: t.string,
    group_title: t.union([t.undefined, nullable(t.string)]),
});

interface ProductTileAttributeRow {
    row_type: t.TypeOf<typeof ProductCompareRowType>;
    is_highlighted: boolean;
    children: ProductTileAttributeRow[];
    attributes: t.TypeOf<typeof ProductTileAttribute>[];
    richtext: ISafeHTML | null;
    flair: t.TypeOf<typeof ProductFlair> | null;
}

export const ProductTileAttributeRow: t.Type<ProductTileAttributeRow> =
    t.recursion("ProductTileAttributeRow", () => {
        const _type = t.interface({
            row_type: ProductCompareRowType,
            is_highlighted: t.boolean,
            children: t.array(ProductTileAttributeRow),
            attributes: t.array(ProductTileAttribute),
            richtext: nullable(SafeHTML),
            flair: nullable(ProductFlair),
            // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        }) as any;
        return _type;
    });

const _BaseCompareTile = <RT extends t.Mixed>(tileType: RT) => {
    return t.interface({
        flair: nullable(ProductFlair),
        secondary_flair: nullable(ProductFlair),
        product_compare_tile: t.intersection([
            t.interface({
                id: TileID,
                product_ids: t.array(ProductID),
                name: t.string,
                title: t.string,
                short_description: t.string,
                image: ImageChooserBlock,
                child_filter: nullable(ChildFilter),
            }),
            tileType,
        ]),
        rows: t.array(ProductTileAttributeRow),
        is_promo_period_active: nullable(t.union([t.boolean, t.undefined])),
    });
};

// IBaseProductCompareTile is the tile as given to us by the CMS
export const BaseProductCompareTile = _BaseCompareTile(
    t.interface({
        product_id: ProductID,
        category_id: t.null,
    }),
);
export const BaseCategoryCompareTile = _BaseCompareTile(
    t.interface({
        product_id: nullable(ProductID),
        category_id: ProductCategoryID,
    }),
);
export const BaseCompareTile = t.union([
    BaseProductCompareTile,
    BaseCategoryCompareTile,
]);

// IProductCompareTile is a IBaseProductCompareTile, but has additional data annotated on it.
const _CompareTile = <RT extends t.Mixed>(baseType: RT) => {
    return t.intersection([
        baseType,
        t.interface({
            rootProducts: t.array(Product),
        }),
        t.partial({
            childFilterProducts: nullable(t.array(Product)),
        }),
    ]);
};
export const ProductCompareTile = _CompareTile(BaseProductCompareTile);
export const CategoryCompareTile = _CompareTile(BaseCategoryCompareTile);
export const CompareTile = t.union([ProductCompareTile, CategoryCompareTile]);

export const GroupColumnsHeader = t.interface({
    alignment: t.string,
    background_style: BackgroundStylesSubBlock,
    content: nullable(SafeHTML),
    full_width: t.boolean,
    spacing: SpacingSubBlock,
});

export const ProductSelectionGridCMSData = t.interface({
    header: SafeHTML,
    product_compare_page: nullable(PageChooserBlock),
    products: t.array(BaseCompareTile),
    size_attribute_option_group: nullable(AttributeOptionGroup),
    preselected_size: nullable(t.string),
    theme: t.partial({
        theme: codecFromEnum("ProductCompareTheme", ProductCompareTheme),
    }),
    footer: nullable(SafeHTML),
    group_columns_header: t.union([t.array(GroupColumnsHeader), t.undefined]),
    show_size_selector: nullable(t.boolean),
});

const _AbstractProductCompareGridCMSData = t.interface({
    header: SafeHTML,
    products: t.array(BaseCompareTile),
    size_attribute_option_group: nullable(AttributeOptionGroup),
    preselected_size: nullable(t.string),
});

export const ProductCompareGridCMSData = t.intersection([
    _AbstractProductCompareGridCMSData,
    t.interface({
        theme: t.partial({
            theme: codecFromEnum("ProductCompareTheme", ProductCompareTheme),
        }),
    }),
]);

export const ProductCompareGridColumnCardCMSData =
    _AbstractProductCompareGridCMSData;
