import React from "react";

import { INumberField } from "../models/formFields.interfaces";
import format from "../utils/format";
import { modulo } from "../utils/math";
import { IFormFieldState } from "./AbstractFormField";
import { AbstractInput } from "./AbstractInput";
import { getFormatter } from "./formatting";

export class FormNumber<T extends string> extends AbstractInput<
    T,
    INumberField<T>,
    IFormFieldState
> {
    public static defaultProps = {
        type: "number",
    };

    public state: IFormFieldState = {
        focused: false,
    };

    public onChange(event: React.FormEvent<HTMLInputElement>) {
        if (!event.currentTarget) {
            return;
        }

        // Format value
        if (this.props.formatOptions) {
            const formatter = getFormatter(this.props.formatOptions);
            const original = event.currentTarget.value;
            const formatted = formatter.format(original);
            if (original !== formatted) {
                event.currentTarget.value = formatted;
            }
        }

        super.onChange(event);
    }

    public componentDidMount() {
        if (this.props.formatOptions && this.inputElem) {
            const formatter = getFormatter(this.props.formatOptions);
            formatter.registerCursorTracker(this.inputElem);
        }
        super.componentDidMount();
    }

    public runValidators() {
        const errors = super.runValidators();
        const floatValue =
            this.props.value === undefined ? 0 : parseFloat(this.props.value);

        if (!this.props.value && floatValue !== 0) {
            return errors;
        }

        if (
            (this.props.min || this.props.min === 0) &&
            this.props.min >= floatValue
        ) {
            errors.push(
                `Please enter an amount equal to or greater than ${format.money(
                    this.props.min,
                )}.`,
            );
        }

        if (
            (this.props.max || this.props.max === 0) &&
            this.props.max < floatValue
        ) {
            errors.push(
                `Please enter a number less than ${format.money(
                    this.props.max,
                )}.`,
            );
        }

        if (this.props.step && this.props.step !== "any") {
            if (modulo(floatValue, this.props.step) !== 0) {
                errors.push(
                    `Please enter a number rounded to the closest ${this.props.step}.`,
                );
            }
        }

        return errors;
    }

    protected getInputProps() {
        return Object.assign(super.getInputProps(), {
            type: "number",
            min: this.props.min,
            max: this.props.max,
            step: this.props.step,
        });
    }

    protected buildControl() {
        const props = this.getInputProps();
        props.className ??= "form__field__input";
        return (
            <input
                ref={(ref) => {
                    this.inputElem = ref;
                }}
                {...props}
            />
        );
    }
}
