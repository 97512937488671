import classNames from "classnames";
import React from "react";

import { IBaseField, ValidationTypes } from "../models/formFields.interfaces";
import { notEmpty, unique } from "../utils/functional";
import { focusElement } from "../utils/keyboardFocus";
import {
    AbstractFormComponent,
    IFormComponentState,
} from "./AbstractFormComponent";
import { runValidator } from "./validation";

export interface IFormFieldState {
    focused?: boolean;
}

interface IFormFieldComponentState
    extends IFormFieldState,
        IFormComponentState {}

export abstract class AbstractFormField<
    FieldName extends string,
    IInputType extends HTMLElement,
    IProps extends IBaseField<IInputType, FieldName>,
    IState extends IFormFieldComponentState,
> extends AbstractFormComponent<IProps, IState> {
    protected inputElem: IInputType | undefined | null;
    public value: string | undefined;

    public getInputElem() {
        return this.inputElem;
    }

    public componentDidMount() {
        if (!this.props.id) {
            console.warn(
                "Form Field mounted without an `id` property. This is bad for accessibility.",
                this,
            );
        }
        if (!this.props.name) {
            console.warn(
                "Form Field mounted without a `name` property. This is bad for accessibility.",
                this,
            );
        }
        this.runValidators();
    }

    public componentDidUpdate(prevProps: IProps) {
        if (prevProps.value !== this.props.value) {
            this.runValidators();
        }
    }

    public componentWillUnmount() {
        if (this.props.onValidStateChange) {
            this.props.onValidStateChange(this.props.name, []);
        }
    }

    public runValidators() {
        const name = this.props.name;
        const value = this.props.value || "";
        const validators = this.getValidators();
        // If field is not required and is empty, skip validation
        if (validators.indexOf("required") === -1 && !this.props.value) {
            return [];
        }
        // Otherwise, run validation
        const errors = validators
            .filter(unique)
            .map((validator) => {
                return runValidator(validator, value.toString().trim());
            })
            .filter(notEmpty);
        if (this.props.onValidStateChange) {
            this.props.onValidStateChange(name, errors);
        }
        return errors;
    }

    protected abstract updateValueAttribute(
        event: React.FormEvent<IInputType>,
    ): void;

    protected abstract buildControl(): JSX.Element;

    protected onChange(event: React.FormEvent<IInputType>) {
        // Update the value property
        this.updateValueAttribute(event);

        // Run field validation
        this.runValidators();

        // Call parent's onChange handler
        if (this.props.onChange) {
            this.props.onChange(event);
        }
    }

    protected onFocus(event: React.FocusEvent<IInputType>) {
        this.setState({
            focused: true,
        });

        // Call parent's onChange handler
        if (this.props.onFocus) {
            this.props.onFocus(event);
        }
    }

    protected onBlur(event: React.FocusEvent<IInputType>) {
        this.setState({
            focused: false,
        });

        // Call parent's onChange handler
        if (this.props.onBlur) {
            this.props.onBlur(event);
        }
    }

    private readonly onLabelClick = () => {
        const input = this.getInputElem();
        if (input) {
            focusElement(input);
        }
    };

    protected getPassthroughInputProps() {
        return {
            id: this.props.id,
            name: this.props.name,
            type: this.getHTMLType(),
            className: this.props.className,
            autoComplete: this.props.autoComplete,
            placeholder: this.props.placeholder,
            value: this.props.value === null ? "" : this.props.value,
            defaultValue: this.props.defaultValue,
            required: this.props.required,
            disabled: this.props.disabled,
            onKeyPress: this.props.onKeyPress,
            onMouseEnter: this.props.onMouseEnter,
            onMouseLeave: this.props.onMouseLeave,
        };
    }

    protected getAriaProps(): React.AriaAttributes {
        return {
            "aria-required": this.isRequired(),
            "aria-describedby": this.showValidationErrors()
                ? this.getErrorListElemID()
                : this.getDescribedByElemID(),
            "aria-expanded": this.isExpanded(),
        };
    }

    protected getInputProps() {
        const passthruProps = this.getPassthroughInputProps();
        const ariaProps = this.getAriaProps();
        return {
            ...passthruProps,
            ...ariaProps,
            onChange: (event: React.FormEvent<IInputType>) => {
                this.onChange(event);
            },
            onFocus: (event: React.FocusEvent<IInputType>) => {
                this.onFocus(event);
            },
            onBlur: (event: React.FocusEvent<IInputType>) => {
                this.onBlur(event);
            },
        };
    }

    protected getValidators(): ValidationTypes[] {
        let validators: ValidationTypes[] = [];
        if (this.props.validation) {
            validators = validators.concat(this.props.validation);
        }
        // If field is not required and is empty, skip validation
        if (validators.indexOf("required") === -1 && !this.props.value) {
            return [];
        }
        return validators;
    }

    protected isRequired(): boolean {
        const validators = this.getValidators();
        return !!this.props.required || validators.includes("required");
    }

    protected isExpanded(): boolean | undefined {
        return this.props.ariaExpanded;
    }

    protected buildLabel(): JSX.Element | null {
        const text = this.props.label || this.props.placeholder;
        if (!text) {
            return null;
        }
        const classes = classNames({
            form__label: true,
            [`${this.props.labelCSSClass}`]: this.props.labelCSSClass
                ? true
                : false,
            [`form__label--${this.props.labelPlacement}`]:
                !!this.props.labelPlacement,
        });
        return (
            <label
                className={classes}
                htmlFor={this.props.id}
                onClick={this.onLabelClick}
            >
                {text}
            </label>
        );
    }

    protected buildAdaText(): JSX.Element | null {
        const text = this.props.adaText;
        if (!text) {
            return null;
        }

        return (
            <span
                className="ada-screenreader-only"
                id={this.getDescribedByElemID()}
            >
                {text}
            </span>
        );
    }

    private getHTMLType(): React.InputHTMLAttributes<IInputType>["type"] {
        switch (this.props.type) {
            case "boolean":
                return "checkbox";
            case "integer":
                return "number";
            case "string":
            case "date":
            case "datetime":
                return "text";
            case "choice":
                return;
            default:
                return this.props.type;
        }
    }

    public render() {
        const isRequired = this.isRequired();
        const classes = this.props.wrapperCSSClass
            ? this.props.wrapperCSSClass
            : classNames({
                  ["form__field"]: true,
                  ["form__field--required"]: isRequired,
                  ["form__field--optional"]: !isRequired,
                  ["form__field--has-errors"]: this.showValidationErrors(),
                  [`form__field--${this.props.type}`]: !!this.props.type,
                  [`form__field--${this.props.name}`]: !!this.props.name,
                  ["form__field--focused"]: this.state.focused,
                  ["form__field--not-blank"]: !!this.props.value,
              });
        this.value = `${this.props.value || ""}`;
        return (
            <div className={classes}>
                {this.buildLabel()}
                {this.buildTooltip()}
                {this.buildControl()}
                {this.buildValidationError()}
                {this.buildHelpText()}
                {this.buildWarning()}
                {this.buildAdaText()}
            </div>
        );
    }
}
