import {
    IOrderAPIURL,
    IOrderID,
    isoOrderAPIURL,
    isoOrderID,
} from "../models/nominals";
import { OrderCancellationInfo, OrderList } from "../models/orders";
import { check } from "../models/utils";
import { CSRF_HEADER, ajax, getCSRFToken } from "../utils/ajax";
import { PromiseMutex } from "../utils/mutex";

export const getOrderID = (orderURL: IOrderAPIURL): IOrderID | null => {
    const groups = isoOrderAPIURL
        .unwrap(orderURL)
        .match(/\/api\/orders\/(\d+)\/$/);
    return groups ? isoOrderID.wrap(parseInt(groups[1], 10)) : null;
};

/**
 * Converts a function which would normally accept a IOrderID as its first
 * parameter, into a function which accepts IOrderURL as it's first parameter.
 */
export const curryOrderURL = <T extends unknown[], U>(
    fn: (orderID: IOrderID, ...args: [...T]) => U,
): ((orderURL: IOrderAPIURL, ...args: [...T]) => U) => {
    return (orderURL, ...args) => {
        const orderID = getOrderID(orderURL);
        if (!orderID) {
            throw new Error(`Failed to parse order ID from url: ${orderURL}`);
        }
        return fn(orderID, ...args);
    };
};

/**
 * Get all customer orders
 */
export const loadCustomerOrders = async () => {
    const mutex = new PromiseMutex<OrderList>(
        `orders-load-customer-orders`,
        true,
    );
    let loading = mutex.getPromise();
    if (!loading) {
        loading = ajax
            .get(`/api/orders/`)
            .set("Accept", "application/json")
            .then((resp) => {
                return check(OrderList.decode(resp.body));
            });
        mutex.setPromise(loading);
    }
    return loading;
};

/**
 * Get information related to order cancellation.
 */
export const loadOrderCancellationInfo = async (
    orderID: IOrderID,
): Promise<OrderCancellationInfo> => {
    const mutex = new PromiseMutex<OrderCancellationInfo>(
        `orders-load-cancellation-info-${orderID}`,
        true,
    );
    let loading = mutex.getPromise();
    if (!loading) {
        loading = ajax
            .get(`/api/orders/${orderID}/cancel/`)
            .set("Accept", "application/json")
            .then((resp) => {
                return check(OrderCancellationInfo.decode(resp.body));
            });
        mutex.setPromise(loading);
    }
    return loading;
};

/**
 * Attempt to cancel an order
 */
export const cancelOrder = async (
    orderID: IOrderID,
    reason: string,
): Promise<OrderCancellationInfo> => {
    const resp = await ajax
        .put(`/api/orders/${orderID}/cancel/`)
        .set("Accept", "application/json")
        .set(CSRF_HEADER, await getCSRFToken())
        .send({
            reason: reason,
        });
    return check(OrderCancellationInfo.decode(resp.body));
};
