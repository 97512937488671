import { combineReducers } from "@reduxjs/toolkit";

import { reduceDataLoadEvents } from "./reducers/data";
import { reduceDerivedData } from "./reducers/derived";
import { reduceUserInputEvents } from "./reducers/form";
import { reduceGiftsEvents } from "./reducers/gifts";

export const reducers = combineReducers({
    data: reduceDataLoadEvents,
    form: reduceUserInputEvents,
    derived: reduceDerivedData,
    gifts: reduceGiftsEvents,
});
