import { Dispatch } from "@reduxjs/toolkit";
import { createBrowserHistory, createMemoryHistory } from "history";

import {
    Action,
    IActionApplyHistoryUpdate,
    IReduxState,
} from "./reducers.interfaces";

export interface IHistoryState {
    filters?: IReduxState["filters"];
}

// Set-up history API
const baseURL = typeof window == "undefined" ? "" : window.location.pathname;
const baseQueryString =
    typeof window == "undefined"
        ? ""
        : new URLSearchParams(
              window.location.search.replace(/^\?/, ""),
          ).toString();

export const history =
    typeof window == "undefined"
        ? createMemoryHistory({ initialEntries: ["/"] })
        : createBrowserHistory({
              basename: baseURL,
              forceRefresh: false,
          });

// React to history change events
export const startHistoryListener = (
    dispatch: Dispatch<IActionApplyHistoryUpdate>,
) => {
    history.listen((location, action) => {
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        const state: IHistoryState | undefined | null = location.state as any;
        if (action !== "PUSH" && state && state.filters) {
            // Update Redux to match the new history location
            dispatch<IActionApplyHistoryUpdate>({
                type: Action.APPLY_HISTORY_UPDATE,
                payload: state.filters,
            });
        }
    });
};

// Get the current filter state from the query string
export const getFilterState = (): IReduxState["filters"] => {
    const filters: IReduxState["filters"] = {};
    if (typeof window == "undefined") {
        return filters;
    }

    const params = new URLSearchParams(
        window.location.search.replace(/^\?/, ""),
    );

    for (const [key, value] of params.entries()) {
        filters[key] = {
            isOpen: true,
            selectedValues: value.split(","),
        };
    }

    return filters;
};

// Push the current filter state into the query string
export const pushFilterStateChange = (filters: IReduxState["filters"]) => {
    // Start with the base params (present at the time of page load)
    const params = new URLSearchParams(baseQueryString);
    // Build query string params for current filter state
    for (const filterID of Object.keys(filters)) {
        if (
            filters[filterID].selectedValues &&
            filters[filterID].selectedValues.length > 0
        ) {
            params.set(filterID, filters[filterID].selectedValues.join(","));
        } else {
            params.delete(filterID);
        }
    }
    // Filter out empty keys
    for (const key of params.keys()) {
        if (!key || !params.get(key)) {
            params.delete(key);
        }
    }
    // Push history entry
    const historyState: IHistoryState = {
        filters: filters,
    };
    const entry = {
        search: `?${params.toString()}`,
        state: historyState,
    };
    history.push(entry);
};
