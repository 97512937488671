import React from "react";

import { IOfferDiscount } from "../../../models/catalogue.interfaces";
import { OfferLineMinimal } from "./OfferLineMinimal";

interface IProps {
    offerData: IOfferDiscount;
}

export const OfferLineMinimalEnhanced = (props: IProps) => {
    return <OfferLineMinimal offerData={props.offerData} />;
};
