import classNames from "classnames";
import React from "react";

import { IRadioChoiceField } from "../models/formFields.interfaces";
import { IFormFieldState } from "./AbstractFormField";
import { AbstractInput } from "./AbstractInput";

export class FormRadioSelect<T extends string> extends AbstractInput<
    T,
    IRadioChoiceField<T>,
    IFormFieldState
> {
    public static defaultProps = {
        type: "radio-choice",
    };

    public state: IFormFieldState = {
        focused: false,
    };

    private buildOptionHelpText(str: string | JSX.Element | undefined) {
        if (!str) {
            return null;
        }

        return <div className="form__radio-option__help-text">{str}</div>;
    }

    protected buildControl() {
        const props = this.getInputProps();

        const options = (this.props.choices || []).map((option) => {
            const isChecked = props.value === option.value;
            const helpText = this.buildOptionHelpText(option.helpText);
            const optionClasses = classNames({
                "form__radio-option": true,
                "form__radio-option--checked": isChecked,
            });
            const optionLabelClasses = classNames({
                "form__radio-option__label": true,
                "form__radio-option__label--checked": isChecked,
            });
            return (
                <div className={optionClasses} key={option.value}>
                    <label
                        className={optionLabelClasses}
                        htmlFor={`${this.props.id}-${option.value}`}
                    >
                        <input
                            type="radio"
                            name={this.props.name}
                            id={`${this.props.id}-${option.value}`}
                            value={option.value}
                            checked={isChecked}
                            disabled={option.disabled}
                            aria-describedby={`${props.name}_errors`}
                            onChange={props.onChange}
                        />
                        <span>
                            {option.label}
                            {helpText}
                            {option.extraText}
                        </span>
                    </label>
                </div>
            );
        });

        const optionsLegendClasses = classNames({
            "ada-screenreader-only":
                props.name === "rating" || props.name === "bottomline",
        });
        return (
            <fieldset id={props.id} className="form__radio-options">
                <legend className={optionsLegendClasses}>
                    {this.props.legend}
                </legend>
                {options}
            </fieldset>
        );
    }
}
