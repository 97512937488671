import classNames from "classnames";
import React from "react";
import { connect } from "react-redux";

import { ConfiguratorTypes } from "../../../constants";
import { IProduct } from "../../../models/catalogue.interfaces";
import { IImageURL, isoImageURL } from "../../../models/nominals";
import { IProductShippingMethod } from "../../../models/shipping.interfaces";
import { TStateMapper } from "../../reducers.interfaces";
import { FreeDeliveryButtonTypes } from "../constants";
import { defaults } from "../defaults";
import { isPreorderProduct } from "../utils";
import { ConfiguratorFreeDelivery } from "./ConfiguratorFreeDelivery";

import styles from "./ConfiguratorDeliveryDisclaimer.module.scss";

interface IOwnProps {
    configuratorType: ConfiguratorTypes;
    variantIsAvailableToBuy: boolean;
    rootProduct: IProduct | null;
    upgradedVariant: IProduct | null;
    showShippingLead?: boolean;
    phoneNumber?: string;
    getDeliveryCopy?: (
        rootProduct: IProduct,
        isPreorder: boolean,
    ) => string | null;
    getDeliveryIcon?: () => IImageURL | null;
}

interface IReduxProps {
    shippingMethod: IProductShippingMethod | null;
}

interface IProps extends IOwnProps, IReduxProps {}

interface IState {}

class ConfiguratorDeliveryDisclaimerContainer extends React.PureComponent<
    IProps,
    IState
> {
    private getAnalyticsClass(shippingDescription: string) {
        let analyticsClass = "";
        if (shippingDescription.includes("1-2 weeks")) {
            analyticsClass = "pdploaded-1to2weeks";
        }
        if (shippingDescription.includes("5-7 days")) {
            analyticsClass = "pdploaded-5to7days";
        }
        if (shippingDescription.includes("3-5 days")) {
            analyticsClass = "pdploaded-3to5days";
        }
        return analyticsClass;
    }

    private getShippingDescr() {
        if (
            !this.props.variantIsAvailableToBuy ||
            !this.props.upgradedVariant ||
            !this.props.rootProduct ||
            !this.props.getDeliveryCopy
        ) {
            return "";
        }

        const deliveryCopyPreorder = this.props.getDeliveryCopy(
            this.props.rootProduct,
            true,
        );
        const deliveryCopyNormal = this.props.getDeliveryCopy(
            this.props.rootProduct,
            false,
        );

        // If this is a preorder product AND the delivery copy function didnt return an empty string,
        // set the shipping description to the function's result
        if (
            isPreorderProduct(this.props.upgradedVariant) &&
            deliveryCopyPreorder
        ) {
            return deliveryCopyPreorder;
        }

        const storedDescription = this.props.shippingMethod
            ? this.props.shippingMethod.description
            : null;
        // If we still don't have a shipping description and the CMS has showShippingLead enabled AND there was a shipping description passed in from the CMS,
        // set the shipping description to that CMS field
        if (this.props.showShippingLead && storedDescription) {
            return storedDescription;
        } else {
            // Otherwise, if the delivery copy function for non-preorder didnt return an empty string,
            // set the shipping description to that function's result
            if (deliveryCopyNormal) {
                return deliveryCopyNormal;
            } else {
                return "";
            }
        }
    }

    private getShippingIcon(altText: string) {
        if (!this.props.getDeliveryIcon) {
            return null;
        }

        const iconURL = this.props.getDeliveryIcon();
        if (!iconURL) {
            return null;
        }

        return (
            <img
                className={styles.icon}
                src={isoImageURL.unwrap(iconURL)}
                alt={altText}
            />
        );
    }

    render() {
        const description = this.getShippingDescr();
        const analyticsClass = this.getAnalyticsClass(description);
        const layoutDesign = this.props.configuratorType;
        const disclaimerClasses = classNames({
            "configurator__delivery-disclaimer": true,
            [`configurator__delivery-disclaimer--${layoutDesign}`]: true,
            [styles.root]: true,
            [styles.updated]: layoutDesign === ConfiguratorTypes.UPDATED,
            [`al-${analyticsClass}`]: true,
        });

        if (!description) {
            return null;
        }

        return (
            <div className={disclaimerClasses}>
                <ConfiguratorFreeDelivery
                    upgradedVariant={this.props.upgradedVariant}
                    variantIsAvailableToBuy={this.props.variantIsAvailableToBuy}
                    freeDeliveryButtonTypes={FreeDeliveryButtonTypes.TEXT}
                    configuratorType={layoutDesign}
                    phoneNumber={this.props.phoneNumber}
                />
                <span>
                    {this.getShippingIcon(description)}
                    {description}
                    <ConfiguratorFreeDelivery
                        upgradedVariant={this.props.upgradedVariant}
                        variantIsAvailableToBuy={
                            this.props.variantIsAvailableToBuy
                        }
                        freeDeliveryButtonTypes={FreeDeliveryButtonTypes.ICON}
                        configuratorType={layoutDesign}
                        phoneNumber={this.props.phoneNumber}
                    />
                </span>
            </div>
        );
    }
}

const mapStateToProps: TStateMapper<"configurator", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    const state = rootState.configurator || defaults;
    return {
        shippingMethod: state.shippingMethod,

        // Direct Props
        ...ownProps,
    };
};

export const ConfiguratorDeliveryDisclaimer = connect(mapStateToProps)(
    ConfiguratorDeliveryDisclaimerContainer,
);
