import { IOptionValues, IProduct } from "../../models/catalogue.interfaces";
import { IProductID } from "../../models/nominals";
import { ISetOptionValuePayload } from "../reducer-utils";

export enum Action {
    PRODUCTS_LOADING_STARTED = "ProductGrid2/PRODUCTS_LOADING_STARTED",
    PRODUCTS_LOADING_DONE = "ProductGrid2/PRODUCTS_LOADING_DONE",
    APPLY_HISTORY_UPDATE = "ProductGrid2/APPLY_HISTORY_UPDATE",
    UPDATE_SELECT_ONE_FILTER_VALUE = "ProductGrid2/UPDATE_SELECT_ONE_FILTER_VALUE",
    UPDATE_SELECT_MANY_FILTER_VALUE = "ProductGrid2/UPDATE_SELECT_MANY_FILTER_VALUE",
    UPDATE_FILTER_VISIBILITY = "ProductGrid2/UPDATE_FILTER_VISIBILITY",
    UPDATE_SORT_METHOD = "ProductGrid2/UPDATE_SORT_METHOD",
    FOCUS_PRODUCT = "ProductGrid2/FOCUS_PRODUCT",
    BLUR_PRODUCT = "ProductGrid2/BLUR_PRODUCT",
    SET_OPTION_VALUE = "ProductGrid2/SET_OPTION_VALUE",
    SET_QUANTITY = "ProductGrid2/SET_QUANTITY",
}

export interface IFilterState {
    selectedValues: string[];
    isOpen: boolean;
}

export interface IReduxState {
    isLoading: boolean;
    products: IProduct[];
    // Sidebar filters
    filters: {
        [filterID: string]: IFilterState;
    };
    sortMethod: string | null;
    // Tile focused state
    focusedProductID: IProductID | null;
    // Product option selector state
    optionValues: IOptionValues;
    quantity: number;
}

export interface IBaseAction {
    type: Action;
}

export interface IActionProductsLoadingStarted extends IBaseAction {
    type: Action.PRODUCTS_LOADING_STARTED;
}

export interface IActionProductsLoadingDone extends IBaseAction {
    type: Action.PRODUCTS_LOADING_DONE;
    payload: IProduct[];
}

export interface IActionApplyHistoryUpdate extends IBaseAction {
    type: Action.APPLY_HISTORY_UPDATE;
    payload: IReduxState["filters"];
}

export interface IActionUpdateSelectOneFilterValue extends IBaseAction {
    type: Action.UPDATE_SELECT_ONE_FILTER_VALUE;
    payload: {
        optionNamespace: string;
        filterID: string;
        optionID: string | null;
    };
}

export interface IActionUpdateSelectManyFilterValue extends IBaseAction {
    type: Action.UPDATE_SELECT_MANY_FILTER_VALUE;
    payload: {
        optionNamespace: string;
        filterID: string;
        optionID: string;
        isSelected: boolean;
    };
}

export interface IActionUpdateFilterVisibility extends IBaseAction {
    type: Action.UPDATE_FILTER_VISIBILITY;
    payload: {
        filterID: string;
        isOpen: boolean;
    };
}

export interface IActionUpdateSortMethod extends IBaseAction {
    type: Action.UPDATE_SORT_METHOD;
    payload: string;
}

export interface IActionFocusProduct extends IBaseAction {
    type: Action.FOCUS_PRODUCT;
    payload: IProductID;
}

export interface IActionBlurProduct extends IBaseAction {
    type: Action.BLUR_PRODUCT;
    payload: IProductID;
}

export interface IActionSetOptionValue extends IBaseAction {
    type: Action.SET_OPTION_VALUE;
    payload: ISetOptionValuePayload;
}

export interface IActionSetQuantity extends IBaseAction {
    type: Action.SET_QUANTITY;
    payload: number;
}

export type IAction =
    | IActionProductsLoadingStarted
    | IActionProductsLoadingDone
    | IActionApplyHistoryUpdate
    | IActionUpdateSelectOneFilterValue
    | IActionUpdateSelectManyFilterValue
    | IActionUpdateFilterVisibility
    | IActionUpdateSortMethod
    | IActionFocusProduct
    | IActionBlurProduct
    | IActionSetOptionValue
    | IActionSetQuantity;
