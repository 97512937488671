import React from "react";
import { t } from "ttag";

import { interpolate } from "@thelabnyc/thelabui/src/utils/i18n";

import { Link } from "../../../common/Link";
import Trans from "../../../common/Trans";
import { strings } from "../../../localization";
import { FinancingPlan } from "../../../models/financing";
import { isoAPR, isoMonths } from "../../../models/nominals";
import format from "../../../utils/format";
import { urls } from "../../../utils/urls";
import { FinePrint } from "./PaymentMethod_Financing_FinePrint";
import { TermLengthAd } from "./PaymentMethod_Financing_TermLengthAd";

interface IProps {}

interface IState {}

export class FinancingDisabledWarning extends React.Component<IProps, IState> {
    private getDefaultPlan() {
        const defaultTermMonths = strings.get(
            "FINANCING_DEFAULT_PLAN_TERM_MONTHS",
        );
        const defaultAPR = strings.get("FINANCING_DEFAULT_PLAN_APR");
        if (!defaultTermMonths || !defaultAPR) {
            return null;
        }
        const plan: Pick<FinancingPlan, "apr" | "term_months"> = {
            apr: isoAPR.wrap(defaultAPR),
            term_months: isoMonths.wrap(parseInt(defaultTermMonths, 10)),
        };
        return plan;
    }

    render() {
        const plan = this.getDefaultPlan();
        const minFinancingAmount = strings.get("FINANCING_MIN_AMOUNT");
        if (!plan || !minFinancingAmount) {
            return null;
        }
        return (
            <div className="financing-form">
                <div className="financing-form__subtext">
                    <Trans
                        fmtString={interpolate(
                            t`<span className="u-responsive-break">Financing is available on orders of %(minFinancingAmount)s or more with,</span> a %(cardName)s issued by Wells Fargo*.`,
                            {
                                minFinancingAmount: `${format.money(
                                    minFinancingAmount,
                                )}`,
                                cardName: `${strings.get(
                                    "FINANCING_CARD_NAME",
                                )}`,
                            },
                        )}
                        data={{
                            SUP: (content) => <sup key={1}>{content}</sup>,
                        }}
                    />
                </div>
                <div className="financing-form__feature-container">
                    <TermLengthAd plan={plan} />
                </div>
                <div className="financing-form__cta">
                    <Link
                        className="financing-form__link"
                        href={urls.pageURL("offers-link")}
                        target="_top"
                    >
                        {t`Learn More`}
                    </Link>
                </div>
                <FinePrint />
            </div>
        );
    }
}
