import { addSeconds, formatDistanceToNow } from "date-fns";
import React from "react";
import { t } from "ttag";

import { sendSessionKeepAlivePing } from "../../../api/user";
import { Modal, ModalStyles } from "../../../common/Modal";
import { SessionKeepAliveResponse } from "../../../models/user";

interface IProps {}

interface IState {
    remainingSessionSeconds: number;
}

const throttle = (func: () => Promise<void>, delay: number) => {
    let lastCall = 0;
    return async () => {
        const now = Date.now();
        if (now - lastCall < delay) {
            return;
        }
        lastCall = now;
        await func();
    };
};

export class SessionKeepAlive extends React.Component<IProps, IState> {
    private readonly intervalMS = 1_000;
    private readonly initialPingDelayMS = 15_000;
    private readonly sessionWarningThresholdSeconds = 180;

    private idleTimer: number | null = null;

    public state: IState = {
        remainingSessionSeconds: 1_209_600, // Default is 2 weeks
    };

    private readonly handleUIEvent = () => {
        if (this.throttledKeepAlive) {
            this.throttledKeepAlive();
        }
    };
    private throttledKeepAlive: (() => Promise<void>) | null = null;

    componentDidMount() {
        // Wait a few seconds before sending the first keep-alive ping
        window.setTimeout(() => {
            // Throttle by a random time between 45-75 seconds
            const throttleTime = (Math.random() * 30 + 45) * 1000;
            this.throttledKeepAlive = throttle(
                this.doSessionKeepAlive,
                throttleTime,
            );
            this.throttledKeepAlive();
            document.addEventListener("mousemove", this.handleUIEvent);
            document.addEventListener("keypress", this.handleUIEvent);
        }, this.initialPingDelayMS);
    }

    componentWillUnmount() {
        document.removeEventListener("mousemove", this.handleUIEvent);
        document.removeEventListener("keypress", this.handleUIEvent);
    }

    private readonly doSessionKeepAlive = async () => {
        // Wrap the session ping in a lock to prevent multiple tabs from trying
        // to do a ping all at the same time. This tends to result in race
        // conditions with OIDC session state.
        const inner = async () => {
            if (this.idleTimer) {
                clearTimeout(this.idleTimer);
            }
            const resp = await sendSessionKeepAlivePing();
            if (SessionKeepAliveResponse.is(resp)) {
                this.setIdleTimer(
                    this.getCurrentTimestamp(),
                    resp.session_lifetime_seconds,
                );
            } else {
                this.doTokenRefresh(resp.refresh_url);
            }
        };
        try {
            // Handle navigator.locks not existing since it doesn't exist in
            // non-SSL environments (like local development).
            if (navigator.locks) {
                await navigator.locks.request("session-keep-alive", inner);
            } else {
                await inner();
            }
        } catch (e) {
            console.warn(e);
        }
    };

    private readonly doTokenRefresh = (refreshURL: string) => {
        const width = 320;
        const height = 240;
        const options = [
            ["toolbar", "no"],
            ["location", "no"],
            ["directories", "no"],
            ["status", "no"],
            ["menubar", "no"],
            ["scrollbars", "no"],
            ["resizable", "yes"],
            ["width", width],
            ["height", height],
            ["top", screen.height / 2 - height / 2],
            ["left", screen.width / 2 - width / 2],
        ];
        const encodedOptions = options
            .map((pair) => {
                return pair.join("=");
            })
            .join(", ");
        const popup = window.open(refreshURL, "Token Refresh", encodedOptions);
        if (!popup) {
            alert("Please enable popups for this website.");
        }
    };

    private setIdleTimer(fetchedTimestamp: number, sessionLifetime: number) {
        const now = this.getCurrentTimestamp();
        // Fuzz the remaining time a little bit to prevent multiple tabs form
        // all doing this at the same time.
        const fuzzTime = Math.random() * 60;
        const remainingTime =
            sessionLifetime - (now - fetchedTimestamp) - fuzzTime;

        if (this.idleTimer) {
            clearTimeout(this.idleTimer);
        }

        // 5 seconds after the session expires, reload the page.
        if (remainingTime <= -5) {
            window.location.reload();
            return;
        }

        this.setState({
            remainingSessionSeconds: remainingTime,
        });

        this.idleTimer = window.setTimeout(() => {
            this.setIdleTimer(fetchedTimestamp, sessionLifetime);
        }, this.intervalMS);
    }

    private getCurrentTimestamp() {
        return Date.now() / 1000;
    }

    render() {
        const modalStyleProps: ModalStyles = {
            content: {
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                margin: "auto",
                width: "450px",
                height: "200px",
                padding: "20px",
                overflow: "hidden",
                boxShadow: "none",
                textAlign: "center",
            },
        };

        // Open the modal when the session has 3 minutes left.
        const modalIsOpen =
            this.state.remainingSessionSeconds <
            this.sessionWarningThresholdSeconds;

        // Render a human readable version of the remaining time.
        const sessionEnd = addSeconds(
            new Date(),
            this.state.remainingSessionSeconds,
        );
        const remainingTime = formatDistanceToNow(sessionEnd, {
            includeSeconds: true,
        });

        // Render modal content
        let modalContent: JSX.Element;
        if (this.state.remainingSessionSeconds <= 0) {
            modalContent = (
                <div>
                    <p>
                        <strong>{t`Your session has expired.`}</strong>
                    </p>
                </div>
            );
        } else {
            modalContent = (
                <div>
                    <p>
                        <strong>
                            {t`Your session will expire in ${remainingTime}.`}
                        </strong>
                    </p>
                    <p>
                        {t`To stay logged in, please click the button below.`}
                    </p>
                    <button
                        className="button"
                        onClick={this.doSessionKeepAlive}
                    >
                        {t`Stay Logged In`}
                    </button>
                </div>
            );
        }

        // Render the modal
        return (
            <Modal
                className="session-keep-alive-modal"
                contentLabel={t`User Idle Timeout`}
                style={modalStyleProps}
                isOpen={modalIsOpen}
                onRequestClose={this.doSessionKeepAlive}
            >
                {modalContent}
            </Modal>
        );
    }
}
