import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { IMattressMatchResults } from "../../models/catalogue.interfaces";
import {
    FetchResultsStatusType,
    MattressSizeOptions,
    stepInformation,
} from "./constants";
import { defaults } from "./defaults";
import { OptionStepsType } from "./models.interfaces";
import { getResultsFromPreferences } from "./thunks/loadResults";

export const mattressMatchSlice = createSlice({
    name: "mattressmatch",
    initialState: defaults,
    reducers: {
        onSelectBodyType: (
            state,
            action: PayloadAction<{
                selectedHeight?: number;
                selectedWeight?: number;
            }>,
        ) => {
            if (action.payload.selectedHeight) {
                state.ui.selections.selectedHeight =
                    action.payload.selectedHeight;
            }
            if (action.payload.selectedWeight) {
                state.ui.selections.selectedWeight =
                    action.payload.selectedWeight;
            }
        },
        onSelectOption: (
            state,
            action: PayloadAction<{
                selectedOption: number;
                step: keyof OptionStepsType;
            }>,
        ) => {
            const key = stepInformation[action.payload.step].key;
            state.ui.selections[key] = action.payload.selectedOption;
        },
        onSelectSize: (
            state,
            action: PayloadAction<{
                selectedSize: MattressSizeOptions;
            }>,
        ) => {
            state.ui.selections.selectedSize = action.payload.selectedSize;
        },

        onNavigate: (
            state,
            action: PayloadAction<{
                currentScreen: number;
            }>,
        ) => {
            state.ui.currentScreen = action.payload.currentScreen;
        },
        loadedResults: (
            state,
            action: PayloadAction<{
                loadedResults: IMattressMatchResults;
            }>,
        ) => {
            (state.ui.loadedResults as IMattressMatchResults) =
                action.payload.loadedResults;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getResultsFromPreferences.pending, (state) => {
                state.ui.fetchResultsStatus = FetchResultsStatusType.LOADING;
            })
            .addCase(getResultsFromPreferences.fulfilled, (state, action) => {
                (state.ui.loadedResults as IMattressMatchResults | null) =
                    action.payload;
                state.ui.fetchResultsStatus = FetchResultsStatusType.SUCCESS;
            })
            .addCase(getResultsFromPreferences.rejected, (state) => {
                state.ui.fetchResultsStatus = FetchResultsStatusType.ERROR;
            });
    },
});

export const {
    onSelectBodyType,
    onSelectOption,
    onSelectSize,
    onNavigate,
    loadedResults,
} = mattressMatchSlice.actions;

export { getResultsFromPreferences };

export default mattressMatchSlice.reducer;
