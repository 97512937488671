import { Dispatch } from "@reduxjs/toolkit";

import { getWriteReviewTemplate } from "../../api/reviews";
import {
    IFormUUID,
    IReviewsProductID,
    IReviewsProductTypeID,
    isoReviewsProductTypeID,
} from "../../models/nominals";
import {
    IReviewQuery,
    IReviewQueryFacets,
    IReviewsProduct,
    ISearchFacetOption,
    IWriteReviewTemplate,
    ReviewSortOption,
} from "../../models/reviews.interfaces";
import { Action, WriteReviewFormMode } from "./constants";
import {
    IAction,
    IActionClearFilters,
    IActionLoadedReviews,
    IActionRegisterNewWriteReviewForm,
    IActionReviewFormCheckboxChange,
    IActionReviewFormRadioChange,
    IActionReviewFormTextChange,
    IActionReviewFormVariantChange,
    IActionReviewSetEmail,
    IActionReviewSetErrors,
    IActionReviewSetLocation,
    IActionReviewSetName,
    IActionSetFacetValues,
    IActionSetProductTypes,
    IActionSetProducts,
    IActionSetReviewTemplate,
    IActionSetWriteReviewFormMode,
    IActionToggleMobileSideNav,
    IActionUpdateFilterOptionCollectionValue,
    IActionUpdateFilterOptionValue,
    IActionUpdateProductTypes,
    IActionUpdateSorting,
    IActionUpdateSourceFilterVisibility,
} from "./reducers.interfaces";

export class Dispatchers {
    dispatch: Dispatch<IAction>;

    constructor(dispatch: Dispatch<IAction>) {
        this.dispatch = dispatch;
    }

    public readonly setProducts = (products: IReviewsProduct[]) => {
        this.dispatch<IActionSetProducts>({
            type: Action.SET_PRODUCTS,
            payload: products,
        });
    };

    public readonly updateSorting = (option: ReviewSortOption) => {
        this.dispatch<IActionUpdateSorting>({
            type: Action.UPDATE_SORTING,
            payload: option,
        });
    };

    public readonly toggleMobileSideNav = () => {
        this.dispatch<IActionToggleMobileSideNav>({
            type: Action.TOGGLE_MOBILE_SIDE_NAV,
        });
    };

    public readonly loadedReviews = (
        payload: IActionLoadedReviews["payload"],
    ) => {
        this.dispatch<IActionLoadedReviews>({
            type: Action.LOADED_REVIEWS,
            payload: payload,
        });
    };

    public readonly setFacetValues = (values: IReviewQuery) => {
        this.dispatch<IActionSetFacetValues>({
            type: Action.SET_FACET_VALUES,
            payload: values,
        });
    };

    public readonly setProductTypes = (
        productTypeIDs: IReviewsProductTypeID[],
    ) => {
        this.dispatch<IActionSetProductTypes>({
            type: Action.SET_PRODUCT_TYPES,
            payload: productTypeIDs,
        });
    };

    public readonly updateProductTypes = (
        productTypeID: IReviewsProductTypeID,
        isSelected: boolean,
    ) => {
        this.dispatch<IActionUpdateProductTypes>({
            type: Action.UPDATE_PRODUCT_TYPES,
            payload: {
                productTypeID: productTypeID,
                isSelected: isSelected,
            },
        });
    };

    public readonly updateFilterOptionValue = (
        facetType: IReviewQueryFacets,
        optionID: string | number,
        isSelected: boolean,
        isExclusive = false,
    ) => {
        if (facetType === "product_type_id") {
            this.updateProductTypes(
                isoReviewsProductTypeID.wrap(Number(optionID)),
                isSelected,
            );
            return;
        }
        this.dispatch<IActionUpdateFilterOptionValue>({
            type: Action.UPDATE_FILTER_OPTION_VALUE,
            payload: {
                facetType: facetType,
                optionID: optionID,
                isSelected: isSelected,
                isExclusive: isExclusive,
            },
        });
    };

    public readonly updateFilterOptionCollectionValue = (
        facetType: IReviewQueryFacets,
        options: ISearchFacetOption[],
        isSelected: boolean,
    ) => {
        this.dispatch<IActionUpdateFilterOptionCollectionValue>({
            type: Action.UPDATE_FILTER_OPTION_COLLECTION_VALUE,
            payload: {
                facetType: facetType,
                options: options,
                isSelected: isSelected,
            },
        });
    };

    public readonly updateSourceFilterVisibility = (isOpen: boolean) => {
        this.dispatch<IActionUpdateSourceFilterVisibility>({
            type: Action.UPDATE_SOURCE_FILTER_VISIBILITY,
            payload: {
                isOpen: isOpen,
            },
        });
    };

    public readonly clearFilters = () => {
        this.dispatch<IActionClearFilters>({
            type: Action.CLEAR_FILTERS,
        });
    };

    public readonly registerNewWriteReviewForm = (
        formUUID: IFormUUID,
        mode: WriteReviewFormMode,
    ) => {
        this.dispatch<IActionRegisterNewWriteReviewForm>({
            type: Action.REGISTER_NEW_WRITE_REVIEW_FORM,
            payload: {
                formUUID: formUUID,
                mode: mode,
            },
        });
    };

    public readonly setWriteReviewFormMode = (
        formUUID: IFormUUID,
        newMode: WriteReviewFormMode,
    ) => {
        this.dispatch<IActionSetWriteReviewFormMode>({
            type: Action.SET_WRITE_REVIEW_FORM_MODE,
            payload: {
                formUUID: formUUID,
                mode: newMode,
            },
        });
    };

    public readonly setReviewFormSelectedProductType = async (
        formUUID: IFormUUID,
        products: IReviewsProduct[],
        productTypeID: IReviewsProductTypeID,
    ) => {
        const typeProducts = products.filter(
            (product) => product.product_type === productTypeID,
        );
        const initialProduct = typeProducts[0];
        if (initialProduct) {
            this.setReviewFormSelectedProduct(formUUID, initialProduct.id);
        }
    };

    public readonly setReviewFormSelectedProduct = async (
        formUUID: IFormUUID,
        productID: IReviewsProductID,
    ) => {
        // Load Review Template for product
        const template = await getWriteReviewTemplate(productID);
        this.setReviewTemplate(formUUID, productID, template);
    };

    public readonly setReviewTemplate = (
        formUUID: IFormUUID,
        productID: IReviewsProductID,
        template: IWriteReviewTemplate,
    ) => {
        this.dispatch<IActionSetReviewTemplate>({
            type: Action.SET_REVIEW_TEMPLATE,
            payload: {
                formUUID: formUUID,
                productID: productID,
                template: template,
            },
        });
    };

    public readonly onReviewFormVariantChange = (
        payload: IActionReviewFormVariantChange["payload"],
    ) => {
        this.dispatch<IActionReviewFormVariantChange>({
            type: Action.REVIEW_FORM_VARIANT_CHANGE,
            payload: payload,
        });
    };

    public readonly onReviewFormTextChange = (
        payload: IActionReviewFormTextChange["payload"],
    ) => {
        this.dispatch<IActionReviewFormTextChange>({
            type: Action.REVIEW_FORM_TEXT_CHANGE,
            payload: payload,
        });
    };

    public readonly onReviewFormCheckboxChange = (
        payload: IActionReviewFormCheckboxChange["payload"],
    ) => {
        this.dispatch<IActionReviewFormCheckboxChange>({
            type: Action.REVIEW_FORM_CHECKBOX_CHANGE,
            payload: payload,
        });
    };

    public readonly onReviewFormRadioChange = (
        payload: IActionReviewFormRadioChange["payload"],
    ) => {
        this.dispatch<IActionReviewFormRadioChange>({
            type: Action.REVIEW_FORM_RADIO_CHANGE,
            payload: payload,
        });
    };

    public readonly setReviewFormErrors = (
        payload: IActionReviewSetErrors["payload"],
    ) => {
        this.dispatch<IActionReviewSetErrors>({
            type: Action.REVIEW_FORM_SET_ERRORS,
            payload: payload,
        });
    };

    public readonly setCustomerInformationEmail = (email: string) => {
        this.dispatch<IActionReviewSetEmail>({
            type: Action.REVIEW_SET_CUSTOMER_INFORMATION_EMAIL,
            payload: email,
        });
    };

    public readonly setCustomerInformationName = (name: string) => {
        this.dispatch<IActionReviewSetName>({
            type: Action.REVIEW_SET_CUSTOMER_INFORMATION_NAME,
            payload: name,
        });
    };

    public readonly setCustomerInformationLocation = (location: string) => {
        this.dispatch<IActionReviewSetLocation>({
            type: Action.REVIEW_SET_CUSTOMER_INFORMATION_LOCATION,
            payload: location,
        });
    };
}
