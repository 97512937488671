import React from "react";

import { Image } from "../../../common/Image";
import { IOptionValue } from "../../../models/catalogue.interfaces";
import { ImageChooserBlock } from "../../../models/streamfield-blocks";

interface IProps {
    attribute: IOptionValue;
    icon: ImageChooserBlock | null;
}

interface IState {}

export class HighlightedAttribute extends React.Component<IProps, IState> {
    render() {
        return (
            <>
                {this.props.icon && (
                    <Image src={this.props.icon.url} alt={""} />
                )}
                <p className="caption">{this.props.attribute}</p>
            </>
        );
    }
}
