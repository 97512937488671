import { combineReducers } from "@reduxjs/toolkit";

import {
    IFormUUID,
    IReviewsProductID,
    IReviewsProductTypeID,
    IReviewsProductVariantID,
    isoFormUUID,
    isoReviewsProductID,
    isoReviewsProductVariantID,
} from "../../models/nominals";
import {
    IReviewQuery,
    IReviewQueryFacets,
} from "../../models/reviews.interfaces";
import { guardReducer } from "../../utils/redux";
import { parseFacetValues, serializeFacetValues } from "../../utils/reviews";
import { difference, intersection, isEqualSet } from "../../utils/sets";
import { Action, LoadedReviewsBehavior } from "./constants";
import { defaultState, getDefaultWriteReviewFormState } from "./defaults";
import {
    IAction,
    IActionReviewFormCheckboxChange,
    IActionReviewFormRadioChange,
    IActionReviewFormTextChange,
    IActionReviewFormVariantChange,
    ICustomerInformationState,
    IReduxState,
    IWriteReviewFormState,
} from "./reducers.interfaces";

const buildFacetValues = (
    facetValues: IReviewQuery,
    facetType: IReviewQueryFacets,
    optionID: string | number,
    isSelected: boolean,
    isExclusive = false,
): IReviewQuery => {
    const values = new Set(parseFacetValues(facetValues[facetType]));
    if (isExclusive) {
        values.clear();
    }
    optionID = `${optionID}`.trim();
    if (isSelected) {
        values.add(optionID);
    } else {
        values.delete(optionID);
    }
    return {
        ...facetValues,
        [facetType]: serializeFacetValues(values),
    };
};

const getWriteReviewFormState = (
    state: IReduxState["ui"],
    formUUID: IFormUUID,
): IWriteReviewFormState => {
    const defaultFormState = getDefaultWriteReviewFormState();
    return {
        ...defaultFormState,
        ...state.writeReviewForms[String(formUUID)],
    };
};

const updateWriteReviewFormState = (
    state: IReduxState["ui"],
    formUUID: IFormUUID,
    updates: Partial<IWriteReviewFormState>,
): IReduxState["ui"] => {
    return {
        ...state,
        writeReviewForms: {
            ...state.writeReviewForms,
            [String(formUUID)]: {
                ...getWriteReviewFormState(state, formUUID),
                ...updates,
            },
        },
    };
};

const handleReviewFormVariantChange = (
    state: IReduxState["ui"],
    actionPayload: IActionReviewFormVariantChange["payload"],
): IReduxState["ui"] => {
    return updateWriteReviewFormState(state, actionPayload.formUUID, {
        selectedVariant: actionPayload.selectedVariant,
    });
};

const handleReviewFormTextChange = (
    state: IReduxState["ui"],
    actionPayload: IActionReviewFormTextChange["payload"],
): IReduxState["ui"] => {
    const valuesText = getWriteReviewFormState(
        state,
        actionPayload.formUUID,
    ).valuesText;
    return updateWriteReviewFormState(state, actionPayload.formUUID, {
        valuesText: {
            ...valuesText,
            [actionPayload.fieldName]: actionPayload.fieldValue,
        },
    });
};

const updateCustomerInformationField = (
    state: IReduxState["ui"],
    update: { [fieldName: string]: string },
): IReduxState["ui"] => {
    interface reviewFormState {
        [formUUID: string]: IWriteReviewFormState;
    }
    // Update field on customer information
    const customerInformationState: ICustomerInformationState = {
        ...state.customerInformation,
        ...update,
    };
    // Update field on any WaR forms
    const reviewForms: reviewFormState = {};
    Object.entries(state.writeReviewForms).forEach(([uuidstr, formState]) => {
        const uuid = isoFormUUID.wrap(uuidstr);
        const valuesText = formState.valuesText;
        const updatedFormState = updateWriteReviewFormState(state, uuid, {
            valuesText: {
                ...valuesText,
                ...update,
            },
        }).writeReviewForms[uuidstr];
        return (reviewForms[uuidstr] = {
            ...formState,
            ...updatedFormState,
        });
    });
    return {
        ...state,
        customerInformation: customerInformationState,
        writeReviewForms: reviewForms,
    };
};

const handleReviewFormCheckboxChange = (
    state: IReduxState["ui"],
    actionPayload: IActionReviewFormCheckboxChange["payload"],
): IReduxState["ui"] => {
    // Get the current value set excluding the value in the payload
    const valuesMultiSelect = getWriteReviewFormState(
        state,
        actionPayload.formUUID,
    ).valuesMultiSelect;
    const newValueSet = (
        valuesMultiSelect[actionPayload.fieldName] || []
    ).filter((opt) => opt !== actionPayload.fieldValue);
    // Add the payload value back in if the checkbox is checked
    if (actionPayload.isChecked) {
        newValueSet.push(actionPayload.fieldValue);
    }
    return updateWriteReviewFormState(state, actionPayload.formUUID, {
        valuesMultiSelect: {
            ...valuesMultiSelect,
            [actionPayload.fieldName]: newValueSet,
        },
    });
};

const handleReviewFormRadioChange = (
    state: IReduxState["ui"],
    actionPayload: IActionReviewFormRadioChange["payload"],
): IReduxState["ui"] => {
    if (!actionPayload.isChecked) {
        return state;
    }
    return handleReviewFormTextChange(state, actionPayload);
};

/* eslint-disable  complexity */
const uiReducer = (
    state = defaultState.ui,
    action: IAction,
): IReduxState["ui"] => {
    switch (action.type) {
        case Action.TOGGLE_MOBILE_SIDE_NAV:
            return {
                ...state,
                mobileSideNavOpen: !state.mobileSideNavOpen,
            };

        case Action.UPDATE_SORTING:
            return {
                ...state,
                selectedSortOption: action.payload,
            };

        case Action.LOADED_REVIEWS:
            return {
                ...state,
                page: action.payload.page,
                hasMore: action.payload.hasMore,
                loadedFacetValues: action.payload.loadedFacetValues,
            };

        case Action.SET_FACET_VALUES:
            return {
                ...state,
                facetValues: action.payload,
            };

        case Action.SET_PRODUCT_TYPES:
            return {
                ...state,
                productTypeIDs: action.payload,
            };

        case Action.UPDATE_PRODUCT_TYPES:
            const productTypeIDs = new Set(state.productTypeIDs);
            if (action.payload.isSelected) {
                productTypeIDs.add(action.payload.productTypeID);
            } else {
                productTypeIDs.delete(action.payload.productTypeID);
            }
            return {
                ...state,
                productTypeIDs: Array.from(productTypeIDs),
            };

        case Action.UPDATE_FILTER_OPTION_VALUE:
            return {
                ...state,
                facetValues: buildFacetValues(
                    state.facetValues,
                    action.payload.facetType,
                    action.payload.optionID,
                    action.payload.isSelected,
                    action.payload.isExclusive,
                ),
            };

        case Action.UPDATE_FILTER_OPTION_COLLECTION_VALUE:
            return {
                ...state,
                facetValues: action.payload.options.reduce((memoA, option) => {
                    const subOptionType = option.sub_option_type;
                    if (
                        subOptionType &&
                        option.sub_options &&
                        option.sub_options.length > 0
                    ) {
                        memoA = option.sub_options.reduce(
                            (memoB, subOption) =>
                                buildFacetValues(
                                    memoB,
                                    subOptionType,
                                    subOption.option_id,
                                    action.payload.isSelected,
                                ),
                            memoA,
                        );
                    }
                    return buildFacetValues(
                        memoA,
                        action.payload.facetType,
                        option.option_id,
                        action.payload.isSelected,
                    );
                }, state.facetValues),
            };

        case Action.UPDATE_SOURCE_FILTER_VISIBILITY:
            return {
                ...state,
                sourceFilterOpen: action.payload.isOpen,
                facetValues: {
                    ...state.facetValues,
                    source_id: "",
                },
            };

        case Action.CLEAR_FILTERS:
            return {
                ...state,
                productTypeIDs: [],
                facetValues: {},
            };

        case Action.REGISTER_NEW_WRITE_REVIEW_FORM:
            return updateWriteReviewFormState(state, action.payload.formUUID, {
                mode: action.payload.mode,
            });

        case Action.SET_WRITE_REVIEW_FORM_MODE:
            return updateWriteReviewFormState(state, action.payload.formUUID, {
                mode: action.payload.mode,
            });

        case Action.SET_REVIEW_TEMPLATE:
            return updateWriteReviewFormState(state, action.payload.formUUID, {
                selectedProductID: action.payload.productID,
            });

        case Action.REVIEW_FORM_VARIANT_CHANGE:
            return handleReviewFormVariantChange(state, action.payload);

        case Action.REVIEW_FORM_TEXT_CHANGE:
            return handleReviewFormTextChange(state, action.payload);

        case Action.REVIEW_FORM_CHECKBOX_CHANGE:
            return handleReviewFormCheckboxChange(state, action.payload);

        case Action.REVIEW_FORM_RADIO_CHANGE:
            return handleReviewFormRadioChange(state, action.payload);

        case Action.REVIEW_FORM_SET_ERRORS:
            return updateWriteReviewFormState(state, action.payload.formUUID, {
                errors: action.payload.errors,
                showErrors: action.payload.showErrors,
                showProductSelectError: action.payload.showProductSelectError,
                showVariantSelectError: action.payload.showVariantSelectError,
            });
        case Action.REVIEW_SET_CUSTOMER_INFORMATION_EMAIL:
            return updateCustomerInformationField(state, {
                email_collection: action.payload,
            });
        case Action.REVIEW_SET_CUSTOMER_INFORMATION_NAME:
            return updateCustomerInformationField(state, {
                name: action.payload,
            });
        case Action.REVIEW_SET_CUSTOMER_INFORMATION_LOCATION:
            return updateCustomerInformationField(state, {
                location: action.payload,
            });
    }
    return state;
};

const dataReducer = (
    state = defaultState.data,
    action: IAction,
): IReduxState["data"] => {
    switch (action.type) {
        case Action.SET_PRODUCTS:
            return {
                ...state,
                loadedInitialProducts: true,
                products: action.payload,
            };

        case Action.SET_REVIEW_TEMPLATE:
            return {
                ...state,
                writeReviewTemplates: state.writeReviewTemplates
                    .filter(
                        ({ productID }) =>
                            productID !== action.payload.productID,
                    )
                    .concat([
                        {
                            productID: action.payload.productID,
                            template: action.payload.template,
                        },
                    ]),
            };

        case Action.LOADED_REVIEWS:
            return {
                ...state,
                loadedInitialReviews: true,
                reviews:
                    action.payload.behavior === LoadedReviewsBehavior.REPLACE
                        ? action.payload.reviews
                        : state.reviews.concat(action.payload.reviews),
                facets: action.payload.facets,
            };
    }
    return state;
};

const _innerReducers = combineReducers({
    ui: uiReducer,
    data: dataReducer,
});

export const reducers = guardReducer(
    Action,
    defaultState,
    (oldState = defaultState, action: IAction) => {
        // Run main reducers
        const newState = _innerReducers(oldState, action);

        // If none of the facet values changed, we don't need to re-do the calculations below
        const facetValuesSame =
            newState.ui.facetValues === oldState.ui.facetValues;
        const productTypesSame =
            newState.ui.productTypeIDs === oldState.ui.productTypeIDs;
        if (facetValuesSame && productTypesSame) {
            return newState;
        }

        // Build up some utility data structures to aide in tweaking the selected facet values
        const selectedProductTypeIDs = new Set<IReviewsProductTypeID>(
            newState.ui.productTypeIDs,
        );
        const variantIDMapping = new Map<
            IReviewsProductID,
            Set<IReviewsProductVariantID>
        >(
            newState.data.products.map((product) => [
                product.id,
                new Set<IReviewsProductVariantID>(
                    product.variants.map((v) => v.id),
                ),
            ]),
        );
        const facetValueProductIDs = new Set(
            parseFacetValues(newState.ui.facetValues.product_id).map((pid) =>
                isoReviewsProductID.wrap(parseInt(pid, 10)),
            ),
        );
        let facetValueVariantIDs = new Set(
            parseFacetValues(newState.ui.facetValues.product_variant_id).map(
                (vid) => isoReviewsProductVariantID.wrap(parseInt(vid, 10)),
            ),
        );

        // Apply tweaks to the facet value data
        for (const product of newState.data.products) {
            // Get all the variant IDs for this product
            const productVariantIDs =
                variantIDMapping.get(product.id) || new Set();

            // If this products type is not select, de-select the product and all it's variants
            const hasType = product.product_type !== null;
            const typeIsSelected =
                product.product_type &&
                selectedProductTypeIDs.has(product.product_type);
            if (hasType && !typeIsSelected) {
                facetValueProductIDs.delete(product.id);
                facetValueVariantIDs = difference(
                    facetValueVariantIDs,
                    productVariantIDs,
                );
            }

            // If this product has variants, link the product_id and product_variant_id facets together
            if (productVariantIDs.size > 0) {
                // Figure out which of this products variants are actually selected right now
                const selectedProductVariantIDs = intersection(
                    productVariantIDs,
                    facetValueVariantIDs,
                );
                if (isEqualSet(selectedProductVariantIDs, productVariantIDs)) {
                    // If all of the products variants are selected, select the main product also.
                    facetValueProductIDs.add(product.id);
                } else {
                    if (selectedProductVariantIDs.size > 0) {
                        // Otherwise, only some of the variants are selected, so de-select the main product.
                        facetValueProductIDs.delete(product.id);
                    }
                }
            }
        }

        // Return the new state
        return {
            ...newState,
            ui: {
                ...newState.ui,
                // Open the source filter if it's set to open OR if a source filter is set.
                sourceFilterOpen:
                    newState.ui.sourceFilterOpen ||
                    !!newState.ui.facetValues.source_id,
                // Serialize the tweaked facet values
                facetValues: {
                    ...newState.ui.facetValues,
                    product_id: serializeFacetValues(facetValueProductIDs),
                    product_variant_id:
                        serializeFacetValues(facetValueVariantIDs),
                },
            },
        };
    },
);
