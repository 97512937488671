import React from "react";

import {
    FormatOptions,
    IPhoneNumberField,
} from "../models/formFields.interfaces";
import { IFormFieldState } from "./AbstractFormField";
import { AbstractInput } from "./AbstractInput";
import { getFormatter } from "./formatting";

export class FormPhoneNumber<T extends string> extends AbstractInput<
    T,
    IPhoneNumberField<T>,
    IFormFieldState
> {
    private readonly countryPrefix: string = "+1";
    private readonly countryDelimiters: string[] = [" ", "(", ")", " ", "-"];
    private readonly countryBlocks: number[] = [
        this.countryPrefix.length,
        0,
        3,
        0,
        3,
        4,
    ];

    public static defaultProps = {
        type: "phone",
    };

    public state: IFormFieldState = {
        focused: false,
    };

    private readonly onKeyDown = (_: React.KeyboardEvent<HTMLInputElement>) => {
        // Modify text selection to exclude prefix
        if (this.inputElem) {
            if (!this.inputElem.selectionStart) {
                this.inputElem.selectionStart = this.countryPrefix.length;
            }
            if (this.inputElem.selectionStart < this.countryPrefix.length) {
                this.inputElem.selectionStart = this.countryPrefix.length;
            }
        }
    };

    protected onChange(event: React.FormEvent<HTMLInputElement>) {
        // Format value
        const formatter = getFormatter(this.getFormatOptions());
        const original = event.currentTarget.value;
        const formatted = formatter.format(original);
        if (original !== formatted) {
            event.currentTarget.value = formatted;
        }
        super.onChange(event);
    }

    public componentDidMount() {
        if (this.inputElem) {
            const formatter = getFormatter(this.getFormatOptions());
            formatter.registerCursorTracker(this.inputElem);
        }
        super.componentDidMount();
    }

    protected getInputProps() {
        const inputProps = {
            ...super.getInputProps(),
            type: "tel",
        };
        inputProps.autoComplete ??= "tel";
        if (inputProps.value !== undefined) {
            const formatter = getFormatter(this.getFormatOptions());
            let rawValue = inputProps.value.replace(/[^\d]/g, "");
            // add US prefix if it is missing
            rawValue = rawValue.replace(
                /^(1)?(.*)?$/,
                `${this.countryPrefix}$2`,
            );
            inputProps.value = formatter.format(rawValue);
        }
        return inputProps;
    }

    private getFormatOptions(): FormatOptions {
        return {
            type: "general",
            prefix: this.countryPrefix,
            delimiters: this.countryDelimiters,
            blocks: this.countryBlocks,
            numericOnly: true,
        };
    }

    protected buildControl() {
        const inputProps = this.getInputProps();
        return (
            <input
                ref={(ref) => {
                    this.inputElem = ref;
                }}
                onKeyDown={this.onKeyDown}
                {...inputProps}
            />
        );
    }
}
