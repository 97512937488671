import classNames from "classnames";
import React from "react";
import { t } from "ttag";

import { interpolate } from "@thelabnyc/thelabui/src/utils/i18n";

import { DiscountDisplayMode } from "../apps/configurator/constants";
import { ConfiguratorTypes } from "../constants";
import { IPrice } from "../models/prices.interfaces";
import {
    PriceType,
    getProductPrice,
    isProductPriceDiscounted,
} from "../utils/catalogue";
import format from "../utils/format";
import { ZERO } from "../utils/money";

interface IProps {
    configuratorType?: ConfiguratorTypes;
    strikeThroughMSRP: boolean;
    discountDisplayMode: DiscountDisplayMode;
    actualPriceStyle: string;
    price: IPrice;
    supDollarSign?: boolean;
    alternatePriceView?: boolean;
    pricingMessage?: string | undefined;
    percentageDiscountFormat?: string;
    absoluteDiscountFormat?: string;
    calloutCopy?: string;
    messageClass?: string;
    actualPriceClass?: string;
    retailPriceClass?: string;
}

const PriceDiscountCopy = (props: IProps) => {
    if (props.discountDisplayMode === DiscountDisplayMode.DISABLED) {
        return null;
    }
    // Get format strings
    const percentageDiscountFormat =
        props.percentageDiscountFormat || t`Save %(discountPercent)s%`;
    const absoluteDiscountFormat =
        props.absoluteDiscountFormat || t`Save %(discountAmount)s`;
    // Format discount
    const retailPrice = getProductPrice(props.price, {
        priceType: PriceType.RETAIL,
        includePostDiscountAddons: false,
        quantity: 1,
    });
    const actualPrice = getProductPrice(props.price, {
        priceType: PriceType.COSMETIC_EXCL_TAX,
        includePostDiscountAddons: false,
        quantity: 1,
    });
    const priceDifference = Math.round(
        (actualPrice.toUnit() / retailPrice.toUnit()) * 100,
    );
    // Show discount as a percentage if the percent-off is an even 5%
    let discountFormat: string;
    if (props.discountDisplayMode === DiscountDisplayMode.SHOW_PERCENTAGE) {
        discountFormat = percentageDiscountFormat;
    } else if (
        props.discountDisplayMode === DiscountDisplayMode.SHOW_ABSOLUTE
    ) {
        discountFormat = absoluteDiscountFormat;
    } else {
        discountFormat =
            priceDifference % 5 === 0
                ? percentageDiscountFormat
                : absoluteDiscountFormat;
    }
    const discountAmount = props.supDollarSign
        ? format.decimal(retailPrice.subtract(actualPrice))
        : format.money(retailPrice.subtract(actualPrice));
    const discountedPriceText = interpolate(discountFormat, {
        discountPercent: `${100 - priceDifference}`,
        discountAmount: `${discountAmount}`,
    });
    // Build discount copy
    if (props.supDollarSign) {
        return (
            <span className="configurator__price-discount-copy">
                <span className="configurator__price__dollar-sign">$</span>
                {discountedPriceText}
            </span>
        );
    }
    return (
        <span className="configurator__price-discount-copy">
            {discountedPriceText}
        </span>
    );
};

const PricingMessage = (props: IProps) => {
    const propMessageClass = props.messageClass ? props.messageClass : "false";
    const messageClassNames = classNames({
        "type-message": true,
        [propMessageClass]: props.messageClass ? true : false,
    });
    if (!props.pricingMessage) {
        return null;
    }
    return <span className={messageClassNames}>{props.pricingMessage}</span>;
};

const NonDiscountedPrice = (props: IProps & { actualPriceClasses: string }) => {
    const actualPrice = getProductPrice(props.price, {
        priceType: PriceType.COSMETIC_EXCL_TAX,
        includePostDiscountAddons: true,
        quantity: 1,
    });
    if (props.supDollarSign) {
        return (
            <span className={props.actualPriceClasses}>
                <span className="configurator__price__dollar-sign">$</span>
                {format.decimal(actualPrice)}
            </span>
        );
    }
    return (
        <span className={props.actualPriceClasses}>
            {format.money(actualPrice)}
        </span>
    );
};

const ProductMSRP = (props: IProps) => {
    const propRetailPriceClass = props.retailPriceClass
        ? props.retailPriceClass
        : "false";
    const retailPriceClassNames = classNames({
        configurator__price__retail: true,
        [propRetailPriceClass]: props.retailPriceClass ? true : false,
    });
    const retailPrice = getProductPrice(props.price, {
        priceType: PriceType.RETAIL,
        includePostDiscountAddons: false,
        quantity: 1,
    });
    const screenReaderOriginalPriceEl = (
        <span className="ada-screenreader-only">{t`Original Price:`}</span>
    );
    if (props.strikeThroughMSRP) {
        if (props.supDollarSign) {
            return (
                <span className={retailPriceClassNames}>
                    {screenReaderOriginalPriceEl}
                    <span className="configurator__price__retail--strikethrough">
                        <span className="configurator__price__dollar-sign">
                            $
                        </span>
                        {format.decimal(retailPrice)}
                    </span>
                </span>
            );
        }
        return (
            <span className={retailPriceClassNames}>
                {screenReaderOriginalPriceEl}
                <span className="configurator__price__retail--strikethrough">
                    {format.money(retailPrice)}
                </span>
            </span>
        );
    }
    if (props.supDollarSign) {
        return (
            <span className={retailPriceClassNames}>
                {screenReaderOriginalPriceEl}
                <span className="configurator__price__dollar-sign configurator__price__dollar-sign--retail">
                    $
                </span>
                {format.decimal(retailPrice)}
            </span>
        );
    }
    return (
        <>
            <span className="ada-screenreader-only">{t`Original Price:`}</span>
            <span className={retailPriceClassNames}>
                {format.money(retailPrice)}
            </span>
        </>
    );
};

const ProductActualPrice = (props: IProps & { actualPriceClasses: string }) => {
    const actualPrice = getProductPrice(props.price, {
        priceType: PriceType.COSMETIC_EXCL_TAX,
        includePostDiscountAddons: true,
        quantity: 1,
    });
    const screenReaderCurrentPriceEl = (
        <span className="ada-screenreader-only">{t`Current Price:`}</span>
    );
    if (props.supDollarSign) {
        return (
            <span className={props.actualPriceClasses}>
                {screenReaderCurrentPriceEl}
                <span className="configurator__price__dollar-sign">$</span>
                {format.decimal(actualPrice)}
            </span>
        );
    }
    return (
        <>
            <span className="ada-screenreader-only">{t`Current Price:`}</span>
            <span className={props.actualPriceClasses}>
                {format.money(actualPrice)}
            </span>
        </>
    );
};

export const ConfiguratorProductPrice = (props: IProps) => {
    const propActualPriceClass = props.actualPriceClass
        ? props.actualPriceClass
        : "false";
    // Build class names for actual price
    const isPriceDiscounted = isProductPriceDiscounted(props.price);
    const actualPriceClasses = classNames({
        "configurator__price__actual": true,
        "configurator__price__actual--highlight": isPriceDiscounted,
        "configurator__price__actual--alternate": props.alternatePriceView,
        [`configurator__price__actual--${props.actualPriceStyle}`]:
            !!props.actualPriceStyle,
        [propActualPriceClass]: props.actualPriceClass ? true : false,
    });
    const priceContainerClasses = classNames({
        "configurator__price-container": true,
        "configurator__price-container--discounted": isPriceDiscounted,
        "configurator__price-container--alternate": props.alternatePriceView,
    });
    // If the price is 0, show out of stock messaging
    const actualPrice = getProductPrice(props.price, {
        priceType: PriceType.COSMETIC_EXCL_TAX,
        includePostDiscountAddons: true,
        quantity: 1,
    });
    if (actualPrice.lessThanOrEqual(ZERO)) {
        return (
            <span className={"configurator__price__out-of-stock"}>
                {t`Out of Stock`}
            </span>
        );
    }
    // If the price isn't discounted, just show the amount
    if (!isPriceDiscounted) {
        return (
            <span className={priceContainerClasses}>
                <PricingMessage {...props} />
                <NonDiscountedPrice
                    {...props}
                    actualPriceClasses={actualPriceClasses}
                />
            </span>
        );
    }
    // Assemble and return everything
    return (
        <span className={priceContainerClasses}>
            <PricingMessage {...props} />
            <ProductMSRP {...props} />
            <PriceDiscountCopy {...props} />
            <ProductActualPrice
                {...props}
                actualPriceClasses={actualPriceClasses}
            />
            {props.configuratorType === ConfiguratorTypes.PANEL &&
                isPriceDiscounted &&
                props.calloutCopy && (
                    <span className="configurator__price-offer-copy">
                        {props.calloutCopy}
                    </span>
                )}
        </span>
    );
};
