import { Store } from "@reduxjs/toolkit";

import * as addressAPI from "../../api/address";
import * as shippingAPI from "../../api/shipping";
import { registerCascade } from "../../utils/cascade";
import format from "../../utils/format";
import { Dispatchers as CommonDispatchers } from "../common/dispatchers";
import { IRootState } from "../reducers.interfaces";
import { Dispatchers as CheckoutDispatchers } from "./dispatchers";
import { IReduxState } from "./reducers.interfaces";
import { getCountryCode, getShippingAddress } from "./utils";

type IRootCheckoutState = Pick<IRootState, "checkout">;

export const registerCheckoutCascades = (store: Store<IRootCheckoutState>) => {
    const checkoutDispatchers = new CheckoutDispatchers(store.dispatch);
    const commonDispatchers = new CommonDispatchers(store.dispatch);

    // When the shipping ZIP code changes, update the global entered location
    registerCascade(store, {
        transformState: (storeState: IRootCheckoutState) => {
            return storeState.checkout;
        },

        getKey: (state) => {
            return `${state.form.shipping_line4} ${state.form.shipping_state} ${state.form.shipping_postcode}`;
        },

        doCascade: async (_, state) => {
            if (
                !state.form.shipping_postcode ||
                state.form.shipping_postcode.length < 5
            ) {
                return true;
            }
            const shortZip = state.form.shipping_postcode.slice(0, 5);
            commonDispatchers.updateEnteredLocation({
                formatted_address:
                    state.form.shipping_line4 && state.form.shipping_state
                        ? `${state.form.shipping_line4}, ${state.form.shipping_state}`
                        : shortZip,
                formatted_address_long:
                    state.form.shipping_line1 &&
                    state.form.shipping_line4 &&
                    state.form.shipping_state &&
                    state.form.shipping_postcode
                        ? `${state.form.shipping_line1}, ${
                              state.form.shipping_line4
                          }, ${
                              state.form.shipping_state
                          } ${state.form.shipping_postcode.slice(0, 5)}`
                        : shortZip,
                zip: shortZip,
            });
            return true;
        },
    });

    // Load shipping state choices when the shipping country changes
    registerCascade(store, {
        transformState: (storeState: IRootCheckoutState) => {
            return storeState.checkout;
        },

        getKey: (state) => {
            return getCountryCode(state.form.shipping_country);
        },

        doCascade: async (country) => {
            console.debug(`Cascade: shipping-states-${country}`);
            const states = await addressAPI.loadStates(country);
            checkoutDispatchers.updateStates("shipping", states);
            return true;
        },
    });

    // Load billing state choices when the billing country changes
    registerCascade(store, {
        transformState: (storeState: IRootCheckoutState) => {
            return storeState.checkout;
        },

        getKey: (state) => {
            return getCountryCode(state.form.billing_country);
        },

        doCascade: async (country) => {
            console.debug(`Cascade: billing-states-${country}`);
            const states = await addressAPI.loadStates(country);
            checkoutDispatchers.updateStates("billing", states);
            return true;
        },
    });

    // Load Shipping Methods when shipping_country or shipping_state changes or
    // when basket lines change.
    registerCascade(store, {
        transformState: (storeState: IRootCheckoutState) => {
            return storeState.checkout;
        },

        getKey: (state) => {
            const numLines = state.data.basket
                ? state.data.basket.lines.length
                : 0;
            // Since shipping methods may depend on ZIP code, we need to update
            // them when ZIP code changes. But, we don't want to load the method
            // 5 times in the process of typing all 5 digits. So, only include
            // the ZIP in the refresh key once its at least 5 chars. And then,
            // only include the first 5 chars.
            const postcode = (
                state.form.shipping_postcode.length >= 5
                    ? state.form.shipping_postcode
                    : ""
            ).slice(0, 5);
            const voucherCodes = state.data.basket?.vouchers
                ?.map((voucher) => voucher.code)
                .join("#");
            return [
                getCountryCode(state.form.shipping_country),
                state.form.shipping_state,
                postcode,
                voucherCodes,
                `${numLines}`,
            ].join("-");
        },

        doCascade: async (key, state) => {
            console.debug(`Cascade: load-shipping-methods-${key}`);
            const shippingAddress = getShippingAddress(state);
            shippingAddress.country = getCountryCode(shippingAddress.country);
            if (
                !shippingAddress.country ||
                !shippingAddress.state ||
                !shippingAddress.postcode ||
                !state.data.basket ||
                state.data.basket.lines.length <= 0
            ) {
                return false;
            }
            try {
                const methods = await shippingAPI.loadMethods(shippingAddress);
                checkoutDispatchers.updateShippingMethods(methods, []);
                // Detect the selected shipping method and re-select it in Redux
                let selectedMethodCode = "";
                if (methods.length > 0) {
                    const selectedMethod = methods.find((method) => {
                        return method.selected;
                    });
                    selectedMethodCode = selectedMethod
                        ? selectedMethod.code
                        : methods[0].code;
                }
                // Update the Redux state
                if (state.form.shipping_method !== selectedMethodCode) {
                    checkoutDispatchers.changeFormField({
                        shipping_method: selectedMethodCode,
                    });
                }
            } catch (error) {
                console.error(error);
                checkoutDispatchers.updateShippingMethods(
                    [],
                    error.response.body.errors,
                );
            }
            return true;
        },
    });

    // Update shipping cost when cost changes
    const _findShippingMethod = (state: IReduxState) => {
        return state.data.shipping_methods.find((method) => {
            return state.form.shipping_method === method.code;
        });
    };
    registerCascade(store, {
        transformState: (storeState: IRootCheckoutState) => {
            return storeState.checkout;
        },

        getKey: (state) => {
            const method = _findShippingMethod(state);
            const price = method && method.price ? method.price.incl_tax : "";
            return `${state.form.shipping_method}-${price}`;
        },

        doCascade: async (key, state) => {
            console.debug(`Cascade: calculate-shipping-cost-${key}`);

            const method = _findShippingMethod(state);

            checkoutDispatchers.setDerivedFields({
                shipping_method_cost_excl_tax: format.decimal(
                    method ? method.price.excl_tax : "0.00",
                ),
                shipping_method_cost_incl_tax: format.decimal(
                    method ? method.price.incl_tax : "0.00",
                ),
                shipping_method_cost_before_discount_excl_tax: format.decimal(
                    method ? method.price_before_discount.excl_tax : "0.00",
                ),
                shipping_method_cost_before_discount_incl_tax: format.decimal(
                    method ? method.price_before_discount.incl_tax : "0.00",
                ),
            });

            return true;
        },
    });
};
