import React from "react";
import { signupForEmailOffers } from "tsi-common-react/src/api/user";
import { updateGTMUserHash } from "tsi-common-react/src/utils/analytics";
import { t } from "ttag";

interface IProps {}

interface IState {
    email: string;
    errors: string[];
    isCompleted: boolean;
}

export class EmailSignupFooter extends React.Component<IProps, IState> {
    public state: IState = {
        email: "",
        errors: [],
        isCompleted: false,
    };

    onEmailChange(e: React.FormEvent<HTMLInputElement>) {
        this.setState({
            email: e.currentTarget.value,
        });
    }

    onSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();

        // Update USER_HASH in the datalayer
        updateGTMUserHash(this.state.email);

        signupForEmailOffers(this.state.email)
            .then(() => {
                this.setState({
                    email: "",
                    errors: [],
                    isCompleted: true,
                });
            })
            .catch((err) => {
                this.setState({
                    errors: err.response.body.email || [
                        "An unexpected error occured.",
                    ],
                    isCompleted: false,
                });
            });
    }

    render() {
        let emailSignupElem: JSX.Element | null = null;

        if (this.state.isCompleted) {
            emailSignupElem = (
                <div className="email-sign-up l-capped-width">
                    Thank you for subscribing!
                </div>
            );
        } else {
            emailSignupElem = (
                <div className="email-sign-up l-capped-width">
                    <form
                        className="email-sign-up__form"
                        onSubmit={(e) => {
                            this.onSubmit(e);
                        }}
                    >
                        <div className="floating-label using-placeholder-shown email-sign-up__email email-sign-up__field">
                            <input
                                autoComplete="email"
                                className="email-sign-up__input"
                                placeholder=" "
                                type="email"
                                required={true}
                                value={this.state.email}
                                onChange={(e) => {
                                    this.onEmailChange(e);
                                }}
                                id="sticky-email-input"
                            />
                            <span
                                className="pseudo-placeholder"
                                aria-hidden={true}
                            >
                                {t`Enter email to get offers`}
                            </span>
                            <label htmlFor="sticky-email-input">
                                {t`Enter email to get offers`}
                            </label>
                        </div>
                        <div className="email-sign-up__email email-sign-up__submit al-email-signup__submit al-footer-email-signup__submit">
                            <input
                                className="button email-sign-up__button"
                                type="submit"
                                value="SUBMIT"
                            />
                            <div className="email-sign-up__diagonal"></div>
                        </div>
                    </form>
                    {this.state.errors.map((msg, i) => {
                        return (
                            <p key={i} className="email-sign-up__error">
                                {msg}
                            </p>
                        );
                    })}
                </div>
            );
        }

        return <div>{emailSignupElem}</div>;
    }
}
