import React from "react";
import { t } from "ttag";

import { FormCheckbox } from "../../../forms/FormCheckbox";
import { FormInput } from "../../../forms/FormInput";
import { FormPhoneNumber } from "../../../forms/FormPhoneNumber";
import { FormSelect } from "../../../forms/FormSelect";
import {
    IAddress,
    IAddressCountry,
    IAddressState,
    IBillingAddress,
    IBillingAddressErrorSet,
} from "../../../models/address.interfaces";
import { newAutocomplete } from "../../../utils/maps";
import { SavedAddressSelector } from "../components/SavedAddressSelector";

interface IProps {
    countries: IAddressCountry[];
    states: IAddressState[];
    savedAddresses: IAddress[];
    form: IBillingAddress;
    errors: Partial<IBillingAddressErrorSet>;
    setBillingAddressIsSameAsShipping: (isSame: boolean) => void;
    selectSavedAddress: (
        prefix: "shipping" | "billing",
        savedAddresses: IAddress[],
        selectedAddressURL: string,
    ) => void;
    onChange: (fields: Partial<IBillingAddress>) => void;
    onValidStateChange?: (name: string, errors: string[]) => void;
    showErrorMessages?: boolean;
    isHidden?: boolean;
    disabled: boolean;
}

interface IState {}

export class BillingAddressForm extends React.Component<IProps, IState> {
    private autocomplete: FormInput<"billing_line1"> | null = null;

    componentDidMount() {
        if (!this.autocomplete) {
            return;
        }
        const elem = this.autocomplete.getInputElem();
        if (!elem) {
            return;
        }
        const autocomplete = newAutocomplete(elem);
        autocomplete.addListener("place_changed", () => {
            const place = autocomplete.getPlace();
            if (place.address_components) {
                this.onAutocompleteChange(place.address_components);
            }
        });
    }

    private onAutocompleteChange(
        place: google.maps.GeocoderAddressComponent[],
    ) {
        const placeData: { [s: string]: string } = {};
        const components: { [s: string]: "long_name" | "short_name" } = {
            street_number: "short_name",
            route: "short_name",
            postal_code: "short_name",
            locality: "long_name",
            administrative_area_level_1: "short_name",
        };

        place.forEach((component) => {
            const type = component.types[0];
            const prop = components[type] || "long_name";
            const value = component[prop];
            placeData[type] = value;
        });

        const line1 =
            (placeData.street_number || "") + " " + (placeData.route || "");
        const postcode = placeData.postal_code;
        const line4 = placeData.sublocality_level_1 || placeData.locality;
        const state = placeData.administrative_area_level_1;
        this.props.onChange({
            billing_line1: line1 || this.props.form.billing_line1,
            billing_postcode: postcode || this.props.form.billing_postcode,
            billing_line4: line4 || this.props.form.billing_line4,
            billing_state: state || this.props.form.billing_state,
        });
    }

    private getCountryOptions() {
        const countries = this.props.countries.map((country) => {
            return {
                value: country.url,
                label: country.printable_name,
            };
        });
        return countries;
    }

    private getStateOptions() {
        const states = this.props.states.map((state) => {
            return {
                value: state.code,
                label: state.name,
            };
        });
        states.unshift({
            value: "",
            label: "",
        });
        return states;
    }

    render() {
        const onChange = (
            event:
                | React.FormEvent<HTMLInputElement>
                | React.FormEvent<HTMLSelectElement>,
        ) => {
            this.props.onChange({
                [event.currentTarget.name]: event.currentTarget.value,
            });
        };
        const onBillingAddrCheckboxChange = (
            event: React.FormEvent<HTMLInputElement>,
        ) => {
            this.props.setBillingAddressIsSameAsShipping(
                event.currentTarget.checked,
            );
        };
        return (
            <div style={{ display: this.props.isHidden ? "none" : "block" }}>
                <div className="checkout-step__address-title">
                    <h3 className="checkout-step__address-title--bold">
                        Billing Address
                    </h3>
                    <div>
                        {t`The billing address must match the address on the credit card or bank statement.`}
                    </div>
                </div>
                <FormCheckbox
                    id="billing_addr_is_shipping_addr"
                    name="billing_addr_is_shipping_addr"
                    checked={this.props.form.billing_addr_is_shipping_addr}
                    onChange={onBillingAddrCheckboxChange}
                    label={t`Billing address is the same as shipping address`}
                    disabled={this.props.disabled}
                />
                <div
                    style={{
                        display: this.props.form.billing_addr_is_shipping_addr
                            ? "none"
                            : "block",
                    }}
                >
                    <SavedAddressSelector prefix="billing" />
                    <FormInput
                        type="text"
                        id="billing_first_name"
                        name="billing_first_name"
                        label="First Name"
                        labelPlacement="dynamic"
                        value={this.props.form.billing_first_name}
                        errors={this.props.errors.billing_first_name}
                        onChange={onChange}
                        validation="required"
                        maxLength={25}
                        autoComplete="given-name"
                        disabled={this.props.disabled}
                        onValidStateChange={this.props.onValidStateChange}
                        showErrorMessages={this.props.showErrorMessages}
                    />
                    <FormInput
                        type="text"
                        id="billing_last_name"
                        name="billing_last_name"
                        label="Last Name"
                        labelPlacement="dynamic"
                        value={this.props.form.billing_last_name}
                        errors={this.props.errors.billing_last_name}
                        onChange={onChange}
                        validation="required"
                        maxLength={25}
                        autoComplete="family-name"
                        disabled={this.props.disabled}
                        onValidStateChange={this.props.onValidStateChange}
                        showErrorMessages={this.props.showErrorMessages}
                    />
                    <FormInput
                        ref={(elem) => {
                            this.autocomplete = elem;
                        }}
                        type="text"
                        id="billing_line1"
                        name="billing_line1"
                        label={t`Street and number, P.O. box, c/o.`}
                        labelPlacement="dynamic"
                        adaText={t`Begin typing to search, use arrow keys to navigate, Enter to select`}
                        placeholder=""
                        value={this.props.form.billing_line1}
                        errors={this.props.errors.billing_line1}
                        onChange={onChange}
                        validation="required"
                        maxLength={25}
                        maxBytes={240}
                        autoComplete="address-line1"
                        disabled={this.props.disabled}
                        onValidStateChange={this.props.onValidStateChange}
                        showErrorMessages={this.props.showErrorMessages}
                    />
                    <FormInput
                        type="text"
                        id="billing_line2"
                        name="billing_line2"
                        label={t`Apartment, suite, unit, etc. (Optional)`}
                        labelPlacement="dynamic"
                        value={this.props.form.billing_line2}
                        errors={this.props.errors.billing_line2}
                        onChange={onChange}
                        autoComplete="address-line2"
                        maxLength={25}
                        maxBytes={240}
                        disabled={this.props.disabled}
                        onValidStateChange={this.props.onValidStateChange}
                        showErrorMessages={this.props.showErrorMessages}
                    />
                    <FormInput
                        type="text"
                        id="billing_line4"
                        name="billing_line4"
                        label="City"
                        labelPlacement="dynamic"
                        value={this.props.form.billing_line4}
                        errors={this.props.errors.billing_line4}
                        onChange={onChange}
                        validation="required"
                        autoComplete="address-level2"
                        maxLength={60}
                        maxBytes={60}
                        disabled={this.props.disabled}
                        onValidStateChange={this.props.onValidStateChange}
                        showErrorMessages={this.props.showErrorMessages}
                    />
                    <FormSelect
                        label="State"
                        labelPlacement="dynamic"
                        id="billing_state"
                        name="billing_state"
                        value={this.props.form.billing_state}
                        errors={this.props.errors.billing_state}
                        onChange={onChange}
                        validation="required"
                        choices={this.getStateOptions()}
                        autoComplete="address-level1"
                        disabled={this.props.disabled}
                        onValidStateChange={this.props.onValidStateChange}
                        showErrorMessages={this.props.showErrorMessages}
                    />
                    <FormInput
                        type="text"
                        id="billing_postcode"
                        name="billing_postcode"
                        label={t`Zip Code`}
                        labelPlacement="dynamic"
                        value={this.props.form.billing_postcode}
                        errors={this.props.errors.billing_postcode}
                        onChange={onChange}
                        validation={["required", "zipcode"]}
                        autoComplete="postal-code"
                        maxLength={10}
                        maxBytes={60}
                        disabled={this.props.disabled}
                        onValidStateChange={this.props.onValidStateChange}
                        showErrorMessages={this.props.showErrorMessages}
                    />
                    <FormSelect
                        label={t`Country`}
                        labelPlacement="dynamic"
                        id="billing_country"
                        name="billing_country"
                        value={this.props.form.billing_country}
                        errors={this.props.errors.billing_country}
                        onChange={onChange}
                        validation="required"
                        choices={this.getCountryOptions()}
                        autoComplete="country"
                        disabled={this.props.disabled}
                        onValidStateChange={this.props.onValidStateChange}
                        showErrorMessages={this.props.showErrorMessages}
                    />
                    <FormPhoneNumber
                        type="phone"
                        id="billing_phone_number"
                        name="billing_phone_number"
                        label={t`Phone Number`}
                        labelPlacement="dynamic"
                        value={this.props.form.billing_phone_number}
                        errors={this.props.errors.billing_phone_number}
                        onChange={onChange}
                        validation={["required", "phone"]}
                        autoComplete="tel"
                        disabled={this.props.disabled}
                        onValidStateChange={this.props.onValidStateChange}
                        showErrorMessages={this.props.showErrorMessages}
                    />
                </div>
            </div>
        );
    }
}
