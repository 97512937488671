import { Key } from "ts-key-enum";

import { BreakPoint } from "../models/screen.interfaces";
import { getViewportBreakpoint } from "../utils/detectMobile";
import {
    focusElement,
    getFocusableElems,
    trapFocus,
} from "../utils/keyboardFocus";
import { getPageSetting } from "../utils/settings";

const closeRichNavOnBodyClick = (event: Event) => {
    event.preventDefault();
    const target = event.target as HTMLElement;
    if (
        document.getElementById("store-locator-panel-trigger")?.contains(target)
    ) {
        return false;
    }
    if (isRichNavOpen()) {
        closeRichNav();
    }
    return true;
};

export const openRichNav = (shouldCloseOnOutsideClick?: boolean) => {
    document
        .querySelector<HTMLElement>(".mobile-toggle")
        ?.classList.add("open");
    document
        .querySelector<HTMLElement>(".mobile-toggle")
        ?.setAttribute("aria-expanded", "true");
    displayRichNavigationElem("false");
    document.body.classList.add("modal-open");
    const richNavElem = document.querySelector<HTMLElement>(".rich-navigation");
    richNavElem?.addEventListener("click", (event) => {
        event.stopPropagation();
    });
    if (shouldCloseOnOutsideClick && richNavElem) {
        richNavElem?.addEventListener("transitionend", () => {
            const bodyWithModal =
                document.querySelector<HTMLElement>(".modal-open");
            if (bodyWithModal) {
                bodyWithModal.addEventListener(
                    "click",
                    closeRichNavOnBodyClick,
                );
            }
        });
    }
};

export const closeRichNav = () => {
    document
        .querySelector<HTMLElement>(".mobile-toggle")
        ?.classList.remove("open");
    document
        .querySelector<HTMLElement>(".mobile-toggle")
        ?.setAttribute("aria-expanded", "false");
    displayRichNavigationElem("true");
    document.body.classList.remove("modal-open");
    document.body.removeEventListener("click", closeRichNavOnBodyClick);
};

export const displayRichNavigationElem = (hide: string) => {
    const navElem = document.querySelector<HTMLElement>("nav.rich-navigation");
    if (navElem) {
        navElem.setAttribute("aria-hidden", hide);
        setBodyMinHeight(navElem);
    }
};

export const isRichNavOpen = () => {
    const navElem = document.querySelector<HTMLElement>("nav.rich-navigation");
    if (!navElem) {
        return false;
    }
    return navElem.getAttribute("aria-hidden") === "false";
};

export const setBodyMinHeight = (navElem: HTMLElement) => {
    const collectionContent =
        navElem.querySelector<HTMLElement>(
            ".rich-navigation--link-collection .collection[aria-hidden=false]",
        ) || navElem;
    const navRect = navElem.getBoundingClientRect();
    const collectionRect = collectionContent.getBoundingClientRect();
    const newBodyMinHeight =
        window.scrollY + Math.max(navRect.bottom, collectionRect.bottom) + 20; // Add 20px to allow for the box shadow
    document.body.style.minHeight = `${newBodyMinHeight}px`;
};

export const buildMobileNavToggleClickHandler = (
    elem: HTMLElement,
    shouldCloseOnOutsideClick?: boolean,
) => {
    return (e: MouseEvent) => {
        e.preventDefault();
        const isOpen = isRichNavOpen();
        const mobileNavElem =
            document.querySelector<HTMLElement>("#mobile-nav");
        if (isOpen) {
            elem.classList.remove("open");
            closeRichNav();
            mobileNavElem?.classList.remove("open");
        } else {
            elem.classList.add("open");
            openRichNav(shouldCloseOnOutsideClick);
            mobileNavElem?.classList.add("open");
        }
    };
};

const closeAllCollections = (elem: HTMLElement) => {
    const collections = elem.querySelectorAll<HTMLElement>(
        ".rich-navigation--link-collection",
    );
    Array.from(collections).forEach((linkCollection) => {
        const collectionButton =
            linkCollection.querySelector<HTMLElement>("button");
        const collectionContent =
            linkCollection.querySelector<HTMLElement>(".collection");

        if (collectionButton && collectionContent) {
            collectionButton.setAttribute("aria-expanded", "false");
            collectionContent.setAttribute("aria-hidden", "true");
        }
    });
    // Restore the body's min-height as it was before opening the collection.
    setBodyMinHeight(elem);
};

const openCollection = (
    event: KeyboardEvent | MouseEvent,
    navElem: HTMLElement,
    collectionButton: HTMLElement,
    collectionContent: HTMLElement,
) => {
    // Close any other open collections
    closeAllCollections(navElem);
    // Make the content visible
    collectionButton.setAttribute("aria-expanded", "true");
    collectionContent.setAttribute("aria-hidden", "false");
    // If the triggering event was a keypress,
    // put keyboard focus on the first link in the collection.
    if (event.type === "keydown") {
        const links = getFocusableElems(collectionContent);
        if (links) {
            focusElement(links[0]);
            event.preventDefault();
        }
    }
    // Trap focus within the collection (like a modal).
    trapFocus(collectionContent);
    // Set body min-height to at least cover the collection content.
    setBodyMinHeight(navElem);
};

const closeCollection = (
    event: KeyboardEvent | MouseEvent,
    navElem: HTMLElement,
    collectionButton: HTMLElement,
) => {
    // Close all the collections
    closeAllCollections(navElem);
    // If the triggering event was a keypress,
    // return keyboard focus to the link which opened the collection.
    if (event.type === "keydown") {
        focusElement(collectionButton);
    }
};

const toggleCollection = (
    event: KeyboardEvent | MouseEvent,
    navElem: HTMLElement,
    collectionButton: HTMLElement,
    collectionContent: HTMLElement,
) => {
    const isOpen = collectionButton.getAttribute("aria-expanded") === "true";
    if (isOpen) {
        closeCollection(event, navElem, collectionButton);
    } else {
        openCollection(event, navElem, collectionButton, collectionContent);
    }
};

const initLinkCollectionForDesktop = (
    navElem: HTMLElement,
    collectionButton: HTMLElement,
    collectionContent: HTMLElement,
) => {
    // When collection link is focused and ENTER is pressed, open the collection.
    collectionButton.addEventListener("keydown", (event) => {
        if (event.key === Key.Enter) {
            openCollection(event, navElem, collectionButton, collectionContent);
        }
    });
    // When collection link is focused and ESCAPE is pressed, or last
    // collection link has focus, close the collection and return keyboard
    // focus to the link which opened it.
    collectionContent.addEventListener("keydown", (event) => {
        const lastMenuItem = collectionContent.querySelector(
            "div.links > a:last-of-type",
        );
        if (
            (event.key === Key.Tab &&
                !event.shiftKey &&
                event.target === lastMenuItem) ||
            event.key === Key.Escape
        ) {
            closeCollection(event, navElem, collectionButton);
        }
    });
    // When mouse leaves a collection link, open the collection.
    collectionButton.addEventListener("mouseover", (event) => {
        openCollection(event, navElem, collectionButton, collectionContent);
    });
    // When mouse leaves collection content, close the collection.
    collectionContent.addEventListener("mouseleave", (event) => {
        closeCollection(event, navElem, collectionButton);
    });
    // Open / close collections on link click
    // As long as the `aria-expanded` attribute is there
    if (collectionButton.hasAttribute("aria-expanded")) {
        collectionButton.addEventListener("click", (event) => {
            toggleCollection(
                event,
                navElem,
                collectionButton,
                collectionContent,
            );
        });
    }
};

const initLinkCollectionForMobile = (
    navElem: HTMLElement,
    collectionButton: HTMLElement,
    collectionContent: HTMLElement,
) => {
    // Open / close collections on link click
    // As long as the `aria-expanded` attribute is there
    if (collectionButton.hasAttribute("aria-expanded")) {
        collectionButton.addEventListener("click", (event) => {
            toggleCollection(
                event,
                navElem,
                collectionButton,
                collectionContent,
            );
        });
    }
};

export const initRichNavigation = async (navElem: HTMLElement) => {
    const currentBreakPoint = getViewportBreakpoint();
    const parentContainer = navElem.parentElement;
    const isCocoon = getPageSetting("app-slug") === "cocoon";

    if (isCocoon && currentBreakPoint >= BreakPoint.LARGE) {
        // Close all collections when mouse leaves the nav elem
        parentContainer?.addEventListener("mouseleave", () => {
            closeAllCollections(navElem);
        });

        // Close all collections when mousing over another link (that may not
        // be a collection itself).
        Array.from(navElem.querySelectorAll("a"))
            .filter((node) => node.parentNode === navElem)
            .forEach((link) => {
                link.addEventListener("mouseover", () => {
                    closeAllCollections(navElem);
                });
            });
    }

    // Rich Navigation Link Collections
    const linkCollections = navElem.querySelectorAll<HTMLElement>(
        ".rich-navigation--link-collection",
    );
    Array.from(linkCollections).forEach((linkCollection) => {
        const collectionButton =
            linkCollection.querySelector<HTMLElement>("button");
        const collectionContent =
            linkCollection.querySelector<HTMLElement>(".collection");

        if (!collectionButton || !collectionContent) {
            return;
        }

        if (isCocoon && currentBreakPoint >= BreakPoint.LARGE) {
            // Handle larger screen sizes with hover interactions on Cocoon
            initLinkCollectionForDesktop(
                navElem,
                collectionButton,
                collectionContent,
            );
        } else {
            // Handle smaller screen sizes with click/touch interactions
            initLinkCollectionForMobile(
                navElem,
                collectionButton,
                collectionContent,
            );
        }
    });
};
