import {
    IWriteReviewTemplate,
    ReviewSortOption,
} from "../../models/reviews.interfaces";
import { WriteReviewFormMode } from "./constants";
import { IReduxState, IWriteReviewFormState } from "./reducers.interfaces";

const sortOptions = [
    {
        label: "Highest Rated",
        value: ReviewSortOption.HIGHEST_RATED,
    },
    {
        label: "Lowest Rated",
        value: ReviewSortOption.LOWEST_RATED,
    },
    {
        label: "Newest",
        value: ReviewSortOption.NEWEST,
    },
    {
        label: "Oldest",
        value: ReviewSortOption.OLDEST,
    },
];

export const defaultReviewTemplate: IWriteReviewTemplate = {
    rating: {
        field_id: "ag14350",
        field_type: "simple",
        input_type: "Stars",
        is_merchant_specific: false,
        is_required: true,
        is_tag: false,
        key: "rating",
        max_length: 5,
        name: "Overall Rating",
        options: [],
        response_type: "numeric",
        type: "numeric",
    },
    name: {
        field_id: "ag14349",
        field_type: "simple",
        input_type: "TextBox",
        is_merchant_specific: false,
        is_required: true,
        is_tag: false,
        key: "name",
        max_length: 40,
        name: "Nickname",
        options: [],
        response_type: "text",
        type: "text",
    },
    headline: {
        field_id: "ag14351",
        field_type: "simple",
        input_type: "TextBox",
        is_merchant_specific: false,
        is_required: true,
        is_tag: false,
        key: "headline",
        max_length: 60,
        name: "Review Headline",
        options: [],
        response_type: "text",
        type: "text",
    },
    comments: {
        field_id: "ag14348",
        field_type: "simple",
        input_type: "TextArea",
        is_merchant_specific: false,
        is_required: true,
        is_tag: false,
        key: "comments",
        max_length: null,
        name: "Describe your experience with the product",
        options: [],
        response_type: "text",
        type: "text",
    },
    location: {
        field_id: "ag14347",
        field_type: "simple",
        input_type: "TextBox",
        is_merchant_specific: false,
        is_required: true,
        is_tag: false,
        key: "location",
        max_length: 40,
        name: "Location",
        options: [],
        response_type: "text",
        type: "text",
    },
    bottomline: {
        field_id: "ag40",
        field_type: "collection",
        input_type: "RadioVertical",
        is_merchant_specific: false,
        is_required: true,
        is_tag: false,
        key: "bottomline",
        max_length: null,
        name: "Do you recommend this product?",
        options: [
            "Yes, I would recommend this product",
            "No, I would not recommend this product",
        ],
        response_type: "single",
        type: "single",
    },
    describeyourself: {
        field_id: "ag23154",
        field_type: "collection",
        input_type: "RadioVertical",
        is_merchant_specific: false,
        is_required: false,
        is_tag: false,
        key: "describeyourself",
        max_length: null,
        name: "Describe Yourself",
        options: ["Budget Shopper", "Midrange Shopper", "High-end Shopper"],
        response_type: "single",
        type: "single",
    },
    email_collection: {
        field_id: null,
        field_type: "simple",
        input_type: "TextBox",
        is_merchant_specific: false,
        is_required: false,
        is_tag: false,
        key: "email_collection",
        max_length: null,
        name: "Your email address",
        options: [],
        response_type: "email",
        type: "email",
    },
};

export const getDefaultWriteReviewFormState = (): IWriteReviewFormState => {
    return {
        mode: WriteReviewFormMode.CLOSED,
        selectedProductID: null,
        selectedVariant: "",
        started: new Date(),
        valuesText: {},
        valuesMultiSelect: {},
        errors: {},
        showErrors: false,
        showProductSelectError: false,
        showVariantSelectError: false,
    };
};

export const defaultState: IReduxState = {
    ui: {
        mobileSideNavOpen: false,
        selectedSortOption: ReviewSortOption.HIGHEST_RATED,
        page: 1,
        hasMore: true,
        productTypeIDs: [],
        facetValues: {},
        loadedFacetValues: {},
        sourceFilterOpen: false,
        customerInformation: {
            email_collection: "",
            name: "",
            location: "",
        },
        writeReviewForms: {},
    },
    data: {
        loadedInitialProducts: false,
        loadedInitialReviews: false,
        sortOptions: sortOptions,
        products: [],
        writeReviewTemplates: [],
        reviews: [],
        facets: [],
    },
};
