import React from "react";

import { Link } from "../common/Link";
import { IFormButtonLink } from "../models/formFields.interfaces";
import { AbstractFormComponent } from "./AbstractFormComponent";

export class FormButtonLink extends AbstractFormComponent<
    IFormButtonLink,
    Record<string, never>
> {
    protected buildContent() {
        return (
            <Link
                href={this.props.href}
                className="button button--inverse"
                target={this.props.target || "_top"}
            >
                {this.props.text}
            </Link>
        );
    }
}
