import React from "react";
import SVG from "react-inlinesvg";

import iconBlog from "../../img/icons/blog.svg";
import iconFacebookF from "../../img/icons/facebook-f.svg";
import iconInstagram from "../../img/icons/instagram.svg";
import iconTwitter from "../../img/icons/twitter.svg";
import iconYoutubePlay from "../../img/icons/youtube-play.svg";

interface IProps {
    type: string | null | undefined;
    className: string;
}

const icons = {
    instagram: iconInstagram,
    facebook: iconFacebookF,
    youtube: iconYoutubePlay,
    twitter: iconTwitter,
    blog: iconBlog,
};

const isValidIconType = (
    type: string | null | undefined,
): type is keyof typeof icons => {
    if (!type) {
        return false;
    }
    return icons[type as keyof typeof icons] !== undefined;
};

export const SocialIcon = (props: IProps) => {
    if (!isValidIconType(props.type)) {
        return null;
    }
    const icon = icons[props.type];
    return <SVG className={props.className} src={icon} />;
};
