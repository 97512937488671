import { getCurrentScope } from "@sentry/browser";

import { IWebPageURL, isoWebPageURL } from "../models/nominals";
import {
    CSRAssistedUser,
    EmailOfferSignupResponse,
    OIDCTokenRefreshResponse,
    SessionKeepAliveResponse,
    User,
    UserLoginResponse,
} from "../models/user";
import {
    ICSRAssistedUser,
    IEmailOfferSignupRequest,
    IEmailOfferSignupResponse,
    IUser,
    IUserLoginResponse,
} from "../models/user.interfaces";
import { check } from "../models/utils";
import { CSRF_HEADER, ajax, getCSRFToken } from "../utils/ajax";
import { PromiseMutex } from "../utils/mutex";

export const load = async () => {
    const mutex = new PromiseMutex<IUser>("load-current-user");
    let loading = mutex.getPromise();
    if (!loading) {
        loading = ajax
            .get("/api/user/")
            .set("Accept", "application/json")
            .then((resp): IUser => {
                const user = check(User.decode(resp.body));
                getCurrentScope().setUser(resp.body);
                return user;
            });
        mutex.setPromise(loading);
    }
    return loading;
};

export const getCurrentlyAssistedUser = async () => {
    const mutex = new PromiseMutex<ICSRAssistedUser | null>(
        "load-currently-assisted-user",
    );
    let loading = mutex.getPromise();
    if (!loading) {
        loading = ajax
            .get("/api/csr/users/currently_assisted_user/")
            .set("Accept", "application/json")
            .then((resp): ICSRAssistedUser | null => {
                if (!resp.body) {
                    return null;
                }
                return check(CSRAssistedUser.decode(resp.body));
            });
        mutex.setPromise(loading);
    }
    return loading;
};

export const updateAssistedUserEmail = async (
    userURL: string,
    email: string,
): Promise<ICSRAssistedUser> => {
    const resp = await ajax
        .patch(userURL)
        .set(CSRF_HEADER, await getCSRFToken())
        .set("Accept", "application/json")
        .send({ email: email });
    return check(CSRAssistedUser.decode(resp.body));
};

export const update = async (data: Partial<IUser>): Promise<IUser> => {
    const resp = await ajax
        .patch("/api/user/")
        .set("Accept", "application/json")
        .set(CSRF_HEADER, await getCSRFToken())
        .send(data);
    const user = check(User.decode(resp.body));
    getCurrentScope().setUser(resp.body);
    return user;
};

export const updateEmailAddress = async (email: string): Promise<IUser> => {
    return update({
        email: email,
    });
};

export const changePassword = async (newPassword: string): Promise<void> => {
    await ajax
        .put("/api/user/change-password/")
        .set("Accept", "application/json")
        .set(CSRF_HEADER, await getCSRFToken())
        .send({
            password: newPassword,
        });
};

export const login = async (
    email: string,
    password: string,
    nextURL?: IWebPageURL,
): Promise<IUserLoginResponse> => {
    const resp = await ajax
        .post("/api/user/login/")
        .set("Accept", "application/json")
        .set(CSRF_HEADER, await getCSRFToken())
        .send({
            email: email,
            password: password,
            nextURL: nextURL ? isoWebPageURL.unwrap(nextURL) : undefined,
        });
    return check(UserLoginResponse.decode(resp.body));
};

export const sendSessionKeepAlivePing = async () => {
    try {
        const resp = await ajax
            .get("/api/user/keep-alive/")
            .set("Accept", "application/json")
            .set("x-requested-with", "XMLHttpRequest")
            .query({ now: new Date().getTime() });
        return check(SessionKeepAliveResponse.decode(resp.body));
    } catch (err) {
        if (err?.response?.body) {
            return check(OIDCTokenRefreshResponse.decode(err.response.body));
        }
        throw err;
    }
};

export const signupForEmailOffers = async (
    email: string,
    zipcode?: string,
    campaign_id?: string,
): Promise<IEmailOfferSignupResponse> => {
    const reqData: IEmailOfferSignupRequest = {
        email: email,
        zipcode: zipcode,
        campaign_id: campaign_id,
    };
    const resp = await ajax
        .post("/api/email-offers-signup/")
        .set("Accept", "application/json")
        .set(CSRF_HEADER, await getCSRFToken())
        .send(reqData);
    return check(EmailOfferSignupResponse.decode(resp.body));
};
