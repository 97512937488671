import * as t from "io-ts";

import {
    CreditApplicationSubmissionResult,
    FinancingAccountInquiry,
    FinancingApplication,
    FinancingApplicationErrorSet,
    FinancingApplicationFields,
    FinancingDocument,
    FinancingPreQualRequestResponse,
    FinancingPreQualResponse,
    TBackendType,
} from "../../models/financing";
import { ErrorSet } from "../../models/formFields";
import { FormStep } from "../../models/forms";
import {
    PartialRecord,
    Range,
    TupleOf,
    codecFromConstMap,
    codecFromEnum,
    nullable,
} from "../../models/utils";
import { FinancingAppType, HousingStatus, ModalStateType } from "./constants";

const THousingStatus = codecFromConstMap("HousingStatus", HousingStatus);

const TFinancingAppType = codecFromEnum("FinancingAppType", FinancingAppType);

export type IndividualSteps = Readonly<
    TupleOf<FormStep<FinancingApplicationFields>, 4>
>;
export type JointSteps = Readonly<
    TupleOf<FormStep<FinancingApplicationFields>, 8>
>;
const numSteps: JointSteps["length"] = 8;
export type StepIndex = Range<0, JointSteps["length"]>;
export const StepIndex = {
    MAIN_CONTACT: 0,
    MAIN_INCOME: 1,
    MAIN_IDENT: 2,
    MAIN_TERMS: 3,
    JOINT_CONTACT: 4,
    JOINT_INCOME: 5,
    JOINT_IDENT: 6,
    JOINT_TERMS: 7,
} as const satisfies {
    [T: string]: StepIndex;
};

export type StepIndexFinancingFormNameMapType = {
    [V in (typeof StepIndex)[keyof typeof StepIndex]]: `financing_${string}`;
};

export const StepIndexFinancingFormNameMap: StepIndexFinancingFormNameMapType =
    {
        [StepIndex.MAIN_CONTACT]: "financing_contact_information",
        [StepIndex.MAIN_INCOME]: "financing_financial_information",
        [StepIndex.MAIN_IDENT]: "financing_identification_information",
        [StepIndex.MAIN_TERMS]: "financing_terms_and_details",
        [StepIndex.JOINT_CONTACT]: "financing_joint_contact_information",
        [StepIndex.JOINT_INCOME]: "financing_joint_financial_information",
        [StepIndex.JOINT_IDENT]: "financing_joint_identification_information",
        [StepIndex.JOINT_TERMS]: "financing_joint_terms_and_details",
    };

export const TStepIndex = Range(0, numSteps);

export const AgreeFields = t.keyof({
    agree_disclosure: null,
    agree_disclosure_joint: null,
    esign: null,
    esign_joint: null,
});
export type AgreeFields = t.TypeOf<typeof AgreeFields>;

export const AgreeState = t.record(AgreeFields, t.boolean);
export type AgreeState = t.TypeOf<typeof AgreeState>;

export const AgreeErrors = PartialRecord(AgreeFields, t.array(t.string));
export type AgreeErrors = t.TypeOf<typeof AgreeErrors>;

export const ClientErrors = t.intersection([
    FinancingApplicationErrorSet,
    AgreeErrors,
]);
export type ClientErrors = t.TypeOf<typeof ClientErrors>;

export const FullApplicationValues = t.intersection([
    FinancingApplication,
    AgreeState,
]);
export type FullApplicationValues = t.TypeOf<typeof FullApplicationValues>;

export const PrefillData = t.partial({
    first_name: t.string,
    last_name: t.string,
    middle_initial: t.string,
    email: t.string,
    address_line_1: t.string,
    address_line_2: t.string,
    city: t.string,
    state_code: t.string,
    postal_code: t.string,
    purchase_price: t.number,
    employer_name: t.string,
    home_phone: t.string,
    mobile_phone: t.string,
    work_phone: t.string,
    housing_status: THousingStatus,
    joint_first_name: t.string,
    joint_last_name: t.string,
    joint_middle_initial: t.string,
    joint_email: t.string,
    joint_address_line_1: t.string,
    joint_address_line_2: t.string,
    joint_city: t.string,
    joint_state_code: t.string,
    joint_postal_code: t.string,
    joint_home_phone: t.string,
    joint_mobile_phone: t.string,
    joint_work_phone: t.string,
});
export type PrefillData = t.TypeOf<typeof PrefillData>;

/**
 * Financing Modal is Closed.
 */
export const ModalClosed = t.type({
    _tag: t.literal(ModalStateType.CLOSED),
});
export type ModalClosed = t.TypeOf<typeof ModalClosed>;

/**
 * Financing Modal is open to the PreQual form
 */
export const ModalPrequalInitial = t.type({
    _tag: t.literal(ModalStateType.PREQUAL_INITIAL),
});
export type ModalPrequalInitial = t.TypeOf<typeof ModalPrequalInitial>;

/**
 * Financing Modal is open to the PreQual form and in the process of submitting
 * data to the server.
 */
export const ModalPrequalSubmitting = t.type({
    _tag: t.literal(ModalStateType.PREQUAL_SUBMITTING),
});
export type ModalPrequalSubmitting = t.TypeOf<typeof ModalPrequalSubmitting>;

/**
 * Financing Modal is open to the PreQual form and the server has returned some
 * validation errors that need fixed.
 */
export const ModalPrequalError = t.type({
    _tag: t.literal(ModalStateType.PREQUAL_ERROR),
    errors: ErrorSet,
});
export type ModalPrequalError = t.TypeOf<typeof ModalPrequalError>;

/**
 * Financing Modal is open to the PreQual Approved screen.
 */
export const ModalPreQualApproved = t.type({
    _tag: t.literal(ModalStateType.PREQUAL_APPROVED),
    data: FinancingPreQualRequestResponse,
    approvedResponse: FinancingPreQualResponse,
});
export type ModalPreQualApproved = t.TypeOf<typeof ModalPreQualApproved>;

/**
 * Financing Modal is open to the PreQual Denied screen.
 */
export const ModalPreQualDenied = t.type({
    _tag: t.literal(ModalStateType.PREQUAL_DENIED),
    data: FinancingPreQualRequestResponse,
});
export type ModalPreQualDenied = t.TypeOf<typeof ModalPreQualDenied>;

/**
 * Financing Modal is open to the PreQual Existing Cardholder screen.
 */
export const ModalPreQualExistingCardHolder = t.type({
    _tag: t.literal(ModalStateType.PREQUAL_EXISTING_CARDHOLDER),
    data: FinancingPreQualRequestResponse,
});
export type ModalPreQualExistingCardHolder = t.TypeOf<
    typeof ModalPreQualExistingCardHolder
>;

/**
 * Financing Modal is closed, but holds approved prescreen data.
 */
export const ModalPreScreenApproved = t.type({
    _tag: t.literal(ModalStateType.PRESCREEN_APPROVED),
    data: FinancingPreQualRequestResponse,
    approvedResponse: FinancingPreQualResponse,
});
export type ModalPreScreenApproved = t.TypeOf<typeof ModalPreScreenApproved>;

/**
 * Shared state for all full-apply-form states
 */
export const SharedFullFormState = t.type({
    backendType: TBackendType,
    appType: TFinancingAppType,
    values: FullApplicationValues,
    currStepIndex: TStepIndex,
    disabledSteps: t.array(TStepIndex),
});
export type SharedFullFormState = t.TypeOf<typeof SharedFullFormState>;

/**
 * Financing Modal is open to the Full Application form.
 */
export const ModalFullApplyInitial = t.intersection([
    t.type({
        _tag: t.literal(ModalStateType.APPLY_INITIAL),
    }),
    SharedFullFormState,
]);
export type ModalFullApplyInitial = t.TypeOf<typeof ModalFullApplyInitial>;

/**
 * Financing Modal is open to the Full Application form and is in the process
 * of submitting data to the server.
 */
export const ModalFullApplySubmitting = t.intersection([
    t.type({
        _tag: t.literal(ModalStateType.APPLY_SUBMITTING),
    }),
    SharedFullFormState,
]);
export type ModalFullApplySubmitting = t.TypeOf<
    typeof ModalFullApplySubmitting
>;

/**
 * Financing Modal is open to the Full Application form and and the server has
 * returned some validation errors that need fixed.
 */
export const ModalFullApplyError = t.intersection([
    t.type({
        _tag: t.literal(ModalStateType.APPLY_ERROR),
        errors: FinancingApplicationErrorSet,
    }),
    SharedFullFormState,
]);
export type ModalFullApplyError = t.TypeOf<typeof ModalFullApplyError>;

/**
 * Full Application form has been submitted and the server reports that the
 * primary financing backend denied the application, but the secondary provider
 * has preapproved the application. The app should be resubmitted with the
 * secondary backend's precursor (assuming the customer approves of this).
 */
export const ModalFullApplyNextBackend = t.intersection([
    t.type({
        _tag: t.literal(ModalStateType.APPLY_NEXT_BACKEND),
        prequal: FinancingPreQualRequestResponse,
    }),
    SharedFullFormState,
]);
export type ModalFullApplyNextBackend = t.TypeOf<
    typeof ModalFullApplyNextBackend
>;

/**
 * Full Application form has been submitted and the server reports that the
 * application is pending.
 */
export const ModalFullApplyPending = t.type({
    _tag: t.literal(ModalStateType.APPLY_PENDING),
    isRefreshing: t.boolean,
    appType: TFinancingAppType,
    values: FullApplicationValues,
    backendType: TBackendType,
});
export type ModalFullApplyPending = t.TypeOf<typeof ModalFullApplyPending>;

/**
 * Full Application form has been submitted and the server reports that the
 * application is approved.
 */
export const ModalFullApplyApproved = t.type({
    _tag: t.literal(ModalStateType.APPLY_APPROVED),
    account: FinancingAccountInquiry,
    documents: t.array(FinancingDocument),
});
export type ModalFullApplyApproved = t.TypeOf<typeof ModalFullApplyApproved>;

/**
 * Full Application form has been submitted and the server reports that the
 * application is Denied.
 */
export const ModalFullApplyDenied = t.type({
    _tag: t.literal(ModalStateType.APPLY_DENIED),
    appType: TFinancingAppType,
    values: FullApplicationValues,
    result: nullable(CreditApplicationSubmissionResult),
});
export type ModalFullApplyDenied = t.TypeOf<typeof ModalFullApplyDenied>;

/**
 * Full Application form has been submitted and the server reports that the
 * application is is for an existing cardholder.
 */
export const ModalFullApplyExistingCardholder = t.type({
    _tag: t.literal(ModalStateType.APPLY_EXISTING_CARDHOLDER),
});
export type ModalFullApplyExistingCardholder = t.TypeOf<
    typeof ModalFullApplyExistingCardholder
>;

export const ModalPreQualFormState = t.union([
    ModalPrequalInitial,
    ModalPrequalSubmitting,
    ModalPrequalError,
]);
export type ModalPreQualFormState = t.TypeOf<typeof ModalPreQualFormState>;

export const ModalFullFormState = t.union([
    ModalFullApplyInitial,
    ModalFullApplySubmitting,
    ModalFullApplyError,
    ModalFullApplyNextBackend,
]);
export type ModalFullFormState = t.TypeOf<typeof ModalFullFormState>;

export const ModalState = t.union([
    ModalClosed,
    ModalPreQualFormState,
    ModalPreQualApproved,
    ModalPreQualDenied,
    ModalPreQualExistingCardHolder,
    ModalPreScreenApproved,
    ModalFullFormState,
    ModalFullApplyApproved,
    ModalFullApplyPending,
    ModalFullApplyDenied,
    ModalFullApplyExistingCardholder,
]);
export type ModalState = t.TypeOf<typeof ModalState>;

export const FinancingState = t.type({
    purchasePrice: nullable(t.number),
    applicationSource: nullable(t.string),
    prefillData: PrefillData,
    closeAttempts: t.number,
    preQualApproval: nullable(FinancingPreQualResponse),
    modal: ModalState,
});
export type FinancingState = t.TypeOf<typeof FinancingState>;
