import classNames from "classnames";
import React from "react";
import { connect } from "react-redux";

import {
    IReviewQueryFacets,
    ISearchFacetOption,
    ISearchFacetSubOption,
} from "../../../models/reviews.interfaces";
import { parseFacetValues } from "../../../utils/reviews";
import { TDispatchMapper, TStateMapper } from "../../reducers.interfaces";
import { defaultState } from "../defaults";
import { Dispatchers } from "../dispatchers";

import styles from "./FilterOption.module.scss";

interface IOwnProps {
    facetType: IReviewQueryFacets;
    option: ISearchFacetOption | ISearchFacetSubOption;
    showCount: boolean;
}

interface IReduxProps {
    isChecked: boolean;
}
interface IDispatchProps {
    updateFilterOptionValue: Dispatchers["updateFilterOptionValue"];
}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

const FilterOptionComponent = (props: IProps) => {
    const optionName = `${props.option.name}`.trim();
    const inputID =
        `${props.facetType}-${props.option.option_id}`.toLowerCase();

    const checkboxClasses = classNames({
        [styles.checkbox]: true,
        [styles.checkboxVariant]: true,
    });

    const variantClasses = classNames({
        [styles.filterTitle]: true,
        [styles.filterTitleVariant]: true,
    });
    return (
        <li className={styles.listItem}>
            <input
                id={inputID}
                type="checkbox"
                value={props.option.option_id}
                checked={props.isChecked}
                className={checkboxClasses}
                onChange={(e) => {
                    props.updateFilterOptionValue(
                        props.facetType,
                        props.option.option_id,
                        e.currentTarget.checked,
                    );
                }}
            />
            <label className={variantClasses} htmlFor={inputID}>
                <span dangerouslySetInnerHTML={{ __html: optionName }} />
                {props.showCount && (
                    <>
                        &nbsp;
                        <span>({props.option.quantity})</span>
                    </>
                )}
            </label>
        </li>
    );
};

const mapStateToProps: TStateMapper<"reviews", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    const state = rootState.reviews || defaultState;
    const values = parseFacetValues(state.ui.facetValues[ownProps.facetType]);
    return {
        isChecked: values.includes(`${ownProps.option.option_id}`),
        ...ownProps,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const dispatchers = new Dispatchers(dispatch);
    return {
        updateFilterOptionValue: dispatchers.updateFilterOptionValue,
    };
};

export const FilterOption = connect(
    mapStateToProps,
    mapDispatchToProps,
)(FilterOptionComponent);
