import { captureException } from "@sentry/browser";

import config from "../config";
import {
    IProductUUID,
    IReviewsBrandID,
    IReviewsProductID,
} from "../models/nominals";
import {
    ReviewResultsPage,
    ReviewsBrand,
    ReviewsProduct,
    ReviewsProducts,
    SearchFacets,
    WriteReviewTemplate,
} from "../models/reviews";
import {
    IReviewResultsPage,
    IReviewsBrand,
    IReviewsProduct,
    ISearchFacet,
    ISearchReviewArgs,
    IWriteReviewData,
    IWriteReviewTemplate,
    ReviewSortOption,
} from "../models/reviews.interfaces";
import { check } from "../models/utils";
import { ajax } from "../utils/ajax";
import { strToBool } from "../utils/format";
import { objectKeys } from "../utils/functional";
import { intersection } from "../utils/sets";

// Declare global exposed by IOVATION Blackbox script
declare function ioGetBlackbox(): { blackbox: string };

export const getReviewAPIURL = (path: string) => {
    let apiBase = config.get("REVIEWS_API");
    if (apiBase.substr(-1) === "/") {
        apiBase = apiBase.substr(0, apiBase.length - 1);
    }
    if (path.substr(0, 1) === "/") {
        path = path.substr(1);
    }
    const apiVersion = config.get("REVIEWS_API_VERSION");
    return `${apiBase}/api/v${apiVersion}/${path}`;
};

export const getReviewsBrand = async (
    brandID: IReviewsBrandID,
): Promise<IReviewsBrand> => {
    let data: unknown;
    if (strToBool(process.env.MOCK_REVIEWS_API || "no")) {
        data = (await import("../fixtures/reviews/brand.json")).default;
    } else {
        const apiURL = getReviewAPIURL(`/brands/${brandID}`);
        const resp = await ajax.get(apiURL).set("Accept", "application/json");
        data = resp.body;
    }
    return check(ReviewsBrand.decode(data));
};

export const getProducts = async (
    brandID: IReviewsBrandID,
): Promise<IReviewsProduct[]> => {
    let data: unknown;
    if (strToBool(process.env.MOCK_REVIEWS_API || "no")) {
        data = (await import("../fixtures/reviews/products.json")).default;
    } else {
        const apiURL = getReviewAPIURL(`/products`);
        const resp = await ajax
            .get(apiURL)
            .set("Accept", "application/json")
            .query({
                brand: brandID,
            });
        data = resp.body;
    }
    return check(ReviewsProducts.decode(data));
};

export const getProductsByUUIDs = async (
    productUUIDs: IProductUUID[],
): Promise<IReviewsProduct[]> => {
    let data: unknown;
    if (strToBool(process.env.MOCK_REVIEWS_API || "no")) {
        data = (await import("../fixtures/reviews/products-subset.json"))
            .default;
    } else {
        const apiURL = getReviewAPIURL(`/products`);
        const resp = await ajax.get(apiURL).set("Accept", "application/json");
        data = resp.body;
    }
    const products = check(ReviewsProducts.decode(data));
    const queriedForUUIDs = new Set(productUUIDs);
    return products.filter((p) => {
        // Build set of UUIDs relevant to this reviews product
        const thisProductUUIDs = new Set([...(p.component_of_uuids || [])]);
        if (p.uuid) {
            thisProductUUIDs.add(p.uuid);
        }
        // Determine if this product matches any of the UUIDs being queried for. If
        // so, return it.
        const overlap = intersection(thisProductUUIDs, queriedForUUIDs);
        return overlap.size > 0;
    });
};

export const getProduct = async (
    productID: IReviewsProductID,
): Promise<IReviewsProduct> => {
    let data: unknown;
    if (strToBool(process.env.MOCK_REVIEWS_API || "no")) {
        data = (await import("../fixtures/reviews/product-1.json")).default;
    } else {
        const apiURL = getReviewAPIURL(`/products/${productID}`);
        const resp = await ajax.get(apiURL).set("Accept", "application/json");
        data = resp.body;
    }
    return check(ReviewsProduct.decode(data));
};

export const getSearchFacets = async (
    brandID: IReviewsBrandID,
    query: ISearchReviewArgs,
): Promise<ISearchFacet[] | null> => {
    let data: unknown;
    if (strToBool(process.env.MOCK_REVIEWS_API || "no")) {
        data = (await import("../fixtures/reviews/facets.json")).default;
    } else {
        const apiURL = getReviewAPIURL(`/brands/${brandID}/facets`);
        delete query.ordering;
        delete query.page;
        delete query.page_size;
        // Remove empty keys
        objectKeys(query).forEach((key) => {
            if (!query[key]) {
                delete query[key];
            }
        });
        const resp = await ajax
            .get(apiURL)
            .set("Accept", "application/json")
            .query(query);
        data = resp.body;
    }
    return check(SearchFacets.decode(data));
};

export const searchReviews = async (
    query: ISearchReviewArgs,
): Promise<IReviewResultsPage | null> => {
    let data: unknown;
    if (strToBool(process.env.MOCK_REVIEWS_API || "no")) {
        data = (await import("../fixtures/reviews/reviews-page.json")).default;
    } else {
        const apiURL = getReviewAPIURL(`/reviews`);
        if (!query.ordering) {
            query.ordering = ReviewSortOption.HIGHEST_RATED;
        }
        if (!query.page) {
            query.page = 1;
        }
        const resp = await ajax
            .get(apiURL)
            .set("Accept", "application/json")
            .query(query);
        data = resp.body;
    }
    return check(ReviewResultsPage.decode(data));
};

export const getWriteReviewTemplate = async (
    productID: IReviewsProductID,
): Promise<IWriteReviewTemplate> => {
    let data: unknown;
    if (strToBool(process.env.MOCK_REVIEWS_API || "no")) {
        data = (await import("../fixtures/reviews/review-template.json"))
            .default;
    } else {
        const apiURL = getReviewAPIURL(`/reviews/template`);
        const resp = await ajax
            .get(apiURL)
            .set("Accept", "application/json")
            .query({ id: productID });
        data = resp.body;
    }
    const baseTemplate = check(WriteReviewTemplate.decode(data));
    // Slightly amend the template returned from the server to make the email_collection field mandatory
    // If the template returned from the server does not contain email_collection field, populate all the
    // template field data given that we make the email_collection field mandatory
    const template: IWriteReviewTemplate = {
        ...baseTemplate,
        email_collection: {
            ...(baseTemplate.email_collection || {
                type: "email",
                response_type: "email",
                input_type: "TextBox",
                field_id: null,
                field_type: "simple",
                key: "email_collection",
                name: "Your email address",
                max_length: null,
                options: [],
                is_tag: false,
                is_merchant_specific: false,
            }),
            is_required: true,
        },
    };
    return template;
};

export const iglooGetBlackbox = (retry: number): string => {
    const tryGetBlackbox = (attempt: number): string => {
        if (typeof window.IGLOO?.getBlackbox !== "function") {
            throw new Error("window.IGLOO.getBlackbox is not a function.");
        }
        const blackboxData = window.IGLOO.getBlackbox();
        if (blackboxData.finished) {
            return blackboxData.blackbox;
        }
        if (attempt >= retry) {
            throw new Error(
                `The device fingerprint blackbox string couldn't be retrieved after ${retry} attempts.`,
            );
        }
        return tryGetBlackbox(attempt + 1);
    };
    return tryGetBlackbox(1);
};

export const submitReview = async (
    productID: IReviewsProductID,
    started: Date,
    fields: { [k: string]: string | string[] },
): Promise<void> => {
    const apiURL = getReviewAPIURL(`/reviews/new`);
    const blackbox = {
        io: "",
        igloo: "",
    };
    try {
        blackbox.io = ioGetBlackbox().blackbox;
        blackbox.igloo = iglooGetBlackbox(5);
    } catch (e) {
        captureException(e);
    }
    const data: IWriteReviewData = {
        ...fields,
        id: `${productID}`,
        blackbox: blackbox,
        started: started.toISOString(),
        created: new Date().toISOString(),
    };
    try {
        await ajax.post(apiURL).set("Accept", "application/json").send(data);
    } catch (err) {
        captureException(err);
        throw err;
    }
};
