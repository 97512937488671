import { createAsyncThunk } from "@reduxjs/toolkit";

import * as financingAPI from "../../../api/financing";
import {
    FinancingPreQualRequest,
    FinancingPreQualRequestResponse,
} from "../../../models/financing";

export const checkPreScreenStatus = createAsyncThunk(
    "financing/checkPreScreenStatus",
    async (
        reqData: FinancingPreQualRequest,
    ): Promise<FinancingPreQualRequestResponse> => {
        const resp = await financingAPI.checkFinancingPreQualStatus(reqData);
        return resp;
    },
);
