import React from "react";
import { connect } from "react-redux";

import { RatingGraphic } from "../../../common/RatingGraphic";
import {
    IReviewQueryFacets,
    ISearchFacetOption,
} from "../../../models/reviews.interfaces";
import { formatNumber } from "../../../utils/format";
import { parseFacetValues } from "../../../utils/reviews";
import { TDispatchMapper, TStateMapper } from "../../reducers.interfaces";
import { defaultState } from "../defaults";
import { Dispatchers } from "../dispatchers";

interface IOwnProps {
    facetType: IReviewQueryFacets;
    option: ISearchFacetOption;
    starHasStroke?: boolean;
}

interface IReduxProps {
    isChecked: boolean;
}

interface IDispatchProps {
    updateFilterOptionValue: Dispatchers["updateFilterOptionValue"];
}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

const RatingFilterOptionComponent = (props: IProps) => {
    const inputID =
        `${props.facetType}-${props.option.option_id}`.toLowerCase();
    return (
        <div className="customer-review__side-filters__star-rating">
            <div className="customer-review__side-filters__radio-button">
                <input
                    id={inputID}
                    type="checkbox"
                    value={props.option.option_id}
                    checked={props.isChecked}
                    aria-label={`${props.option.option_id} of 5 star rating`}
                    onChange={(e) => {
                        props.updateFilterOptionValue(
                            props.facetType,
                            props.option.option_id,
                            e.currentTarget.checked,
                        );
                    }}
                />
            </div>
            <label
                htmlFor={inputID}
                className="customer-review__side-filters__stars"
            >
                <RatingGraphic
                    cardClass="product-reviews-filter"
                    cardSize="small"
                    rating={props.option.option_id}
                    onlyStars={true}
                    starHasStroke={props.starHasStroke}
                />
            </label>
            <div className="customer-review__side-filters__number">
                ({formatNumber(props.option.quantity)})
            </div>
        </div>
    );
};

const mapStateToProps: TStateMapper<"reviews", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    const state = rootState.reviews || defaultState;
    const values = parseFacetValues(
        state.ui.facetValues[ownProps.facetType] || "",
    );
    return {
        isChecked: values.includes(`${ownProps.option.option_id}`),
        ...ownProps,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const dispatchers = new Dispatchers(dispatch);
    return {
        updateFilterOptionValue: dispatchers.updateFilterOptionValue,
    };
};

export const RatingFilterOption = connect(
    mapStateToProps,
    mapDispatchToProps,
)(RatingFilterOptionComponent);
