import { createSelector } from "@reduxjs/toolkit";

import { milesToMeters } from "../../utils/maps";
import { IRootState } from "../reducers.interfaces";

/**
 * Return the currently loaded stores
 */
export const retailSelector = (state: Pick<IRootState, "retail">) => {
    return state.retail;
};

/**
 * Return the currently loaded stores
 */
export const storeSelector = createSelector(retailSelector, (state) => {
    return state.stores;
});

/**
 * Return the currently selected store filters
 */
export const storeFilterSelector = createSelector(retailSelector, (state) => {
    return state.storeFilters;
});

/**
 * Return the currently focused store (used for highlighting stores on maps)
 */
export const focusedStoreIDSelector = createSelector(
    retailSelector,
    (state) => {
        return state.focusedStoreID;
    },
);

/**
 * Return the store designated as "My Store" this is normally the closest store
 * to the user, but can also be manually overridden.
 */
export const myStoreSelector = createSelector(retailSelector, (state) => {
    return state.stores[0] || null;
});

/**
 * Return the currently loaded stores that match all filter options
 */
export const filteredStoreSelector = createSelector(
    storeSelector,
    storeFilterSelector,
    (stores, filters) => {
        const maxDistance = milesToMeters(filters.distance);
        let filteredStores = stores
            .filter((store) => {
                return store.distance ? store.distance <= maxDistance : true;
            })
            .filter((store) => {
                return filters.category
                    ? store.category <= filters.category
                    : true;
            })
            .filter((store) => {
                return filters.retailerName && filters.retailerName.length > 0
                    ? filters.retailerName.includes(store.name)
                    : true;
            });
        if (filters.limit) {
            filteredStores = filteredStores.slice(0, filters.limit);
        }
        return filteredStores;
    },
);
