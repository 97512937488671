import classNames from "classnames";
import React from "react";
import SVG from "react-inlinesvg";
import Modal from "react-modal";
import { assertNever } from "tsi-common-react/src/utils/never";

import iconHamburger from "../../svg/hamburger.svg";
import iconX from "../../svg/x.svg";
import {
    IMenuDjangoLink,
    IMenuExternalLink,
    IMenuItem,
    IMenuPageLink,
    IMenuSnippetLink,
} from "../menu.interface";

interface IProps {
    menuItems: IMenuItem[];
}

interface IState {
    modalIsOpen: boolean;
    linkBottom: number | null;
    reviewsSection: boolean;
    reviewsEle: HTMLElement | null;
}

export class MainMenu extends React.Component<IProps, IState> {
    public state: IState = {
        modalIsOpen: false,
        linkBottom: null,
        reviewsSection: false,
        reviewsEle: document.querySelector("#reviews"),
    };

    componentDidMount() {
        window.addEventListener("resize", () => {
            this.isWindowResizing();
        });

        if (this.state.reviewsEle !== null) {
            this.handleScroll();

            window.addEventListener("scroll", () => {
                this.handleScroll();
            });
        }
    }

    componentWillUnmount() {
        if (this.state.reviewsEle !== null) {
            window.removeEventListener("scroll", () => {
                this.handleScroll();
            });
        }
    }

    private handleScroll() {
        const scrollTop = document.body.scrollTop;
        const reviewsY = this.state.reviewsEle
            ? this.state.reviewsEle.getBoundingClientRect().top
            : 0;
        const setReviewState = (val: boolean) => {
            this.setState({
                reviewsSection: val,
            });
        };

        if (reviewsY < scrollTop + 300) {
            setReviewState(true);
        } else {
            setReviewState(false);
        }
    }

    private isWindowResizing() {
        if (this.state.modalIsOpen) {
            // If the modal is open and the window is resized, close the modal
            this.closeModal();
        }
    }

    private openModal(event?: React.MouseEvent<HTMLElement>) {
        let menuLinkElem = null;

        if (event) {
            event.preventDefault();
            menuLinkElem = event.currentTarget;
        }

        const state: IState = {
            ...this.state,
            modalIsOpen: true,
        };

        if (menuLinkElem) {
            state.linkBottom = menuLinkElem.getBoundingClientRect().bottom;

            if (
                !menuLinkElem.classList.contains(
                    "site-nav__main-menu-item--mobile",
                )
            ) {
                document.body.classList.add("body-dropdown-open--desktop");
            }
        }

        this.setState(state);
    }

    private closeModal(
        event?: React.MouseEvent<Element> | React.KeyboardEvent<Element>,
    ) {
        let menuLinkElem: HTMLElement | null = null;

        if (event) {
            event.preventDefault();
            menuLinkElem = event.currentTarget as HTMLElement;
        }

        if (menuLinkElem) {
            if (
                !menuLinkElem.classList.contains(
                    "site-nav__main-menu-item--mobile",
                )
            ) {
                document.body.classList.remove("body-dropdown-open--desktop");
            }
        }

        this.setState({
            modalIsOpen: false,
        });
    }

    private renderLink(
        slug: string,
        url: string,
        overrideTitle: string,
        isMobile: boolean,
        linkID: number,
    ) {
        const linkClass = classNames({
            "main-dropdown-menu__mobile": true,
            "main-dropdown-menu__mobile-link": true,
            [`main-dropdown-menu__mobile--${slug}`]: true,
        });

        const menuItemClass = classNames({
            "site-nav__main-menu-item": true,
            [`site-nav__main-menu-item--${slug}`]: true,
        });

        const menuButtonActiveClass = classNames({
            "site-nav__menu-button": true,
            "site-nav__menu-button--active": true,
            [`site-nav__main-menu-item--${slug}`]: true,
        });

        const menuButtonInactiveClass = classNames({
            "site-nav__menu-button": true,
            [`site-nav__main-menu-item--${slug}`]: true,
        });

        let menuButtonClass = "";
        if (!isMobile) {
            if (window.location.href.includes(url)) {
                if (window.location.hash === "#reviews") {
                    if (slug !== "shop") {
                        menuButtonClass = menuButtonActiveClass;
                    } else {
                        menuButtonClass = menuButtonInactiveClass;
                    }
                } else {
                    menuButtonClass = menuButtonActiveClass;
                }
            } else {
                if (slug === "account") {
                    if (window.location.href.includes(slug)) {
                        menuButtonClass = menuButtonActiveClass;
                    } else {
                        menuButtonClass = menuButtonInactiveClass;
                    }
                } else {
                    menuButtonClass = menuButtonInactiveClass;
                }
            }

            if (this.state.reviewsEle) {
                if (slug === "shop") {
                    menuButtonClass = this.state.reviewsSection
                        ? menuButtonInactiveClass
                        : menuButtonActiveClass;
                } else if (slug === "reviews") {
                    menuButtonClass = this.state.reviewsSection
                        ? menuButtonActiveClass
                        : menuButtonInactiveClass;
                }
            }
        }

        if (isMobile) {
            return (
                <a key={linkID} href={url} className={linkClass}>
                    {overrideTitle}
                </a>
            );
        }

        return (
            <span className={menuButtonClass} key={linkID}>
                <a className={menuItemClass} href={url}>
                    {overrideTitle}
                </a>
            </span>
        );
    }

    private renderMenuLinks(isMobile: boolean, isSubNav: boolean) {
        let index = 0;

        const buildPageLinks = (menuItem: IMenuPageLink) => {
            return this.renderLink(
                menuItem.value.page.slug,
                menuItem.value.page.url,
                menuItem.value.override_title,
                isMobile,
                index++,
            );
        };

        const buildDjangoLinks = (menuItem: IMenuDjangoLink) => {
            return this.renderLink(
                menuItem.value.url_name,
                menuItem.value.url,
                menuItem.value.override_title,
                isMobile,
                index++,
            );
        };

        const buildExternalLinks = (
            menuItem: IMenuExternalLink | IMenuSnippetLink,
        ) => {
            const slug = menuItem.value.override_title
                .toLowerCase()
                .replace(/[^a-z0-9]+/, "-");
            return this.renderLink(
                slug,
                menuItem.value.link,
                menuItem.value.override_title,
                isMobile,
                index++,
            );
        };

        const menuLinks = this.props.menuItems.reduce((memo, menuItem) => {
            switch (menuItem.type) {
                case "external_link":
                case "snippet_link":
                    if (!isSubNav) {
                        memo = memo.concat(buildExternalLinks(menuItem));
                    }
                    break;

                case "page_link":
                    if (!isSubNav) {
                        memo = memo.concat(buildPageLinks(menuItem));
                    }
                    break;

                case "django_url":
                    if (!isSubNav) {
                        memo = memo.concat(buildDjangoLinks(menuItem));
                    }
                    break;

                default:
                    throw assertNever(menuItem);
            }
            return memo;
        }, [] as JSX.Element[]);

        return <span>{menuLinks}</span>;
    }

    render() {
        const windowWidth = window.innerWidth;
        let contentPosition: React.CSSProperties["position"];
        let contentTop: React.CSSProperties["top"];
        let contentHeight: React.CSSProperties["height"];
        if (windowWidth < 500) {
            contentPosition = "fixed";
            contentTop = "0px";
            contentHeight = "100%";
        } else {
            contentPosition = "relative";
            contentTop = "160px";
            contentHeight = "auto";
        }

        const modalStyle: Modal.Styles = {
            overlay: {
                position: "absolute",
                backgroundColor: "rgba(255, 255, 255, 0)",
            },
            content: {
                position: contentPosition,
                top: contentTop,
                left: "auto",
                right: "auto",
                width: "100%",
                padding: "20px",
                height: contentHeight,
                overflow: "hidden",
                border: "none",
                boxShadow: "0 10px 10px -5px rgba(0,0,0,.3)",
            },
        };

        return (
            <span>
                <span>
                    <span>
                        {/* TODO: Need link title since content is an image. See #13290 */}
                        <a
                            href="#"
                            onClick={(e) => {
                                this.openModal(e);
                            }}
                            aria-label="Open Menu"
                            className="site-nav__main-menu-item site-nav__main-menu-item--mobile"
                        >
                            <SVG
                                className="site-nav__mobile-toggle-icon"
                                src={iconHamburger}
                            />
                        </a>
                    </span>
                    {this.renderMenuLinks(false, false)}
                </span>
                <Modal
                    aria={{ modal: true }}
                    contentLabel="Site Navigation"
                    style={modalStyle}
                    isOpen={this.state.modalIsOpen}
                    onRequestClose={(e) => {
                        this.closeModal(e);
                    }}
                    role="dialog"
                >
                    <div className="main-dropdown-menu">
                        <button
                            onClick={(e) => {
                                this.closeModal(e);
                            }}
                            className="main-dropdown-menu__mobile main-dropdown-menu__close main-dropdown-menu__mobile--is-active"
                        >
                            <SVG
                                aria-hidden="true"
                                className="main-dropdown-menu__close-icon"
                                src={iconX}
                            />
                        </button>
                        <div
                            className="main-dropdown-menu__content l-capped-width"
                            id="main-dropdown-menu-shop"
                        >
                            {this.renderMenuLinks(true, false)}
                        </div>
                    </div>
                </Modal>
            </span>
        );
    }
}
