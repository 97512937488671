import { guardUnhandledAction } from "../../../utils/never";
import { guardReducer } from "../../../utils/redux";
import { Action } from "../constants";
import { defaults } from "../defaults";
import { IDerivedAction } from "../reducers.interfaces";

export const reduceDerivedData = guardReducer(
    Action,
    defaults.derived,
    (state = defaults.derived, action: IDerivedAction) => {
        switch (action.type) {
            case Action.SET_DERIVED_FIELDS:
                return {
                    ...state,
                    ...action.fields,
                };

            case Action.ACKNOWLEDGE_MERGED_BASKET:
                return {
                    ...state,
                    acknowledged_merged_baskets:
                        state.acknowledged_merged_baskets.concat(
                            action.basketID,
                        ),
                };

            default:
                guardUnhandledAction(action);
        }
        return state;
    },
);
