import { captureException } from "@sentry/browser";

import config from "../config";
import { LocationGuessResponse } from "../models/location";
import { ILocationGuessResponse } from "../models/location.interfaces";
import { check } from "../models/utils";
import { ajax } from "../utils/ajax";
import { PromiseMutex } from "../utils/mutex";

export const guess = async () => {
    const mutex = new PromiseMutex<ILocationGuessResponse | null>(
        "guess-user-location",
    );
    const API_KEY = config.get("IP_API_KEY");
    let loading = mutex.getPromise();
    if (!loading) {
        loading = ajax
            .get(`https://pro.ip-api.com/json/`)
            .query({
                key: API_KEY,
            })
            .set("Accept", "application/json")
            .then((resp): ILocationGuessResponse | null => {
                if (!resp.body) {
                    return null;
                }
                return check(LocationGuessResponse.decode(resp.body));
            })
            .catch((err) => {
                console.log(err);
                captureException(err);
                return null;
            });
        mutex.setPromise(loading);
    }
    return loading;
};
