import React from "react";
import { connect } from "react-redux";

import { ReviewSortOption } from "../../../models/reviews.interfaces";
import { TDispatchMapper, TStateMapper } from "../../reducers.interfaces";
import { defaultState } from "../defaults";
import { Dispatchers } from "../dispatchers";
import { ISortOption } from "../reducers.interfaces";

import styles from "./SortingSelector.module.scss";

interface IOwnProps {}

interface IReduxProps {
    sortOptions: ISortOption[];
    selectedSortOption: ReviewSortOption;
}

interface IDispatchProps {
    updateSorting: Dispatchers["updateSorting"];
}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

const SortingSelectorComponent = (props: IProps) => {
    return (
        <div className={styles.sortContainer}>
            <label htmlFor="review-sort">
                <span className={styles.selectPrefix}>Sort by:</span>
            </label>
            <select
                className={styles.sortList}
                value={props.selectedSortOption}
                onChange={(event) => {
                    props.updateSorting(
                        event.currentTarget.value as ReviewSortOption,
                    );
                }}
                id="review-sort"
                name="review-sort"
            >
                {props.sortOptions.map((opt) => (
                    <option
                        key={opt.value}
                        value={opt.value}
                        className="customer-review__top-filters__sort-item"
                    >
                        {opt.label}
                    </option>
                ))}
            </select>
        </div>
    );
};

const mapStateToProps: TStateMapper<"reviews", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    const state = rootState.reviews || defaultState;
    return {
        sortOptions: state.data.sortOptions,
        selectedSortOption: state.ui.selectedSortOption,
        ...ownProps,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const dispatchers = new Dispatchers(dispatch);
    return {
        updateSorting: dispatchers.updateSorting,
    };
};

export const SortingSelector = connect(
    mapStateToProps,
    mapDispatchToProps,
)(SortingSelectorComponent);
