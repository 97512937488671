import React from "react";

import { SortingSelector } from "./SortingSelector";

import styles from "./TopBar.module.scss";

interface IProps {}

export const TopBar = (_props: IProps) => {
    return (
        <div className={styles.topFilters}>
            <div className={styles.itemContainer}></div>
            <SortingSelector />
        </div>
    );
};
