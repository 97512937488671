import * as t from "io-ts";
import { DateFromISOString } from "io-ts-types/lib/DateFromISOString";

import { WebPageURL } from "./nominals";
import { nullable } from "./utils";

/**
 * Base User
 */
export const BaseUser = t.interface({
    username: t.string,
    first_name: t.string,
    last_name: t.string,
    email: t.string,
});

/**
 * User Account
 */
export const User = t.intersection([
    BaseUser,
    t.interface({
        has_password: t.boolean,
        is_csr: t.boolean,
    }),
]);

/**
 * CSR Assisted User
 */
export const CSRAssistedUser = t.intersection([
    BaseUser,
    t.interface({
        groups: t.array(t.string),
        dashboard_link: WebPageURL,
        is_assisting: t.boolean,
        currently_assisted_by: BaseUser,
        assistance_log: t.array(
            t.interface({
                id: t.number,
                csr: BaseUser,
                action_time: t.string,
                message: t.string,
            }),
        ),
    }),
]);

/**
 * User login response
 */
export const UserLoginResponse = t.partial({
    redirect: WebPageURL,
    message: t.string,
});

/**
 * Session Keep-Alive response
 */
export const SessionKeepAliveResponse = t.interface({
    session_lifetime_seconds: t.number,
});

/**
 * OIDC auth refresh response
 */
export const OIDCTokenRefreshResponse = t.type({
    refresh_url: t.string,
});

export const EmailOfferSignupRequest = t.intersection([
    t.interface({
        email: t.string,
    }),
    t.partial({
        zipcode: nullable(t.string),
        campaign_id: nullable(t.string),
    }),
]);

export const LeadWelcomeVoucherAllocation = t.interface({
    voucher_code: t.string,
    created_datetime: DateFromISOString,
});

export const EmailOfferSignupResponse = t.intersection([
    EmailOfferSignupRequest,
    t.partial({
        voucher_allocation: nullable(LeadWelcomeVoucherAllocation),
    }),
]);
