import React from "react";

import {
    IBooleanField,
    ValidationTypes,
} from "../models/formFields.interfaces";
import { notEmpty, unique } from "../utils/functional";
import { IFormFieldState } from "./AbstractFormField";
import { AbstractInput } from "./AbstractInput";
import { runBooleanValidator } from "./validation";

export class FormCheckbox<T extends string> extends AbstractInput<
    T,
    IBooleanField<T>,
    IFormFieldState
> {
    static defaultProps = {
        type: "checkbox",
    };

    state: IFormFieldState = {
        focused: false,
    };

    public componentDidUpdate(prevProps: IBooleanField<T>) {
        if (prevProps.checked !== this.props.checked) {
            this.runValidators();
        }
    }

    public runValidators() {
        if (!this.props.validation) {
            return [];
        }
        const value = this.props.checked || false;
        const validators = ([] as ValidationTypes[]).concat(
            this.props.validation,
        );

        const errors = validators
            .filter(unique)
            .map((validator) => {
                return runBooleanValidator(validator, value);
            })
            .filter(notEmpty);
        if (this.props.onValidStateChange) {
            this.props.onValidStateChange(this.props.name, errors);
        }
        return errors;
    }

    protected buildLabel(): JSX.Element | null {
        // Override this because checkbox labels wrap the input, instead of getting placed adjacent to it.
        return null;
    }

    protected buildControl() {
        const props = { ...this.getInputProps(), checked: this.props.checked };
        props.type = props.type || "checkbox";
        return (
            <label className={this.props.labelCSSClass}>
                <input
                    ref={(ref) => {
                        this.inputElem = ref;
                    }}
                    {...props}
                />
                {this.props.label}
            </label>
        );
    }
}
