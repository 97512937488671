import {
    IOfferDiscount,
    IOptionSingleValue,
    IOptionValue,
    IVoucherDiscount,
} from "../models/catalogue.interfaces";
import {
    ILocation,
    ILocationLatLon,
    ILocationZip,
} from "../models/location.interfaces";

/**
 * ReactDOM.findDOMNode returns an Element or a TextNode. This type-guard determines
 * if the returned value is an Element or not.
 */
export const isElement = (
    node: Element | Text | string | null,
): node is Element => {
    if (!node) {
        return false;
    }
    return (node as Element).querySelector !== undefined;
};

/**
 * Type gate to differentiate offer discounts from voucher discounts
 */
export const discountIsVoucher = (
    discount: IVoucherDiscount | IOfferDiscount,
): discount is IVoucherDiscount => {
    const voucherDiscount = discount as IVoucherDiscount;
    return !!(voucherDiscount.voucher && voucherDiscount.voucher.code);
};

export const isDinero = (value: unknown): value is Dinero.Dinero => {
    return !!(value && (value as Dinero.Dinero).toUnit !== undefined);
};

export const isNumber = (value: unknown): value is number => {
    return typeof value === "number";
};

export const isBoolean = (value: unknown): value is boolean => {
    return typeof value === "boolean";
};

export const isZipLocation = (loc: ILocation): loc is ILocationZip => {
    return (loc as ILocationZip).zip !== undefined;
};

export const isLatLngLocation = (loc: ILocation): loc is ILocationLatLon => {
    return (loc as ILocationLatLon).lat !== undefined;
};

export const isSingleValueOption = (
    value: IOptionValue,
): value is IOptionSingleValue => {
    return typeof value === "string";
};

export const hasKeys = <T>(
    state: unknown,
    keys: Array<keyof T>,
): state is T => {
    return (
        state !== null &&
        typeof state === "object" &&
        keys.every((key) => key in state)
    );
};
