import React from "react";
import { Provider } from "react-redux";
import { snapUserDataLayerState } from "tsi-common-react/src/api/datalayer";
import { registerCascades } from "tsi-common-react/src/apps/common/cascades";
import { getDefaultConfiguratorOptionSet } from "tsi-common-react/src/apps/configurator/utils";
import {
    IProductID,
    isoProductID,
    isoWebPageURL,
} from "tsi-common-react/src/models/nominals";
import { readyStateComplete } from "tsi-common-react/src/utils/events";
import { dynamicPlaceComponent } from "tsi-common-react/src/utils/react";
import { urls } from "tsi-common-react/src/utils/urls";
import { t } from "ttag";

import { rehydratingStore, store } from "../store";
import { getMenuConfiguration } from "./menu";
import * as pdpModels from "./models";

/**
 * Load CMS data and the initially selected product variant
 */
let cmsData: pdpModels.ILandingPage | null = null;
const detailContainerElem = document.querySelector<HTMLDivElement>(
    ".landing-page-hero__configurator[data-cms]",
);
let pdpRootProductDescriptions: pdpModels.ILandingPage["variant_configurator_description_data"] =
    [];

if (detailContainerElem) {
    // Load PDP CMS data
    cmsData = JSON.parse(detailContainerElem.dataset.cms || "");
    if (cmsData) {
        pdpRootProductDescriptions =
            cmsData.variant_configurator_description_data;
    }
}

// Register store cascades
registerCascades(store);

// Load data for main menu from Wagtail JSON and render main menu
const menuConfig = getMenuConfiguration();
if (menuConfig) {
    dynamicPlaceComponent(
        '[data-place-react="main-menu-dropdown"]',
        async () => {
            const { MainMenu } = await import("./containers/MainMenu");
            return <MainMenu menuItems={menuConfig} />;
        },
    );
}

// Render standalone login forms
dynamicPlaceComponent(
    '[data-place-react="auth-login-standalone"]',
    async (elem) => {
        const { LoginForm } = await import(
            "tsi-common-react/src/apps/authentication/components/LoginForm"
        );
        const nextURL = elem.dataset.continueUrl
            ? isoWebPageURL.wrap(elem.dataset.continueUrl)
            : undefined;
        return <LoginForm nextURL={nextURL} />;
    },
);

// Render Session Keep-Alive Modal
dynamicPlaceComponent(
    '[data-place-react="session-keep-alive-modal"]',
    async () => {
        const { SessionKeepAlive } = await import(
            "tsi-common-react/src/apps/authentication/components/SessionKeepAlive"
        );
        return <SessionKeepAlive />;
    },
);

// Render Email Signup Modal
dynamicPlaceComponent('[data-place-react="email-signup-modal"]', async () => {
    const { EmailSignupModal } = await import("./containers/EmailSignupModal");
    return <EmailSignupModal />;
});

// Render Email Signup Footer
dynamicPlaceComponent('[data-place-react="email-signup-footer"]', async () => {
    const { EmailSignupFooter } = await import(
        "./containers/EmailSignupFooter"
    );
    return <EmailSignupFooter />;
});

// Place Live Chat Links
dynamicPlaceComponent('[data-place-react="chat-link"]', async (elem) => {
    const { ChatLink } = await import(
        "tsi-common-react/src/apps/chat/ChatLink"
    );
    return (
        <ChatLink
            className={elem.dataset.chatLinkClass}
            chatOnlineText={elem.dataset.chatOnlineText}
            chatOfflineText={elem.dataset.chatOfflineText}
            chatOfflineLink={
                elem.dataset.chatOfflineLink
                    ? isoWebPageURL.wrap(elem.dataset.chatOfflineLink)
                    : urls.pageURL("contact-link")
            }
        />
    );
});

/**
 * Render CardSlider
 */
dynamicPlaceComponent('[data-place-react="card-slider"]', async (elem) => {
    const { CardSlider } = await import(
        "tsi-common-react/src/common/CardSlider"
    );
    const dataBlock = elem.parentElement?.dataset.blockClass;
    const rawBlocks = document.querySelectorAll<HTMLElement>(`.${dataBlock}`);
    const carouselBlocks = rawBlocks ? Array.from(rawBlocks) : [];
    const images = carouselBlocks.map((item) => {
        return {
            imageUrl: item.dataset.imageUrl || "",
            copy: item.dataset.copy || "",
        };
    });
    await readyStateComplete;
    return <CardSlider images={images} />;
});

/**
 * Render Configurators
 */
dynamicPlaceComponent('[data-place-react="configurator-ld"]', async (elem) => {
    const { ModularConfigurator } = await import(
        "tsi-common-react/src/apps/configurator/containers/ModularConfigurator"
    );
    const productId = isoProductID.wrap(parseInt(elem.dataset.id || "0", 10));
    const initialVariantID: IProductID | null = elem.dataset.initialVariant
        ? isoProductID.wrap(parseInt(elem.dataset.initialVariant || "", 10))
        : null;

    const { ChatLink } = await import(
        "tsi-common-react/src/apps/chat/ChatLink"
    );
    const isAlternativeDesign = elem.dataset.design === "alt";
    const optionSetJSON = JSON.stringify({
        ...getDefaultConfiguratorOptionSet(),
        product_ids: [productId],
        initial_variant_id: initialVariantID,
    });
    const phoneNumber = elem.dataset.phoneNumber;
    await readyStateComplete;
    await rehydratingStore;
    return (
        <Provider store={store}>
            <ModularConfigurator
                optionSetJSON={optionSetJSON}
                passthroughProps={{
                    title: elem.dataset.title,
                    preheader: cmsData ? cmsData.preheader : "",
                    subhead: cmsData ? cmsData.subhead : "",
                    description:
                        cmsData && cmsData.hero_description
                            ? cmsData.hero_description
                            : "",
                    descriptions: pdpRootProductDescriptions
                        ? pdpRootProductDescriptions
                        : [],
                    promoCopy:
                        cmsData && cmsData.hero_promo_copy
                            ? cmsData.hero_promo_copy
                            : "",
                    enableHistoryInteraction: true,
                    buttonColor: "ming",
                    configType: "pdp-hero",
                    getDeliveryCopy: () => {
                        return "";
                    },
                    getDeliveryIcon: () => {
                        return cmsData && cmsData.shipping_icon_url
                            ? cmsData.shipping_icon_url
                            : null;
                    },
                    showShippingLead: !!(cmsData && cmsData.show_shipping_lead),
                    liveChatHeader: cmsData ? cmsData.live_chat_header : "",
                    chatLinkComponent: !isAlternativeDesign ? null : (
                        <ChatLink
                            className={"button"}
                            chatOnlineText={t`Chat`}
                            chatOfflineLink={urls.pageURL("contact-link")}
                        />
                    ),
                    showStarRatingsModal: true,
                    showFeelScale: true,
                    showPreTitle: false,
                    showVariantCallout: false,
                    starRatingURL: isoWebPageURL.wrap("#reviews"),
                    learnMoreSelector: cmsData
                        ? `${cmsData.learn_more_target}`
                        : "#pdp-change-variant-block",
                    strikeThroughMSRP: false,
                    actualPriceStyle: "",
                    boxHeaderContent: "",
                    topBadgeContent: "",
                    phoneNumber: phoneNumber,
                    showImagePerUpgradeOption: false,
                    showFinancingModal: !!(
                        cmsData && cmsData.show_financing_modal
                    ),
                }}
            />
        </Provider>
    );
});

// Render user data layer element
dynamicPlaceComponent('[data-place-react="user-data-layer"]', async (elem) => {
    await readyStateComplete;
    // Pause for 1 second to allow the data layer push scripts to run after readyState is complete
    await (async () => {
        return new Promise((res) => {
            setTimeout(res, 1000);
        });
    })();
    const orderID = elem.dataset.order || "";
    await snapUserDataLayerState(orderID);
    return null;
});

// Render Value Props bar
dynamicPlaceComponent('[data-place-react="value-props-bar"]', async (elem) => {
    const { ValuePropsBar } = await import(
        "tsi-common-react/src/common/ValuePropsBar"
    );
    const parsedData = JSON.parse(elem.dataset.cms || "");
    return <ValuePropsBar valueProps={parsedData.value_props} />;
});
