import memoize from "memoize-one";

import {
    IBasket,
    IBasketLine,
    IBasketLineAdvertistingContent,
} from "../../models/catalogue.interfaces";
import { IBasketLineAPIURL, ISafeHTML } from "../../models/nominals";
import { IShippingMethodLine } from "../../models/shipping.interfaces";
import { PriceType, getProductPrice } from "../../utils/catalogue";
import format from "../../utils/format";
import { objectKeys } from "../../utils/functional";
import { Dinero, ZERO, getDinero } from "../../utils/money";
import { IReduxState } from "./reducers.interfaces";

interface IMethods {
    [methodID: string]: string;
}

type ILineMethods = Map<IBasketLineAPIURL, IMethods>;

export interface IMethodDetails {
    code: string;
    name: IShippingMethodLine["method"]["name"];
    description: IShippingMethodLine["method"]["description"];
    type: IShippingMethodLine["method"]["type"];
    price: string;
}

export interface ILineOptionGroupingData {
    methods: IMethodDetails[];
    lines: IBasketLineAPIURL[];
}

interface ILineMethodGroups {
    [methodKey: string]: ILineOptionGroupingData;
}

export const getLineOptionGroupingData = (
    state: IReduxState,
): ILineOptionGroupingData[] => {
    const shipping_methods = state.data.shipping_methods;

    // Figure out the shipping methods available for each line
    const lineMethods = shipping_methods.reduce<ILineMethods>(
        (memoA, method) => {
            return method.lines.reduce((memoB, line) => {
                const lineURL = line.line_url;
                const methodID = line.method.id;
                const lm = memoB.get(lineURL) || {};
                lm[methodID] = method.code;
                memoB.set(lineURL, lm);
                return memoB;
            }, memoA);
        },
        new Map(),
    );

    // Given a mapping of {methodID => optionCode}, return array of option codes, names, descriptions, prices
    const pickMethods = (codes: IMethods) => {
        const methodIDs = objectKeys(codes);
        const optionCodes = methodIDs.map((methodID) => {
            return codes[methodID];
        });
        return shipping_methods
            .filter((option) => {
                return optionCodes.indexOf(option.code) !== -1;
            })
            .map((option): IMethodDetails => {
                const lineMethods_ = option.lines.filter((line) => {
                    return methodIDs.indexOf(`${line.method.id}`) !== -1;
                });
                const price = lineMethods_.reduce((memo, lineMethod) => {
                    return memo.add(getDinero(lineMethod.charge_total));
                }, getDinero(0.0));
                return {
                    code: option.code,
                    name: lineMethods_[0].method.name,
                    description: lineMethods_[0].method.description,
                    type: lineMethods_[0].method.type,
                    price: format.money(price),
                };
            });
    };

    // Split lines into groups based on the number of shipping methods available to them.
    const lineGroups = Array.from(lineMethods.keys()).reduce<ILineMethodGroups>(
        (memo, line_url) => {
            const lineMethod = lineMethods.get(line_url);
            if (!lineMethod) {
                return memo;
            }
            const methodsIDs = Object.keys(lineMethod);
            const key = methodsIDs.sort().join(",");
            if (!memo[key]) {
                memo[key] = {
                    methods: pickMethods(lineMethod),
                    lines: [],
                };
            }
            memo[key].lines.push(line_url);
            return memo;
        },
        {},
    );

    // Discard the keys
    return Object.keys(lineGroups).map((key) => {
        return lineGroups[key];
    });
};

type LineURLUnitPrice = [IBasketLineAPIURL | null, Dinero];

const getLineAdContentMapping = memoize(
    (basket: IBasket): Map<ISafeHTML, LineURLUnitPrice> => {
        // Gather all of the advert content for the whole basket and deduplicate it
        // by it's html content
        const allContent = basket.lines.reduce<
            IBasketLineAdvertistingContent[]
        >((memo, line) => {
            return memo.concat(line.advertising_content || []);
        }, []);
        const uniqueContent = Array.from(
            new Map(allContent.map((c) => [c.content, c])).values(),
        );

        // Build a map of unique content objects => the highest unit price
        // line it's associated with.
        const defaultLinePrice: LineURLUnitPrice = [null, ZERO];
        const contentLinePrice = new Map(
            uniqueContent.map((c) => [c.content, defaultLinePrice]),
        );
        for (const line of basket.lines) {
            const lineUnitPrice = getProductPrice(line.product.price, {
                priceType: PriceType.ACTUAL_EXCL_TAX,
                includePostDiscountAddons: true,
                quantity: 1,
            });
            for (const ad of line.advertising_content || []) {
                const [, lastHighestPrice] =
                    contentLinePrice.get(ad.content) || defaultLinePrice;
                if (lineUnitPrice.greaterThan(lastHighestPrice)) {
                    contentLinePrice.set(ad.content, [line.url, lineUnitPrice]);
                }
            }
        }

        return contentLinePrice;
    },
);

export const getBasketLineAdvertisingContent = (
    basket: IBasket,
    line: IBasketLine,
): IBasketLineAdvertistingContent[] => {
    const lineMapping = getLineAdContentMapping(basket);

    // Only show each unique ad once, on the highest unit price line its
    // associated with.
    const ads = (line.advertising_content || []).filter((ad) => {
        const [lineURL] = lineMapping.get(ad.content) || [null, ZERO];
        return line.url === lineURL;
    });
    return ads;
};
