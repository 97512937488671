import { format as formatDate } from "date-fns";

import {
    APIUserConfigurableBundles,
    Basket,
    BasketLastModifiedTimestamp,
} from "../models/catalogue";
import {
    IBasket,
    IBasketLastModifiedTimestamp,
    IUserConfigurableBundle,
} from "../models/catalogue.interfaces";
import {
    DeliveryDatePredictionResponse,
    DeliveryDatePreference,
    IDeliveryDatePredictionResponse,
    IDeliveryDatePreference,
} from "../models/deliverydates";
import {
    IBasketLineAPIURL,
    IProductAPIURL,
    IProductID,
    isoBasketLineAPIURL,
} from "../models/nominals";
import { check } from "../models/utils";
import { CSRF_HEADER, Response, ajax, getCSRFToken } from "../utils/ajax";
import { papply } from "../utils/functional";
import { PromiseMutex } from "../utils/mutex";
import { getProduct, getProductURL } from "./products";

/**
 * Load the user's current basket.
 */
export const load = async () => {
    const mutex = new PromiseMutex<IBasket>("basket-load");
    let loading = mutex.getPromise();
    if (!loading) {
        loading = ajax
            .get("/api/basket/")
            .set("Accept", "application/json")
            .then((resp) => {
                return check(Basket.decode(resp.body));
            });
        mutex.setPromise(loading);
    }
    return loading;
};

/**
 * Load the last-modified timestamp of the user's current basket
 */
export const getLastModifiedTimestamp = async () => {
    const mutex = new PromiseMutex<IBasketLastModifiedTimestamp>(
        "basket-last-modified-load",
    );
    let loading = mutex.getPromise();
    if (!loading) {
        loading = ajax
            .get("/api/basket/last-modified/")
            .set("Accept", "application/json")
            .then((resp) => {
                return check(BasketLastModifiedTimestamp.decode(resp.body));
            });
        mutex.setPromise(loading);
    }
    return loading;
};

/**
 * Load the predicted white-glove delivery date for the given post code
 */
export const getPredictedDeliveryDate = async (postcode: string) => {
    const mutex = new PromiseMutex<IDeliveryDatePredictionResponse>(
        `basket-predicted-delivery-date-${postcode}`,
    );
    let loading = mutex.getPromise();
    if (!loading) {
        loading = ajax
            .get("/api/basket/predicted-delivery-date/")
            .set("Accept", "application/json")
            .query({
                postcode: postcode,
            })
            .then((resp) => {
                return check(DeliveryDatePredictionResponse.decode(resp.body));
            });
        mutex.setPromise(loading);
    }
    return loading;
};

/**
 * Set the preferred white-glove delivery date
 */
export const setPreferredDeliveryDate = async (
    postcode: string,
    date: Date | null,
) => {
    const mutex = new PromiseMutex<IDeliveryDatePreference>(
        `basket-preferred-delivery-date`,
    );
    let loading = mutex.getPromise();
    if (!loading) {
        const data = {
            postcode: postcode,
            preferred_date: date ? formatDate(date, "yyyy-MM-dd") : null,
        };
        loading = ajax
            .post("/api/basket/predicted-delivery-date/")
            .set("Accept", "application/json")
            .set(CSRF_HEADER, await getCSRFToken())
            .send(data)
            .then((resp) => {
                return check(DeliveryDatePreference.decode(resp.body));
            });
        mutex.setPromise(loading);
    }
    return loading;
};

/**
 * Load all the user-configurable-bundles which the basket is eligible for
 */
const listUserConfigurableBundlesForProduct = async (
    productURL: IProductAPIURL,
): Promise<IUserConfigurableBundle[]> => {
    const resp = await ajax
        .get(`${productURL}userconfigurablebundles/`)
        .set("Accept", "application/json");
    const rawBundles = check(APIUserConfigurableBundles.decode(resp.body));
    const bundles = await Promise.all(
        rawBundles.map(
            async (inputBundle): Promise<IUserConfigurableBundle> => {
                const products = await Promise.all(
                    inputBundle.suggested_range_products.map((pID) => {
                        return getProduct(getProductURL(pID));
                    }),
                );
                const bundle: IUserConfigurableBundle = {
                    ...inputBundle,
                    suggested_range_products: products,
                };
                return bundle;
            },
        ),
    );
    return bundles;
};

const filterUserConfigurableBundles = (
    bundles: IUserConfigurableBundle[],
): IUserConfigurableBundle[] => {
    // Filter out any products which which are completely out of stock
    bundles = bundles.map((bundle) => {
        const products = bundle.suggested_range_products.filter(
            (rootProduct) => {
                let numInStock = rootProduct.availability.is_available_to_buy
                    ? 1
                    : 0;
                numInStock = rootProduct.children.reduce<number>(
                    (memo, variant) => {
                        if (variant.availability.is_available_to_buy) {
                            memo++;
                        }
                        return memo;
                    },
                    numInStock,
                );
                return numInStock > 0;
            },
        );
        return {
            ...bundle,
            suggested_range_products: products,
        };
    });
    // Filter out any bundles which don't have any products left
    bundles = bundles.filter((bundle) => {
        return bundle.suggested_range_products.length > 0;
    });
    return bundles;
};

const adjustUserConfigurableBundleQuantityForBasket = (
    basket: IBasket,
    bundle: IUserConfigurableBundle,
): IUserConfigurableBundle => {
    // Determine the desired gift quantity based on the basket line quantity of the triggering product
    const initialQty = bundle.quantity;
    const triggeringLine = basket.lines.find((line) => {
        if (
            line.product.parent &&
            line.product.parent.id === bundle.triggering_product
        ) {
            return true;
        }
        return line.product.id === bundle.triggering_product;
    });
    const triggeringLineQty = triggeringLine ? triggeringLine.quantity : 1;
    const desiredQty = triggeringLineQty * initialQty;
    // Build a set of all the products this bundle could suggest
    const suggestedProductIDs = bundle.suggested_range_products.reduce<
        Set<IProductID>
    >((memo, product) => {
        memo.add(product.id);
        return memo;
    }, new Set());
    // Subtract quantity for any gifts previously added to the basket
    const previouslyAddedGiftLines = basket.lines.filter((line) => {
        if (
            line.product.parent &&
            suggestedProductIDs.has(line.product.parent.id)
        ) {
            return true;
        }
        return suggestedProductIDs.has(line.product.id);
    });
    const previouslyAddedGiftLineQty = previouslyAddedGiftLines.reduce<number>(
        (memo, line) => {
            return memo + line.quantity;
        },
        0,
    );
    // New quantity is the desired quantity, minus suggestions already in the cart.
    return {
        ...bundle,
        quantity: desiredQty - previouslyAddedGiftLineQty,
    };
};

const sortUserConfigurableBundles = (
    bundles: IUserConfigurableBundle[],
): IUserConfigurableBundle[] => {
    // Sort the bundles by the number of options, descending
    const countVariants = (bundle: IUserConfigurableBundle) => {
        return bundle.suggested_range_products.reduce<number>((memo, p) => {
            return memo + p.children.length;
        }, 0);
    };
    return bundles.sort((a, b) => {
        const aRootCount = a.suggested_range_products.length;
        const bRootCount = b.suggested_range_products.length;
        if (aRootCount !== bRootCount) {
            return aRootCount > bRootCount ? -1 : 1;
        }
        const aVariantCount = countVariants(a);
        const bVariantCount = countVariants(b);
        if (aVariantCount === bVariantCount) {
            return 0;
        }
        return aVariantCount > bVariantCount ? -1 : 1;
    });
};

const listUserConfigurableBundlesInner = async (
    basket: IBasket,
): Promise<IUserConfigurableBundle[]> => {
    // Build a list of all product URLs to load bundles for
    const productURLs = basket.lines.reduce<IProductAPIURL[]>((memo, line) => {
        // If there's a parent, load bundles for it
        if (line.product.parent) {
            memo.push(getProductURL(line.product.parent.id));
        }
        // Also load bundles for the variant
        memo.push(getProductURL(line.product.id));
        return memo;
    }, []);
    // Load all the bundles
    const nestedBundles = await Promise.all(
        productURLs.map(listUserConfigurableBundlesForProduct),
    );
    // Flatten the list
    let bundles = nestedBundles.reduce<IUserConfigurableBundle[]>((memo, b) => {
        return memo.concat(b);
    }, []);
    // Filter out out of stock products and empty bundles
    bundles = filterUserConfigurableBundles(bundles);
    // Adjust bundle quantities based on the line quantities in the basket
    bundles = bundles.map(
        papply(adjustUserConfigurableBundleQuantityForBasket, basket),
    );
    // Remove bundles which are already completely satisfied by the contents of the cart
    bundles = bundles.filter((bundle) => {
        return bundle.quantity > 0;
    });
    // Return the remaining bundles
    return sortUserConfigurableBundles(bundles);
};

export const listUserConfigurableBundles = async (basket: IBasket) => {
    const mutex = new PromiseMutex<IUserConfigurableBundle[]>(
        "product-configurable-bundle-list",
    );
    let loading = mutex.getPromise();
    if (!loading) {
        loading = listUserConfigurableBundlesInner(basket);
        mutex.setPromise(loading);
    }
    return loading;
};

/**
 * Add a product to the user's basket.
 */
export const addProduct = async (
    productURL: IProductAPIURL,
    qty?: number,
): Promise<Response> => {
    const data = {
        url: productURL,
        quantity: qty || 1,
    };
    return ajax
        .post("/api/basket/add-product/")
        .set("Accept", "application/json")
        .set(CSRF_HEADER, await getCSRFToken())
        .send(data)
        .then((resp) => {
            return resp;
        });
};

/**
 * Update the quantity of a basket line.
 */
export const updateLineQuantity = async (
    lineURL: IBasketLineAPIURL,
    newQty: number,
): Promise<Response> => {
    const data = {
        quantity: newQty,
    };
    return ajax
        .patch(isoBasketLineAPIURL.unwrap(lineURL))
        .set("Accept", "application/json")
        .set(CSRF_HEADER, await getCSRFToken())
        .send(data)
        .then((resp) => {
            return resp;
        });
};

/**
 * Remove a line from the user's current basket.
 */
export const removeLine = async (
    lineURL: IBasketLineAPIURL,
): Promise<Response> => {
    return ajax
        .del(isoBasketLineAPIURL.unwrap(lineURL))
        .set(CSRF_HEADER, await getCSRFToken())
        .set("Accept", "application/json")
        .then((resp) => {
            return resp;
        });
};

/**
 * Attempt to add a voucher to the user's current basket.
 */
export const addVoucherCode = async (code: string): Promise<Response> => {
    return ajax
        .post("/api/basket/add-voucher/")
        .set("Accept", "application/json")
        .set(CSRF_HEADER, await getCSRFToken())
        .send({ vouchercode: code })
        .then((resp) => {
            return resp;
        });
};

/**
 * Remove a voucher form the user's current basket.
 */
export const removeVoucherCode = async (code: string): Promise<Response> => {
    return ajax
        .del("/api/basket/remove-voucher/")
        .set("Accept", "application/json")
        .set(CSRF_HEADER, await getCSRFToken())
        .send({ vouchercode: code })
        .then((resp) => {
            return resp;
        });
};
