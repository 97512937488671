import React from "react";
import SVG from "react-inlinesvg";
import { t } from "ttag";

import iconDeliveryTruck from "../../../../img/icons/delivery-truck.svg";
import iconQuestionMarkCircleFilled from "../../../../img/icons/question-mark-circle-filled.svg";
import { UncontrolledPopover } from "../../../common/Popover";
import { RichText } from "../../../common/RichText";
import { ConfiguratorTypes } from "../../../constants";
import { IProduct } from "../../../models/catalogue.interfaces";
import { BreakPoint } from "../../../models/screen.interfaces";
import { FreeDeliveryButtonTypes } from "../constants";
import { IModularConfiguratorShippingMessage } from "../models.interfaces";

import styles from "./ConfiguratorFreeDelivery.module.scss";

interface IProps {
    upgradedVariant?: IProduct | null;
    variantIsAvailableToBuy?: boolean;
    freeDeliveryButtonTypes: FreeDeliveryButtonTypes;
    phoneNumber: string | undefined;
    configuratorType: ConfiguratorTypes;
    shippingMessages?: IModularConfiguratorShippingMessage | null;
}

interface IState {}

export class ConfiguratorFreeDelivery extends React.PureComponent<
    IProps,
    IState
> {
    private readonly freeDeliveryElem = React.createRef<HTMLDivElement>();
    private readonly contentList = React.createRef<HTMLUListElement>();

    private readonly onAfterModalOpen = () => {
        // In the modal, adding a modifier to center the vertical lines between lists
        if (this.contentList.current) {
            Array.from(this.contentList.current.children, (elem) => {
                if (elem.clientHeight >= 45) {
                    elem.classList.add("wider");
                }
            });
        }
    };

    componentDidMount() {
        // Put setTimeout to ensures that the browser DOM has been loaded before executing the animation.
        // There might be a better way but not feagured out yet.
        setTimeout(() => {
            if (!this.freeDeliveryElem.current) {
                return;
            }
            this.freeDeliveryElem.current.classList.add("animate");
        }, 0);
    }

    private getFreeDeliveryText() {
        if (
            this.props.freeDeliveryButtonTypes ===
                FreeDeliveryButtonTypes.TEXT &&
            this.props.variantIsAvailableToBuy &&
            this.props.upgradedVariant &&
            this.props.upgradedVariant.value_props
        ) {
            const shippingProp = this.props.upgradedVariant.value_props.find(
                (vp) => vp.name === "Free Delivery",
            );
            if (shippingProp) {
                return (
                    <div ref={this.freeDeliveryElem}>
                        <SVG
                            className="mysvg"
                            aria-hidden="true"
                            src={iconDeliveryTruck}
                        />
                        {t`FREE Delivery`}{" "}
                    </div>
                );
            }
        }
        return null;
    }

    private getFreeDeliveryIcon() {
        if (
            this.props.freeDeliveryButtonTypes !== FreeDeliveryButtonTypes.ICON
        ) {
            return null;
        }
        return <SVG aria-hidden="true" src={iconQuestionMarkCircleFilled} />;
    }

    private buildShippingMessages() {
        if (!this.props.shippingMessages?.messages) {
            return null;
        }
        const shippingMessage = this.props.shippingMessages.messages.map(
            (message, index) => {
                return (
                    <li key={index}>
                        <figure>{index + 1}</figure>
                        <p>{message}</p>
                    </li>
                );
            },
        );

        return shippingMessage;
    }

    render() {
        if (
            this.props.configuratorType !== ConfiguratorTypes.PANEL &&
            this.props.configuratorType !== ConfiguratorTypes.UPDATED
        ) {
            return null;
        }
        const pNumber =
            this.props.phoneNumber &&
            this.props.phoneNumber.replace(/[^A-Z0-9]/gi, "");

        return (
            <UncontrolledPopover
                triggerContent={
                    <>
                        {this.getFreeDeliveryText()}
                        {this.getFreeDeliveryIcon()}
                    </>
                }
                popperOptions={{
                    modifiers: [
                        {
                            name: "offset",
                            options: {
                                offset: [0, -18],
                            },
                        },
                    ],
                    placement: "left",
                }}
                modalProps={{
                    overlayClassName: `${styles.root}`,
                    className: `${styles.freeDeliveryModal}`,
                    contentLabel: t`Hassle-Free Delivery info`,
                }}
                showArrow={true}
                isStandardModalBelowSize={BreakPoint.MEDIUM}
                onAfterOpen={this.onAfterModalOpen}
            >
                <div className={styles.freeDeliveryModalContent}>
                    {this.props.shippingMessages?.header ? (
                        <RichText
                            className={styles.freeDeliveryModalContentHeader}
                            html={this.props.shippingMessages?.header}
                        />
                    ) : (
                        <h5>{t`Hassle-Free Delivery`}</h5>
                    )}
                    <ul ref={this.contentList}>
                        {this.buildShippingMessages()}
                    </ul>
                    {this.props.shippingMessages?.footer ? (
                        <RichText
                            className={styles.freeDeliveryModalContentFooter}
                            html={this.props.shippingMessages?.footer}
                        />
                    ) : (
                        <>
                            <p>
                                {t`Need assistance with your order? Call us at`}
                                <br />
                                <a href={`tel:1${pNumber}`}>
                                    {this.props.phoneNumber}
                                </a>
                            </p>
                            <p>
                                {t`and we'll do our best to accommodate your request.`}
                            </p>
                        </>
                    )}
                </div>
            </UncontrolledPopover>
        );
    }
}
