import {
    IAddon,
    IConcreteBundle,
    IOptionValues,
    IProduct,
    IVerboseProductClass,
} from "../../models/catalogue.interfaces";
import { FinancingPlan, FinancingPlans } from "../../models/financing";
import { IProductCategoryID, IProductID } from "../../models/nominals";
import { IAPIPrice } from "../../models/prices.interfaces";
import { IProductShippingMethod } from "../../models/shipping.interfaces";
import { ISetOptionValuePayload } from "../reducer-utils";
import { Action } from "./constants";
import {
    IModularConfiguratorPromoCard,
    IModularConfiguratorUpsellInfoModal,
    IPLCOptionPanel,
    IPLCProductCategorySelector,
    IVariantPrefilter,
} from "./models.interfaces";

export enum RootSelectionMode {
    DIRECT = "direct",
    CATEGORY = "category",
}

export type ISelectedCategories = {
    [selectorID: string]: IProductCategoryID;
};

interface IRootSelectionModeDirect {
    type: RootSelectionMode.DIRECT;
    productID: IProductID | null;
}

interface IRootSelectionModeCategory {
    type: RootSelectionMode.CATEGORY;
    categories: ISelectedCategories;
}

export type IRootSelectionMode =
    | IRootSelectionModeDirect
    | IRootSelectionModeCategory;

export interface ISelectionState {
    // Root Product
    selectedRoot: IRootSelectionMode | null;

    // Product option selector state
    optionValues: IOptionValues;
    quantity: number;
}

interface IUIState extends ISelectionState {
    // Track the previous selection state to allow selection "undo"
    previousSelectionState: ISelectionState | null;

    // Product Upgrades
    upgradeVisible: boolean;
    selectedUpgrade: IProductID | null;

    // Product Add-Ons
    addonsVisible: boolean;
    selectedAddons: IAddon[];

    // Product Upgrades / Add-Ons Shared
    // The product ID to show info about when the modal is open, null is the modal is closed.
    upsellInfoModalActiveBundle: number | null;

    // Add to basket button
    addToBasketCooldownActive: boolean;
    addToBasketButtonText: string;

    // Add to favorites button
    addToFavoritesButtonText: string;

    // Add to basket error modal
    addToBasketErrorOpen: boolean;
    addToBasketErrorReason: string;

    // Ratings Modal
    ratingsModalOpen: boolean;

    // Option Selection Error
    optionSelectionErrorReason: string;

    // Options panel
    panelTriggerClicked: boolean;
}

export interface IReduxState {
    // Product Data
    entities: {
        rootProducts: IProduct[];
        variantPrefilter: IVariantPrefilter | null;
        categorySelectors?: IPLCProductCategorySelector[];
        optionPanels?: IPLCOptionPanel[];
        price: IAPIPrice | null;
        financingPlans?: FinancingPlans;
        concreteBundles: IConcreteBundle[];
        // Array of all upsell info modals available to show
        upsellInfoModals: IModularConfiguratorUpsellInfoModal[];
        promoCards: IModularConfiguratorPromoCard[];
        productClass: IVerboseProductClass | null;
    };

    // UI State Data
    ui: IUIState;

    shippingMethod: IProductShippingMethod | null;
}

export interface IBaseAction {
    type: Action;
}

export interface IActionLoadedProducts extends IBaseAction {
    type: Action.LOADED_PRODUCTS;
    payload: {
        rootProducts: IProduct[];
        initialVariantID?: IProductID | null;
        categorySelectors?: IPLCProductCategorySelector[];
        optionPanels?: IPLCOptionPanel[];
    };
}

export interface IActionLoadedProductClass extends IBaseAction {
    type: Action.LOADED_PRODUCT_CLASS;
    payload: IVerboseProductClass;
}

export interface IActionLoadedPrice extends IBaseAction {
    type: Action.LOADED_PRICE;
    payload: IAPIPrice | null;
}

export interface IActionLoadedFinancingPlans extends IBaseAction {
    type: Action.LOADED_FINANCING_PLANS;
    payload: FinancingPlan[];
}

export interface IActionLoadedShippingMethod extends IBaseAction {
    type: Action.LOADED_SHIPPING_METHOD;
    payload: IProductShippingMethod | null;
}

export interface IActionLoadedConcreteBundles extends IBaseAction {
    type: Action.LOADED_CONCRETE_BUNDLES;
    payload: {
        bundles: IConcreteBundle[];
        upsellInfoModals: IModularConfiguratorUpsellInfoModal[];
        promoCards: IModularConfiguratorPromoCard[];
        selectedUpgradeID: IProductID | null;
    };
}

export interface IActionSetVariantPrefilter extends IBaseAction {
    type: Action.SET_VARIANT_PREFILTER;
    payload: IVariantPrefilter | null;
}

export interface IActionSetSelectedRootProduct extends IBaseAction {
    type: Action.SET_SELECTED_ROOT_PRODUCT;
    payload: IProduct;
}

export interface IActionSetSelectedCategory extends IBaseAction {
    type: Action.SET_SELECTED_CATEGORY;
    payload: {
        selectorID: string;
        categoryID: IProductCategoryID;
        categorySelectors: IPLCProductCategorySelector[];
        optionPanels: IPLCOptionPanel[];
    };
}

export interface IActionSetSelectedVariant extends IBaseAction {
    type: Action.SET_SELECTED_VARIANT;
    payload: {
        root: IProduct;
        variant: IProduct;
        categorySelectors?: IPLCProductCategorySelector[];
    };
}

export interface IActionSetOptionValue extends IBaseAction {
    type: Action.SET_OPTION_VALUE;
    payload: ISetOptionValuePayload;
}

export interface IActionSetQuantity extends IBaseAction {
    type: Action.SET_QUANTITY;
    payload: number;
}

export interface IActionUndoSelection extends IBaseAction {
    type: Action.UNDO_SELECTION;
}

export interface IActionSetUpgradesVisible extends IBaseAction {
    type: Action.SET_UPGRADES_VISIBLE;
    payload: boolean;
}

export interface IActionSetAddonsVisible extends IBaseAction {
    type: Action.SET_ADDONS_VISIBLE;
    payload: boolean;
}

export interface IActionSetSelectedUpgrade extends IBaseAction {
    type: Action.SET_SELECTED_UPGRADE;
    payload: IProductID | null;
}

export interface IActionUpdateSelectedAddons extends IBaseAction {
    type: Action.UPDATE_SELECTED_ADDONS;
    payload: Array<{
        productID: IProductID;
        quantity: number;
    }>;
}

export interface IActionShowUpsellInfoModal extends IBaseAction {
    type: Action.SHOW_UPSELL_INFO_MODAL;
    payload: {
        bundleID: number;
    };
}

export interface IActionHideUpsellInfoModal extends IBaseAction {
    type: Action.HIDE_UPSELL_INFO_MODAL;
}

export interface IActionSetAddToBasketBtnState extends IBaseAction {
    type: Action.SET_ADD_TO_BASKET_BTN_STATE;
    payload: {
        cooldownActive: boolean;
        text: string;
    };
}

export interface IActionSetAddToFavoritesBtnState extends IBaseAction {
    type: Action.SET_ADD_TO_FAVORITES_BTN_STATE;
    payload: {
        text: string;
    };
}

export interface IActionSetAddToBasketErrorModalState extends IBaseAction {
    type: Action.SET_ADD_TO_BASKET_ERROR_MODAL_STATE;
    payload: {
        isOpen: boolean;
        reason: string;
    };
}

export interface IActionSetRatingsModalState extends IBaseAction {
    type: Action.SET_RATINGS_MODAL_STATE;
    payload: {
        isOpen: boolean;
    };
}

export interface IActionSetOptionSelectionErrorState extends IBaseAction {
    type: Action.SET_OPTION_SELECTION_ERROR_STATE;
    payload: {
        reason: string;
    };
}

export interface IActionSetPanelTriggerState extends IBaseAction {
    type: Action.SET_PANEL_TRIGGER_STATE;
    payload: {
        isClicked: boolean;
    };
}

export type IAction =
    | IActionLoadedProducts
    | IActionLoadedProductClass
    | IActionLoadedPrice
    | IActionLoadedShippingMethod
    | IActionLoadedConcreteBundles
    | IActionSetVariantPrefilter
    | IActionSetSelectedRootProduct
    | IActionSetSelectedCategory
    | IActionSetSelectedVariant
    | IActionSetOptionValue
    | IActionSetQuantity
    | IActionUndoSelection
    | IActionSetUpgradesVisible
    | IActionSetAddonsVisible
    | IActionSetSelectedUpgrade
    | IActionUpdateSelectedAddons
    | IActionLoadedFinancingPlans
    | IActionShowUpsellInfoModal
    | IActionHideUpsellInfoModal
    | IActionSetAddToBasketBtnState
    | IActionSetAddToFavoritesBtnState
    | IActionSetAddToBasketErrorModalState
    | IActionSetRatingsModalState
    | IActionSetOptionSelectionErrorState
    | IActionSetPanelTriggerState;
