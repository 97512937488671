import {
    IReviewsProductID,
    IReviewsProductTypeID,
    IReviewsProductVariantID,
} from "../models/nominals";

const DELIM = ",";

type SerializeTypes =
    | string
    | number
    | IReviewsProductID
    | IReviewsProductVariantID
    | IReviewsProductTypeID;

export const parseFacetValues = (
    values: string | number | undefined,
): string[] => {
    if (!values || values !== values.toString()) {
        return [];
    }
    return `${values}`.split(DELIM);
};

export const serializeFacetValues = (
    values: Array<SerializeTypes> | Set<SerializeTypes>,
): string => {
    return Array.from(values).sort().join(DELIM);
};
