import React from "react";
import { connect } from "react-redux";

import { IReviewsProductTypeID } from "../../../models/nominals";
import { IReviewQuery } from "../../../models/reviews.interfaces";
import { urls } from "../../../utils/urls";
import { TStateMapper } from "../../reducers.interfaces";
import { defaultState } from "../defaults";
import { buildReviewsURL, getParamsFromFacetValues } from "../history";

interface IOwnProps {
    className: string;
    children?: React.ReactNode;
}

interface IReduxProps {
    productTypeIDs: IReviewsProductTypeID[];
    facetValues: IReviewQuery;
}

interface IProps extends IOwnProps, IReduxProps {}

const ReviewsPageLinkComponent = (props: IProps) => {
    const baseURL = urls.pageURL("reviews-link");
    if (!baseURL) {
        return null;
    }
    const params = getParamsFromFacetValues(
        props.facetValues,
        props.productTypeIDs,
    );
    const url = buildReviewsURL(baseURL, params);
    return (
        <a href={url} className={props.className}>
            {props.children}
        </a>
    );
};

const mapStateToProps: TStateMapper<"reviews", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    const state = rootState.reviews || defaultState;
    return {
        productTypeIDs: state.ui.productTypeIDs,
        facetValues: state.ui.facetValues,
        ...ownProps,
    };
};

export const ReviewsPageLink = connect(mapStateToProps)(
    ReviewsPageLinkComponent,
);
