import * as vitals from "web-vitals";

import { eventuallySendMetric, recordMetrics } from "../api/metrics";
import { Metric } from "../models/metrics.interfaces";

const eventNameDelim = "#";
let marks: Array<{
    eventName: string;
    elemName: string;
    startTime: number;
    duration: number;
}> = [];
let logTimer: number | null = null;

const sendMarks = async () => {
    const commonAttrs: Partial<Metric> = {};
    const metrics = marks
        .filter((mark) => mark.eventName && mark.elemName && mark.startTime)
        .map((mark, i): Metric => {
            return {
                name: `${mark.eventName}.start-time`,
                dimensions: [
                    {
                        name: "elem-name",
                        value: mark.elemName,
                    },
                    {
                        name: "batch-index",
                        value: `${i}`,
                    },
                ],
                type: "DOUBLE",
                value: `${mark.startTime}`,
            };
        });
    if (metrics.length > 0) {
        await recordMetrics(commonAttrs, metrics);
    }
};

/**
 * Log performance timings as they happen
 */
if (window.PerformanceObserver) {
    const observer = new window.PerformanceObserver((list) => {
        const entries = list.getEntries();
        for (const entry of entries) {
            let eventName: string, elemName: string;
            try {
                [eventName, elemName] = entry.name.split(eventNameDelim, 2);
            } catch (e) {
                [eventName, elemName] = [entry.name, ""];
            }
            // Ignore React's autogenerated marks
            if (eventName.match(/^--/)) {
                continue;
            }
            // Push event into a buffer to log in a batch
            marks.push({
                eventName: eventName,
                elemName: elemName,
                startTime: entry.startTime,
                duration: entry.duration,
            });
        }
        // Log a batch of timings after a 500ms debounce
        if (logTimer) {
            window.clearTimeout(logTimer);
        }
        logTimer = window.setTimeout(() => {
            if (marks.length <= 0) {
                return;
            }
            if (process.env.NODE_ENV !== "production") {
                console.table(marks);
            }
            sendMarks();
            marks = [];
        }, 500);
    });
    observer.observe({ entryTypes: ["mark"] });
}

/**
 * Mark an event timestamp
 */
export const markPerfTiming = (eventName: string, elemName = "") => {
    const markName = `${eventName}${eventNameDelim}${elemName}`;
    if (window.performance && window.performance.mark) {
        window.performance.mark(markName);
    }
};

/**
 * Send web vitals info to the metrics API
 */
export const reportWebVitals = () => {
    const metrics = [
        vitals.onCLS,
        vitals.onFCP,
        vitals.onINP,
        vitals.onLCP,
        vitals.onTTFB,
    ] as const;
    type WebVital = Parameters<Parameters<(typeof metrics)[number]>[0]>[0];
    const logDelta = (data: WebVital): void => {
        const metric: Metric = {
            name: `webvitals.${data.name}`,
            dimensions: [],
            type: "DOUBLE",
            value: `${data.value}`,
        };
        eventuallySendMetric(metric);
    };
    metrics.forEach((onData) => {
        onData(logDelta);
    });
};
