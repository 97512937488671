import { Dispatch } from "@reduxjs/toolkit";

import {
    ILocation,
    ILocationGuessResponse,
} from "../../models/location.interfaces";
import { IViewport } from "../../models/screen.interfaces";
import { IUser } from "../../models/user.interfaces";
import {
    Action,
    IAction,
    IActionSetUser,
    IActionSetViewport,
    IActionUpdateDetectedLocation,
    IActionUpdateEnteredLocation,
} from "./reducers.interfaces";

export class Dispatchers {
    dispatch: Dispatch<IAction>;

    constructor(dispatch: Dispatch<IAction>) {
        this.dispatch = dispatch;
    }

    public readonly updateDetectedLocation = (
        location: ILocationGuessResponse,
    ) => {
        this.dispatch<IActionUpdateDetectedLocation>({
            type: Action.UPDATE_DETECTED_LOCATION,
            payload: location,
        });
    };

    public readonly updateEnteredLocation = (location: ILocation) => {
        this.dispatch<IActionUpdateEnteredLocation>({
            type: Action.UPDATE_ENTERED_LOCATION,
            payload: location,
        });
    };

    public readonly setCurrentUser = (user: IUser) => {
        this.dispatch<IActionSetUser>({
            type: Action.SET_USER,
            payload: user,
        });
    };

    public readonly setViewport = (viewport: IViewport) => {
        this.dispatch<IActionSetViewport>({
            type: Action.SET_VIEWPORT,
            payload: viewport,
        });
    };
}
