import React from "react";
import { connect } from "react-redux";
import { t } from "ttag";

import { getProductURL } from "../../../api/products";
import { config } from "../../../config";
import {
    IAddon,
    IConcreteBundle,
    IProduct,
    IVerboseProductClass,
} from "../../../models/catalogue.interfaces";
import { FinancingPlans } from "../../../models/financing";
import {
    IImageURL,
    IProductID,
    IProductSKU,
    ISafeHTML,
    IWebPageURL,
} from "../../../models/nominals";
import { IAPIPrice, IPrice } from "../../../models/prices.interfaces";
import { VariantDescriptionBlock } from "../../../models/streamfield-blocks";
import {
    trackConfiguratorLoadedEvent,
    trackListrackProductBrowse,
    trackProductValuePageView,
    trackVariantSelectedEvent,
    trackViewItemEvent,
} from "../../../utils/analytics";
import { PriceType, getProductPrice } from "../../../utils/catalogue";
import { decimal } from "../../../utils/format";
import { isZipLocation } from "../../../utils/guards";
import { ZERO } from "../../../utils/money";
import { urls } from "../../../utils/urls";
import { TDispatchMapper, TStateMapper } from "../../reducers.interfaces";
import { addProductToWishList } from "../actions";
import { Configurator as Component } from "../components/Configurator";
import { LIST_FAVORITES, VIEW_FAVORITES } from "../constants";
import { defaults } from "../defaults";
import { Dispatchers } from "../dispatchers";
import { showFinanceBlock } from "../elements/ConfiguratorPanelTrigger";
import {
    getSelectedUpgradeIDFromURL,
    pushPDPVariantURLChange,
    startHistoryListener,
} from "../history";
import { Loaders } from "../loaders";
import {
    IModularConfiguratorOptionSet,
    IUpsellModalComponentClass,
} from "../models.interfaces";
import {
    addonVariantPricesSelector,
    addonVariantsSelector,
    baseVariantSelector,
    rootProductSelector,
    upgradedVariantPriceSelector,
    upgradedVariantSelector,
} from "../selectors";
import { isPreorderProduct } from "../utils";

export interface IOwnProps {
    optionSet: IModularConfiguratorOptionSet;

    // CMS Page Data
    title?: string;
    preheader?: string;
    subhead?: string;
    description?: string;
    descriptions?: VariantDescriptionBlock[];
    promoCopy?: string;
    liveChatHeader?: string;
    chatLinkComponent?: JSX.Element | null;

    showImage?: boolean;
    showShippingLead?: boolean;

    // Site Page URLs for links and redirects
    financingLink: IWebPageURL;
    basketLink: IWebPageURL;
    favoritesLink: IWebPageURL;
    configureGiftsLink: IWebPageURL;

    // Optional: Up Sell Modal functionality
    showUpsellModal?: (product: IProduct | null) => boolean;
    showUpsellModalIcon?: boolean | false;
    getUpsellModalComponentClass?: (
        product: IProduct,
    ) => IUpsellModalComponentClass | null;

    // Colors
    buttonColor?: string;

    // Behavior options
    enableHistoryInteraction?: boolean;

    // Misc UI options
    buildAdditionalContent?: (
        rootProduct: IProduct | null,
        optionSet: IModularConfiguratorOptionSet,
    ) => JSX.Element | null;
    configType?: string;
    getDeliveryCopy?: (
        rootProduct: IProduct,
        isPreorder: boolean,
    ) => string | null;
    getDeliveryIcon?: () => IImageURL | null;
    showFeelScale?: boolean;
    showPreTitle?: boolean;

    learnMoreSelector?: string;
    hideLearnMoreLink?: boolean;

    showVariantCallout?: boolean;

    starRatingURL?: IWebPageURL;
    strikeThroughMSRP?: boolean;
    actualPriceStyle?: string;
    boxHeaderContent?: string;
    topBadgeContent?: ISafeHTML | string;
    overrideFinancingCopy?: string;
    supDollarSign?: boolean;

    enableAddToFavorites?: boolean;
    showStarRatingsModal?: boolean;
    showFinancingModal?: boolean;
    phoneNumber?: string;
    showImagePerUpgradeOption?: boolean;

    isPlpPanelStyle?: boolean;
}

interface IReduxProps {
    // Basic Data
    rootProducts: IProduct[];
    concreteBundles: IConcreteBundle[];

    // Derived data
    rootProduct: IProduct | null;
    baseVariant: IProduct | null;
    selectedUpgradeID: IProductID | null;
    upgradedVariant: IProduct | null;
    price: IAPIPrice | null;
    financingPlans?: FinancingPlans;
    productClass: IVerboseProductClass | null;

    // Location data
    postCode: string;

    // Option selector state
    quantity: number;

    // Product Add-Ons
    selectedAddonVariants: IAddon[];
    selectedAddonPrice: IPrice | null;

    // Add to basket button
    addToBasketCooldownActive: boolean;
    addToBasketButtonText: string;

    // Add to favorites button
    addToFavoritesButtonText: string;

    // Add to basket error modal
    addToBasketErrorOpen: boolean;
    addToBasketErrorReason: string;

    // Option Selection Error
    optionSelectionErrorReason: string;
}

interface IDispatchProps {
    onDidMount: (opts: { enableHistoryInteraction: boolean }) => void;
    loaders: Loaders;
    dispatchers: Dispatchers;
}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

interface IState {}

class ConfiguratorContainer extends React.Component<IProps, IState> {
    static defaultProps: Partial<IProps> = {
        configType: "pdp-hero",
        getDeliveryCopy: () => {
            return null;
        },
        showFeelScale: false,
        showPreTitle: false,
        learnMoreSelector: "#pdp-features",
        hideLearnMoreLink: false,
        showVariantCallout: false,
        strikeThroughMSRP: false,
        actualPriceStyle: "",
        boxHeaderContent: "",
        topBadgeContent: "",
        overrideFinancingCopy: "",
    };

    private readonly onBeforeAddToFavorites = () => {
        this.props.dispatchers.setAddToFavoritesBtnState(t`Saving...`);
    };

    private readonly onAfterAddToFavorites = () => {
        this.props.dispatchers.setAddToFavoritesBtnState(t`Saved!`);
        setTimeout(() => {
            this.props.dispatchers.setAddToFavoritesBtnState(VIEW_FAVORITES);
        }, 1000);
    };

    private readonly onAddToFavorites = async () => {
        if (this.props.addToFavoritesButtonText === VIEW_FAVORITES) {
            this.redirectToFavoritesPage();
            return;
        }
        this.onBeforeAddToFavorites();
        if (this.props.upgradedVariant) {
            await addProductToWishList(
                LIST_FAVORITES,
                this.props.upgradedVariant.url,
            );
        }
        for (const addon of this.props.selectedAddonVariants) {
            await addProductToWishList(
                LIST_FAVORITES,
                getProductURL(addon.productID),
            );
        }
        this.onAfterAddToFavorites();
    };

    private getRootProductID() {
        const rootProductID = this.props.rootProduct
            ? this.props.rootProduct.id
            : this.props.optionSet.product_ids[0];
        return rootProductID;
    }

    private getCurrentProductID() {
        return this.props.rootProduct
            ? this.props.rootProduct.id
            : this.props.optionSet.product_ids[0];
    }

    private async loadShippingMethods() {
        const rootProductID = this.getRootProductID();
        if (!this.props.postCode) {
            await this.props.loaders.loadLocation();
        }
        this.props.loaders.loadProductShippingMethods(
            rootProductID,
            this.props.postCode,
        );
    }

    private getDescription() {
        const productID = this.getCurrentProductID();
        if (this.props.descriptions && this.props.descriptions.length) {
            const match = this.props.descriptions.find(({ product }) => {
                return product === productID;
            });
            if (match && match.description) {
                return match.description;
            }
        }
        return this.props.description;
    }

    private updateAddToBasketText() {
        // Change add to cart label to preorder where necessary
        const isPreorder =
            this.props.upgradedVariant &&
            isPreorderProduct(this.props.upgradedVariant);
        const addToBasketButtonText = isPreorder
            ? t`Pre-Order`
            : t`Add to Cart`;
        if (this.props.addToBasketButtonText !== addToBasketButtonText) {
            this.props.dispatchers.setAddToBasketBtnState(
                false,
                addToBasketButtonText,
            );
        }
    }

    private trackPageView() {
        const skus: IProductSKU[] = this.props.upgradedVariant
            ? this.props.upgradedVariant.skus
            : this.props.rootProducts.reduce<IProductSKU[]>(
                  (skuList, product) => skuList.concat(product.skus),
                  [],
              );
        const price = decimal(
            this.props.price
                ? getProductPrice(this.props.price, {
                      priceType: PriceType.COSMETIC_EXCL_TAX,
                      includePostDiscountAddons: true,
                      quantity: 1,
                  })
                : ZERO,
        );
        trackProductValuePageView(skus, price, "product", "ProductPageView");
        if (this.props.baseVariant) {
            trackViewItemEvent(this.props.baseVariant);
        }
    }

    componentDidMount() {
        this.props.onDidMount({
            enableHistoryInteraction: !!this.props.enableHistoryInteraction,
        });

        // Load main product from API
        if (this.props.optionSet.product_ids.length <= 0) {
            throw new Error("rootProductIDs array must not be empty");
        }
        this.props.loaders.loadProducts(
            this.props.optionSet.product_ids,
            this.props.optionSet.initial_variant_id,
        );

        // Load Bundles
        const baseVariantID =
            this.props.baseVariant && this.props.baseVariant.id
                ? this.props.baseVariant.id
                : null;
        if (baseVariantID) {
            const selectedUpgradeID = getSelectedUpgradeIDFromURL();
            this.props.loaders.loadConcreteBundles(
                baseVariantID,
                selectedUpgradeID,
            );
        }

        trackConfiguratorLoadedEvent();

        // Load shipping method
        this.loadShippingMethods();

        // Load financing details to display product-specific plans if available
        const productID =
            baseVariantID ||
            this.props.optionSet.initial_variant_id ||
            this.getCurrentProductID();
        this.props.loaders.loadFinancingPlans(productID);
    }

    /* eslint-disable  complexity */
    componentDidUpdate(prevProps: IProps) {
        showFinanceBlock();

        // Did the base selection change?
        const prevBaseVariantID =
            prevProps.baseVariant && prevProps.baseVariant.id
                ? prevProps.baseVariant.id
                : null;
        const nextBaseVariantID =
            this.props.baseVariant && this.props.baseVariant.id
                ? this.props.baseVariant.id
                : null;
        const baseVariantChanged = prevBaseVariantID !== nextBaseVariantID;

        // Did the upgraded variant change?
        const prevUpgradedVariantID =
            prevProps.upgradedVariant && prevProps.upgradedVariant.id
                ? prevProps.upgradedVariant.id
                : null;
        const nextUpgradedVariantID =
            this.props.upgradedVariant && this.props.upgradedVariant.id
                ? this.props.upgradedVariant.id
                : null;
        const upgradedVariantChanged =
            prevUpgradedVariantID !== nextUpgradedVariantID;

        // Did the quantity change?
        const prevQty = prevProps.quantity;
        const nextQty = this.props.quantity;
        const qtyChanged = prevQty !== nextQty;
        const selectedUpgradeInConfigurator =
            this.props.selectedUpgradeID &&
            this.props.rootProducts
                .map((product: IProduct) => product.id)
                .includes(this.props.selectedUpgradeID);
        const selectedUpgradeChanged =
            this.props.selectedUpgradeID &&
            this.props.selectedUpgradeID !== prevProps.selectedUpgradeID;

        // If the base variant changed, reload the bundle and financing data for the new variant
        if (baseVariantChanged && nextBaseVariantID) {
            const selectedUpgradeID = getSelectedUpgradeIDFromURL();
            this.props.loaders.loadConcreteBundles(
                nextBaseVariantID,
                selectedUpgradeID,
            );
            this.props.loaders.loadFinancingPlans(nextBaseVariantID);
            // Track variant changes for pdp-hero configurator, see: #18585
            if (this.props.configType === "pdp-hero") {
                trackVariantSelectedEvent(nextBaseVariantID);
            }
        }

        // Update the page URL to include the new selection data
        if (
            this.props.enableHistoryInteraction &&
            (baseVariantChanged || upgradedVariantChanged)
        ) {
            pushPDPVariantURLChange(
                this.props.rootProduct,
                this.props.baseVariant,
                this.props.selectedUpgradeID,
            );
            // Refire Listrak AddProductBrowse activity only when the selected variant is changed.
            if (
                prevProps.baseVariant &&
                this.props.baseVariant &&
                this.props.baseVariant.skus.length > 0
            ) {
                trackListrackProductBrowse(this.props.baseVariant.skus[0]);
            }
        }

        // Load the price data for quantities > 1
        if (
            nextUpgradedVariantID &&
            (upgradedVariantChanged || qtyChanged) &&
            this.props.quantity > 1
        ) {
            this.props.loaders.loadPrice(
                nextUpgradedVariantID,
                this.props.quantity,
            );
        }

        // Update add to basket button text?
        if (upgradedVariantChanged && this.props.upgradedVariant) {
            this.updateAddToBasketText();
        }

        // Reload shipping methods if zip code changed
        if (prevProps.postCode !== this.props.postCode) {
            this.loadShippingMethods();
        }

        // Call Google remarketing analytics after products are loaded
        // prices update to 0 briefly during load, filter out a null this.props.price
        if (
            this.props.price &&
            prevProps.price !== this.props.price &&
            this.props.configType === "pdp-hero"
        ) {
            this.trackPageView();
        }

        // If selectedUpgrade has changed, and upgrade is selectable in configurator, select it
        if (selectedUpgradeChanged && selectedUpgradeInConfigurator) {
            const product = this.props.rootProducts.filter(
                (p) => p.id === this.props.selectedUpgradeID,
            )[0];
            this.props.dispatchers.setSelectedRootProduct(product);
        }
        // If selecting upsell changes rootproduct to match, clear selectedUpgrade
        if (
            this.props.rootProduct &&
            this.props.selectedUpgradeID &&
            this.props.rootProduct.id === this.props.selectedUpgradeID
        ) {
            this.props.dispatchers.setSelectedUpgrade(null);
            pushPDPVariantURLChange(
                this.props.rootProduct,
                this.props.baseVariant,
                null,
            );
        }
    }

    private redirectToFavoritesPage() {
        setTimeout(() => {
            urls.navigateToURL(this.props.favoritesLink);
        }, 0);
    }

    private buildAdditionalContent() {
        if (!this.props.buildAdditionalContent || !this.props.rootProduct) {
            return null;
        }
        return this.props.buildAdditionalContent(
            this.props.rootProduct,
            this.props.optionSet,
        );
    }

    render() {
        const enableAddToFavorites =
            !config.get("ENABLE_ECOM") || !!this.props.enableAddToFavorites;
        return (
            <Component
                optionSet={this.props.optionSet}
                title={this.props.title}
                preheader={this.props.preheader}
                subhead={this.props.subhead}
                description={this.getDescription()}
                financingLink={this.props.financingLink}
                rootProduct={this.props.rootProduct}
                baseVariant={this.props.baseVariant}
                upgradedVariant={this.props.upgradedVariant}
                price={this.props.price}
                financingPlans={this.props.financingPlans}
                concreteBundles={this.props.concreteBundles}
                showImage={this.props.showImage}
                promoCopy={this.props.promoCopy}
                chatLinkComponent={this.props.chatLinkComponent}
                liveChatHeader={this.props.liveChatHeader}
                basketLink={this.props.basketLink}
                configureGiftsLink={this.props.configureGiftsLink}
                showShippingLead={this.props.showShippingLead}
                selectedAddonPrice={this.props.selectedAddonPrice}
                enableAddToFavorites={enableAddToFavorites}
                addToFavoritesButtonText={this.props.addToFavoritesButtonText}
                onAddToFavorites={this.onAddToFavorites}
                showUpsellModal={this.props.showUpsellModal}
                showUpsellModalIcon={this.props.showUpsellModalIcon}
                getUpsellModalComponentClass={
                    this.props.getUpsellModalComponentClass
                }
                buttonColor={this.props.buttonColor || "primary-congress-blue"}
                configType={this.props.configType || "pdp-hero"}
                getDeliveryCopy={
                    this.props.getDeliveryCopy ||
                    (() => {
                        return null;
                    })
                }
                showFeelScale={this.props.showFeelScale || false}
                showPreTitle={this.props.showPreTitle || false}
                learnMoreSelector={
                    this.props.learnMoreSelector || "#pdp-features"
                }
                hideLearnMoreLink={this.props.hideLearnMoreLink}
                showVariantCallout={this.props.showVariantCallout || false}
                starRatingURL={this.props.starRatingURL}
                strikeThroughMSRP={this.props.strikeThroughMSRP || false}
                actualPriceStyle={this.props.actualPriceStyle || ""}
                boxHeaderContent={this.props.boxHeaderContent || ""}
                topBadgeContent={this.props.topBadgeContent || ""}
                overrideFinancingCopy={this.props.overrideFinancingCopy || ""}
                supDollarSign={this.props.supDollarSign}
                showStarRatingsModal={this.props.showStarRatingsModal}
                showFinancingModal={this.props.showFinancingModal}
                phoneNumber={this.props.phoneNumber}
                optionSelectionErrorReason={
                    this.props.optionSelectionErrorReason
                }
                showImagePerUpgradeOption={
                    this.props.showImagePerUpgradeOption || false
                }
                isPlpPanelStyle={this.props.isPlpPanelStyle}
            >
                {this.buildAdditionalContent()}
            </Component>
        );
    }
}

const mapStateToProps: TStateMapper<"configurator", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    const state = rootState.configurator || defaults;
    const postCode =
        rootState.common.enteredLocation &&
        isZipLocation(rootState.common.enteredLocation)
            ? rootState.common.enteredLocation.zip
            : rootState.common.detectedLocation &&
                rootState.common.detectedLocation.zip
              ? rootState.common.detectedLocation.zip
              : "";
    return {
        // Basic Data
        rootProducts: state.entities.rootProducts,
        concreteBundles: state.entities.concreteBundles,

        // Data derived from API data combined with user selected options
        rootProduct: rootProductSelector(state),
        baseVariant: baseVariantSelector(state),
        selectedUpgradeID: state.ui.selectedUpgrade,
        upgradedVariant: upgradedVariantSelector(state),
        price: upgradedVariantPriceSelector(state),
        productClass: state.entities.productClass,
        financingPlans: state.entities.financingPlans,

        // Location data
        postCode: postCode,
        shippingMethod: state.shippingMethod,

        // Option selector state
        quantity: state.ui.quantity,

        // Product Add-Ons
        selectedAddonVariants: addonVariantsSelector(state),
        selectedAddonPrice: addonVariantPricesSelector(state),

        // Add to basket button
        addToBasketCooldownActive: state.ui.addToBasketCooldownActive,
        addToBasketButtonText: state.ui.addToBasketButtonText,

        // Add to favorites button
        addToFavoritesButtonText: state.ui.addToFavoritesButtonText,

        // Add to basket error modal
        addToBasketErrorOpen: state.ui.addToBasketErrorOpen,
        addToBasketErrorReason: state.ui.addToBasketErrorReason,

        // Option Selection Error
        optionSelectionErrorReason: state.ui.optionSelectionErrorReason,

        // Direct Props
        ...ownProps,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const dispatchers = new Dispatchers(dispatch);
    const loaders = new Loaders(dispatchers);
    return {
        onDidMount: ({ enableHistoryInteraction }) => {
            if (enableHistoryInteraction) {
                startHistoryListener(dispatch);
            }
        },
        dispatchers: dispatchers,
        loaders: loaders,
    };
};

export const Configurator = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ConfiguratorContainer);
