import React from "react";
import { t } from "ttag";

import { Form } from "../../../forms/Form";
import { FormInput } from "../../../forms/FormInput";
import { FormSubmit } from "../../../forms/FormSubmit";
import { focusElement } from "../../../utils/keyboardFocus";

import styles from "./BasketVoucherForm.module.scss";

interface IProps {
    addVoucherCode: (code: string) => Promise<void>;
}

interface IState {
    isSaving: boolean;
    vouchercode: string;
    promocodeFormExpanded: boolean;
    errors: {
        non_field_errors?: string[];
        vouchercode?: string[];
        reason?: string;
    };
}

export class BasketVoucherForm extends React.Component<IProps, IState> {
    private readonly formField =
        React.createRef<FormInput<"checkout-promo-code">>();

    public state: IState = {
        isSaving: false,
        vouchercode: "",
        promocodeFormExpanded: false,
        errors: {},
    };

    componentDidUpdate(_prevProps: IProps, prevState: IState) {
        const elem =
            this.formField.current && this.formField.current.getInputElem();
        if (
            this.state.promocodeFormExpanded &&
            !prevState.promocodeFormExpanded &&
            this.formField.current
        ) {
            if (elem) {
                focusElement(elem);
            }
        }

        // As screen reader users need to be immediately notified of any errors in the promo form fields,
        // ensure this happens only when the errors are initially set in the state.
        if (
            Object.keys(prevState.errors).length <= 0 &&
            Object.keys(this.state.errors).length > 0
        ) {
            if (elem) {
                focusElement(elem);
            }
        }
    }

    private readonly onChange = (event: React.FormEvent<HTMLInputElement>) => {
        this.setState({
            vouchercode: event.currentTarget.value,
        });
    };

    private readonly onSubmit = async (
        event: React.FormEvent<HTMLFormElement>,
    ) => {
        event.preventDefault();
        this.setState({
            isSaving: true,
            errors: {},
        });
        try {
            await this.props.addVoucherCode(this.state.vouchercode);
        } catch (err) {
            this.setState({
                errors: err.response.body,
            });
        }
        this.setState({
            vouchercode: "",
            isSaving: false,
        });
    };

    private readonly onToggleFormExpanded = () => {
        this.setState({
            promocodeFormExpanded: !this.state.promocodeFormExpanded,
        });
    };

    private convertErrorMessage(errorMsg: string) {
        switch (errorMsg) {
            case "Voucher code unknown":
                return t`This is not a valid promo code`;
            case "This coupon has already been used":
                return t`This promo code has already been used`;
        }
        return errorMsg;
    }

    private buildErrors() {
        let errors = this.state.errors.non_field_errors || [];
        if (this.state.errors.vouchercode) {
            errors = errors.concat(this.state.errors.vouchercode);
        }
        if (this.state.errors.reason) {
            errors = errors.concat(this.state.errors.reason);
        }
        return errors.map((errorMsg) => {
            return this.convertErrorMessage(errorMsg);
        });
    }

    render() {
        const expandIndicator = this.state.promocodeFormExpanded ? "-" : "+";
        return (
            <>
                <button
                    type="button"
                    aria-expanded={this.state.promocodeFormExpanded}
                    aria-controls="basket-summary-promocode-form"
                    className={styles.trigger}
                    onClick={this.onToggleFormExpanded}
                >
                    {t`Enter a Promo Code ${expandIndicator}`}
                </button>
                {this.state.promocodeFormExpanded && (
                    <div
                        id="basket-summary-promocode-form"
                        // Should be standardized across sites
                        // and refactored into CSS Modules
                        className={styles.wrapper}
                    >
                        <Form onSubmit={this.onSubmit} className={styles.form}>
                            <FormInput
                                ref={this.formField}
                                type="text"
                                id="checkout-promo-code"
                                name="checkout-promo-code"
                                className={styles.input}
                                label="Enter a Promo Code"
                                labelPlacement="outside"
                                labelCSSClass={styles.label}
                                placeholder=""
                                required={true}
                                value={this.state.vouchercode}
                                errors={this.buildErrors()}
                                onChange={this.onChange}
                                disabled={this.state.isSaving}
                            />
                            <FormSubmit
                                value="Apply"
                                disabled={this.state.isSaving}
                                className={styles.submitButton}
                            />
                        </Form>
                    </div>
                )}
            </>
        );
    }
}
