import * as productsAPI from "../../api/products";
import { ITileID } from "../../models/product-compare";
import {
    IBaseCompareTile,
    ICompareTile,
} from "../../models/product-compare.interfaces";
import { Dispatchers } from "./dispatchers";
import { getChildFilterVariants } from "./selectors";

export class Loaders {
    private readonly dispatchers: Dispatchers;

    constructor(dispatchers: Dispatchers) {
        this.dispatchers = dispatchers;
    }

    public readonly loadTiles = async (
        baseTiles: IBaseCompareTile[],
    ): Promise<ICompareTile[]> => {
        const loading = baseTiles.map((baseTile) => {
            return this.loadTile(baseTile);
        });
        const tiles = await Promise.all(loading);
        this.dispatchers.setCompareTiles(tiles);
        return tiles;
    };

    public readonly loadTile = async (
        baseTile: IBaseCompareTile,
    ): Promise<ICompareTile> => {
        const rootProducts = await Promise.all(
            baseTile.product_compare_tile.product_ids.map((pid) => {
                const productURL = productsAPI.getProductURL(pid);
                return productsAPI.getProduct(productURL);
            }),
        );
        const annotatedTile: ICompareTile = {
            ...baseTile,
            rootProducts: rootProducts,
        };
        if (baseTile.product_compare_tile.child_filter) {
            const childFilterVariants = getChildFilterVariants(
                annotatedTile,
                baseTile.product_compare_tile.child_filter,
            );
            annotatedTile.childFilterProducts = childFilterVariants;
        }
        return annotatedTile;
    };

    public readonly loadSelectedTileID = async (tileID: ITileID) => {
        this.dispatchers.addToSelected(tileID);
    };
}
