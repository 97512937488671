import * as VisuallyHidden from "@radix-ui/react-visually-hidden";
import classNames from "classnames";
import React from "react";

import styles from "./index.module.scss";

export const ThreeDotLoader = ({
    className,
    loadingText = "Loading",
}: {
    className?: string;
    loadingText?: string;
}) => (
    <div
        role="img"
        aria-label={loadingText}
        className={classNames(className, styles.loadingDots)}
    ></div>
);

export const LoadingOverlay = ({
    visuallyHiddenText,
    extraClass,
}: {
    visuallyHiddenText: string;
    extraClass?: string;
}) => {
    return (
        <div
            aria-live="polite"
            className={classNames({
                [styles.loadingOverlay]: true,
                [extraClass!]: extraClass,
            })}
        >
            <p>
                <VisuallyHidden.Root>{visuallyHiddenText}</VisuallyHidden.Root>
            </p>
            <ThreeDotLoader className={styles.loader} />
        </div>
    );
};
