import { AdaChatConnector } from "./connector-ada";
import { ChatConnector } from "./connector-base";
import { NullChatConnector } from "./connector-null";
import { ZendeskChatConnector } from "./connector-zendesk";

export { NullChatConnector, ZendeskChatConnector, AdaChatConnector };

// Module level variable to hold the currently loaded and configured chat connector
let chatConnector: ChatConnector = new NullChatConnector();

export const registerChatConnector = (conn: ChatConnector): void => {
    console.log(
        `[chat] Setting live chat connector to ${conn.constructor.name}`,
    );
    chatConnector = conn;
};

export const getChatConnector = (): ChatConnector => {
    return chatConnector;
};
