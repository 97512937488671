import React from "react";

import {
    IOptionCode,
    IOptionSingleValue,
    IProduct,
} from "../models/catalogue.interfaces";
import { PriceType, getProductPrice } from "../utils/catalogue";
import { roundedMoney } from "../utils/format";
import { SelectOptions } from "./SelectOptions";

import styles from "./ProductOptionDropdownSelector.module.scss";

interface IProps {
    selectorID: string;
    code: IOptionCode;
    value: IOptionSingleValue;
    options: IOptionSingleValue[];
    prefixNameCapped: string;
    configuratorClass: string;
    prefixClass: string;
    selectClass: string;
    variant?: IProduct | null;
    showPricing?: boolean;
    onChange: (event: React.FormEvent<HTMLSelectElement>) => void;
}

interface IState {}

export class ProductOptionDropdownSelector extends React.PureComponent<
    IProps,
    IState
> {
    private buildPricingOverlay() {
        if (
            !this.props.showPricing ||
            !this.props.variant ||
            !this.props.variant.availability.is_available_to_buy
        ) {
            return null;
        }
        const retailPrice = getProductPrice(this.props.variant.price, {
            priceType: PriceType.RETAIL,
            includePostDiscountAddons: true,
            quantity: 1,
        });
        const cosmeticPrice = getProductPrice(this.props.variant.price, {
            priceType: PriceType.COSMETIC_EXCL_TAX,
            includePostDiscountAddons: true,
            quantity: 1,
        });
        return (
            <div className={styles.pricingOverlay}>
                <span className={styles.actualPrice}>
                    <span className="ada-screenreader-only">
                        Original price:{" "}
                    </span>
                    {roundedMoney(cosmeticPrice)}
                </span>
                <span className={styles.retailPrice}>
                    <span className="ada-screenreader-only">
                        Current price:{" "}
                    </span>
                    {roundedMoney(retailPrice)}
                </span>
            </div>
        );
    }

    render() {
        if (this.props.options.length === 1) {
            return (
                <div
                    key={this.props.code}
                    className="configurator__option-container"
                >
                    <label className={this.props.prefixClass}>
                        {this.props.prefixNameCapped}
                    </label>
                    <div className={this.props.configuratorClass}>
                        {this.props.value}
                        {this.buildPricingOverlay()}
                    </div>
                </div>
            );
        }
        return (
            <div
                key={this.props.selectorID}
                className={`${styles.root} configurator__option-container`}
            >
                <label
                    htmlFor={this.props.selectorID}
                    className={this.props.prefixClass}
                >
                    {this.props.prefixNameCapped}
                </label>
                <div className={this.props.configuratorClass}>
                    <select
                        value={this.props.value}
                        name={this.props.code}
                        onChange={this.props.onChange}
                        className={this.props.selectClass}
                        id={this.props.selectorID}
                    >
                        <optgroup label={this.props.prefixNameCapped}>
                            <SelectOptions options={this.props.options} />
                        </optgroup>
                    </select>
                    {this.buildPricingOverlay()}
                </div>
            </div>
        );
    }
}
