import React, { useEffect, useState } from "react";

import { ConfiguratorTypes } from "../../../constants";
import { IImageURL } from "../../../models/nominals";
import { FreeDeliveryButtonTypes } from "../constants";
import {
    IModularConfiguratorOptionSet,
    IModularConfiguratorShippingMessage,
} from "../models.interfaces";
import { ConfiguratorFreeDelivery } from "./ConfiguratorFreeDelivery";

import styles from "./ConfiguratorDynamicshippingTimeMessaging.module.scss";

type TDynamicShippingTimeMessaging =
    IModularConfiguratorOptionSet["layout"]["dynamic_shipping_time_messaging"];

interface IProps {
    dynamicShippingTimeMessaging: TDynamicShippingTimeMessaging;
    configuratorType: ConfiguratorTypes;
    iconURL?: IImageURL | null | undefined;
    modalShippingMessages?: IModularConfiguratorShippingMessage | null;
    phoneNumber?: string;
}

export const ConfiguratorDynamicshippingTimeMessaging: React.FC<IProps> = ({
    dynamicShippingTimeMessaging,
    configuratorType,
    iconURL,
    modalShippingMessages,
    phoneNumber,
}) => {
    const [shippingMessage, setShippingMessage] = useState<string>("");

    useEffect(() => {
        const getCurrentShippingMessage = (
            shippingData: TDynamicShippingTimeMessaging,
        ) => {
            if (!shippingData) {
                return "";
            }

            const userCurrentTime = new Date();

            // Convert user's time to EST
            const estTime = new Date(
                userCurrentTime.toLocaleString("en-US", {
                    timeZone: "America/New_York",
                }),
            );

            const currentDayOfWeek = estTime
                .toLocaleDateString("en-US", { weekday: "long" })
                .toLowerCase();
            const beforeMessage =
                shippingData[
                    `${currentDayOfWeek}_before_message` as keyof typeof shippingData
                ];
            const afterMessage =
                shippingData[
                    `${currentDayOfWeek}_after_message` as keyof typeof shippingData
                ];
            const shippingTime =
                shippingData[
                    `${currentDayOfWeek}_time` as keyof typeof shippingData
                ];

            const currentTime = estTime.toLocaleTimeString("en-US", {
                hour12: false,
            });

            return currentTime < shippingTime ? beforeMessage : afterMessage;
        };

        const message = getCurrentShippingMessage(dynamicShippingTimeMessaging);
        setShippingMessage(message);
    }, [dynamicShippingTimeMessaging]);

    return (
        <div className={styles.container}>
            {
                <div className={styles.content}>
                    {iconURL && (
                        <img src={`${iconURL}`} aria-hidden="true" alt="" />
                    )}
                    <span>
                        {shippingMessage}
                        {!!modalShippingMessages && (
                            <ConfiguratorFreeDelivery
                                freeDeliveryButtonTypes={
                                    FreeDeliveryButtonTypes.ICON
                                }
                                configuratorType={configuratorType}
                                phoneNumber={phoneNumber}
                                shippingMessages={modalShippingMessages}
                            />
                        )}
                    </span>
                </div>
            }
        </div>
    );
};
