import { ILevelColumn } from "../common/LevelSelectionPanel";
import {
    IOptionCode,
    IOptionMultiValue,
    IOptionSingleValue,
    IOptionValue,
    IOptionValues,
    IProduct,
    IUserConfigurableBundle,
} from "../models/catalogue.interfaces";
import { notEmpty } from "../utils/functional";
import { intersection, union } from "../utils/sets";
import { IGiftSelection } from "./checkout/reducers.interfaces";

/**
 * Given a list of root products and a set of selected option values, determine which variant of the root
 * product (if there are any variants of the root product) is actually selected.
 */
export const getProductVariant = (
    rootProducts: IProduct[],
    optionValues: IOptionValues,
): IProduct | null => {
    if (rootProducts.length <= 0) {
        return null;
    }
    if (
        (rootProducts.length === 1 && !rootProducts[0].children) ||
        rootProducts[0].children.length <= 0
    ) {
        return rootProducts[0];
    }
    const codes = rootProducts[0].attributes.product_options?.value || [];
    const matchingVariants = getProductVariantsForOptionCodes(
        rootProducts,
        codes,
        optionValues,
    );
    // Nothing? Return null.
    if (matchingVariants.length <= 0) {
        return null;
    }
    // Multiple matches? Try and filter down to only the available-to-buy matches.
    if (matchingVariants.length > 1) {
        const availableMatchingVariants = matchingVariants.filter((variant) => {
            return variant.availability.is_available_to_buy;
        });
        if (availableMatchingVariants.length >= 1) {
            return availableMatchingVariants[0];
        }
    }
    // Fallback to just returning the first match, even though it's not available to buy.
    return matchingVariants[0];
};

const compareAttr_SingleToSingle = (
    variantValue: IOptionSingleValue,
    selectedValue: IOptionSingleValue,
): number => {
    return variantValue === selectedValue ? 1 : 0;
};

const compareAttr_MultiToMulti = (
    variantValue: IOptionMultiValue,
    selectedValue: IOptionMultiValue,
): number => {
    const setVariant = new Set(variantValue);
    const setSelected = new Set(selectedValue);
    const combined = union(setVariant, setSelected);
    const common = intersection(setVariant, setSelected);
    const maxPoints = combined.size * selectedValue.length * 2;
    // Award {selectedValue.length} points for each item in common to both values
    let totalPoints = common.size * selectedValue.length;
    // Award additional points for how close to the beginning of the setSelected array each variant
    // option is. I.E. user's first selection is worth more than their second selection.
    for (let i = 0; i < selectedValue.length; i++) {
        const val = selectedValue[i];
        if (common.has(val)) {
            const pointsForSlot = selectedValue.length - i;
            totalPoints += pointsForSlot;
        }
    }
    return totalPoints / maxPoints;
};

const compareAttr_SingleToMulti = (
    variantValue: IOptionSingleValue,
    selectedValue: IOptionMultiValue,
): number => {
    return compareAttr_MultiToMulti([variantValue], selectedValue);
};

const compareAttr_MultiToSingle = (
    variantValue: IOptionMultiValue,
    selectedValue: IOptionSingleValue,
): number => {
    return compareAttr_MultiToMulti(variantValue, [selectedValue]);
};

const compareAttr = (
    variantValue: IOptionValue,
    selectedValue: IOptionValue,
): number => {
    // Treat singletons like a single value rather than a multi value
    if (Array.isArray(variantValue) && variantValue.length === 1) {
        variantValue = variantValue.filter(notEmpty)[0] || "";
    }
    if (Array.isArray(selectedValue) && selectedValue.length === 1) {
        selectedValue = selectedValue.filter(notEmpty)[0] || "";
    }
    // Compare attrs based on the combination of types
    if (Array.isArray(variantValue) && Array.isArray(selectedValue)) {
        return compareAttr_MultiToMulti(variantValue, selectedValue);
    }
    if (!Array.isArray(variantValue) && !Array.isArray(selectedValue)) {
        return compareAttr_SingleToSingle(variantValue, selectedValue);
    }
    if (Array.isArray(variantValue) && !Array.isArray(selectedValue)) {
        return compareAttr_MultiToSingle(variantValue, selectedValue);
    }
    if (!Array.isArray(variantValue) && Array.isArray(selectedValue)) {
        return compareAttr_SingleToMulti(variantValue, selectedValue);
    }
    throw new Error("Impossible!");
};

export const getProductVariantsForOptionCodes = (
    rootProducts: IProduct[],
    codes: readonly IOptionCode[],
    optionValues: IOptionValues,
): IProduct[] => {
    if (rootProducts.length <= 0) {
        return [];
    }
    const variantOptions = rootProducts.reduce<IProduct[]>(
        (memo, rootProduct) => {
            const variants =
                rootProduct.children.length > 0
                    ? rootProduct.children
                    : [rootProduct];
            return memo.concat(variants);
        },
        [],
    );
    const sortedVariants = variantOptions
        .map((variant: IProduct): [number, IProduct] => {
            const score = codes.reduce<number>((memo, key) => {
                const variantAttr = variant.attributes[key];
                if (!optionValues[key] && !variantAttr) {
                    return memo;
                }
                if (!variantAttr) {
                    return 0;
                }
                return (
                    memo +
                    compareAttr(variantAttr.value, optionValues[key] || "")
                );
            }, 0);
            return [score, variant];
        })
        .sort(([scoreA], [scoreB]) => {
            if (scoreA === scoreB) {
                return 0;
            }
            return scoreA < scoreB ? 1 : -1;
        });
    const minScore = Math.min(codes.length, Object.keys(optionValues).length);
    const topScore = sortedVariants[0][0];
    const matchingVariants = sortedVariants
        .filter(([score]) => {
            const roundedScore = Math.round(score);
            return score >= topScore && score > 0 && roundedScore >= minScore;
        })
        .map(([_, variant]) => variant);
    return matchingVariants;
};

/**
 * Given a list of user-configurable-bundles and a Gift configuration, return the matching product variant
 */
export const getSelectedGift = (
    bundles: IUserConfigurableBundle[],
    giftConfig: IGiftSelection,
): IProduct => {
    const bundle = bundles.find((b) => {
        return b.id === giftConfig.bundleID;
    });
    if (!bundle) {
        throw new Error("Invalid bundle");
    }
    const rootProduct = bundle.suggested_range_products.find((p) => {
        return p.id === giftConfig.selectedRootProduct;
    });
    if (!rootProduct) {
        throw new Error("Invalid product");
    }
    const variant = getProductVariant([rootProduct], giftConfig.optionValues);
    if (!variant || !variant.availability.is_available_to_buy) {
        throw new Error("Variant is unavailable");
    }
    return variant;
};

export const buildRelevantLevelVariants = (
    options: string[],
    currentOptionValues: IOptionValues,
    rootProduct: IProduct,
    code: IOptionCode,
) => {
    return (
        options
            .map((option) => {
                const variants = getProductVariantsForOptionCodes(
                    [rootProduct],
                    [code],
                    { [code]: option },

                    // We only want to see Product Variants
                    // that apply to the previously selected
                    // `option_feel` and `option_size` values
                ).filter((variant) => {
                    return (
                        variant.attributes.option_feel?.value ===
                            currentOptionValues.option_feel &&
                        variant.attributes.option_size?.value ===
                            currentOptionValues.option_size
                    );
                });
                if (variants.length === 0) {
                    return {
                        level: option,
                        variant: null,
                    };
                }

                return {
                    level: option,
                    variant: variants[0],
                };
            })
            // Remove null values
            .filter((i) => i.variant !== null)
    );
};

export const findLevelVariants = (
    variants: ILevelColumn[],
    currentOptionValues: IOptionValues,
) => {
    const currentLevelVariant = variants.filter((level) => {
        return level.level === currentOptionValues.option_level;
    })[0];

    // We want to show the current level, and the next highest level
    // Unless we're currently at the highest level, then we want to show
    // the current level and the previous one
    const startingIndex =
        variants.indexOf(currentLevelVariant) + 1 === variants.length
            ? variants.indexOf(currentLevelVariant) - 1
            : variants.indexOf(currentLevelVariant);
    const endingIndex = startingIndex + 2;
    return variants.slice(startingIndex, endingIndex);
};
