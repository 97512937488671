import React, { useState } from "react";
import { t } from "ttag";

import { Image } from "../../../common/Image";
import { IOfferDiscount } from "../../../models/catalogue.interfaces";

import styles from "./VirtualLineFull.module.scss";

interface IProps {
    offerData: IOfferDiscount;
}

export const OfferLineFull = (props: IProps) => {
    const [isSavingsInfoExpanded, setIsSavingsInfoExpanded] = useState(true);
    const toggleAccordion = () => {
        setIsSavingsInfoExpanded((prev) => !prev);
    };
    const basketSavingsID = `voucher-line-savings-details-${props.offerData.short_name}`;
    return (
        <section className={styles.virtualLine}>
            {props.offerData.desktop_image && (
                <div className={styles.firstColumn}>
                    <Image
                        alt={props.offerData.short_name}
                        src={props.offerData.desktop_image}
                    />
                </div>
            )}
            <div>
                <h2 className={styles.virtualTitle}>{props.offerData.name}</h2>
            </div>
            <div className={styles.quantity}>
                <span>
                    {t`Qty:`} &nbsp; {props.offerData.freq}
                </span>
            </div>
            <div className={styles.virtualLineSavings}>
                <div className={styles.savingSummary}>
                    <button
                        className={styles.accordionButton}
                        aria-controls={basketSavingsID}
                        onClick={toggleAccordion}
                        aria-expanded={isSavingsInfoExpanded}
                    >
                        {t`Total Savings`}
                    </button>
                </div>
                {isSavingsInfoExpanded && (
                    <div className={styles.savingSummary}>
                        <div
                            id={basketSavingsID}
                            className={styles.savingDetails}
                        >
                            {props.offerData.short_name}
                        </div>
                    </div>
                )}
            </div>
            <div className={styles.whiteGloveDelivery}>
                <span className={styles.deliveryText}>
                    {" "}
                    Free Delivery - additional details sent via email.
                </span>
            </div>
        </section>
    );
};
