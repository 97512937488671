import { isRight } from "fp-ts/lib/Either";
import * as t from "io-ts";

import { WebPageURL } from "../models/nominals";

const FooterSettings = t.interface({
    talk_modal_title_top: t.string,
    talk_modal_text_top: t.string,
    talk_modal_hours_top: t.string,

    talk_modal_title_middle: t.string,
    talk_modal_text_middle: t.string,
    talk_modal_hours_middle: t.string,

    talk_modal_title_bottom: t.string,
    talk_modal_text_bottom: t.string,
    talk_modal_hours_bottom: t.string,

    talk_modal_links: t.array(
        t.interface({
            id: t.string,
            title: t.string,
            url: WebPageURL,
        }),
    ),
});

/**
 * This method gets a global, immutable setting from the Page DOM.
 */
export const getPageSetting = (name: string, defaultValue = "") => {
    const elem = document.getElementById("common-page-settings");
    let value = defaultValue;
    if (elem) {
        const span = elem.querySelector<HTMLElement>(`.${name}`);
        if (span) {
            value = span.dataset.value || defaultValue;
        } else {
            console.warn(`Missing #common-page-settings.${name} element`);
        }
    } else {
        console.warn("Missing #common-page-settings element");
    }
    if (value && (value[0] === "{" || value[0] === "[" || value === "null")) {
        try {
            return JSON.parse(value);
        } catch (e) {
            console.error(e);
        }
    }
    return value;
};

/**
 * Load and validate basket settings from the DOM
 */
export const getFooterSettings = (): t.TypeOf<typeof FooterSettings> | null => {
    const maybeSettings = FooterSettings.decode(
        getPageSetting("footer-settings"),
    );
    return isRight(maybeSettings) ? maybeSettings.right : null;
};

export const isPageInsideIFrame = (): boolean => {
    return document.body.classList.contains("iframe-content");
};
