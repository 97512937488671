import React from "react";

import { Form } from "../../../forms/Form";
import { FormSubmit } from "../../../forms/FormSubmit";
import { strings } from "../../../localization";
import { SYFTransactionCode } from "../../../models/synchrony";
import format from "../../../utils/format";
import { getDinero } from "../../../utils/money";
import { assertNever, guardUnhandledAction } from "../../../utils/never";
import {
    CARD_TYPE_AMEX,
    PRIMARY_PAYMENT_METHOD_KEY,
    SECONDARY_PAYMENT_METHOD_KEY,
    getCardNameFromType,
} from "../constants";
import { Dispatchers } from "../dispatchers";
import { IFinancingPayment, IPayment } from "../reducers.interfaces";
import {
    getFinancingData,
    getSynchronyData,
    isFortivaAcctNum,
    isNewFinancingAccount,
} from "../utils";
import { IPaymentMethodProps } from "./PaymentMethod.interfaces";
import { Bluefin } from "./PaymentMethod_Bluefin";
import { Cash } from "./PaymentMethod_Cash";
import { Cybersource } from "./PaymentMethod_Cybersource";
import { Financing } from "./PaymentMethod_Financing";
import { FinePrint } from "./PaymentMethod_Financing_FinePrint";
import { PayLater } from "./PaymentMethod_PayLater";
import { Synchrony } from "./PaymentMethod_Synchrony";

interface IProps extends IPaymentMethodProps {
    buildFinancingUpsell: (grandTotal: string) => JSX.Element | null;
    changeFormField: Dispatchers["changeFormField"];
    setPaymentMethodFields: Dispatchers["setPaymentMethodFields"];
    resetPaymentMethods: Dispatchers["resetPaymentMethods"];
    changeCreditCardNumber: Dispatchers["changeCreditCardNumber"];
    changeCreditCardExpDate: Dispatchers["changeCreditCardExpDate"];
    changeAmount: Dispatchers["changeAmount"];
    changeFinancingAccountNumber: Dispatchers["changeFinancingAccountNumber"];
    onApplyDenial: () => void;
    onSubmit: (event: React.FormEvent<HTMLFormElement>) => Promise<void>;
    addSplitPayToFinancing: () => void;
    enableSplitPay: boolean;
    isCSR?: boolean;
    formRef?: React.RefObject<Form>;
}

interface IState {}

export class PaymentMethod extends React.Component<IProps, IState> {
    private shouldEnableSubmitButton() {
        const data = getFinancingData(this.props);
        if (data) {
            // Fortiva is always enabled.
            if (isFortivaAcctNum(data.financing_account)) {
                return true;
            }
            if (data.new_financing_account) {
                // in the case of a new account, we'll use a submit button inside the accordion
                return false;
            }
            if (!data.financing_account) {
                return false;
            }
            if (
                data.amount &&
                this.props.data.financing_plans.length > 0 &&
                parseFloat(data.amount) <
                    parseFloat(
                        this.props.data.financing_plans[0]
                            .product_price_threshold,
                    )
            ) {
                // we are below the lowest financing threshold, so cannot finance
                return false;
            }
            // Figure out if credit applications are allowed
            const allowCreditApplications =
                this.props.data.financing_plans.reduce((memo, plan) => {
                    return memo || plan.allow_credit_application;
                }, false);
            return allowCreditApplications
                ? data.has_esigned && data.has_agreed
                : data.has_esigned;
        } else {
            // submit button is always enabled for cybersource, cash, and pay-later
            return true;
        }
    }

    private shouldShowSubmitButton() {
        // If SYF payment is selected, but the modal hasn't yet completed, hide
        // the place order button.
        const syfData = getSynchronyData(this.props);
        if (
            syfData &&
            syfData.transaction_code !== SYFTransactionCode.SUCCESS
        ) {
            return false;
        }
        // in the case of a new account, we'll use a submit button inside the accordion
        return !isNewFinancingAccount(this.props);
    }

    private shouldShowFinePrint() {
        // check for Fortiva
        const data = getFinancingData(this.props);
        if (data) {
            // if it's a Fortiva Account, hide the fineprint
            if (isFortivaAcctNum(data.financing_account)) {
                return false;
            }
        }

        // in the case of a new account, we'll use fine print inside the accordion
        return !isNewFinancingAccount(this.props);
    }

    private isSplitPay() {
        return SECONDARY_PAYMENT_METHOD_KEY in this.props.form.payment_methods;
    }

    private maskAccountNumber(
        accountNumber: string | null | undefined,
        paymentMethod: IPayment,
    ) {
        if (!accountNumber) {
            return "";
        }

        const amex_mask = "**** ****** *";
        const standard_mask = "**** **** **** ";

        const methodType = paymentMethod.method_type;
        if (methodType === "bluefin") {
            // unfortunately this makes assumptions about bluefin encrypted string formatting
            const number_start = accountNumber.indexOf(";") + 1;
            const number_end = accountNumber.indexOf("=");
            const mask =
                number_end - number_start === 15 ? amex_mask : standard_mask;
            return mask + accountNumber.substring(number_end - 4, number_end);
        } else {
            const mask =
                methodType === "cybersource" &&
                paymentMethod.card_type === CARD_TYPE_AMEX
                    ? amex_mask
                    : standard_mask;
            return accountNumber
                ? mask.substr(0, Math.max(accountNumber.length - 4, 0)) +
                      accountNumber.substr(-4)
                : "";
        }
    }

    private buildMethodForm() {
        const formProps = {
            form: this.props.form,
            data: this.props.data,
            derived: this.props.derived,
            gifts: this.props.gifts,
            errors: this.props.errors,
            onValidStateChange: this.props.onValidStateChange,
            showErrorMessages: this.props.showErrorMessages,
        };

        const splitProps: {
            [method_key: string]: {
                header: string | null;
                disabled: boolean;
                freezeAmount: boolean;
            };
        } = {
            [PRIMARY_PAYMENT_METHOD_KEY]: {
                header: null,
                disabled: false,
                freezeAmount: false,
            },
            [SECONDARY_PAYMENT_METHOD_KEY]: {
                header: null,
                disabled: false,
                freezeAmount: true,
            },
        };

        if (this.isSplitPay()) {
            // assign headers to the payment forms
            if (
                this.props.form.payment_methods[PRIMARY_PAYMENT_METHOD_KEY]
                    .method_type === "cybersource" ||
                this.props.form.payment_methods[PRIMARY_PAYMENT_METHOD_KEY]
                    .method_type === "bluefin"
            ) {
                splitProps[PRIMARY_PAYMENT_METHOD_KEY].header = "Credit Card 1";
                splitProps[SECONDARY_PAYMENT_METHOD_KEY].header =
                    "Credit Card 2";
            } else {
                splitProps[PRIMARY_PAYMENT_METHOD_KEY].header = "Financing";
                splitProps[SECONDARY_PAYMENT_METHOD_KEY].header = "Credit Card";
            }
            // do we have credit card or account information?
            let hasCardInfo = false;
            const primaryMethod =
                this.props.form.payment_methods[PRIMARY_PAYMENT_METHOD_KEY];
            if (primaryMethod.method_type === "cybersource") {
                hasCardInfo = primaryMethod.card_number.length > 0;
            } else if (primaryMethod.method_type === "bluefin") {
                hasCardInfo = primaryMethod.payment_data.length > 0;
            } else if (primaryMethod.method_type === "financing") {
                hasCardInfo = primaryMethod.financing_account.length > 0;
            }
            // if we have card info and payment states, we might wish to disable one of the forms
            if (this.props.data.payment_states && hasCardInfo) {
                const methodStates =
                    this.props.data.payment_states.payment_method_states;
                // disable form if a payment is complete
                splitProps[PRIMARY_PAYMENT_METHOD_KEY].disabled =
                    PRIMARY_PAYMENT_METHOD_KEY in methodStates &&
                    methodStates[PRIMARY_PAYMENT_METHOD_KEY].status ===
                        "Complete";
                splitProps[SECONDARY_PAYMENT_METHOD_KEY].disabled =
                    SECONDARY_PAYMENT_METHOD_KEY in methodStates &&
                    methodStates[SECONDARY_PAYMENT_METHOD_KEY].status ===
                        "Complete";
                // disable PRIMARY amount field if the SECONDARY payment is complete
                splitProps[PRIMARY_PAYMENT_METHOD_KEY].freezeAmount =
                    splitProps[SECONDARY_PAYMENT_METHOD_KEY].disabled;
            }
        }

        const form = Object.keys(this.props.form.payment_methods)
            .sort()
            .map((methodKey) => {
                const methodType =
                    this.props.form.payment_methods[methodKey].method_type;
                switch (methodType) {
                    case "cybersource":
                        return (
                            <Cybersource
                                {...formProps}
                                setPaymentMethodFields={
                                    this.props.setPaymentMethodFields
                                }
                                changeCreditCardNumber={
                                    this.props.changeCreditCardNumber
                                }
                                changeCreditCardExpDate={
                                    this.props.changeCreditCardExpDate
                                }
                                changeAmount={this.props.changeAmount}
                                header={splitProps[methodKey].header}
                                isSplitPay={this.isSplitPay()}
                                shouldFreezeAmount={
                                    splitProps[methodKey].freezeAmount
                                }
                                disabled={splitProps[methodKey].disabled}
                                methodKey={methodKey}
                                key={methodKey}
                            />
                        );
                    case "bluefin":
                        return (
                            <Bluefin
                                {...formProps}
                                setPaymentMethodFields={
                                    this.props.setPaymentMethodFields
                                }
                                changeAmount={this.props.changeAmount}
                                header={splitProps[methodKey].header}
                                isSplitPay={this.isSplitPay()}
                                shouldFreezeAmount={
                                    splitProps[methodKey].freezeAmount
                                }
                                disabled={splitProps[methodKey].disabled}
                                methodKey={methodKey}
                                key={methodKey}
                            />
                        );
                    case "financing":
                        return (
                            <Financing
                                {...formProps}
                                setPaymentMethodFields={
                                    this.props.setPaymentMethodFields
                                }
                                resetPaymentMethods={
                                    this.props.resetPaymentMethods
                                }
                                buildFinancingUpsell={
                                    this.props.buildFinancingUpsell
                                }
                                changeFinancingAccountNumber={
                                    this.props.changeFinancingAccountNumber
                                }
                                changeAmount={this.props.changeAmount}
                                onApplyDenial={this.props.onApplyDenial}
                                header={splitProps[methodKey].header}
                                isSplitPay={this.isSplitPay()}
                                shouldFreezeAmount={
                                    splitProps[methodKey].freezeAmount
                                }
                                disabled={splitProps[methodKey].disabled}
                                methodKey={methodKey}
                                key={methodKey}
                                addSplitPayToFinancing={
                                    this.props.addSplitPayToFinancing
                                }
                                enableSplitPay={this.props.enableSplitPay}
                            />
                        );
                    case "synchrony":
                        return (
                            <Synchrony
                                {...formProps}
                                setPaymentMethodFields={
                                    this.props.setPaymentMethodFields
                                }
                                header={null}
                                isSplitPay={false}
                                shouldFreezeAmount={false}
                                disabled={false}
                                methodKey={methodKey}
                                key={methodKey}
                            />
                        );
                    case "cash":
                        return (
                            <Cash
                                {...formProps}
                                header={null}
                                isSplitPay={false}
                                shouldFreezeAmount={false}
                                disabled={false}
                                methodKey={methodKey}
                                key={methodKey}
                            />
                        );
                    case "pay-later":
                        return (
                            <PayLater
                                {...formProps}
                                header={null}
                                isSplitPay={false}
                                shouldFreezeAmount={false}
                                disabled={false}
                                methodKey={methodKey}
                                key={methodKey}
                            />
                        );
                    case "default":
                        return null;
                    default:
                        guardUnhandledAction(methodType);
                }
                throw assertNever(methodType);
            });

        return form;
    }

    private isReadyToSubmit(methodKey: string, methodType: string) {
        if (methodType === "cybersource") {
            const errors = this.props.errors[methodKey];
            if (
                (errors.card_cvc && errors.card_cvc.length) ||
                (errors.card_expiration && errors.card_expiration.length) ||
                (errors.card_number && errors.card_number.length)
            ) {
                return false;
            }
        } else if (methodType === "financing") {
            const errors = this.props.errors[methodKey];
            const method = this.props.form.payment_methods[
                methodKey
            ] as IFinancingPayment;
            if (
                (errors.financing_account && errors.financing_account.length) ||
                !method.has_agreed ||
                !method.has_esigned
            ) {
                return false;
            }
        } else if (methodType === "bluefin") {
            const errors = this.props.errors[methodKey];
            if (errors.payment_data && errors.payment_data.length) {
                return false;
            }
        }
        return true;
    }

    private buildSummary() {
        if (this.isSplitPay()) {
            return (
                <div>
                    <h3 className="checkout-step__split-pay--heading">
                        Split Pay Summary
                    </h3>
                    {Object.keys(this.props.form.payment_methods).map(
                        (methodKey) => {
                            const paymentMethod =
                                this.props.form.payment_methods[methodKey];
                            const methodType = paymentMethod.method_type;

                            const amount = getDinero(
                                paymentMethod.amount || "0.00",
                            );

                            let accountNumber;
                            let accountType;
                            switch (methodType) {
                                case "cybersource":
                                    if (
                                        !this.isReadyToSubmit(
                                            methodKey,
                                            methodType,
                                        )
                                    ) {
                                        break;
                                    }
                                    accountNumber = paymentMethod.card_number;
                                    accountType = getCardNameFromType(
                                        paymentMethod.card_type,
                                    );
                                    break;
                                case "financing":
                                    if (
                                        !this.isReadyToSubmit(
                                            methodKey,
                                            methodType,
                                        )
                                    ) {
                                        break;
                                    }
                                    accountNumber =
                                        paymentMethod.financing_account;
                                    if (paymentMethod.financing_plan) {
                                        const plan_id =
                                            paymentMethod.financing_plan;
                                        const selected_plan =
                                            this.props.data.financing_plans.find(
                                                (plan) => {
                                                    return plan.id === plan_id;
                                                },
                                            );
                                        if (selected_plan) {
                                            accountType = `Financing | Selected term: ${selected_plan.term_months} months`;
                                        }
                                    }
                                    break;
                                case "bluefin":
                                    if (
                                        !this.isReadyToSubmit(
                                            methodKey,
                                            methodType,
                                        )
                                    ) {
                                        break;
                                    }
                                    accountNumber = paymentMethod.payment_data;
                                    accountType = "Secure Credit Card";
                                    break;
                                case "synchrony":
                                case "cash":
                                case "pay-later":
                                case "default":
                                    break;
                                default:
                                    guardUnhandledAction(methodType);
                            }
                            return (
                                <div
                                    className="checkout-step__split-pay--row"
                                    key={methodKey}
                                >
                                    <p>
                                        <span>
                                            {this.maskAccountNumber(
                                                accountNumber,
                                                paymentMethod,
                                            )}
                                        </span>
                                        <span>{format.money(amount)}</span>
                                    </p>
                                    <small>{accountType}</small>
                                </div>
                            );
                        },
                    )}
                </div>
            );
        } else {
            return null;
        }
    }

    render() {
        return (
            <div
                className="checkout-step__payment-method"
                id="payment-method-instructions"
            >
                <Form
                    className="checkout-step__payment-form"
                    onSubmit={this.props.onSubmit}
                    ref={this.props.formRef}
                >
                    {this.buildMethodForm()}
                    {this.buildSummary()}
                    {this.shouldShowSubmitButton() && (
                        <FormSubmit
                            disabled={!this.shouldEnableSubmitButton()}
                            className="button button--full-width button--place-order checkout-place-order-button--cc"
                            value={
                                strings.get(
                                    "CONTINUE_BTN_LABEL_PAYMENT_METHODS",
                                ) || "Place Order"
                            }
                        />
                    )}
                    <FinePrint showFinePrint={this.shouldShowFinePrint()} />
                </Form>
            </div>
        );
    }
}
