import { Store, configureStore } from "@reduxjs/toolkit";
import React from "react";
import { Provider } from "react-redux";

import { IProductID, IWebPageURL } from "../../../models/nominals";
import { urls } from "../../../utils/urls";
import { Configurator } from "../../configurator/containers/Configurator";
import { getDefaultConfiguratorOptionSet } from "../../configurator/utils";
import { middleware } from "../../middleware";
import { coreReducers } from "../../reducers";
import { IRootState } from "../../reducers.interfaces";

interface IMicroConfiguratorProps {
    rootProductIDs: IProductID[];
    basketLink?: IWebPageURL;
    financingLink?: IWebPageURL;
}

// NOTE: This is a temporary solution to the MicroConfigurator functionality,
//       written by @cweber and @zlebar

export class MicroConfiguratorRowContent extends React.Component<
    IMicroConfiguratorProps,
    Record<string, never>
> {
    private microStore: Store<IRootState> | null = null;

    render() {
        if (!this.microStore) {
            this.microStore = configureStore({
                reducer: coreReducers,
                middleware: (getDefaultMiddleware) => {
                    return getDefaultMiddleware().concat(middleware);
                },
                devTools: {
                    name: `MicroConfigurator-${this.props.rootProductIDs}`,
                },
            });
        }
        return (
            <Provider store={this.microStore}>
                <Configurator
                    optionSet={{
                        ...getDefaultConfiguratorOptionSet(),
                        product_ids: this.props.rootProductIDs,
                    }}
                    enableHistoryInteraction={false}
                    financingLink={
                        this.props.financingLink || urls.pageURL("finance-link")
                    }
                    basketLink={
                        this.props.basketLink || urls.pageURL("basket-summary")
                    }
                    favoritesLink={urls.pageURL("basket-summary")}
                    configureGiftsLink={urls.pageURL("configure-gifts")}
                    configType={"micro"}
                    buttonColor={"primary-congress-blue"}
                    showShippingLead={false}
                    showFeelScale={true}
                    showPreTitle={false}
                    showVariantCallout={false}
                    strikeThroughMSRP={false}
                    actualPriceStyle={""}
                    boxHeaderContent={""}
                    topBadgeContent={""}
                    showFinancingModal={true}
                />
            </Provider>
        );
    }
}
