import React from "react";
import { t } from "ttag";

import { Modal } from "../../../common/Modal";
import { IModularConfiguratorOptionSet } from "../models.interfaces";
import { RootProductCompareModalContent } from "./RootProductCompareModalContent";

import styles from "./RootProductCompareModal.module.scss";

interface IProps {
    optionSet: IModularConfiguratorOptionSet;
}

interface IState {
    isOpen: boolean;
}

export class RootProductCompareModal extends React.PureComponent<
    IProps,
    IState
> {
    public state: IState = {
        isOpen: false,
    };

    private readonly onOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        this.setState({
            isOpen: true,
        });
    };

    private readonly onRequestClose = () => {
        this.setState({
            isOpen: false,
        });
    };

    render() {
        if (!this.props.optionSet.root_product_compare_modal) {
            return null;
        }
        return (
            <>
                <button className={styles.openBtn} onClick={this.onOpen}>
                    {t`Compare`}
                </button>
                <Modal
                    isOpen={this.state.isOpen}
                    onRequestClose={this.onRequestClose}
                    className={styles.modalContent}
                    aria={{
                        labelledby: "heading",
                    }}
                >
                    <RootProductCompareModalContent
                        optionSet={this.props.optionSet}
                    />
                </Modal>
            </>
        );
    }
}
