import { Dispatch } from "@reduxjs/toolkit";

import {
    IConcreteBundle,
    IOptionCode,
    IOptionSingleValue,
    IProduct,
    IVerboseProductClass,
} from "../../models/catalogue.interfaces";
import { FinancingPlan } from "../../models/financing";
import { ILocationGuessResponse } from "../../models/location.interfaces";
import { IProductID } from "../../models/nominals";
import { IAPIPrice } from "../../models/prices.interfaces";
import { IProductShippingMethod } from "../../models/shipping.interfaces";
import { Dispatchers as CommonDispatchers } from "../common/dispatchers";
import { IAction as ICommonAction } from "../common/reducers.interfaces";
import { Action } from "./constants";
import {
    IModularConfiguratorPromoCard,
    IModularConfiguratorUpsellInfoModal,
    IPLCOptionPanel,
    IPLCProductCategorySelector,
    IVariantPrefilter,
} from "./models.interfaces";
import {
    IAction,
    IActionHideUpsellInfoModal,
    IActionLoadedConcreteBundles,
    IActionLoadedFinancingPlans,
    IActionLoadedPrice,
    IActionLoadedProductClass,
    IActionLoadedProducts,
    IActionLoadedShippingMethod,
    IActionSetAddToBasketBtnState,
    IActionSetAddToBasketErrorModalState,
    IActionSetAddToFavoritesBtnState,
    IActionSetAddonsVisible,
    IActionSetOptionSelectionErrorState,
    IActionSetOptionValue,
    IActionSetPanelTriggerState,
    IActionSetQuantity,
    IActionSetRatingsModalState,
    IActionSetSelectedCategory,
    IActionSetSelectedRootProduct,
    IActionSetSelectedUpgrade,
    IActionSetSelectedVariant,
    IActionSetUpgradesVisible,
    IActionSetVariantPrefilter,
    IActionShowUpsellInfoModal,
    IActionUndoSelection,
    IActionUpdateSelectedAddons,
} from "./reducers.interfaces";

export class Dispatchers {
    dispatch: Dispatch<IAction>;
    common: CommonDispatchers;

    constructor(dispatch: Dispatch<IAction | ICommonAction>) {
        this.dispatch = dispatch;
        this.common = new CommonDispatchers(dispatch);
    }

    public readonly loadedProducts = (
        rootProducts: IProduct[],
        initialVariantID?: IProductID | null,
        categorySelectors?: IPLCProductCategorySelector[],
        optionPanels?: IPLCOptionPanel[],
    ) => {
        this.dispatch<IActionLoadedProducts>({
            type: Action.LOADED_PRODUCTS,
            payload: {
                rootProducts: rootProducts,
                initialVariantID: initialVariantID,
                categorySelectors: categorySelectors,
                optionPanels: optionPanels,
            },
        });
    };

    public readonly loadedProductClass = (
        productClass: IVerboseProductClass,
    ) => {
        this.dispatch<IActionLoadedProductClass>({
            type: Action.LOADED_PRODUCT_CLASS,
            payload: productClass,
        });
    };

    public readonly loadedPrice = (price: IAPIPrice) => {
        this.dispatch<IActionLoadedPrice>({
            type: Action.LOADED_PRICE,
            payload: price,
        });
    };

    public readonly loadedFinancingPlans = (plans: FinancingPlan[]) => {
        this.dispatch<IActionLoadedFinancingPlans>({
            type: Action.LOADED_FINANCING_PLANS,
            payload: plans,
        });
    };

    public readonly loadedLocation = (location: ILocationGuessResponse) => {
        this.common.updateDetectedLocation(location);
    };

    public readonly loadedProductShippingMethod = (
        shippingMethod: IProductShippingMethod | null,
    ) => {
        this.dispatch<IActionLoadedShippingMethod>({
            type: Action.LOADED_SHIPPING_METHOD,
            payload: shippingMethod,
        });
    };

    public readonly loadedConcreteBundles = (
        bundles: IConcreteBundle[],
        upsellModals: IModularConfiguratorUpsellInfoModal[],
        promoCards: IModularConfiguratorPromoCard[],
        selectedUpgradeID: IProductID | null,
    ) => {
        this.dispatch<IActionLoadedConcreteBundles>({
            type: Action.LOADED_CONCRETE_BUNDLES,
            payload: {
                bundles: bundles,
                upsellInfoModals: upsellModals,
                promoCards: promoCards,
                selectedUpgradeID: selectedUpgradeID,
            },
        });
    };

    public readonly setVariantPrefilter = (
        filter: IVariantPrefilter | null,
    ) => {
        this.dispatch<IActionSetVariantPrefilter>({
            type: Action.SET_VARIANT_PREFILTER,
            payload: filter,
        });
    };

    public readonly setSelectedRootProduct = (root: IProduct) => {
        this.dispatch<IActionSetSelectedRootProduct>({
            type: Action.SET_SELECTED_ROOT_PRODUCT,
            payload: root,
        });
    };

    public readonly setSelectedCategory = (
        payload: IActionSetSelectedCategory["payload"],
    ) => {
        this.dispatch<IActionSetSelectedCategory>({
            type: Action.SET_SELECTED_CATEGORY,
            payload: payload,
        });
    };

    public readonly setSelectedVariant = (
        root: IProduct,
        variant: IProduct,
        categorySelectors?: IPLCProductCategorySelector[],
    ) => {
        this.dispatch<IActionSetSelectedVariant>({
            type: Action.SET_SELECTED_VARIANT,
            payload: {
                root: root,
                variant: variant,
                categorySelectors: categorySelectors,
            },
        });
    };

    public readonly setOptionValue = (
        namespace: string,
        code: IOptionCode,
        index: number,
        totalNumValues: number,
        value: IOptionSingleValue,
    ) => {
        this.dispatch<IActionSetOptionValue>({
            type: Action.SET_OPTION_VALUE,
            payload: {
                namespace: namespace,
                code: code,
                index: index,
                totalNumValues: totalNumValues,
                value: value,
            },
        });
    };

    public readonly setQuantity = (quantity: number) => {
        this.dispatch<IActionSetQuantity>({
            type: Action.SET_QUANTITY,
            payload: quantity,
        });
    };

    public readonly undoSelection = () => {
        this.dispatch<IActionUndoSelection>({
            type: Action.UNDO_SELECTION,
        });
    };

    public readonly setUpgradesVisible = (isVisible: boolean) => {
        this.dispatch<IActionSetUpgradesVisible>({
            type: Action.SET_UPGRADES_VISIBLE,
            payload: isVisible,
        });
    };

    public readonly setAddonsVisible = (isVisible: boolean) => {
        this.dispatch<IActionSetAddonsVisible>({
            type: Action.SET_ADDONS_VISIBLE,
            payload: isVisible,
        });
    };

    public readonly setSelectedUpgrade = (
        selectedUpgrade: IProductID | null,
    ) => {
        this.dispatch<IActionSetSelectedUpgrade>({
            type: Action.SET_SELECTED_UPGRADE,
            payload: selectedUpgrade,
        });
    };

    public readonly updateSelectedAddons = (
        updates: IActionUpdateSelectedAddons["payload"],
    ) => {
        this.dispatch<IActionUpdateSelectedAddons>({
            type: Action.UPDATE_SELECTED_ADDONS,
            payload: updates,
        });
    };

    public readonly showUpsellInfoModal = (bundleID: number) => {
        this.dispatch<IActionShowUpsellInfoModal>({
            type: Action.SHOW_UPSELL_INFO_MODAL,
            payload: {
                bundleID: bundleID,
            },
        });
    };

    public readonly hideUpsellInfoModal = () => {
        this.dispatch<IActionHideUpsellInfoModal>({
            type: Action.HIDE_UPSELL_INFO_MODAL,
        });
    };

    public readonly setAddToBasketBtnState = (
        cooldownAction: boolean,
        text = "Add to cart",
    ) => {
        this.dispatch<IActionSetAddToBasketBtnState>({
            type: Action.SET_ADD_TO_BASKET_BTN_STATE,
            payload: {
                cooldownActive: cooldownAction,
                text: text,
            },
        });
    };

    public readonly setAddToFavoritesBtnState = (
        text = "Save to favorites",
    ) => {
        this.dispatch<IActionSetAddToFavoritesBtnState>({
            type: Action.SET_ADD_TO_FAVORITES_BTN_STATE,
            payload: {
                text: text,
            },
        });
    };

    public readonly setAddToBasketErrorModalState = (
        isOpen: boolean,
        reason = "",
    ) => {
        this.dispatch<IActionSetAddToBasketErrorModalState>({
            type: Action.SET_ADD_TO_BASKET_ERROR_MODAL_STATE,
            payload: {
                isOpen: isOpen,
                reason: reason,
            },
        });
    };

    public readonly setRatingsModalState = (isOpen: boolean) => {
        this.dispatch<IActionSetRatingsModalState>({
            type: Action.SET_RATINGS_MODAL_STATE,
            payload: {
                isOpen: isOpen,
            },
        });
    };

    public readonly setSetOptionSelectionErrorState = (reason: string) => {
        this.dispatch<IActionSetOptionSelectionErrorState>({
            type: Action.SET_OPTION_SELECTION_ERROR_STATE,
            payload: {
                reason: reason,
            },
        });
    };

    public readonly setPanelTriggerState = (isClicked: boolean) => {
        this.dispatch<IActionSetPanelTriggerState>({
            type: Action.SET_PANEL_TRIGGER_STATE,
            payload: {
                isClicked: isClicked,
            },
        });
    };
}
