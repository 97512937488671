import classNames from "classnames";
import React from "react";
import { connect } from "react-redux";
import { t } from "ttag";

import {
    IReviewQueryFacets,
    ISearchFacetOption,
} from "../../../models/reviews.interfaces";
import { parseFacetValues } from "../../../utils/reviews";
import { TDispatchMapper, TStateMapper } from "../../reducers.interfaces";
import { defaultState } from "../defaults";
import { Dispatchers } from "../dispatchers";
import { FilterOption } from "./FilterOption";

import styles from "./FilterOptionCollection.module.scss";

interface IOwnProps {
    facetType: IReviewQueryFacets;
    name: string | null;
    options: ISearchFacetOption[];
    isOpen: boolean;
    isCarouselComponentType: boolean;
    isCollapsedView?: boolean;
    isCondensedView?: boolean;
}

interface IReduxProps {
    isChecked: boolean;
}

interface IDispatchProps {
    updateFilterOptionCollectionValue: Dispatchers["updateFilterOptionCollectionValue"];
}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

interface IState {
    collectionOpen: boolean;
    variantListOpen: boolean;
}

class FilterOptionCollectionComponent extends React.PureComponent<
    IProps,
    IState
> {
    public state: IState = {
        collectionOpen: false,
        variantListOpen: this.props.isCondensedView ? false : true,
    };

    private readonly onChange = (e: React.FormEvent<HTMLInputElement>) => {
        this.onToggleListClick();
        this.props.updateFilterOptionCollectionValue(
            this.props.facetType,
            this.props.options,
            e.currentTarget.checked,
        );
    };

    private readonly onToggleCollection = () => {
        this.setState((prevState) => ({
            collectionOpen: !prevState.collectionOpen,
        }));
    };
    private readonly onToggleListClick = () => {
        if (!this.props.isCondensedView) {
            return;
        }
        this.setState((prevState) => ({
            variantListOpen: !prevState.variantListOpen,
        }));
    };

    private buildOptions(): JSX.Element[] {
        const options = this.props.options.reduce<JSX.Element[]>(
            (memo, option, i) => {
                // If suboptions exist, only show them
                const subOptionType = option.sub_option_type;
                if (subOptionType && option.sub_options.length > 0) {
                    const subOptions = option.sub_options.map(
                        (subOption, j) => (
                            <FilterOption
                                key={`${i}.${j}`}
                                facetType={subOptionType}
                                option={subOption}
                                showCount={false}
                            />
                        ),
                    );
                    memo = memo.concat(subOptions);
                } else {
                    memo.push(
                        <FilterOption
                            key={i}
                            facetType={"product_id"}
                            option={option}
                            showCount={true}
                        />,
                    );
                }
                return memo;
            },
            [],
        );
        return options;
    }

    private buildCollectionHeader(collectionName: string) {
        const categoryClasses = classNames({
            [styles.filterTitle]: true,
            [styles.filterTitleCategory]: true,
        });
        const collectionID = collectionName
            .toLowerCase()
            .replace(/[^a-zA-Z0-9]+/g, "-");
        const count = this.props.options
            .map((col) => col.quantity)
            .reduce((a, b) => a + b, 0);
        const checkboxClasses = classNames({
            [styles.checkbox]: true,
            [styles.checkboxCategory]: true,
        });
        const isChecked: boolean =
            this.props.isChecked ||
            (!!this.props.isCondensedView && this.state.variantListOpen);
        return (
            <div>
                <div className={categoryClasses}>
                    <input
                        id={collectionID}
                        type="checkbox"
                        checked={isChecked}
                        onChange={this.onChange}
                        className={checkboxClasses}
                    />
                    <label htmlFor={collectionID}>
                        <span
                            dangerouslySetInnerHTML={{
                                __html: collectionName,
                            }}
                        />
                        &nbsp;
                        <span>({count})</span>
                    </label>
                </div>
            </div>
        );
    }

    render() {
        const collapsedViewAndOpen =
            !this.props.isCollapsedView || this.state.collectionOpen;
        const variantListOpen = this.state.variantListOpen;
        const listClasses = classNames({
            [styles.list]: true,
            [styles.listParentOpen]: this.props.isOpen || variantListOpen,
            [styles.listOpen]: this.props.isOpen,
            [styles.indentedList]: this.props.isCondensedView || false,
        });
        const toggleClasses = classNames({
            [styles.list]: true,
            [styles.titleToggle]: true,
            [styles.titleToggleOpen]: collapsedViewAndOpen,
            [styles.listOpen]: this.props.isOpen,
        });
        const toggleOpenClasses = classNames({
            [styles.list]: true,
            [styles.listOpen]: true,
        });
        const collectionName = this.props.name || "";

        return (
            <>
                {this.props.isCollapsedView && (
                    <button
                        className={toggleClasses}
                        onClick={this.onToggleCollection}
                        aria-expanded={this.state.collectionOpen}
                        aria-controls={"collection-side-filters"}
                    >
                        <span
                            dangerouslySetInnerHTML={{ __html: collectionName }}
                        ></span>
                    </button>
                )}
                <span className="ada-screenreader-only">
                    {t`Activating this element will cause content on the page to be updated`}
                </span>
                {collapsedViewAndOpen && (
                    <ul className={listClasses} id={"collection-side-filters"}>
                        <li>
                            {!this.props.isCarouselComponentType &&
                                this.buildCollectionHeader(collectionName)}
                            {variantListOpen && (
                                <ul className={toggleOpenClasses}>
                                    {this.buildOptions()}
                                </ul>
                            )}
                        </li>
                    </ul>
                )}
            </>
        );
    }
}

const mapStateToProps: TStateMapper<"reviews", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    const state = rootState.reviews || defaultState;

    const currentOptionIDs = parseFacetValues(state.ui.facetValues.product_id);
    const currentSubOptionIDs = parseFacetValues(
        state.ui.facetValues.product_variant_id,
    );

    const collectionOptionIDs = ownProps.options.map(
        (col) => `${col.option_id}`,
    );
    const collectionSubOptionIDs = ownProps.options.reduce<string[]>(
        (memo, option) => {
            const subOptionIDs = option.sub_options.map(
                (variant) => `${variant.option_id}`,
            );
            return memo.concat(subOptionIDs);
        },
        [],
    );

    const allOptionChecked = collectionOptionIDs.every((id) =>
        currentOptionIDs.includes(id),
    );
    const allSubOptionsChecked = collectionSubOptionIDs.every((id) =>
        currentSubOptionIDs.includes(id),
    );

    return {
        ...ownProps,
        isChecked:
            (collectionOptionIDs.length > 0 && allOptionChecked) ||
            (collectionSubOptionIDs.length > 0 && allSubOptionsChecked),
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const dispatchers = new Dispatchers(dispatch);
    return {
        updateFilterOptionCollectionValue:
            dispatchers.updateFilterOptionCollectionValue,
    };
};

export const FilterOptionCollection = connect(
    mapStateToProps,
    mapDispatchToProps,
)(FilterOptionCollectionComponent);
