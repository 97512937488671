import React from "react";
import { t } from "ttag";

import { IProduct } from "../models/catalogue.interfaces";

interface IProps {
    variant: IProduct | null;
}

interface IState {}

export class ProductUnavailable extends React.PureComponent<IProps, IState> {
    render() {
        let reasonMessage: string = t`This product does not come in this configuration`;
        if (this.props.variant) {
            if (this.props.variant.availability.is_available_to_buy) {
                return null;
            }
            reasonMessage = this.props.variant.availability.message;
        }
        return <div className="configurator__unavailable">{reasonMessage}</div>;
    }
}
