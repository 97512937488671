import React from "react";

import { RatingGraphic } from "../../../common/RatingGraphic";
import { UGCCarousel } from "../../../common/UGCCarousel";
import { IFormUUID, IReviewsProductTypeID } from "../../../models/nominals";
import { UGCListSubBlock } from "../../../models/ugc";
import { roundReviewRating } from "../../../utils/math";
import { ReviewsComponentTypes } from "../constants";
import { CarouselReviewList } from "../elements/CarouselReviewList";
import { LoadMoreButton } from "../elements/LoadMoreButton";
import { ReviewList } from "../elements/ReviewList";
import { ReviewStarRating } from "../elements/ReviewStarRating";
import { SidebarFilterSection } from "../elements/SidebarFilterSection";
import { TopBar } from "../elements/TopBar";
import { TopFilterSection } from "../elements/TopFilterSection";
import { WriteReviewButton } from "../elements/WriteReviewButton";
import { WriteReviewForm } from "../elements/WriteReviewForm";
import { WriteReviewThankYou } from "../elements/WriteReviewThankYou";

import styles from "./ProductReviews.module.scss";

interface IProps {
    displayedProductTypeIDWhitelist: IReviewsProductTypeID[];
    title: string;
    numReviews: number;
    averageRating: number;
    showWriteReviewButton: boolean;
    ugcCarouselDesc: string;
    ugcCarouselPosts: UGCListSubBlock[];
    brand: string;
    showProductsFilter: boolean;
    showSourcesFilter: boolean;
    writeReviewFormUUID: IFormUUID;
    writeReviewFormTitle: string;
    starHasStroke?: boolean;
    reviewsComponentType?: ReviewsComponentTypes;
    onLoadMore: () => void;
    isCollapsedView?: boolean;
}

interface IState {
    reviewsComponentType: ReviewsComponentTypes;
}

export class ProductReviews extends React.PureComponent<IProps, IState> {
    private readonly MAX_SEARCH_KEYWORDS_NUMBER = 6;
    private readonly titleRef = React.createRef<HTMLDivElement>();

    public state: IState = {
        reviewsComponentType: ReviewsComponentTypes.CLASSIC,
    };

    componentDidMount() {
        if (this.props.reviewsComponentType) {
            this.setState({
                reviewsComponentType: this.props.reviewsComponentType,
            });
        }
        const scrollToReviews = document.body.getAttribute("scrollToReviews");
        const el = document.querySelector<HTMLElement>("#reviews__body");
        if (el && scrollToReviews) {
            el?.scrollIntoView(true);
            document.body.removeAttribute("scrollToReviews");
        }
    }

    private readonly onReviewSubmit = () => {
        if (this.titleRef.current) {
            this.titleRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };

    private buildSideNav() {
        return (
            <SidebarFilterSection
                isMobile={false}
                displayedProductTypeIDWhitelist={
                    this.props.displayedProductTypeIDWhitelist
                }
                showProducts={this.props.showProductsFilter}
                showSources={this.props.showSourcesFilter}
                starHasStroke={this.props.starHasStroke}
                writeReviewURL={""}
                onToggleMobileSideNav={() => {}}
                mobileSideNavOpen={true}
                showAppliedFilters={false}
                isCollapsedView={this.props.isCollapsedView}
            />
        );
    }

    private buildTopFilterNav() {
        return (
            <TopFilterSection
                isMobile={false}
                displayedProductTypeIDWhitelist={
                    this.props.displayedProductTypeIDWhitelist
                }
                showProducts={this.props.showProductsFilter}
                showSources={this.props.showSourcesFilter}
                maxKeywordsNumber={this.MAX_SEARCH_KEYWORDS_NUMBER}
                isCarouselComponentType={
                    this.state.reviewsComponentType ===
                    ReviewsComponentTypes.CAROUSEL
                }
            />
        );
    }

    private buildReviews() {
        return (
            <ReviewList
                showSideDate={true}
                enablePDPLinks={false}
                showRecommended={false}
                showVerboseUserInfo={false}
                starHasStroke={this.props.starHasStroke}
            />
        );
    }

    private buildLoadMoreButton() {
        return <LoadMoreButton onLoadMore={this.props.onLoadMore} />;
    }

    private buildUGCCarousel() {
        if (
            !this.props.ugcCarouselPosts ||
            this.props.ugcCarouselPosts.length < 1
        ) {
            return null;
        }

        return (
            <div
                role="region"
                aria-label="Testimonials Carousel"
                className="ugc-carousel-container ugc-carousel-container__reviews-full l-full-width"
            >
                {this.props.ugcCarouselDesc && (
                    <section
                        className="ugc-carousel__header"
                        dangerouslySetInnerHTML={{
                            __html: this.props.ugcCarouselDesc,
                        }}
                    />
                )}
                {this.props.ugcCarouselPosts.length > 0 && (
                    <UGCCarousel tiles={this.props.ugcCarouselPosts} />
                )}
            </div>
        );
    }

    private buildRatingGraphic() {
        return (
            <div className="customer-review__stars customer-review__stars--mobile">
                <RatingGraphic
                    cardClass="product-reviews-header"
                    cardSize="large"
                    rating={this.props.averageRating}
                    onlyStars={true}
                    starHasStroke={this.props.starHasStroke}
                />
            </div>
        );
    }

    private buildReviewList() {
        return (
            <div className={styles.reviewList}>
                {this.buildReviews()}
                {this.buildLoadMoreButton()}
            </div>
        );
    }

    private buildCarouselReviewList() {
        return (
            <CarouselReviewList
                showSideDate={true}
                enablePDPLinks={false}
                starHasStroke={this.props.starHasStroke}
                onLoadMore={this.props.onLoadMore}
                maxKeywordsNumber={this.MAX_SEARCH_KEYWORDS_NUMBER}
            />
        );
    }

    render() {
        return (
            <div className="customer-review l-capped-width">
                <div className="customer-review__header customer-review__header--pdp">
                    {this.state.reviewsComponentType !==
                        ReviewsComponentTypes.CAROUSEL &&
                        this.buildRatingGraphic()}
                    {this.props.title && (
                        <h2 className={styles.title} ref={this.titleRef}>
                            {this.props.title}
                        </h2>
                    )}
                    {this.buildUGCCarousel()}
                    <ReviewStarRating
                        roundedRating={roundReviewRating(
                            this.props.averageRating,
                        )}
                        numReviews={this.props.numReviews}
                        averageRating={this.props.averageRating}
                        starHasStroke={this.props.starHasStroke}
                        reviewsComponentType={this.state.reviewsComponentType}
                    />
                    {this.props.showWriteReviewButton && (
                        <>
                            <WriteReviewButton
                                formUUID={this.props.writeReviewFormUUID}
                            />
                            <WriteReviewForm
                                formUUID={this.props.writeReviewFormUUID}
                                formTitle={this.props.writeReviewFormTitle}
                                brand={this.props.brand}
                                onReviewSubmit={this.onReviewSubmit}
                            />
                            <WriteReviewThankYou
                                formUUID={this.props.writeReviewFormUUID}
                            />
                        </>
                    )}
                </div>
                <div
                    id="reviews__body"
                    className="customer-review__body"
                    tabIndex={-1}
                >
                    {this.state.reviewsComponentType !==
                    ReviewsComponentTypes.CAROUSEL
                        ? this.buildSideNav()
                        : this.buildTopFilterNav()}
                    <div
                        className={`${styles.content} customer-review__content`}
                    >
                        <TopBar />
                        {this.state.reviewsComponentType !==
                        ReviewsComponentTypes.CAROUSEL
                            ? this.buildReviewList()
                            : this.buildCarouselReviewList()}
                    </div>
                </div>
            </div>
        );
    }
}
