import { unique } from "../../utils/functional";
import { guardReducer } from "../../utils/redux";
import { Action } from "./constants";
import { defaults } from "./defaults";
import { IAction, IReduxState } from "./reducers.interfaces";

export const reducers = guardReducer(
    Action,
    defaults,
    (state = defaults, action: IAction): IReduxState => {
        switch (action.type) {
            case Action.SET_COMPARE_TILES:
                return {
                    ...state,
                    tiles: action.payload.tiles,
                };

            case Action.UPDATE_PRICE_SELECT_VALUE:
                return {
                    ...state,
                    priceSelectValue: action.payload.value,
                };

            case Action.ADD_TO_SELECTED:
                return {
                    ...state,
                    selectedTileIDs: [
                        ...state.selectedTileIDs,
                        action.payload.tileID,
                    ].filter(unique),
                };

            case Action.REMOVE_FROM_SELECTED:
                return {
                    ...state,
                    selectedTileIDs: state.selectedTileIDs.filter((tileID) => {
                        return tileID !== action.payload.tileID;
                    }),
                };

            case Action.COLLAPSE_TRAY:
                return {
                    ...state,
                    trayIsExpanded: false,
                    trayLabel: "expand",
                };

            case Action.EXPAND_TRAY:
                return {
                    ...state,
                    trayIsExpanded: true,
                    trayLabel: "collapse",
                };

            case Action.SHOW_MODAL:
                return {
                    ...state,
                    showCompareModal: true,
                };

            case Action.HIDE_MODAL:
                return {
                    ...state,
                    showCompareModal: false,
                };

            case Action.SET_ROOT_PRODUCTS:
                return {
                    ...state,
                    rootProducts: action.payload.rootProducts,
                };
        }

        return state;
    },
);
