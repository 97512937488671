import React from "react";
import SVG from "react-inlinesvg";
import ReactModal from "react-modal";
import { t } from "ttag";

import iconXClose from "../../img/icons/x-close.svg";
import { focusElement, trapFocus } from "../utils/keyboardFocus";
import { getPageSetting } from "../utils/settings";
import { ChildrenResizeSensor } from "./ChildrenResizeSensor";

import styles from "./Modal.module.scss";

export interface IProps extends ReactModal.Props {
    closeBtnProps?: React.DetailedHTMLProps<
        React.ButtonHTMLAttributes<HTMLButtonElement>,
        HTMLButtonElement
    >;
    closeBtnContent?: React.ReactNode;
    closeBtnIcon?: string;
    preCloseBtnContent?: React.ReactNode;
    disableHeightExpansion?: boolean;
}

interface IState {}

// Forward some types from react-modal
export type ModalStyles = ReactModal.Styles;

// Reset defaults
ReactModal.defaultStyles = {
    overlay: {},
    content: {},
};

export class Modal extends React.Component<IProps, IState> {
    public readonly closeBtn = React.createRef<HTMLButtonElement>();

    public contentElem: HTMLDivElement | null = null;
    public portalElem: HTMLElement | null = null;

    private isOpen = false;

    private readonly onAfterOpen: ReactModal.OnAfterOpenCallback = (
        opts,
    ): void => {
        this.isOpen = true;
        // Expand the body height to meet or exceed the bottom of the modal content
        if (!this.props.disableHeightExpansion) {
            this.expandPortalHeight();
        }
        // Prevent scroll on focus in Sealy
        const options = {
            preventScroll: getPageSetting("app-slug") === "sealy",
        };
        // Set keyboard focus to the close button
        if (this.closeBtn.current) {
            focusElement(this.closeBtn.current, options);
        }
        // Trap focus inside the modal
        if (this.contentElem) {
            trapFocus(this.contentElem);
        }
        // Bubble up event to parent component
        if (this.props.onAfterOpen) {
            this.props.onAfterOpen(opts);
        }
    };

    private readonly onAfterClose = (): void => {
        this.isOpen = false;
        // Collapse the portal back to its default, zero-height state.
        this.collapsePortalHeight();
        // Bubble up event to parent component
        if (this.props.onAfterClose) {
            this.props.onAfterClose();
        }
    };

    private readonly onResize = (): void => {
        if (this.isOpen && !this.props.disableHeightExpansion) {
            this.expandPortalHeight();
        }
    };

    private readonly setContentRef = (elem: HTMLDivElement | null): void => {
        this.contentElem = elem;
        // Bubble up event to parent component
        if (this.props.contentRef && elem) {
            this.props.contentRef(elem);
        }
    };

    private readonly setOverlayRef = (elem: HTMLDivElement | null): void => {
        // Cache the portal elem too (but purposefully don't overwrite it if overlay is null).
        // This allows us to zero out the portal height after the overlay elem is removed.
        if (!elem) {
            return;
        }
        this.portalElem = elem.parentElement;
        // Bubble up event to parent component
        if (this.props.overlayRef) {
            this.props.overlayRef(elem);
        }
    };

    private expandPortalHeight(): void {
        if (!this.portalElem || !this.contentElem) {
            return;
        }
        const portalRect = this.portalElem.getBoundingClientRect();
        const modalContentRect = this.contentElem.getBoundingClientRect();
        const portalBottom = window.scrollX + portalRect.bottom;
        const modalContentBottom = window.scrollX + modalContentRect.bottom;
        // Adjust the height of the portal so that its at or below the height of the modal content. This ensures
        // that the modal content is never taller than the page itself (even though the content is absolutely
        // positioned, and therefore doesn't otherwise affect the page layout at all).
        const delta = portalRect.height + (modalContentBottom - portalBottom);
        this.portalElem.style.height = `${Math.max(0, delta)}px`;
    }

    private collapsePortalHeight(): void {
        // Undo the changes made by expandPortalHeight.
        if (this.portalElem) {
            this.portalElem.style.height = "";
        }
    }

    render() {
        return (
            <ReactModal
                aria={{
                    modal: true,
                }}
                role="dialog"
                {...this.props}
                contentRef={this.setContentRef}
                overlayRef={this.setOverlayRef}
                onAfterOpen={this.onAfterOpen}
                onAfterClose={this.onAfterClose}
            >
                {this.props.preCloseBtnContent}
                <button
                    aria-label={t`Close`}
                    className={`${styles.modalCloseBtn} ${
                        this.props.closeBtnProps?.className || ""
                    }`}
                    {...this.props.closeBtnProps}
                    ref={this.closeBtn}
                    onClick={this.props.onRequestClose}
                >
                    {this.props.closeBtnContent || (
                        <SVG
                            className={styles.modalCloseIcon}
                            src={this.props.closeBtnIcon || iconXClose}
                            aria-hidden="true"
                        />
                    )}
                </button>
                <ChildrenResizeSensor onResize={this.onResize}>
                    {this.props.children}
                </ChildrenResizeSensor>
            </ReactModal>
        );
    }
}
