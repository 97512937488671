import dinero from "dinero.js/build/cjs/dinero";

import { IBasketLineDiscount } from "../models/catalogue";
import { IMonths, Months, isoMonths } from "../models/nominals";
import {
    IPostDiscountAddons,
    IPrice,
    IPriceQuantity,
} from "../models/prices.interfaces";
import { getBestEligiblePlan } from "./financing";
import { decimal } from "./format";
import { anyTruthy, notEmpty } from "./functional";
import { isDinero } from "./guards";
import { getNumber } from "./numbers";

type IDineroConvertable = string | number | dinero.Dinero | null | undefined;

export type Dinero = dinero.Dinero;

export const ZERO = dinero({ amount: 0 });

/**
 * Convert the given number to minor currency
 *
 * dinero.js requires the amount to be in minor currency (cents).
 * For now assumes USD is used.
 */
const getMinorCurrency = (value: number, multiplier = 100): number => {
    return value * multiplier;
};

/**
 * Convert the value to a type accepted by dinero.js
 */
const getDineroNum = (value: string | number | undefined | null): number => {
    const num = getNumber(value, 0);
    return Math.round(getMinorCurrency(num));
};

/**
 * Construct and return a dinero object using the parameter
 */
export const getDinero = (value: IDineroConvertable): dinero.Dinero => {
    if (isDinero(value)) {
        return value;
    }
    const amount = getDineroNum(value);
    return dinero({ amount: amount });
};

/**
 * Calculate a monthly financing price based on total principal and term length (always
 * assumes 0% interest).
 */
export const getMonthlyPrice = (
    totalPrice: dinero.Dinero,
    termLength: IMonths | number,
) => {
    const length = Months.is(termLength)
        ? isoMonths.unwrap(termLength)
        : termLength;
    const ratios = new Array<number>(length).fill(1);
    const payments = totalPrice.allocate(ratios);
    const largestPayment = payments.reduce<dinero.Dinero>((memo, payment) => {
        return !memo || payment.greaterThan(memo) ? payment : memo;
    }, payments[0]);
    return largestPayment;
};

const sumPriceQuantityProperties = (
    priceQuantities: IPriceQuantity[],
    property:
        | "excl_tax"
        | "incl_tax"
        | "tax"
        | "retail"
        | "cosmetic_excl_tax"
        | "cosmetic_incl_tax",
): dinero.Dinero => {
    return priceQuantities.reduce<dinero.Dinero>((memo, [quantity, price]) => {
        return memo.add(getDinero(price[property]).multiply(quantity));
    }, getDinero(0));
};

export const sumPriceQuantities = (
    priceQuantities: IPriceQuantity[],
    updateTermLength = true,
): IPrice | null => {
    if (priceQuantities.length <= 0) {
        return null;
    }
    const price: IPrice = {
        ...priceQuantities[0][1],
        excl_tax: decimal(
            sumPriceQuantityProperties(priceQuantities, "excl_tax"),
        ),
        incl_tax: decimal(
            sumPriceQuantityProperties(priceQuantities, "incl_tax"),
        ),
        tax: decimal(sumPriceQuantityProperties(priceQuantities, "tax")),
        retail: decimal(sumPriceQuantityProperties(priceQuantities, "retail")),
        cosmetic_excl_tax: decimal(
            sumPriceQuantityProperties(priceQuantities, "cosmetic_excl_tax"),
        ),
        cosmetic_incl_tax: decimal(
            sumPriceQuantityProperties(priceQuantities, "cosmetic_incl_tax"),
        ),
        post_discount_addons: priceQuantities.reduce<IPostDiscountAddons>(
            (memo, [qty, prc]) => {
                for (const addon of prc.post_discount_addons || []) {
                    memo.push({
                        name: addon.name,
                        price_excl_tax: decimal(
                            getDinero(addon.price_excl_tax).multiply(qty),
                        ),
                    });
                }
                return memo;
            },
            [],
        ),
    };
    const perMonths = priceQuantities.map(
        ([_qty, p]) => p.enable_per_month_pricing && p.per_month_term_length,
    );
    const financingPrincipal = getDinero(price.cosmetic_excl_tax);
    const enablePerMonth = anyTruthy(perMonths);
    let termLength = isoMonths.wrap(
        Math.max(
            ...priceQuantities
                .map(([_qty, p]) => p.per_month_term_length)
                .filter(notEmpty)
                .map(isoMonths.unwrap),
        ),
    );
    if (updateTermLength) {
        const plan = getBestEligiblePlan(financingPrincipal);
        if (plan) {
            termLength = plan.length;
        }
    }
    if (price.cosmetic_excl_tax && enablePerMonth && termLength) {
        price.enable_per_month_pricing = true;
        price.per_month_term_length = termLength;
        price.per_month = decimal(
            getMonthlyPrice(financingPrincipal, termLength),
        );
    }
    return price;
};

export const getTotalPriceWithAddons = (
    priceTotal: IPrice,
    selectedAddonPrice: IPrice | null,
): IPrice => {
    const prices: IPriceQuantity[] = [[1, priceTotal]];
    if (selectedAddonPrice) {
        prices.push([1, selectedAddonPrice]);
    }
    const totalPriceWithAddons = sumPriceQuantities(prices) || priceTotal;
    return totalPriceWithAddons;
};

export const getHighestDiscountName = (
    discounts: IBasketLineDiscount[],
): string | null => {
    const highestDiscount = discounts.reduce((prev, current) => {
        return parseFloat(current.amount) > parseFloat(prev.amount)
            ? current
            : prev;
    }, discounts[0]);
    return highestDiscount
        ? highestDiscount.voucher_name || highestDiscount.offer_name
        : null;
};
