import {
    clearAllBodyScrollLocks,
    disableBodyScroll,
    enableBodyScroll,
} from "body-scroll-lock";
import classNames from "classnames";
import React from "react";
import { connect } from "react-redux";

import Flair from "../../../common/Flair";
import { Image } from "../../../common/Image";
import { Modal } from "../../../common/Modal";
import { ConfiguratorTypes } from "../../../constants";
import { IProduct } from "../../../models/catalogue.interfaces";
import { IImageURL } from "../../../models/nominals";
import { TDispatchMapper, TStateMapper } from "../../reducers.interfaces";
import { MOBILE_WIDTH_THRESHOLD_ALTERNATIVE } from "../constants";
import { defaults } from "../defaults";
import { Dispatchers } from "../dispatchers";
import { IModularConfiguratorPromoCardInfoModal } from "../models.interfaces";
import { baseVariantSelector, rootProductSelector } from "../selectors";
import { UpsellInfoColumnContent } from "./ConfiguratorUpsellInfoModalContent";

import styles from "./ConfiguratorPromoCardInfoModal.module.scss";

interface IOwnProps {
    configuratorType: ConfiguratorTypes;
    infoModal: IModularConfiguratorPromoCardInfoModal;
    isOpen: boolean;
    onCloseModal: () => void;
    modalTitle: string | null;
    title: string | null;
    cardImage: IImageURL | null | undefined;
}

interface IReduxProps {
    baseVariant: IProduct | null;
    selectedRoot: IProduct | null;
}

interface IDispatchProps {
    dispatchers: Dispatchers;
}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

interface IState {}

class PromoCardInfoModalComponent extends React.PureComponent<IProps, IState> {
    private readonly modalContainerRef = React.createRef<HTMLDivElement>();

    componentWillUnmount() {
        clearAllBodyScrollLocks();
    }

    private readonly onClosePanel = () => {
        enableBodyScroll(this.modalContainerRef.current as HTMLElement);
        document.body.classList.remove("configurator-panel-open");
    };

    private readonly onOpenPanel = () => {
        if (window.innerWidth < MOBILE_WIDTH_THRESHOLD_ALTERNATIVE) {
            disableBodyScroll(this.modalContainerRef.current as HTMLElement);
        }

        document.body.classList.add("configurator-panel-open");
    };

    private readonly renderHeroImage = () => {
        const heroAlt = this.props.title
            ? this.props.title
            : "promo card - hero image";

        if (this.props.infoModal.image) {
            return (
                <Image
                    className={styles.heroImage}
                    src={this.props.infoModal.image}
                    alt={heroAlt}
                    lazy={false}
                />
            );
        }

        if (this.props.cardImage) {
            return (
                <Image
                    className={styles.heroImage}
                    src={this.props.cardImage}
                    alt={heroAlt}
                    lazy={false}
                />
            );
        }

        return null;
    };

    private readonly renderHeroTitle = () => {
        if (this.props.infoModal.title) {
            return (
                <h2 className={styles.heroTitle}>
                    {this.props.infoModal.title}
                </h2>
            );
        }

        if (this.props.title) {
            return <h2 className={styles.heroTitle}>{this.props.title}</h2>;
        }

        return null;
    };

    render() {
        const modalClasses = classNames({
            [styles.modalWrapper]:
                this.props.configuratorType !== ConfiguratorTypes.PANEL,
            [styles.panelWrapper]:
                this.props.configuratorType === ConfiguratorTypes.PANEL,
        });
        //
        const overlayClasses = classNames({
            [styles.panelOverlay]:
                this.props.configuratorType === ConfiguratorTypes.PANEL,
        });

        const modalLabel = this.props.modalTitle
            ? this.props.modalTitle
            : "info modal";

        return (
            <div ref={this.modalContainerRef}>
                <Modal
                    overlayClassName={overlayClasses}
                    className={modalClasses}
                    isOpen={this.props.isOpen}
                    onAfterOpen={this.onOpenPanel}
                    onAfterClose={this.onClosePanel}
                    onRequestClose={this.props.onCloseModal}
                    contentLabel={modalLabel}
                >
                    {this.props.modalTitle && (
                        <h1 className={styles.title}>
                            {this.props.modalTitle}
                        </h1>
                    )}
                    {this.props.infoModal.flair &&
                        this.props.infoModal.flair.length > 0 && (
                            <Flair
                                flair={this.props.infoModal.flair[0].value}
                                inline={true}
                            />
                        )}
                    <div className={styles.hero}>
                        {this.renderHeroImage()}
                        {this.renderHeroTitle()}
                    </div>
                    <div className={styles.container}>
                        <UpsellInfoColumnContent
                            isOneProduct={true}
                            content={this.props.infoModal.content}
                        />
                    </div>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps: TStateMapper<"configurator", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    const state = rootState.configurator || defaults;
    const baseVariant = baseVariantSelector(state);
    const selectedRoot = rootProductSelector(state);
    return {
        baseVariant: baseVariant,
        selectedRoot: selectedRoot,
        ...ownProps,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const dispatchers = new Dispatchers(dispatch);
    return {
        dispatchers: dispatchers,
    };
};

export const PromoCardInfoModal = connect(
    mapStateToProps,
    mapDispatchToProps,
)(PromoCardInfoModalComponent);
