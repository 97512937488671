import React from "react";
import { connect } from "react-redux";

import { TDispatchMapper, TStateMapper } from "../../reducers.interfaces";
import { defaultState } from "../defaults";
import { Dispatchers } from "../dispatchers";
import { getParamsFromFacetValues, updateQueryParams } from "../history";
import { ReviewsViewer as ReviewsViewerComponent } from "./../components/ReviewsViewer";
import {
    IReviewsAppDispatchProps,
    IReviewsAppOwnProps,
    IReviewsAppReduxProps,
    ReviewsAppContainer,
} from "./_base";

interface IOwnProps extends IReviewsAppOwnProps {
    showSources?: boolean;
    writeReviewURL?: string;
    showAppliedFilters: boolean;
    isCondensedView?: boolean;
}

interface IReduxProps extends IReviewsAppReduxProps {
    mobileSideNavOpen: boolean;
}

interface IDispatchProps extends IReviewsAppDispatchProps {}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

class ReviewsViewerContainer extends ReviewsAppContainer<IProps> {
    static defaultProps: Partial<IProps> = {
        showSources: true,
        pageSize: 6,
    };

    private readonly onToggleMobileSideNav = () => {
        this.props.dispatchers.toggleMobileSideNav();
    };

    componentDidMount() {
        super.componentDidMount();
        // Update query string to match the current facet selections
        this.pushQueryStringUpdate();
    }

    componentDidUpdate(prevProps: IProps) {
        super.componentDidUpdate(prevProps);
        this.pushQueryStringUpdate();
    }

    private pushQueryStringUpdate() {
        const params = getParamsFromFacetValues(
            this.props.facetValues,
            this.props.productTypeIDs,
        );
        updateQueryParams(params);
    }

    protected buildContent() {
        const showSources = !!this.props.showSources;
        return (
            <ReviewsViewerComponent
                displayedProductTypeIDWhitelist={
                    this.props.displayedProductTypeIDWhitelist || []
                }
                enablePDPLinks={showSources}
                isCondensedView={this.props.isCondensedView}
                mobileSideNavOpen={this.props.mobileSideNavOpen}
                onLoadMore={this.onLoadMore}
                onToggleMobileSideNav={this.onToggleMobileSideNav}
                showAppliedFilters={
                    this.props.showAppliedFilters !== undefined
                        ? this.props.showAppliedFilters
                        : false
                }
                showSources={showSources}
                starHasStroke={this.props.starHasStroke}
                writeReviewURL={
                    this.props.writeReviewURL !== undefined
                        ? this.props.writeReviewURL
                        : ""
                }
            />
        );
    }
}

const mapStateToProps: TStateMapper<"reviews", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    const state = rootState.reviews || defaultState;
    return {
        // Direct Props
        ...ownProps,

        // Custom Redux Data
        mobileSideNavOpen: state.ui.mobileSideNavOpen,

        // Base Redux Data
        loadedInitialProducts: state.data.loadedInitialProducts,
        loadedInitialReviews: state.data.loadedInitialReviews,
        products: state.data.products,
        productTypeIDs: state.ui.productTypeIDs,
        selectedSortOption: state.ui.selectedSortOption,
        reviews: state.data.reviews,
        facets: state.data.facets,
        facetValues: state.ui.facetValues,
        page: state.ui.page,
        hasMore: state.ui.hasMore,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const dispatchers = new Dispatchers(dispatch);
    return {
        dispatchers: dispatchers,
    };
};

export const ReviewsViewer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ReviewsViewerContainer);
