import classNames from "classnames";
import React from "react";
import { t } from "ttag";

import { IValueProp } from "../models/value-props.interfaces";
import { UncontrolledPopover } from "./Popover";
import { RichText } from "./RichText";

import styles from "./ValueProp.module.scss";

interface IProps {
    prop: IValueProp;
    className?: string;
}

interface IState {}

export class ValueProp extends React.Component<IProps, IState> {
    private buildValueProp(valueProp: IValueProp) {
        const content = (
            <>
                {valueProp.icon && (
                    <img
                        className="lazyload"
                        data-src={valueProp.icon.url}
                        alt=""
                    />
                )}
                <div
                    className="value-prop-list-item__copy"
                    dangerouslySetInnerHTML={{
                        __html: valueProp.title + valueProp.subtitle,
                    }}
                ></div>
            </>
        );
        if (!valueProp.show_tooltip) {
            return <span>{content}</span>;
        }
        const modalClasses = classNames({
            [styles.modal]: true,
            "place-bottom": true,
        });
        return (
            <UncontrolledPopover
                triggerContent={content}
                triggerBtnProps={{}}
                popperOptions={{
                    modifiers: [
                        {
                            name: "offset",
                            options: {
                                offset: [0, 16],
                            },
                        },
                        {
                            name: "flip",
                            options: {
                                fallbackPlacements: ["top", "bottom"],
                            },
                        },
                    ],
                    placement: "bottom",
                }}
                modalProps={{
                    className: modalClasses,
                    contentLabel: t`Offers`,
                    style: {
                        overlay: {
                            backgroundColor: "transparent",
                        },
                    },
                }}
            >
                <RichText html={valueProp.tooltip_contents} />
            </UncontrolledPopover>
        );
    }

    render() {
        const listItemClasses = classNames({
            [styles.listItem]: true,
            [this.props.className || ""]: true,
        });
        return (
            <li className={listItemClasses}>
                {this.buildValueProp(this.props.prop)}
            </li>
        );
    }
}

export default ValueProp;
