import React from "react";

import { Image } from "../../../common/Image";
import { IValueProp } from "../../../models/catalogue.interfaces";

import styles from "./BasketLineValueProps.module.scss";

interface IProps {
    valueProps: IValueProp[];
    isMobile: boolean;
}

interface IState {}

export class BasketLineValueProps extends React.Component<IProps, IState> {
    private buildValueProp(prop: IValueProp) {
        return (
            <li className={styles.prop} key={prop.name}>
                <Image className={styles.image} src={prop.image_url} alt="" />
                <span className={styles.text}>{prop.description}</span>
            </li>
        );
    }

    private buildValueProps(props: IValueProp[]) {
        if (this.props.isMobile) {
            return props.slice(0, 2).map((prop) => {
                return this.buildValueProp(prop);
            });
        }
        return props.map((prop) => {
            return this.buildValueProp(prop);
        });
    }

    render() {
        return (
            <ul className={styles.list}>
                {this.buildValueProps(this.props.valueProps)}
            </ul>
        );
    }
}
