import React from "react";

import {
    IOptionCode,
    IOptionSingleValue,
    IProduct,
} from "../models/catalogue.interfaces";
import { SelectOptions } from "./SelectOptions";

interface IProps {
    variant: IProduct | null;
    code: IOptionCode;
    value: IOptionSingleValue;
    options: IOptionSingleValue[];
    prefixNameCapped: string;
    configuratorClass: string;
    prefixClass: string;
    selectClass: string;
    onChange: (event: React.FormEvent<HTMLSelectElement>) => void;
}

interface IState {}

export class ProductOptionColorSelector extends React.PureComponent<
    IProps,
    IState
> {
    private buildSwatch(code: IOptionCode = "option_color_hex") {
        if (!this.props.variant) {
            return;
        }
        const attr = this.props.variant.attributes[code];
        if (!attr) {
            return;
        }
        const colorHex = attr.value;
        if (!colorHex) {
            return;
        }
        const swatchStyle: React.CSSProperties = {
            backgroundColor: `#${colorHex}`,
        };
        return (
            <div
                className="configurator__color-swatch"
                style={swatchStyle}
            ></div>
        );
    }

    render() {
        return (
            <div>
                <div className="configurator__select-container configurator__select-container--swatch configurator__color-swatch-container">
                    {this.buildSwatch()}
                </div>
                <div className={this.props.configuratorClass}>
                    <span className={this.props.prefixClass}>
                        {this.props.prefixNameCapped}:
                    </span>
                    <select
                        value={this.props.value}
                        name={this.props.code}
                        onChange={this.props.onChange}
                        className={this.props.selectClass}
                    >
                        <optgroup label={this.props.prefixNameCapped}>
                            <SelectOptions options={this.props.options} />
                        </optgroup>
                    </select>
                </div>
            </div>
        );
    }
}
