import React from "react";

import { IChoiceField } from "../models/formFields.interfaces";
import { IFormFieldState } from "./AbstractFormField";
import { AbstractSelect } from "./AbstractSelect";

const Option = (props: { label: string; value: string }) => (
    <option value={props.value}>{props.label}</option>
);

export class FormSelect<T extends string> extends AbstractSelect<
    T,
    IChoiceField<T>,
    IFormFieldState
> {
    public static defaultProps = {
        type: "choice",
    };

    public state: IFormFieldState = {
        focused: false,
    };

    /**
     * This exists strictly as an IE Edge bug workaround. Done on 10 Sept 2016
     * See https://github.com/facebook/react/issues/7655 and
     * https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/8794503/
     * It fixes a bug where the Wells Fargo application wasn't taking the State value
     * into its select box, and it was throwing a validation error.
     */
    public componentDidMount() {
        let selectedIndex = -1;
        const input = this.getInputElem();
        if (input) {
            if (
                typeof input !== "undefined" &&
                typeof input.selectedIndex !== "undefined"
            ) {
                selectedIndex = input.selectedIndex;
            }

            if (selectedIndex >= 0) {
                let tempOption: HTMLOptionElement | null = null;
                if (input.childNodes.length === 1) {
                    tempOption = document.createElement("option");
                    input.appendChild(tempOption);
                }

                const options = input.options;
                const tempIndex = (selectedIndex + 1) % options.length;
                options.item(tempIndex)!.selected = true;
                options.item(selectedIndex)!.selected = true;

                if (tempOption) {
                    input.removeChild(tempOption);
                }
            }
        }

        super.componentDidMount();
    }

    protected buildControl() {
        const props = this.getInputProps();

        const options = (this.props.choices || []).map((option) => (
            <Option key={option.value} {...option} />
        ));

        props.id ??= this.props.id;
        return (
            <select
                ref={(ref) => {
                    this.inputElem = ref;
                }}
                {...props}
            >
                {options}
            </select>
        );
    }
}
