import React from "react";
import { connect } from "react-redux";

import { FormCheckbox } from "../../../forms/FormCheckbox";
import { IFormUUID } from "../../../models/nominals";
import { IWriteReviewTemplateField } from "../../../models/reviews.interfaces";
import { TDispatchMapper, TStateMapper } from "../../reducers.interfaces";
import { Dispatchers } from "../dispatchers";
import { getWriteReviewFormState } from "../selectors";

interface IOwnProps {
    formUUID: IFormUUID;
    name: string;
    field: IWriteReviewTemplateField;
}

interface IReduxProps {
    values: string[];
    errors: string[];
    showErrors: boolean;
}

interface IDispatchProps {
    dispatchers: Dispatchers;
}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

interface IState {}

class WriteReviewFormCheckboxFieldComponent extends React.PureComponent<
    IProps,
    IState
> {
    private readonly onChange = (e: React.FormEvent<HTMLInputElement>) => {
        this.props.dispatchers.onReviewFormCheckboxChange({
            formUUID: this.props.formUUID,
            fieldName: this.props.name,
            fieldValue: e.currentTarget.value,
            isChecked: e.currentTarget.checked,
        });
    };

    render() {
        const inputs = this.props.field.options.map((option) => {
            return (
                <FormCheckbox
                    key={option}
                    name={`${this.props.name}-${this.props.formUUID}`}
                    required={this.props.field.is_required}
                    label={option}
                    labelPlacement={"static"}
                    value={option}
                    errors={this.props.errors}
                    showErrorMessages={this.props.showErrors}
                    checked={this.props.values.includes(option)}
                    onChange={this.onChange}
                />
            );
        });
        return (
            <fieldset>
                <legend>{this.props.field.name}</legend>
                {inputs}
            </fieldset>
        );
    }
}

const mapStateToProps: TStateMapper<"reviews", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    const formState = getWriteReviewFormState(rootState.reviews, ownProps);
    return {
        values: formState.valuesMultiSelect[ownProps.name] ?? [],
        errors: formState.errors[ownProps.name] ?? [],
        showErrors: formState.showErrors,
        ...ownProps,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const dispatchers = new Dispatchers(dispatch);
    return {
        dispatchers: dispatchers,
    };
};

export const WriteReviewFormCheckboxField = connect(
    mapStateToProps,
    mapDispatchToProps,
)(WriteReviewFormCheckboxFieldComponent);
