import React from "react";
import { connect } from "react-redux";

import { IRetailStoreWithDistance } from "../../../models/location.interfaces";
import { openStoreLocatorPanel } from "../../../utils/domManipulation";
import { TStateMapper } from "../../reducers.interfaces";
import { myStoreSelector } from "../../retail/selectors";

import styles from "./TryInStoreCallout.module.scss";

interface IOwnProps {
    cssClass?: string;
}

interface IReduxProps {
    store: IRetailStoreWithDistance | null;
}

interface IDispatchProps {}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

interface IState {}

export const TryInStoreCallout = (props: {
    cssClass?: string;
    name: string;
}) => {
    return (
        <p className={props.cssClass ? props.cssClass : styles.root}>
            Try in-store at{" "}
            <button onClick={openStoreLocatorPanel} className={styles.button}>
                {props.name}
            </button>
        </p>
    );
};

class TryInStoreCalloutComponent extends React.Component<IProps, IState> {
    render() {
        if (!this.props.store) {
            return null;
        }
        return (
            <TryInStoreCallout
                cssClass={this.props.cssClass}
                name={this.props.store.name}
            />
        );
    }
}

const mapStateToProps: TStateMapper<
    "configurator" | "retail",
    IReduxProps,
    IOwnProps
> = (rootState, ownProps) => {
    return {
        store: myStoreSelector(rootState),
        // Direct Props
        ...ownProps,
    };
};

export const TryInStoreCalloutContainer = connect(mapStateToProps)(
    TryInStoreCalloutComponent,
);
