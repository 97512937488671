import { clearAllBodyScrollLocks } from "body-scroll-lock";
import classNames from "classnames";
import React from "react";
import SVG from "react-inlinesvg";
import Modal from "react-modal";
import { connect } from "react-redux";

import iconXClose from "../../../../img/icons/x-close.svg";
import { ConfiguratorTypes } from "../../../constants";
import {
    IAddon,
    IConcreteBundle,
    IProduct,
} from "../../../models/catalogue.interfaces";
import { IProductID } from "../../../models/nominals";
import { TDispatchMapper, TStateMapper } from "../../reducers.interfaces";
import { defaults } from "../defaults";
import { Dispatchers } from "../dispatchers";
import { IModularConfiguratorUpsellInfoModal } from "../models.interfaces";
import {
    baseVariantSelector,
    getUpsellInfoModal,
    rootProductSelector,
    upgradeBundlesSelector,
} from "../selectors";
import { UpsellInfoModalContent } from "./ConfiguratorUpsellInfoModalContent";

import styles from "./ConfiguratorUpsellInfoModal.module.scss";

interface IOwnProps {
    configuratorType: ConfiguratorTypes;
    selectedAddons: IAddon[];
    selectedUpgrade: IProductID | null;
}

interface IReduxProps {
    baseVariant: IProduct | null;
    selectedRoot: IProduct | null;
    modal: IModularConfiguratorUpsellInfoModal | null;
    upgradeBundles: IConcreteBundle[];
}

interface IDispatchProps {
    dispatchers: Dispatchers;
}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

interface IState {}

class UpsellInfoModalComponent extends React.PureComponent<IProps, IState> {
    componentWillUnmount() {
        clearAllBodyScrollLocks();
    }

    private readonly onClosePanel = () => {
        document.body.classList.remove("configurator-panel-open");
    };

    private readonly onOpenPanel = () => {
        document.body.classList.add("configurator-panel-open");
    };
    render() {
        const isOpen = this.props.modal !== null;
        const modalClasses = classNames({
            [styles.modalWrapper]:
                this.props.configuratorType !== ConfiguratorTypes.PANEL,
            [styles.panelWrapper]:
                this.props.configuratorType === ConfiguratorTypes.PANEL,
        });
        //
        const overlayClasses = classNames({
            [styles.panelOverlay]:
                this.props.configuratorType === ConfiguratorTypes.PANEL,
        });

        return (
            <Modal
                overlayClassName={overlayClasses}
                className={modalClasses}
                isOpen={isOpen}
                onAfterOpen={this.onOpenPanel}
                onAfterClose={this.onClosePanel}
                onRequestClose={this.props.dispatchers.hideUpsellInfoModal}
                contentLabel={this.props.modal?.bundle_description}
            >
                <button
                    aria-label="Close"
                    className="close-btn"
                    onClick={this.props.dispatchers.hideUpsellInfoModal}
                >
                    <SVG
                        aria-hidden="true"
                        className="close-icon"
                        src={iconXClose}
                    />
                </button>
                {this.props.modal && (
                    <UpsellInfoModalContent
                        baseVariant={this.props.baseVariant}
                        selectedRoot={this.props.selectedRoot}
                        modal={this.props.modal}
                        selectedAddons={this.props.selectedAddons}
                        selectedUpgrade={this.props.selectedUpgrade}
                        updateSelectedAddons={
                            this.props.dispatchers.updateSelectedAddons
                        }
                        setSelectedUpgrade={
                            this.props.dispatchers.setSelectedUpgrade
                        }
                        upgradeBundles={this.props.upgradeBundles}
                    />
                )}
            </Modal>
        );
    }
}

const mapStateToProps: TStateMapper<"configurator", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    const state = rootState.configurator || defaults;
    const baseVariant = baseVariantSelector(state);
    const selectedRoot = rootProductSelector(state);
    const modal = getUpsellInfoModal(state, {
        bundleID: state.ui.upsellInfoModalActiveBundle,
    });
    return {
        baseVariant: baseVariant,
        selectedRoot: selectedRoot,
        concreteBundles: state.entities.concreteBundles,
        modal: modal,
        upgradeBundles: upgradeBundlesSelector(
            state.entities.concreteBundles,
            baseVariant,
            modal ? modal.bundle_type : "",
        ),
        ...ownProps,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const dispatchers = new Dispatchers(dispatch);
    return {
        dispatchers: dispatchers,
    };
};

export const UpsellInfoModal = connect(
    mapStateToProps,
    mapDispatchToProps,
)(UpsellInfoModalComponent);
