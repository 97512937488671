import { CSRF_HEADER, Response, ajax, getCSRFToken } from "../utils/ajax";

/**
 * Attempt to grab the state of the user data layer.
 */
export const snapUserDataLayerState = async (
    orderID: string,
): Promise<Response> => {
    const dataLayer = window.dataLayer || [];
    const data = {
        order_id: orderID,
        data_layer: dataLayer,
    };
    return ajax
        .post("/api/user-data-layer/")
        .set("Accept", "application/json")
        .set("Content-Type", "application/json")
        .set(CSRF_HEADER, await getCSRFToken())
        .send(data)
        .then((resp) => {
            return resp;
        });
};
