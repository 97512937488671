import React from "react";
import { t } from "ttag";

import { interpolate } from "@thelabnyc/thelabui/src/utils/i18n";

import { Link } from "../../../common/Link";
import { IProduct } from "../../../models/catalogue.interfaces";
import { ICompareTile } from "../../../models/product-compare.interfaces";
import { getShopNowURL } from "../selectors";

interface IProps {
    tile: ICompareTile;
    selectedVariant: IProduct | null;
}

export const ShopNowLink = (props: IProps) => {
    if (!props.selectedVariant) {
        return null;
    }
    const url = getShopNowURL(props.tile, props.selectedVariant);
    return (
        <Link href={url} className="button al-comparisongrid-shopnow">
            {t`Shop Now`}
            <span className="ada-screenreader-only">
                {interpolate(t`for %(productName)s`, {
                    productName: props.selectedVariant?.title || "",
                })}
            </span>
        </Link>
    );
};
