import classNames from "classnames";
import React from "react";
import { connect } from "react-redux";
import { t } from "ttag";

import { getProductURL } from "../../../api/products";
import { ErrorModal } from "../../../common/ErrorModal";
import { config } from "../../../config";
import { AddToCartType, ConfiguratorTypes } from "../../../constants";
import {
    IAddon,
    IBasket,
    IBasketLine,
    IConcreteBundle,
    IProduct,
} from "../../../models/catalogue.interfaces";
import { IWebPageURL } from "../../../models/nominals";
import { trackAddToBasketEvent } from "../../../utils/analytics";
import { urls } from "../../../utils/urls";
import { TDispatchMapper, TStateMapper } from "../../reducers.interfaces";
import { addProductToBasket } from "../actions";
import { defaults } from "../defaults";
import { Dispatchers } from "../dispatchers";
import { Loaders } from "../loaders";
import {
    IUpsellModalComponent,
    IUpsellModalComponentClass,
} from "../models.interfaces";
import {
    addonVariantsSelector,
    rootProductSelector,
    upgradedVariantSelector,
} from "../selectors";
import { isPreorderProduct } from "../utils";

interface IOwnProps {
    // Links
    basketLink: IWebPageURL;
    configureGiftsLink: IWebPageURL;
    // Misc options
    configuratorType: ConfiguratorTypes;
    // is quantitySelector enabled
    enableQuantitySelector?: boolean;
    buttonColor: string;
    // Optional: Up Sell Modal functionality
    showUpsellModal?: (product: IProduct | null) => boolean;
    getUpsellModalComponentClass?: (
        product: IProduct,
    ) => IUpsellModalComponentClass | null;
}

interface IReduxProps {
    concreteBundles: IConcreteBundle[];
    rootProduct: IProduct | null;
    upgradedVariant: IProduct | null;
    basket: IBasket | null;
    // Option selector state
    quantity: number;
    // Product Add-Ons
    selectedAddonVariants: IAddon[];
    // Add to basket button
    addToBasketCooldownActive: boolean;
    addToBasketButtonText: string;
    // Add to basket error modal
    addToBasketErrorOpen: boolean;
    addToBasketErrorReason: string;
}

interface IDispatchProps {
    loaders: Loaders;
    dispatchers: Dispatchers;
}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

interface IState {}

class AddToBasketButtonComponent extends React.Component<IProps, IState> {
    upsellModal: IUpsellModalComponent | undefined;

    private readonly onCloseErrorModal = () => {
        this.props.dispatchers.setAddToBasketErrorModalState(false, "");
    };

    private readonly onBeforeAddToBasket = () => {
        // Disable buttons
        this.props.dispatchers.setAddToBasketBtnState(true, t`Adding`);
    };

    private readonly onAfterAddToBasket = () => {
        // Re-enable buttons
        this.props.dispatchers.setAddToBasketBtnState(false, t`Added`);

        // Send an `add_to_cart` event to the data layer
        const basketHasProduct = this.props.basket?.lines.some(
            (line: IBasketLine) =>
                line.product.id === this.props.upgradedVariant?.id,
        );
        trackAddToBasketEvent(
            this.props.upgradedVariant,
            this.props.quantity,
            basketHasProduct
                ? AddToCartType.INCREASE_QUANTITY
                : AddToCartType.ADDED,
        );

        // Show the upsell modal?
        if (
            this.props.selectedAddonVariants.length === 0 &&
            this.upsellModal &&
            this.props.concreteBundles.length > 0 &&
            this.props.showUpsellModal &&
            this.props.showUpsellModal(this.props.upgradedVariant)
        ) {
            this.upsellModal.openModal();
            return;
        }

        // Redirect customer to the basket page
        this.redirectToNextPage();
    };

    private readonly onAddToBasketError = (reason: string) => {
        // Re-enable buttons
        const isPreorder =
            this.props.upgradedVariant &&
            isPreorderProduct(this.props.upgradedVariant);
        const addToBasketButtonText = isPreorder
            ? t`Pre-Order`
            : t`Add to Cart`;
        this.props.dispatchers.setAddToBasketBtnState(
            false,
            addToBasketButtonText,
        );
        this.props.dispatchers.setAddToBasketErrorModalState(true, reason);
    };

    private readonly onAddToBasket = async () => {
        this.onBeforeAddToBasket();
        try {
            if (this.props.upgradedVariant) {
                await addProductToBasket(
                    this.props.upgradedVariant.url,
                    this.props.quantity,
                );
            }
            for (const addon of this.props.selectedAddonVariants) {
                await addProductToBasket(
                    getProductURL(addon.productID),
                    addon.quantity,
                );
            }
            this.onAfterAddToBasket();
        } catch (e) {
            // Display the error to the user
            let reason = t`An unexpected error occurred. Please try again.`;
            try {
                reason = e.response.body.reason;
            } catch (e2) {
                console.error(e2);
            }
            this.onAddToBasketError(reason);
        }
    };

    private readonly onFindRetailer = () => {
        urls.navigateTo("find-a-retailer");
    };

    private readonly onUpsellProceed = () => {
        this.redirectToNextPage();
    };

    private async redirectToNextPage() {
        // Check if the user has any gifts to configure
        const bundles = await this.props.loaders.loadUserConfigurableBundles();
        console.log(
            `[Configurator] Found ${bundles.length} user-configurable bundles needing configuration`,
        );

        // Check if there is at least one available gift.
        const isAtLeastOneAvailable = bundles.map((bundle) => {
            const availability = bundle.suggested_range_products.map((p) => {
                if (p.children.length <= 0) {
                    return p.availability.is_available_to_buy;
                }
                const variantAvailability = p.children.map((v) => {
                    return v.availability.is_available_to_buy;
                });
                return variantAvailability.some((i) => {
                    return i;
                });
            });
            return availability.some((i) => {
                return i;
            });
        });

        const nextURL =
            bundles.length <= 0
                ? this.props.basketLink
                : isAtLeastOneAvailable
                  ? this.props.configureGiftsLink
                  : this.props.basketLink;
        setTimeout(() => {
            urls.navigateToURL(nextURL);
        }, 0);
    }

    private buildUpsellModal() {
        if (
            !this.props.getUpsellModalComponentClass ||
            !this.props.upgradedVariant
        ) {
            return null;
        }
        const UpsellModal = this.props.getUpsellModalComponentClass(
            this.props.upgradedVariant,
        );
        if (!UpsellModal) {
            return null;
        }
        return (
            <UpsellModal
                ref={(ref: IUpsellModalComponent) => {
                    this.upsellModal = ref;
                }}
                bundles={this.props.concreteBundles}
                onProceed={this.onUpsellProceed}
            />
        );
    }

    render() {
        const isPreorder =
            this.props.upgradedVariant &&
            isPreorderProduct(this.props.upgradedVariant);
        const inStoreOnly =
            !config.get("ENABLE_ECOM") ||
            (this.props.rootProduct &&
                this.props.rootProduct.attributes.in_store_only &&
                this.props.rootProduct.attributes.in_store_only.value);
        const variantIsAvailableToBuy = !!(
            this.props.rootProduct &&
            this.props.upgradedVariant &&
            this.props.upgradedVariant.availability.is_available_to_buy
        );
        // Build button classnames
        const btnClasses = classNames({
            "configurator__add-button": true,
            [`configurator__add-button--${this.props.configuratorType}`]: true,
            [`configurator__add-button--${this.props.configuratorType}--with-qty`]:
                this.props.enableQuantitySelector,
            "button": true,
            "button--secondary": isPreorder,
            [`button--${this.props.buttonColor}`]: !isPreorder,
            "al-configurator__add-to-cart": true,
        });
        const btnText = inStoreOnly
            ? t`Find a Retailer`
            : this.props.addToBasketButtonText;
        return (
            <>
                <button
                    className={btnClasses}
                    disabled={
                        this.props.addToBasketCooldownActive ||
                        !variantIsAvailableToBuy
                    }
                    onClick={() => {
                        if (inStoreOnly) {
                            this.onFindRetailer();
                        } else {
                            this.onAddToBasket();
                        }
                    }}
                >
                    {btnText}
                </button>
                {this.buildUpsellModal()}
                <ErrorModal
                    name="configurator"
                    isOpen={this.props.addToBasketErrorOpen}
                    onRequestClose={this.onCloseErrorModal}
                >
                    <p>
                        {t`Product could not be added to cart (${this.props.addToBasketErrorReason}).`}
                    </p>
                    <p>{t`Please adjust your selection and try again.`}</p>
                </ErrorModal>
            </>
        );
    }
}

const mapStateToProps: TStateMapper<
    "configurator" | "checkout",
    IReduxProps,
    IOwnProps
> = (rootState, ownProps) => {
    const state = rootState.configurator || defaults;
    return {
        concreteBundles: state.entities.concreteBundles,
        rootProduct: rootProductSelector(state),
        upgradedVariant: upgradedVariantSelector(state),
        quantity: state.ui.quantity,
        selectedAddonVariants: addonVariantsSelector(state),
        addToBasketCooldownActive: state.ui.addToBasketCooldownActive,
        addToBasketButtonText: state.ui.addToBasketButtonText,
        addToBasketErrorOpen: state.ui.addToBasketErrorOpen,
        addToBasketErrorReason: state.ui.addToBasketErrorReason,
        basket: rootState.checkout.data.basket,
        // Direct Props
        ...ownProps,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const dispatchers = new Dispatchers(dispatch);
    const loaders = new Loaders(dispatchers);
    return {
        dispatchers: dispatchers,
        loaders: loaders,
    };
};

export const AddToBasketButton = connect(
    mapStateToProps,
    mapDispatchToProps,
)(AddToBasketButtonComponent);
