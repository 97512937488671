import classNames from "classnames";
import React from "react";
import SVG from "react-inlinesvg";
import { connect } from "react-redux";

import iconUpsellModalSelectIcon from "../../img/icons/upsell-modal-select-icon.svg";
import iconUpsellModalSelectedIcon from "../../img/icons/upsell-modal-selected-icon.svg";
import { defaults } from "../apps/configurator/defaults";
import { productAttributeOptionGroupSelector } from "../apps/configurator/selectors";
import { TStateMapper } from "../apps/reducers.interfaces";
import {
    // getProductVariantsForOptionCodes,
    buildRelevantLevelVariants,
    findLevelVariants,
} from "../apps/selectors";
import { ConfiguratorTypes } from "../constants";
import { InternalProduct } from "../models/catalogue";
import {
    IOptionCode,
    IOptionSingleValue,
    IOptionValues,
    IProduct,
    IProductAttributeOptionGroup,
} from "../models/catalogue.interfaces";
import { IProductID } from "../models/nominals";
import { PriceType, getProductPrice } from "../utils/catalogue";
import format from "../utils/format";
import { getPageSetting } from "../utils/settings";
import { Image } from "./Image";

import styles from "./LevelSelectionPanel.module.scss";

interface IOwnProps {
    configuratorType?: ConfiguratorTypes;
    selectorID: string;
    rootProduct: IProduct;
    variant: IProduct | null;
    code: IOptionCode;
    value: IOptionSingleValue;
    options: IOptionSingleValue[];
    prefixNameCapped: string;
    configuratorClass: string;
    prefixClass: string;
    onChange: (event: React.FormEvent<HTMLInputElement>) => void;
    onKeyboardDown: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    onClick: () => void;
    isIcon?: boolean;
    showPricing?: boolean;
    isPlpPanelStyle?: boolean;
}

interface IReduxProps {
    optionValues: IOptionValues;
    productAttributeOptionGroup: IProductAttributeOptionGroup | null;
}

interface IProps extends IOwnProps, IReduxProps {}

export interface ILevelColumn {
    level: string;
    variant: InternalProduct | null;
}

interface ICoreFeature {
    icon: string | null;
    copy: string | null;
}

interface IVariantLevelCoreFeatures {
    variant: IProductID;
    core_features_list: ICoreFeature[];
}

interface IState {
    columns: ILevelColumn[];
}

const CoreFeaturesList = (props: {
    core_features: ICoreFeature[];
    isOneColumn: boolean;
}) => {
    const containerClasses = classNames({
        [styles.coreFeatures]: true,
        [styles.oneColumnCoreFeatures]: props.isOneColumn,
    });
    return (
        <div className={containerClasses}>
            <h3 className={styles.featuresHeading}>Core Features</h3>
            <ul className={styles.featuresList}>
                {props.core_features.map((feature, idx) => (
                    <li key={idx} className={styles.featuresItem}>
                        {feature.icon && (
                            <Image
                                className={styles.featuresIcon}
                                src={feature.icon}
                            />
                        )}
                        <span>{feature.copy}</span>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export class LevelSelectionPanelComponent extends React.Component<
    IProps,
    IState
> {
    public state: IState = {
        columns: [{ level: "", variant: null }],
    };

    private buildImage(product: InternalProduct) {
        // use variant images if they exist
        if (this.props.rootProduct.images.length > 0) {
            return this.props.rootProduct.images[0].original;
        }
        // else use the variant image
        return product.images[0].original;
    }

    private buildPrice(product: InternalProduct, isOneColumn: boolean) {
        const firstColumnVariant = this.state.columns[0].variant;
        if (firstColumnVariant) {
            const firstColumnProductPrice = getProductPrice(
                firstColumnVariant.price,
                {
                    priceType: PriceType.COSMETIC_EXCL_TAX,
                    includePostDiscountAddons: true,
                    quantity: 1,
                },
            );
            const columnProductPrice = getProductPrice(product.price, {
                priceType: PriceType.COSMETIC_EXCL_TAX,
                includePostDiscountAddons: true,
                quantity: 1,
            });

            if (isOneColumn) {
                return format.money(firstColumnProductPrice);
            }

            if (columnProductPrice.greaterThan(firstColumnProductPrice)) {
                return `+ ${format.money(
                    columnProductPrice.subtract(firstColumnProductPrice),
                )}`;
            }
            return null;
        }
        return null;
    }

    private buildUI(level: string, idx: number, isOneColumn: boolean) {
        const optionNameSuffix = `${this.props.rootProduct.id}-${this.props.selectorID}-${this.props.code}-${idx}`;
        const optionName = `configurator__radio-option--${optionNameSuffix}`;
        const optionsGroupName = `${this.props.code}-${this.props.selectorID}`;
        const optionSelected = level === this.props.value;

        const actionText = idx === 0 ? "Select" : "Upgrade";
        const textLabel = optionSelected ? "Selected" : actionText;
        const icon = optionSelected ? (
            <SVG
                className={styles.selectedIcon}
                src={iconUpsellModalSelectedIcon}
            />
        ) : (
            <SVG
                className={styles.selectIcon}
                src={iconUpsellModalSelectIcon}
            />
        );
        const buttonClasses = classNames({
            [styles.selectButton]: true,
            [styles.selectedButton]: optionSelected,
            [styles.oneColumnButton]: isOneColumn,
            "al-level-selection-button": true,
        });

        return (
            <label className={buttonClasses}>
                <input
                    type="radio"
                    id={optionName}
                    name={optionsGroupName}
                    onChange={this.props.onChange}
                    onClick={() => {}}
                    onKeyDown={this.props.onKeyboardDown}
                    value={level}
                    checked={optionSelected}
                />
                <div>
                    {icon}
                    {textLabel}
                </div>
            </label>
        );
    }

    private buildLevelColumns(columns: ILevelColumn[]) {
        const levelMarketingCopy = getPageSetting("level-marketing-copy");
        const variantLevelCoreFeaturesData = getPageSetting(
            "variant-level-core-features",
        );

        return columns.map((column, idx) => {
            const thisLevelMarketingCopy = levelMarketingCopy[column.level];
            const thisLevelCoreFeatures = variantLevelCoreFeaturesData.filter(
                (data: IVariantLevelCoreFeatures) => {
                    return data.variant === column.variant?.id;
                },
            );

            const columnClasses = classNames({
                [styles.column]: true,
                [styles.oneColumn]: columns.length === 1,
            });

            const mainCopyClasses = classNames({
                [styles.mainCopy]: true,
                [styles.oneColumnMainCopy]: columns.length === 1,
            });

            const imageClasses = classNames({
                [styles.image]: true,
                [styles.oneColumnImage]: columns.length === 1,
            });

            const descriptionCopyClasses = classNames({
                [styles.descriptionCopy]: true,
                [styles.oneColumnDescriptionCopy]: columns.length === 1,
            });

            const productNameClasses = classNames({
                [styles.productName]: true,
                [styles.oneColumnProductName]: columns.length === 1,
            });

            const priceClasses = classNames({
                [styles.price]: true,
                [styles.oneColumnPrice]: columns.length === 1,
            });

            return (
                <li key={column.level} className={columnClasses}>
                    {thisLevelMarketingCopy && (
                        <span className={mainCopyClasses}>
                            {thisLevelMarketingCopy.main_copy}
                        </span>
                    )}
                    {column.variant && (
                        <Image
                            className={imageClasses}
                            src={this.buildImage(column.variant)}
                        />
                    )}
                    {thisLevelMarketingCopy && (
                        <span className={descriptionCopyClasses}>
                            {thisLevelMarketingCopy.description_copy}
                        </span>
                    )}
                    <h2 className={productNameClasses}>
                        {column.variant?.title}
                    </h2>
                    <span className={priceClasses}>
                        {column.variant &&
                            this.buildPrice(
                                column.variant,
                                columns.length === 1,
                            )}
                    </span>
                    {this.buildUI(column.level, idx, columns.length === 1)}

                    {thisLevelCoreFeatures.length > 0 && (
                        <CoreFeaturesList
                            core_features={
                                thisLevelCoreFeatures[0].core_features_list
                            }
                            isOneColumn={columns.length === 1}
                        />
                    )}
                </li>
            );
        });
    }

    componentDidMount() {
        const levelVariants = buildRelevantLevelVariants(
            this.props.options,
            this.props.optionValues,
            this.props.rootProduct,
            this.props.code,
        );
        // We can only show 2 variants, so if there are 3,
        const levelColumns =
            levelVariants.length > 2
                ? findLevelVariants(levelVariants, this.props.optionValues)
                : levelVariants;

        this.setState({
            columns: levelColumns,
        });
    }

    render() {
        const containerClasses = classNames({
            [styles.grid]: true,
            [styles.oneColumnGrid]: this.state.columns.length === 1,
            "al-level-selection-panel": true,
        });
        return (
            <ul className={containerClasses}>
                {this.buildLevelColumns(this.state.columns)}
            </ul>
        );
    }
}

const mapStateToProps: TStateMapper<"configurator", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    const state = rootState.configurator || defaults;
    return {
        productAttributeOptionGroup: productAttributeOptionGroupSelector(
            state,
            ownProps.code,
        ),
        optionValues: state.ui.optionValues,
        ...ownProps,
    };
};

export const LevelSelectionPanel = connect(mapStateToProps)(
    LevelSelectionPanelComponent,
);
