import classNames from "classnames";
import React from "react";
import { connect } from "react-redux";

import { ISearchFacet } from "../../../models/reviews.interfaces";
import { TStateMapper } from "../../reducers.interfaces";
import { defaultState } from "../defaults";
import { Filter, IProps as IFilterProps } from "./Filter";

import styles from "./TopFilterSection.module.scss";

interface IOwnProps extends IFilterProps {
    isMobile: boolean;
}

interface IReduxProps {
    facets: ISearchFacet[];
}

interface IProps extends IOwnProps, IReduxProps {}

const TopFilterSectionComponent = (props: IProps) => {
    const filterClasses = classNames({
        [styles.topFilters]: true,
    });
    return (
        <div className={filterClasses}>
            {props.facets.map((facet) => (
                <Filter {...props} key={facet.type} facet={facet} />
            ))}
        </div>
    );
};

const mapStateToProps: TStateMapper<"reviews", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    const state = rootState.reviews || defaultState;
    return {
        facets: state.data.facets,
        ...ownProps,
    };
};

export const TopFilterSection = connect(mapStateToProps)(
    TopFilterSectionComponent,
);
