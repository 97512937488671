import React from "react";

import { BannerBlock as Value } from "@reactivated";

import { RichTextBlock } from "@thelabnyc/thelabui/src/components/RichText";
import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";
import { IntrinsicElementProps } from "@thelabnyc/thelabui/src/utils/types";

import { getSpacingClasses } from "../spacing";

import styles from "./index.module.scss";

export interface Props {
    value: Value;
    attrs?: IntrinsicElementProps<HTMLElement>;
}

export const BannerBlock = (props: Props) => {
    if (!props.value.content) {
        return null;
    }
    return (
        <section
            className={concatClassNames([
                styles.root,
                props.value.background_style?.style ?? "",
                props.value.responsive_visibility?.visibility ?? "",
                props.value.spacing
                    ? getSpacingClasses(props.value.spacing)
                    : "",
            ])}
        >
            <div className="l-max-width">
                <RichTextBlock
                    className={styles.content}
                    value={props.value.content}
                />
            </div>
        </section>
    );
};
