import React from "react";
import { connect } from "react-redux";
import { t } from "ttag";

import { FormRadioSelect } from "../../../forms/FormRadioSelect";
import { FormSelect } from "../../../forms/FormSelect";
import {
    IFormUUID,
    IReviewsProductID,
    IReviewsProductTypeID,
    isoReviewsProductID,
    isoReviewsProductTypeID,
} from "../../../models/nominals";
import {
    IReviewsProduct,
    IReviewsProductVariantChoices,
} from "../../../models/reviews.interfaces";
import { TDispatchMapper, TStateMapper } from "../../reducers.interfaces";
import { NOT_SURE_VARIANT_CHOICE_SLUG } from "../constants";
import { Dispatchers } from "../dispatchers";
import { IWriteReviewFormState } from "../reducers.interfaces";
import {
    getWriteReviewFormProducts,
    getWriteReviewFormSelectedProductID,
    getWriteReviewFormSelectedProductTypeID,
    getWriteReviewFormSelectedVariantSlug,
    getWriteReviewFormState,
    getWriteReviewFormVariantChoices,
} from "../selectors";

interface IOwnProps {
    formUUID: IFormUUID;
    displayedProductTypeIDWhitelist?: IReviewsProductTypeID[];
}

interface IReduxProps {
    products: IReviewsProduct[];
    formState: IWriteReviewFormState;
    variantChoices: IReviewsProductVariantChoices[];
    selectedProductTypeID: IReviewsProductTypeID | null;
    selectedProductID: IReviewsProductID | null;
    selectedVariantSlug: string;
}

interface IDispatchProps {
    dispatchers: Dispatchers;
}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

interface IState {}

class WriteReviewFormProductSelectComponent extends React.PureComponent<
    IProps,
    IState
> {
    private readonly onTypeChange = (e: React.FormEvent<HTMLInputElement>) => {
        if (!e.currentTarget.checked) {
            return;
        }
        e.preventDefault();
        const productTypeID = isoReviewsProductTypeID.wrap(
            parseInt(e.currentTarget.value.split(":")[2], 10),
        );
        this.props.dispatchers.setReviewFormSelectedProductType(
            this.props.formUUID,
            this.props.products,
            productTypeID,
        );
    };

    private readonly onProductChange = (
        e: React.FormEvent<HTMLSelectElement>,
    ) => {
        const productID = isoReviewsProductID.wrap(
            parseInt(e.currentTarget.value.split(":")[1], 10),
        );
        this.props.dispatchers.setReviewFormSelectedProduct(
            this.props.formUUID,
            productID,
        );
    };

    private readonly onVariantChange = (
        e: React.FormEvent<HTMLSelectElement>,
    ) => {
        this.props.dispatchers.onReviewFormVariantChange({
            formUUID: this.props.formUUID,
            selectedVariant: e.currentTarget.value,
        });
    };

    private getProductTypeChoices() {
        if (!this.props.products) {
            return [];
        }
        const productTypeIDs: { [i: string]: IReviewsProductTypeID } = {};
        const productTypeSortOrders: { [i: string]: number } = {};
        const productTypeNames: string[] = [];
        this.props.products.forEach((product) => {
            if (product.product_type && product.product_type_name) {
                productTypeIDs[product.product_type_name] =
                    product.product_type;
                productTypeSortOrders[product.product_type_name] =
                    product.product_type_sort_order;
                if (
                    productTypeNames.indexOf(product.product_type_name) === -1
                ) {
                    productTypeNames.push(product.product_type_name);
                }
            }
        }, []);
        productTypeNames.sort((a, b) => {
            const sortA = productTypeSortOrders[a];
            const sortB = productTypeSortOrders[b];
            if (sortA === -1) {
                return 1;
            }
            if (sortB === -1) {
                return -1;
            }
            if (sortA === sortB) {
                return 0;
            }
            return sortA > sortB ? 1 : -1;
        });
        const productChoices = productTypeNames.map((typeName) => {
            return {
                label: typeName,
                value: `${this.props.formUUID}:product-type:${productTypeIDs[typeName]}`,
            };
        });
        return productChoices;
    }

    private getProductChoices() {
        if (
            !this.props.selectedProductTypeID ||
            this.props.products.length <= 0
        ) {
            return [];
        }
        const productChoices = this.props.products
            .filter((product) => {
                return (
                    product.product_type === this.props.selectedProductTypeID
                );
            })
            .map((product) => {
                return {
                    label: product.name,
                    value: `product:${product.id}`,
                };
            });
        return productChoices;
    }

    private getVariantChoices() {
        if (
            !this.props.selectedProductTypeID ||
            this.props.products.length <= 0 ||
            this.props.variantChoices.length <= 0
        ) {
            return [];
        }
        const variantChoices = this.props.variantChoices.map((variant) => {
            if (variant === NOT_SURE_VARIANT_CHOICE_SLUG) {
                return {
                    label: t`I'm not sure`,
                    value: NOT_SURE_VARIANT_CHOICE_SLUG,
                };
            } else if (variant) {
                return {
                    label: variant.display_name,
                    value: variant.slug,
                };
            } else {
                return {
                    label: t`--- select model ---`,
                    value: "",
                };
            }
        });
        return variantChoices;
    }

    render() {
        if (!this.props.products) {
            return null;
        }
        const productTypeChoices = this.getProductTypeChoices();
        const productChoices = this.getProductChoices();
        const variantChoices = this.getVariantChoices();
        let productTypeSelect: JSX.Element | null = null;
        let productSelect: JSX.Element | null = null;
        let variantSelect: JSX.Element | null = null;
        let productSelectError: JSX.Element | null = null;
        let variantSelectError: JSX.Element | null = null;
        if (productTypeChoices.length > 1) {
            productTypeSelect = (
                <FormRadioSelect
                    name={`${this.props.formUUID}__product-type-id`}
                    required={true}
                    label={t`Which type of product?`}
                    labelPlacement="static"
                    choices={productTypeChoices}
                    onChange={this.onTypeChange}
                    value={`${this.props.formUUID}:product-type:${this.props.selectedProductTypeID}`}
                />
            );
            if (this.props.formState.showProductSelectError) {
                productSelectError = (
                    <div className="wrf-product-select-form__error">
                        {t`Please select a product type`}
                    </div>
                );
            }
        }
        if (this.props.selectedProductID) {
            productSelect = (
                <FormSelect
                    name={`${this.props.formUUID}__product-id`}
                    id={`${this.props.formUUID}__product-id`}
                    required={true}
                    label={t`Which product do you own?`}
                    labelPlacement="static"
                    choices={productChoices}
                    onChange={this.onProductChange}
                    value={`product:${this.props.selectedProductID}`}
                />
            );
        }
        if (variantChoices.length > 1) {
            // variantChoices always has one "not sure" choice
            variantSelect = (
                <FormSelect
                    name={`${this.props.formUUID}__variant`}
                    id={`${this.props.formUUID}__variant`}
                    required={true}
                    label={t`Which model do you own?`}
                    labelPlacement="static"
                    choices={variantChoices}
                    onChange={this.onVariantChange}
                    value={this.props.selectedVariantSlug}
                />
            );
            if (this.props.formState.showVariantSelectError) {
                variantSelectError = (
                    <div className="wrf-product-select-form__error">
                        {t`Please select a model`}
                    </div>
                );
            }
        }
        return (
            <div className="wrf-product-select-form">
                {productTypeSelect}
                {productSelectError}
                {productSelect}
                {variantSelect}
                {variantSelectError}
            </div>
        );
    }
}

const mapStateToProps: TStateMapper<"reviews", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    const state = rootState.reviews;
    return {
        ...ownProps,
        products: getWriteReviewFormProducts(state, ownProps),
        formState: getWriteReviewFormState(state, ownProps),
        variantChoices: getWriteReviewFormVariantChoices(state, ownProps),
        selectedProductTypeID: getWriteReviewFormSelectedProductTypeID(
            state,
            ownProps,
        ),
        selectedProductID: getWriteReviewFormSelectedProductID(state, ownProps),
        selectedVariantSlug: getWriteReviewFormSelectedVariantSlug(
            state,
            ownProps,
        ),
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const dispatchers = new Dispatchers(dispatch);
    return {
        dispatchers: dispatchers,
    };
};

export const WriteReviewFormProductSelect = connect(
    mapStateToProps,
    mapDispatchToProps,
)(WriteReviewFormProductSelectComponent);
