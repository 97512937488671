import cookies from "js-cookie";
import { v4 as uuidv4 } from "uuid";

import { constants } from "@reactivated/constants";

import config from "../config";
import {
    Dimension,
    Metric,
    MetricsPostData,
} from "../models/metrics.interfaces";
import { ajax } from "../utils/ajax";
import { getUA } from "../utils/detectMobile";
import { IS_PUPPETEER } from "../utils/environment";
import { notEmpty } from "../utils/functional";

const PAGE_LOAD_UUID = uuidv4();

let metricsQueue: Metric[] = [];
let sendTimer: number | null = null;

const getMetricsSessionID = (): string => {
    const cookieName = "tsi-metrics-session-id";
    let sid: string;
    try {
        sid = cookies.get(cookieName) || "";
        if (!sid) {
            sid = uuidv4();
            // Update cookie value
            cookies.set(cookieName, sid, { expires: 365 });
        }
    } catch (e) {
        console.log(e);
        sid = uuidv4();
    }
    return sid;
};

const getMetricsAPIURL = (path: string): string | null => {
    const apiBase = config.get("METRICS_API");
    if (!apiBase) {
        return null;
    }
    const url = new URL(path, apiBase);
    return url.toString();
};

const getCommonDimensions = (): Dimension[] => {
    const agent = getUA();
    const dims: Array<[string, string | number | null | undefined]> = [
        ["session-id", getMetricsSessionID()],
        ["page-id", PAGE_LOAD_UUID],
        ["browser-name", agent.browser.name],
        ["browser-version", agent.browser.major],
        ["os-name", agent.os.name],
        ["os-version", agent.os.version],
        ["screen-width", window.screen.width],
        ["screen-height", window.screen.height],
        ["viewport-width", window.innerWidth],
        ["viewport-height", window.innerHeight],
        ["url", `${window.location.origin}${window.location.pathname}`],
        [
            "page-type",
            document.body.dataset.trackPageType ||
                document.body.dataset.viewName,
        ],
    ];
    return dims
        .filter((dim): dim is [string, string | number] => {
            return notEmpty(dim[1]) && dim[1] !== "";
        })
        .map(([key, val]) => {
            return {
                name: key,
                value: `${val}`,
            };
        });
};

const recordMetrics_Beacon = (
    apiURL: string,
    data: MetricsPostData,
): boolean => {
    if (!navigator.sendBeacon) {
        return false;
    }
    // Even though this is JSON data, we set the Content-Type as text/plain
    // because this allows the browser to send the beacon request without first
    // doing a CORS preflight request.
    // See https://calendar.perfplanet.com/2020/beaconing-in-practice/
    const blob = new Blob([JSON.stringify(data)], {
        type: "text/plain",
    });
    const success = navigator.sendBeacon(apiURL, blob);
    return success;
};

const recordMetrics_XHR = async (apiURL: string, data: MetricsPostData) => {
    await ajax.post(apiURL).set("Accept", "application/json").send(data);
};

export const recordMetrics = async (
    commonAttrs: Partial<Metric>,
    metrics: Metric[],
) => {
    // Just abort when running tests
    if (IS_PUPPETEER) {
        return;
    }
    if (metrics.length <= 0) {
        return;
    }
    if (!commonAttrs.dimensions) {
        commonAttrs.dimensions = [];
    }
    commonAttrs.dimensions = commonAttrs.dimensions.concat(
        getCommonDimensions(),
    );
    const version = constants.CI_COMMIT_SHA || "dev";
    const environment = constants.CI_COMMIT_REF_SLUG || "dev";
    const data: MetricsPostData = {
        source: {
            system: "ecom",
            slug: document.body.dataset.appslug || "tsiecom",
            version: version,
            environment: environment,
        },
        common_attributes: commonAttrs,
        metrics: metrics,
    };
    const apiURL = getMetricsAPIURL("/api/metrics/");
    if (!apiURL) {
        return;
    }
    // First, try to send the metrics using the sendBeacon API. If that doesn't
    // work, fallback to a normal XHR request.
    const beaconSuccess = recordMetrics_Beacon(apiURL, data);
    if (beaconSuccess) {
        await recordMetrics_XHR(apiURL, data);
    }
};

const sendMetricsBatch = () => {
    recordMetrics({}, metricsQueue);
    metricsQueue = [];
};

export const eventuallySendMetric = (metric: Metric) => {
    metricsQueue.push(metric);
    // Log a batch of metrics after a 1000ms debounce
    if (sendTimer) {
        window.clearTimeout(sendTimer);
    }
    sendTimer = window.setTimeout(sendMetricsBatch, 1000);
};

/**
 * eventuallySendMetric sets a timer to send metrics batches at-most once per
 * second. This handler is triggered on page-unload and makes sure the last
 * metrics batch is sent when page navigation occurs.
 *
 * See: https://developer.mozilla.org/en-US/docs/Web/API/Navigator/sendBeacon#sending_analytics_at_the_end_of_a_session
 */
document.addEventListener("visibilitychange", () => {
    if (document.visibilityState === "hidden") {
        sendMetricsBatch();
    }
});
