import classNames from "classnames";
import React from "react";

import { IFormSubmitButton } from "../models/formFields.interfaces";
import { AbstractFormComponent } from "./AbstractFormComponent";

export class FormSubmit<
    FieldName extends string = string,
> extends AbstractFormComponent<
    IFormSubmitButton<FieldName>,
    Record<string, never>
> {
    public static defaultProps = {
        hidden: false,
        type: "submit",
        value: "Submit",
        className: "button button--primary-congress-blue",
    };

    protected getInputProps() {
        return {
            id: this.props.id,
            ref: this.props.inputRef,
            name: this.props.name,
            type: this.props.type,
            className: this.props.className,
            value: this.props.value === null ? "" : this.props.value,
            disabled: this.props.disabled,
            onMouseEnter: this.props.onMouseEnter,
            onMouseLeave: this.props.onMouseLeave,
            onSubmit: this.props.onSubmit,
        };
    }

    protected buildContent() {
        const props = this.getInputProps();
        return <input {...props} />;
    }

    public render() {
        const classes = classNames({
            "form__field": true,
            "form__field--submit": true,
            "form__field--hidden": this.props.hidden,
            "form__field--has-errors": this.showValidationErrors(),
        });
        return <div className={classes}>{this.buildContent()}</div>;
    }
}
