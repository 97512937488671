import React from "react";
import { connect } from "react-redux";
import { t } from "ttag";

import { Popover } from "../../../common/Popover";
import { RichText } from "../../../common/RichText";
import { TDispatchMapper } from "../../reducers.interfaces";
import { Dispatchers } from "../dispatchers";

interface IOwnProps {
    optionSelectionErrorReason: string | undefined;
}

interface IState {
    isModalOpen: boolean;
}

interface IDispatchProps {
    dispatchers: Dispatchers;
}

interface IProps extends IOwnProps, IDispatchProps {}

class ConfiguratorOptionSelectionErrorComponent extends React.Component<
    IProps,
    IState
> {
    public state: IState = {
        isModalOpen: false,
    };

    public readonly onRequestClose = () => {
        this.props.dispatchers.setSetOptionSelectionErrorState("");
        this.setState({
            isModalOpen: false,
        });
    };

    private readonly onOpen = () => {
        this.setState({
            isModalOpen: true,
        });
    };

    componentDidUpdate() {
        if (
            this.props.optionSelectionErrorReason &&
            this.props.optionSelectionErrorReason.length > 0 &&
            !this.state.isModalOpen
        ) {
            this.onOpen();
        }
        if (
            (!this.props.optionSelectionErrorReason ||
                this.props.optionSelectionErrorReason.length <= 0) &&
            this.state.isModalOpen
        ) {
            this.onRequestClose();
        }
    }

    render() {
        return (
            <Popover
                triggerContent={""}
                triggerBtnProps={{
                    className: "configurator-option-selection-error__holder",
                }}
                popperOptions={{
                    modifiers: [
                        {
                            name: "offset",
                            options: {
                                offset: [0, 16],
                            },
                        },
                        {
                            name: "flip",
                            enabled: false,
                        },
                    ],
                    placement: "bottom",
                }}
                modalProps={{
                    bodyOpenClassName:
                        "configurator-option-selection-error__body-open",
                    overlayClassName:
                        "configurator-option-selection-error__overlay",
                    className: "configurator-option-selection-error",
                    contentLabel: t`Error`,
                    style: {
                        overlay: {
                            backgroundColor: "transparent",
                        },
                    },
                }}
                isOpen={this.state.isModalOpen}
                onRequestOpen={() => null}
                onRequestClose={this.onRequestClose}
                disableOpenButton={true}
            >
                <RichText
                    html={
                        this.props.optionSelectionErrorReason
                            ? this.props.optionSelectionErrorReason
                            : ""
                    }
                    tagName="p"
                />
            </Popover>
        );
    }
}

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const dispatchers = new Dispatchers(dispatch);
    return {
        dispatchers,
    };
};

export const ConfiguratorOptionSelectionError = connect(
    null,
    mapDispatchToProps,
)(ConfiguratorOptionSelectionErrorComponent);
