import { FinancingPreQualRequestResponse } from "../../models/financing";
import { ModalStateType } from "./constants";
import { FinancingState, FullApplicationValues, PrefillData } from "./models";

export const financingDefaults: Readonly<FinancingState> = {
    purchasePrice: null,
    applicationSource: null,
    prefillData: {},
    closeAttempts: 0,
    preQualApproval: null,
    modal: {
        _tag: ModalStateType.CLOSED,
    },
};

export const emptyFullAppValues: Readonly<FullApplicationValues> = {
    requested_credit_limit: "",
    main_applicant__first_name: "",
    main_applicant__middle_initial: "",
    main_applicant__last_name: "",
    main_applicant__annual_income: "",
    main_applicant__date_of_birth: "",
    main_applicant__email_address: "",
    main_applicant__employer_name: "",
    main_applicant__home_phone: "",
    main_applicant__housing_status: "",
    main_applicant__mobile_phone: "",
    main_applicant__ssn: "",
    main_applicant__work_phone: "",
    main_applicant__address__address_line_1: "",
    main_applicant__address__address_line_2: "",
    main_applicant__address__city: "",
    main_applicant__address__postal_code: "",
    main_applicant__address__state_code: "",
    joint_applicant__first_name: "",
    joint_applicant__middle_initial: "",
    joint_applicant__last_name: "",
    joint_applicant__annual_income: "",
    joint_applicant__date_of_birth: "",
    joint_applicant__email_address: "",
    joint_applicant__employer_name: "",
    joint_applicant__home_phone: "",
    joint_applicant__housing_status: "",
    joint_applicant__mobile_phone: "",
    joint_applicant__ssn: "",
    joint_applicant__work_phone: "",
    joint_applicant__address__address_line_1: "",
    joint_applicant__address__address_line_2: "",
    joint_applicant__address__city: "",
    joint_applicant__address__postal_code: "",
    joint_applicant__address__state_code: "",
    agree_disclosure: false,
    esign: false,
    agree_disclosure_joint: false,
    esign_joint: false,
};

/* eslint-disable-next-line complexity */
export const getInitialFullAppValues = ({
    prefillData,
    purchasePrice,
    preQualData,
}: {
    prefillData: PrefillData;
    purchasePrice: number | null;
    preQualData: FinancingPreQualRequestResponse | null;
}): Readonly<FullApplicationValues> => {
    let values: FullApplicationValues = {
        requested_credit_limit: purchasePrice ? `${purchasePrice}` : "",

        // Prefill using billing address. Last name can't have hyphens.
        // See https://thelabnyc.plan.io/issues/1869
        main_applicant__first_name: prefillData.first_name || "",
        main_applicant__middle_initial: prefillData.middle_initial || "",
        main_applicant__last_name: (prefillData.last_name || "").replace(
            "-",
            " ",
        ),
        main_applicant__annual_income: "",
        main_applicant__date_of_birth: "",
        main_applicant__email_address: prefillData.email || "",
        main_applicant__employer_name: prefillData.employer_name || "",
        main_applicant__home_phone: prefillData.home_phone || "",
        main_applicant__housing_status: prefillData.housing_status || "",
        main_applicant__mobile_phone: prefillData.mobile_phone || "",
        main_applicant__ssn: "",
        main_applicant__work_phone: prefillData.work_phone || "",
        main_applicant__address__address_line_1:
            prefillData.address_line_1 || "",
        main_applicant__address__address_line_2:
            prefillData.address_line_2 || "",
        main_applicant__address__city: prefillData.city || "",
        main_applicant__address__postal_code: prefillData.postal_code || "",
        main_applicant__address__state_code: prefillData.state_code || "",
        joint_applicant__first_name: prefillData.joint_first_name || "",
        joint_applicant__middle_initial: prefillData.joint_middle_initial || "",
        joint_applicant__last_name: (prefillData.joint_last_name || "").replace(
            "-",
            " ",
        ),
        joint_applicant__annual_income: "",
        joint_applicant__date_of_birth: "",
        joint_applicant__email_address: prefillData.joint_email || "",
        joint_applicant__employer_name: "",
        joint_applicant__home_phone: prefillData.joint_home_phone || "",
        joint_applicant__housing_status: "",
        joint_applicant__mobile_phone: prefillData.joint_mobile_phone || "",
        joint_applicant__ssn: "",
        joint_applicant__work_phone: prefillData.joint_work_phone || "",
        joint_applicant__address__address_line_1:
            prefillData.joint_address_line_1 || "",
        joint_applicant__address__address_line_2:
            prefillData.joint_address_line_2 || "",
        joint_applicant__address__city: prefillData.joint_city || "",
        joint_applicant__address__postal_code:
            prefillData.joint_postal_code || "",
        joint_applicant__address__state_code:
            prefillData.joint_state_code || "",

        agree_disclosure: false,
        esign: false,

        agree_disclosure_joint: false,
        esign_joint: false,
    };
    // If prequal data exists, it trumps the prefill data.
    if (preQualData) {
        values = {
            ...values,
            main_applicant__first_name: preQualData.first_name,
            main_applicant__middle_initial: preQualData.middle_initial || "",
            main_applicant__last_name: preQualData.last_name,
            main_applicant__address__address_line_1: preQualData.line1,
            main_applicant__address__address_line_2: preQualData.line2 || "",
            main_applicant__address__city: preQualData.city,
            main_applicant__address__postal_code: preQualData.postcode,
            main_applicant__address__state_code: preQualData.state,
            main_applicant__email_address: preQualData.email || "",
            main_applicant__home_phone: preQualData.phone,
            main_applicant__mobile_phone: preQualData.phone,
        };
    }
    return values;
};
