import { interpolate } from "@thelabnyc/thelabui/src/utils/i18n";

import { strings } from "../../localization";
import {
    IAddress,
    IBillingAddress,
    IShippingAddress,
} from "../../models/address.interfaces";
import { FinancingPlan } from "../../models/financing";
import { isoAPR } from "../../models/nominals";
import { objectKeys } from "../../utils/functional";
import { getDinero } from "../../utils/money";
import { PRIMARY_PAYMENT_METHOD_KEY } from "./constants";
import {
    IFinancingPayment,
    IReduxState,
    ISynchronyPayment,
} from "./reducers.interfaces";

export type IErrorMsgTemplateContext = {
    basketID?: string;
    orderID?: string;
};

const _getAddress = (type: "shipping" | "billing", state: IReduxState) => {
    const prefix = `${type}_`;
    const address = objectKeys(state.form)
        .filter(
            (key): key is keyof IShippingAddress | keyof IBillingAddress => {
                return key.indexOf(prefix) === 0;
            },
        )
        .reduce<Partial<IAddress>>((memo, key) => {
            const outKey = key.replace(prefix, "") as keyof IAddress;
            return {
                ...memo,
                [outKey]: state.form[key],
            };
        }, {});
    return address as IAddress;
};

export const getShippingAddress = (state: IReduxState) => {
    return _getAddress("shipping", state);
};

export const getBillingAddress = (state: IReduxState) => {
    return _getAddress("billing", state);
};

export const getCountryCode = (url: string) => {
    // TODO: make this less awful
    const segments = url.split("/");
    return segments[segments.indexOf("countries") + 1];
};

export const filterFinancingPlans = (plans: FinancingPlan[]) => {
    const planAPRIsZero = (plan: FinancingPlan) => {
        return getDinero(isoAPR.unwrap(plan.apr)).isZero();
    };

    const hasZeroPercentAPRPlan = plans.reduce((memo, plan) => {
        return memo || planAPRIsZero(plan);
    }, false);

    return plans.filter((plan) => {
        return planAPRIsZero(plan) || !hasZeroPercentAPRPlan;
    });
};

export const sortFinancingPlans = (plans: FinancingPlan[]) => {
    return plans.sort((a, b) => {
        if (a.apr !== b.apr) {
            return parseFloat(isoAPR.unwrap(a.apr)) >
                parseFloat(isoAPR.unwrap(b.apr))
                ? 1
                : -1;
        }

        if (a.term_months !== b.term_months) {
            return a.term_months > b.term_months ? 1 : -1;
        }

        return 0;
    });
};

export const getShortestFinancingPlan = (plans: FinancingPlan[]) => {
    return plans.length ? sortFinancingPlans(plans)[0] : null;
};

export const getLongestFinancingPlan = (plans: FinancingPlan[]) => {
    return plans.length ? sortFinancingPlans(plans)[plans.length - 1] : null;
};

export const renderErrorMessageTemplate = (
    template: string,
    data: IErrorMsgTemplateContext,
) => {
    // Guard against empty template string
    if (!template) {
        return "";
    }
    let error = template;

    // Render old style error message templates (for backwards compatibility)
    // Replace the BASKET_ID placeholder if it exists
    if (data.basketID) {
        error = error.replace("BASKET_ID", data.basketID);
    }
    // Replace the ORDER_ID placeholder if it exists
    if (data.orderID) {
        error = error.replace("ORDER_ID", data.orderID);
    }
    const websiteSupportNumber = strings.get("WEBSITE_SUPPORT_NUMBER");
    if (websiteSupportNumber) {
        error = error.replace("WEBSITE_SUPPORT_NUMBER", websiteSupportNumber);
    }
    const websiteSupportContactHours = strings.get(
        "WEBSITE_SUPPORT_CONTACT_HOURS",
    );
    if (websiteSupportContactHours) {
        error = error.replace(
            "WEBSITE_SUPPORT_CONTACT_HOURS",
            websiteSupportContactHours,
        );
    }

    // Render new-style error message templates
    return interpolate(error, {
        basketID: data.basketID || "",
        orderID: data.orderID || "",
        websiteSupportNumber: websiteSupportNumber || "",
        websiteSupportContactHours: websiteSupportContactHours || "",
    });
};

export const getFinancingData = (
    props: IReduxState,
): IFinancingPayment | null => {
    const data = props.form.payment_methods[PRIMARY_PAYMENT_METHOD_KEY];
    if (data.method_type === "financing") {
        return data;
    }
    return null;
};

export const isNewFinancingAccount = (props: IReduxState): boolean => {
    const data = getFinancingData(props);
    if (data) {
        if (data.new_financing_account) {
            return true;
        }
    }
    return false;
};

export const getSynchronyData = (
    props: IReduxState,
): ISynchronyPayment | null => {
    const data = props.form.payment_methods[PRIMARY_PAYMENT_METHOD_KEY];
    if (data.method_type === "synchrony") {
        return data;
    }
    return null;
};

export const isFortivaAcctNum = (value: string) => {
    return value.startsWith("7656") || value.startsWith("2000");
};
