import classNames from "classnames";
import React from "react";

import { RichTextBlock } from "@thelabnyc/thelabui/src/components/RichText";
import { getStyleVariantClass } from "@thelabnyc/thelabui/src/utils/styles";

import { IProductFlair } from "../../models/product-compare.interfaces";

import styles from "./index.module.scss";

interface IProps {
    flair: IProductFlair;
    inline?: boolean;
}

export const Flair = (props: IProps) => {
    if (!props.flair.content) {
        return null;
    }

    const variantClass = getStyleVariantClass(styles, props.flair.style) || "";
    const flairClasses = classNames({
        flair: true,
        [styles.flair]: true,
        [variantClass]: true,
    });

    return (
        <div className={flairClasses}>
            <RichTextBlock
                inline={props.inline}
                value={props.flair.content as string}
            ></RichTextBlock>
        </div>
    );
};

export default Flair;
