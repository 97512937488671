import React from "react";
import SVG from "react-inlinesvg";
import { t } from "ttag";

import iconInfo from "../../../../img/icons/info.svg";
import { ErrorBoundary } from "../../../common/ErrorBoundary";
import { ErrorModal } from "../../../common/ErrorModal";
import { IBasket, IBasketLine } from "../../../models/catalogue.interfaces";
import { FinancingPlan } from "../../../models/financing";
import { IProductAPIURL, isoMonths } from "../../../models/nominals";
import {
    format,
    formatDateString,
    formatTermLength,
} from "../../../utils/format";
import { getDinero, getMonthlyPrice } from "../../../utils/money";
import { Actions } from "../actions";
import { BasketLineValueProps } from "../components/BasketLineValueProps";
import {
    PredictedDeliveryDateDisplayType,
    PredictedDeliveryDateStyle,
    PreferredDeliveryDateDisplayType,
} from "../constants";
import { getBasketLineAdvertisingContent } from "../selectors";
import { getLongestFinancingPlan } from "../utils";
import {
    BasketLineImage,
    BasketLineOptions,
    BasketLinePrice,
    BasketLineTitle,
    BasketLineUpsellMessage,
    IBasketLineBaseProps,
} from "./BasketLineBase";
import { PredictedDeliveryDate } from "./PredictedDeliveryDate";
import { PreferredDeliveryDateHelpText } from "./PreferredDeliveryDateHelpText";

import styles from "./BasketLineFull.module.scss";

export type TProductUpsell = {
    placement: "in-details" | "post-details";
    hideBottomBorder?: boolean;
    content: JSX.Element;
};

export type TGetProductUpsell = (
    basket: IBasket,
    line: IBasketLine,
    addBasketLine: Actions["addBasketLine"],
) => TProductUpsell | null;

interface IProps extends IBasketLineBaseProps {
    line: IBasketLine;
    basket: IBasket;
    financingPlans: FinancingPlan[];
    getProductUpsell: TGetProductUpsell;
    showValueProps: boolean;
    predictedDeliveryDates: PredictedDeliveryDateDisplayType;
    preferredDeliveryDates: PreferredDeliveryDateDisplayType;
    loadConcreteBundles: (url: IProductAPIURL) => void;
    addBasketLine: Actions["addBasketLine"];
    removeBasketLine: Actions["removeBasketLine"];
    updateBasketLineQuantity: Actions["updateBasketLineQuantity"];
    onRemoveLine: (event?: React.MouseEvent<HTMLElement>) => void;
    onUpdateLineQuantity: (event: React.FormEvent<HTMLSelectElement>) => void;
    isMobile: boolean;
    deliveryIsFree?: boolean;
}

interface IState {
    isOperationPending: boolean;
    errorModalOpen: boolean;
    errorModalReason: string;
}

export class BasketLineFull extends React.Component<IProps, IState> {
    state: IState = {
        isOperationPending: false,
        errorModalOpen: false,
        errorModalReason: "",
    };

    componentDidMount() {
        this.props.loadConcreteBundles(this.props.line.product.url);
    }

    private readonly onFinePrintClick = (
        event: React.MouseEvent<HTMLElement>,
    ) => {
        if (event) {
            event.preventDefault();
        }
        const finePrint = document.querySelector("#basket-lines__fine-print");
        finePrint?.scrollIntoView({ behavior: "smooth" });
    };

    private closeErrorModal(
        e: React.FormEvent<Element> | React.KeyboardEvent<Element>,
    ) {
        e.preventDefault();
        this.setState({
            errorModalOpen: false,
        });
    }

    private buildRemoveButton() {
        return (
            <button
                onClick={this.props.onRemoveLine}
                className={`${styles.removeButton} al-basket-line__button--remove`}
            >
                {t`[Remove from cart]`}
            </button>
        );
    }

    private buildQuantitySelector() {
        const line = this.props.line;
        if (!line.quantity) {
            return null;
        }
        const maxQty = 10;
        const options = [];
        for (let i = 0; i <= maxQty; i++) {
            options.push(
                <option key={i} value={i}>
                    {i}
                </option>,
            );
        }
        return (
            <div className={styles.quantity}>
                <label htmlFor="product_quantity">{t`Qty:`} &nbsp;</label>
                <select
                    value={line.quantity}
                    onChange={this.props.onUpdateLineQuantity}
                    id="product_quantity"
                >
                    {options}
                </select>
            </div>
        );
    }

    private buildModificationLog() {
        const line = this.props.line;
        if (!line.quantity) {
            return null;
        }
        const logEntries = line.modification_log_entries.filter((entry) => {
            return (
                entry.user_is_staff && // User must be a staff user
                !!entry.user_name
            ); // Don't display anything added by a user without a name
        });
        if (logEntries.length <= 0) {
            return null;
        }
        const logEntry = logEntries[logEntries.length - 1];
        return (
            <div className={styles.modificationMessage}>
                <SVG className={styles.modificationIcon} src={iconInfo} />
                <span>
                    {t`This item was added/modified on your behalf by ${logEntry.user_name}.`}
                </span>
                <span className={styles.modificationTimestamp}>
                    {formatDateString(
                        logEntry.action_time,
                        "MM/dd/yyyy h:mm a zz",
                    )}
                </span>
            </div>
        );
    }

    protected buildFinancing(line: IBasketLine) {
        const longestActivePlan = getLongestFinancingPlan(
            this.props.financingPlans,
        );
        const termLength = longestActivePlan
            ? longestActivePlan.term_months
            : line.product.price.per_month_term_length || isoMonths.wrap(0);
        const linePrice = getDinero(line.price_excl_tax);
        const showMonthlyPricing = !!(
            line.product.price.enable_per_month_pricing && termLength
        );
        if (!showMonthlyPricing) {
            return null;
        }
        const monthlyPrice = getMonthlyPrice(
            getDinero(line.price_excl_tax),
            termLength,
        );
        const formattedPrice = format.money(linePrice);
        return (
            <div className={styles.financing}>
                <button
                    className={styles.financingButton}
                    onClick={this.onFinePrintClick}
                >
                    or{" "}
                    <span className={styles.underlined}>
                        {format.money(monthlyPrice)}/mo.
                    </span>
                    <sup>2</sup> for{" "}
                    <span className={styles.underlined}>
                        {formatTermLength(termLength)} mos.
                    </span>
                    {longestActivePlan && (
                        <sup>{longestActivePlan.fine_print_superscript}</sup>
                    )}
                </button>
                <br />
                {t`(total payments of ${formattedPrice})`}
            </div>
        );
    }

    protected buildErrorModal() {
        return (
            <ErrorModal
                name="basket-line"
                isOpen={this.state.errorModalOpen}
                onRequestClose={this.closeErrorModal}
            >
                <p>{this.state.errorModalReason}</p>
            </ErrorModal>
        );
    }

    private buildValueProps() {
        if (this.props.line.product.value_props) {
            return (
                <BasketLineValueProps
                    isMobile={this.props.isMobile}
                    valueProps={this.props.line.product.value_props}
                />
            );
        }
        return null;
    }

    render() {
        const line = this.props.line;
        const product = line.product;
        const upsell = this.props.getProductUpsell(
            this.props.basket,
            this.props.line,
            this.props.addBasketLine,
        );

        let financing: JSX.Element | null = null;
        let price: JSX.Element | null = null;
        if (line.price_excl_tax_excl_discounts) {
            financing = this.buildFinancing(line);
            price = (
                <BasketLinePrice
                    line={line}
                    unitPrice={line.product.price}
                    linePriceInclDiscounts={getDinero(
                        line.line_price_excl_tax_incl_discounts,
                    )}
                    quantity={line.quantity}
                    postDiscountAddons={line.product.price.post_discount_addons}
                />
            );
        }

        const advertisingContent = getBasketLineAdvertisingContent(
            this.props.basket,
            this.props.line,
        );

        return (
            <li>
                <section
                    className={styles.basketLineFull}
                    role="group"
                    aria-label={t`product`}
                >
                    <div className={styles.firstColumn}>
                        <BasketLineImage product={product} />
                        {!this.props.isMobile && (
                            <ErrorBoundary>
                                {this.props.showValueProps &&
                                    this.buildValueProps()}
                            </ErrorBoundary>
                        )}
                    </div>
                    <div>
                        <BasketLineTitle product={product} />
                        <BasketLineOptions product={product} />
                    </div>
                    {this.buildQuantitySelector()}
                    <div>
                        {price}
                        {financing}
                        {this.props.isMobile && (
                            <ErrorBoundary>
                                {this.props.showValueProps &&
                                    this.buildValueProps()}
                            </ErrorBoundary>
                        )}
                    </div>
                    {this.props.line.product.enable_predicted_delivery_date && (
                        <div className={styles.whiteGloveDelivery}>
                            <PredictedDeliveryDate
                                type={this.props.predictedDeliveryDates}
                                style={PredictedDeliveryDateStyle.ONE_LINE}
                                deliveryIsFree={this.props.deliveryIsFree}
                            />
                            <PreferredDeliveryDateHelpText
                                type={this.props.preferredDeliveryDates}
                            />
                        </div>
                    )}
                    {advertisingContent.map((content) => (
                        <BasketLineUpsellMessage
                            key={`${content.group_priority}-${content.offer_priority}`}
                            content={content}
                        />
                    ))}
                    {this.buildModificationLog()}
                    {this.buildRemoveButton()}
                    <ErrorBoundary>
                        <div className={styles.basketLineSuggestions}>
                            {upsell?.content}
                        </div>
                    </ErrorBoundary>
                    {this.buildErrorModal()}
                </section>
            </li>
        );
    }
}
