import { Store, configureStore } from "@reduxjs/toolkit";
import {
    FLUSH,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
    REHYDRATE,
    persistStore,
} from "redux-persist";
import { guess as guessLocation } from "tsi-common-react/src/api/location";
import { Dispatchers } from "tsi-common-react/src/apps/common/dispatchers";
import { listenerMiddleware } from "tsi-common-react/src/apps/common/listeners";
import { middleware } from "tsi-common-react/src/apps/middleware";

import { reducers } from "./reducers";
import { IReduxState } from "./reducers.interfaces";

type PromiseResolve<T> = (value: T | PromiseLike<T>) => void;
type PromiseReject<T> = (reason: T | PromiseLike<T>) => void;

const newDefer = () => {
    let resolve: PromiseResolve<Store<IReduxState>> = Promise.resolve;
    let reject: PromiseReject<Store<IReduxState>> = Promise.reject;
    const promise = new Promise<Store<IReduxState>>((res, rej) => {
        resolve = res;
        reject = rej;
    });
    return {
        resolve: resolve,
        reject: reject,
        promise: promise,
    };
};
const _rehydratingStore = newDefer();
export const rehydratingStore = _rehydratingStore.promise;

rehydratingStore.then(async (_store) => {
    const state = _store.getState();
    if (!state.common.detectedLocation) {
        const location = await guessLocation();
        if (location) {
            const dispatchers = new Dispatchers(store.dispatch);
            dispatchers.updateDetectedLocation(location);
        }
    }
});

export const store = configureStore({
    reducer: reducers,
    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware({
            immutableCheck: {
                ignoredPaths: [
                    /^reviews\.data\.products\.([0-9]+)/,
                    /^checkout\.data\.payment_methods\.([0-9]+)/,
                ],
            },
            serializableCheck: {
                ignoredPaths: [
                    /^reviews\.ui\.writeReviewForms\.([a-zA-Z0-9-]+)\.started/,
                ],
                ignoredActions: [
                    FLUSH,
                    REHYDRATE,
                    PAUSE,
                    PERSIST,
                    PURGE,
                    REGISTER,
                ],
            },
        })
            .prepend(listenerMiddleware.middleware)
            .concat(middleware);
    },
    devTools: {
        name: "Global",
    },
});

export const persistor = persistStore(store, null, () => {
    _rehydratingStore.resolve(store);
});

export default store;
