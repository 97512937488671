import {
    DPOSTokenResponse,
    TransactionLookupResponse,
} from "../models/synchrony";
import { check } from "../models/utils";
import { ajax } from "../utils/ajax";
import { PromiseMutex } from "../utils/mutex";

export const getDPOSToken = async (): Promise<DPOSTokenResponse> => {
    const mutex = new PromiseMutex<DPOSTokenResponse>("get-dpos-token");
    let loading = mutex.getPromise();
    if (!loading) {
        loading = ajax
            .get("/api/financing/synchrony/dpos-token/")
            .set("Accept", "application/json")
            .then((resp) => check(DPOSTokenResponse.decode(resp.body)));
        mutex.setPromise(loading);
    }
    return loading;
};

export const lookupTransaction = async (
    dposToken: string,
): Promise<TransactionLookupResponse> => {
    const resp = await ajax
        .get(`/api/financing/synchrony/transaction/${dposToken}/`)
        .set("Accept", "application/json");
    return check(TransactionLookupResponse.decode(resp.body));
};
