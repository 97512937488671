import React from "react";
import { connect } from "react-redux";
import { t } from "ttag";

import { IFormUUID } from "../../../models/nominals";
import { TDispatchMapper, TStateMapper } from "../../reducers.interfaces";
import { WriteReviewFormMode } from "../constants";
import { Dispatchers } from "../dispatchers";
import { getWriteReviewFormHTMLID, getWriteReviewFormMode } from "../selectors";

interface IOwnProps {
    formUUID: IFormUUID;
}

interface IReduxProps {
    displayMode: WriteReviewFormMode;
}

interface IDispatchProps {
    dispatchers: Dispatchers;
}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

interface IState {}

class WriteReviewButtonComponent extends React.PureComponent<IProps, IState> {
    private readonly onClick = () => {
        const newMode =
            this.props.displayMode === WriteReviewFormMode.FORM_OPEN
                ? WriteReviewFormMode.CLOSED
                : WriteReviewFormMode.FORM_OPEN;
        this.props.dispatchers.setWriteReviewFormMode(
            this.props.formUUID,
            newMode,
        );
    };

    render() {
        if (this.props.displayMode === WriteReviewFormMode.THANK_YOU_OPEN) {
            return null;
        }
        return (
            <button
                aria-expanded={
                    this.props.displayMode === WriteReviewFormMode.FORM_OPEN
                }
                aria-controls={getWriteReviewFormHTMLID(this.props.formUUID)}
                className="customer-review__button button button--rock-blue"
                onClick={this.onClick}
            >
                <span className="customer-review__button__link">
                    <span className="customer-review__button__icon"></span>
                    {t`WRITE A REVIEW`}
                </span>
            </button>
        );
    }
}

const mapStateToProps: TStateMapper<"reviews", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    const displayMode = getWriteReviewFormMode(rootState.reviews, ownProps);
    return {
        ...ownProps,
        displayMode: displayMode,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const dispatchers = new Dispatchers(dispatch);
    return {
        dispatchers: dispatchers,
    };
};

export const WriteReviewButton = connect(
    mapStateToProps,
    mapDispatchToProps,
)(WriteReviewButtonComponent);
