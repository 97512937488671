import { combineReducers } from "@reduxjs/toolkit";

import { guardReducer } from "../../utils/redux";
import { defaults } from "./defaults";
import { Action, IAction, IReduxState } from "./reducers.interfaces";

const storesLoadedKey = guardReducer(
    Action,
    defaults.storesLoadedKey,
    (
        state = defaults.storesLoadedKey,
        action: IAction,
    ): IReduxState["storesLoadedKey"] => {
        switch (action.type) {
            case Action.SET_STORES:
                return action.payload.loadKey;
        }
        return state;
    },
);

export const stores = guardReducer(
    Action,
    defaults.stores,
    (
        state: IReduxState["stores"] = defaults.stores,
        action: IAction,
    ): IReduxState["stores"] => {
        switch (action.type) {
            case Action.CLEAR_STORES:
                return [];

            case Action.ADD_STORES:
                return state.concat(action.payload);

            case Action.SET_STORES:
                return action.payload.stores;
        }
        return state;
    },
);

const storeFilters = guardReducer(
    Action,
    defaults.storeFilters,
    (
        state = defaults.storeFilters,
        action: IAction,
    ): IReduxState["storeFilters"] => {
        switch (action.type) {
            case Action.UPDATE_STORE_FILTERS:
                return {
                    ...state,
                    ...action.payload,
                };
        }
        return state;
    },
);

export const focusedStoreID = guardReducer(
    Action,
    defaults.focusedStoreID,
    (
        state = defaults.focusedStoreID,
        action: IAction,
    ): IReduxState["focusedStoreID"] => {
        switch (action.type) {
            case Action.SET_FOCUSED_STORE:
                return action.payload.externalID;
        }
        return state;
    },
);

export const reducersFns = {
    storesLoadedKey: storesLoadedKey,
    stores: stores,
    storeFilters: storeFilters,
    focusedStoreID: focusedStoreID,
};

export const reducers = combineReducers(reducersFns);
