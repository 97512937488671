import type { AdaEmbedAPI } from "@ada-support/embed-types";

import { ReCaptcha } from "./@types/recaptcha";
import type {
    IWidgetPositionSettings,
    IZenChat,
} from "./apps/chat/connector-zendesk";
import config from "./config";
import type { SyfMPP, SyfWidgetConfig } from "./models/synchrony";
import { TSIEcomPublicAPI, publicAPI } from "./public-api";
import { domContentLoaded, readyStateComplete } from "./utils/events";
import { markPerfTiming, reportWebVitals } from "./utils/performance";

markPerfTiming("js-init");
reportWebVitals();

// Event logging
(async () => {
    await domContentLoaded;
})();

(async () => {
    await readyStateComplete;
})();

interface IFrameResizerPageInfo {
    // The height of the iframe in pixels
    iframeHeight: number;
    // The width of the iframe in pixels
    iframeWidth: number;
    // The number of pixels between the left edge of the containing page and the left edge of the iframe
    offsetLeft: number;
    // The number of pixels between the top edge of the containing page and the top edge of the iframe
    offsetTop: number;
    // The number of pixels between the left edge of the iframe and the left edge of the iframe viewport
    scrollLeft: number;
    // The number of pixels between the top edge of the iframe and the top edge of the iframe viewport
    scrollTop: number;
    // The containing document's height in pixels (the equivalent of document.documentElement.clientHeight in the container)
    documentHeight: number;
    // The containing document's width in pixels (the equivalent of document.documentElement.clientWidth in the container)
    documentWidth: number;
    // The containing window's height in pixels (the equivalent of window.innerHeight in the container)
    windowHeight: number;
    // The containing window's width in pixels (the equivalent of window.innerWidth in the container)
    windowWidth: number;
}

interface IIgloo {
    // Enabling real IP protection.
    enable_rip: boolean;
    // Enabling flash
    enable_flash: boolean;
    // Asking user to install flash
    install_flash: boolean;
    loader: {
        // Non-experimental 5.x versions
        version: string;
        // Loading 1st party resources
        fp_static?: boolean;
    };
    "getBlackbox"?(): {
        finished: boolean;
        blackbox: string;
    };
}

declare global {
    interface Window {
        /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
        dataLayer: any[];

        // Interfaces for Synchrony Unify
        syfWidgetObject?: SyfWidgetConfig;
        syfMPP?: SyfMPP;

        // Ada chat
        adaEmbed?: AdaEmbedAPI | undefined;

        // Zendesk chat
        zE?: IZenChat | undefined;
        zESettings?: IWidgetPositionSettings | undefined;

        // Wistia
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        _wq: any;
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        Wistia?: any;
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        wistiaEmbed?: any;

        // Iframe resizer
        // See https://github.com/davidjbradshaw/iframe-resizer/blob/master/docs/iframed_page/methods.md
        parentIFrame?: {
            autoResize: (enable: boolean) => void;
            close: () => void;
            getId: () => void;
            getPageInfo: (
                callback: (info: IFrameResizerPageInfo) => void,
            ) => void;
            scrollTo: (x: number, y: number) => void;
            scrollToOffset: (x: number, y: number) => void;
            setHeightCalculationMethod: (
                heightCalculationMethod: string,
            ) => void;
            size: (customHeight?: number, customWidth?: number) => void;
        };

        tsiecom?: TSIEcomPublicAPI;

        grecaptcha?: ReCaptcha;

        IGLOO?: IIgloo;

        // Listrak
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        _ltk?: any;
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        _ltk_util?: any;
    }
}

// Polyfill requestIdleCallback / cancelIdleCallback
// See https://developer.mozilla.org/en-US/docs/Web/API/Background_Tasks_API
type RequestIdleCallbackEvent = {
    didTimeout: boolean;
    timeRemaining: () => number;
};

window.requestIdleCallback =
    window.requestIdleCallback ||
    ((cb: (event: RequestIdleCallbackEvent) => void) => {
        const startTime = Date.now();
        return setTimeout(() => {
            cb({
                didTimeout: false,
                timeRemaining: function () {
                    return Math.max(0, 50.0 - (Date.now() - startTime));
                },
            });
        }, 1);
    });

window.cancelIdleCallback =
    window.cancelIdleCallback ||
    ((id: number) => {
        window.clearTimeout(id);
    });

window.tsiecom = publicAPI;

export { config };
