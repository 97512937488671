import classNames from "classnames";
import React from "react";
import { connect } from "react-redux";

import {
    IReviewQueryFacets,
    ISearchFacet,
} from "../../../models/reviews.interfaces";
import { TDispatchMapper, TStateMapper } from "../../reducers.interfaces";
import { defaultState } from "../defaults";
import { Dispatchers } from "../dispatchers";
import { FilterOption } from "./FilterOption";

import styles from "./SourceFilterSection.module.scss";

const FACET_TYPE: IReviewQueryFacets = "source_id";

interface IOwnProps {}

interface IReduxProps {
    sourceFacet: ISearchFacet | null;
    isOpen: boolean;
}
interface IDispatchProps {
    updateSourceFilterVisibility: Dispatchers["updateSourceFilterVisibility"];
}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

interface IState {}

class SourceFilterSectionComponent extends React.PureComponent<IProps, IState> {
    private readonly onToggleVisibility = (
        e: React.FormEvent<HTMLInputElement>,
    ) => {
        this.props.updateSourceFilterVisibility(e.currentTarget.checked);
    };

    render() {
        const parentClasses = classNames({
            [styles.filterTitle]: true,
            [styles.filterTitleParent]: true,
            [styles.filterTitleParentOpen]: this.props.isOpen,
        });
        const listClasses = classNames({
            [styles.list]: true,
            [styles.listParentOpen]: this.props.isOpen,
            [styles.listOpen]: this.props.isOpen,
            "customer-review__side-filters__list": true,
            "customer-review__side-filters__list--parent-open":
                this.props.isOpen,
            "customer-review__side-filters__list--open": this.props.isOpen,
        });
        const options = this.props.sourceFacet
            ? this.props.sourceFacet.options
            : [];
        const inputID = "source";
        return (
            <div>
                <div>
                    <h3 className={parentClasses}>
                        <input
                            id={inputID}
                            type="checkbox"
                            value="Source"
                            checked={this.props.isOpen}
                            onChange={this.onToggleVisibility}
                            className={styles.checkbox}
                        />
                        <label htmlFor={inputID}>Sources</label>
                    </h3>
                    <ul className={listClasses}>
                        {options.map((option, i) => (
                            <FilterOption
                                key={i}
                                facetType={FACET_TYPE}
                                option={option}
                                showCount={true}
                            />
                        ))}
                    </ul>
                </div>
            </div>
        );
    }
}

const mapStateToProps: TStateMapper<"reviews", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    const state = rootState.reviews || defaultState;
    return {
        sourceFacet:
            state.data.facets.find((facet) => facet.type === FACET_TYPE) ||
            null,
        isOpen: state.ui.sourceFilterOpen,

        ...ownProps,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const dispatchers = new Dispatchers(dispatch);
    return {
        updateSourceFilterVisibility: dispatchers.updateSourceFilterVisibility,
    };
};

export const SourceFilterSection = connect(
    mapStateToProps,
    mapDispatchToProps,
)(SourceFilterSectionComponent);
