import ScrollMagic from "scrollmagic";
import { isMobile } from "tsi-common-react/src/utils/detectMobile";
import { onDOMContentLoaded } from "tsi-common-react/src/utils/events";

const LP_INTRO_MAX_WIDTH = 768; // mobile breakpoint 768
const TRUE = "true";
const LOCAL_STORAGE_KEY = "lp-intro-closed";

const getHasBeenClosed = () => {
    try {
        return localStorage[LOCAL_STORAGE_KEY] === TRUE;
    } catch (e) {
        console.error(e);
    }
    return false;
};

const setHasBeenClosed = () => {
    try {
        localStorage[LOCAL_STORAGE_KEY] = TRUE;
    } catch (e) {
        console.error(e);
    }
};

const readyLandingPageIntro = () => {
    const introSection = document.getElementById(
        "#landing-page-promo-intro-section",
    );
    if (!introSection) {
        return;
    }
    const allSmallSize = window.innerWidth < LP_INTRO_MAX_WIDTH;
    let controller: ScrollMagic.Controller | null = null;
    let promoHeader: HTMLElement | null = null;

    const resizeHandler = () => {
        const wWidth = window.innerWidth;
        if (wWidth < LP_INTRO_MAX_WIDTH) {
            if (controller !== null && controller !== undefined) {
                // completely destroy the controller
                controller.destroy(true);
                controller = null;
            }
        } else if (wWidth >= LP_INTRO_MAX_WIDTH) {
            if (controller === null || controller === undefined) {
                // reinitialize ScrollMagic only if it is not already initialized
                initScrollMagic();
            }
        }
        // set container with 100%
        const header = document.getElementById("site-nav-header");
        if (header) {
            header.style.width = "100%";
        }
    };

    const clickHandler = () => {
        const aTag = document.getElementById("site-nav-header");
        if (aTag) {
            window.scrollTo(aTag.offsetTop, 0);
        }
    };

    const introAnmationFrame = () => {
        const promoHeight =
            document.getElementById("intro-promo-holder")?.offsetHeight || 0;
        const speed = 500;
        let delay = 400;
        window.scrollTo(promoHeight, 0);
        setTimeout(() => {
            document
                .querySelector<HTMLElement>(".site-nav")
                ?.classList.add("site-nav--position-static");
            document
                .querySelector<HTMLElement>(".promo-header")
                ?.classList.add("promo-header--no-margin-top");
            window.scrollTo(0, 0);
        }, delay);
        delay += speed;
        setTimeout(setItems, delay);
    };

    const setItems = () => {
        promoHeader = document.querySelector(".promo-header");
        promoHeader?.classList.add("promo-header--no-margin-top");
        if (!allSmallSize) {
            initScrollMagic();
        }
        window.addEventListener("resize", resizeHandler);
        document
            .querySelectorAll<HTMLElement>(
                ".landing-page-promo-intro__button, .landing-page-promo-intro__click-area",
            )
            .forEach((elem) => {
                elem.addEventListener("click", clickHandler);
            });
    };

    const removeIntro = () => {
        introSection.remove();
        // destroy the controller
        controller?.destroy(true);
        controller = null;
        window.removeEventListener("resize", resizeHandler);
        document
            .querySelectorAll<HTMLElement>(
                ".landing-page-promo-intro__button, .landing-page-promo-intro__click-area",
            )
            .forEach((elem) => {
                elem.removeEventListener("click", clickHandler);
            });
        document
            .querySelector(".site-nav-container")
            ?.classList.add("site-nav-container--fixed");
        promoHeader?.classList.remove("promo-header--no-margin-top");
        window.scrollTo(0, 0);
    };

    const initScrollMagic = () => {
        if (!document.getElementById("site-nav-header")) {
            return;
        }
        window.scrollTo(0, 0);
        controller = new ScrollMagic.Controller();
        // add ScrollMagic scene
        if (!document.getElementById("site-nav-header")) {
            return;
        }
        const scene = new ScrollMagic.Scene({
            triggerElement: "#site-nav-header",
            triggerHook: 0,
        });
        scene
            .setPin("#site-nav-header")
            .addTo(controller)
            .on("start", () => {
                removeIntro();
            });
    };

    if (getHasBeenClosed()) {
        return;
    }
    setHasBeenClosed();
    introSection.style.display = "block";
    setTimeout(introAnmationFrame, 1);
};

onDOMContentLoaded.on(() => {
    if (
        !document.getElementById("landing-page-promo-intro-section") ||
        window.innerWidth < LP_INTRO_MAX_WIDTH ||
        isMobile.any()
    ) {
        return;
    }

    window.scrollTo(0, 0);

    const holderElem = document.getElementById("intro-promo-holder");
    const introElem = document.getElementById(
        "landing-page-promo-intro-section",
    );
    if (holderElem && introElem) {
        holderElem.appendChild(introElem);
    }

    setTimeout(readyLandingPageIntro, 100);
});
