import React from "react";

import { ConfiguratorTypes } from "../../../constants";
import { IWebPageURL } from "../../../models/nominals";
import { RootProductSelectorInputType } from "../constants";
import { IModularConfiguratorOptionSet } from "../models.interfaces";
import { DropdownRootProductSelector } from "./DropdownRootProductSelector";
import { PanelRootProductSelector } from "./PanelRootProductSelector";
import { RadioRootProductSelector } from "./RadioRootProductSelector";

interface IProps {
    optionSet: IModularConfiguratorOptionSet;
    chatLinkComponent?: JSX.Element | null;
    liveChatHeader?: string;
    financingLink?: IWebPageURL;
    isPlpPanelStyle?: boolean;
}

export const RootProductSelector = (props: IProps) => {
    if (
        props.optionSet.layout.design === ConfiguratorTypes.PANEL &&
        !props.isPlpPanelStyle
    ) {
        return <PanelRootProductSelector {...props} />;
    }
    if (
        props.optionSet.root_product_selector.input_type ===
        RootProductSelectorInputType.RADIO
    ) {
        return (
            <RadioRootProductSelector
                {...props}
                isPlpPanelStyle={props.isPlpPanelStyle}
            />
        );
    }
    return <DropdownRootProductSelector {...props} />;
};
