import { SpacingSubBlock } from "@reactivated";

import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import { SpacingRules } from "./constants";

import bottomInnerStyles from "./bottom-inner-spacing.module.scss";
import bottomStyles from "./bottom-spacing.module.scss";
import baseStyles from "./spacing.module.scss";
import topInnerStyles from "./top-inner-spacing.module.scss";
import topStyles from "./top-spacing.module.scss";

const findSpacingRule = (rule: string, styles: CSSModuleClasses) => {
    switch (rule) {
        case SpacingRules.L:
            return styles.l;
        case SpacingRules.M:
            return styles.m;
        case SpacingRules.S:
            return styles.s;
        case SpacingRules.XL:
            return styles.xl;
        case SpacingRules.XS:
            return styles.xs;
        default:
            return;
    }
};

export const getSpacingClasses = (spacing: SpacingSubBlock) => {
    let topClasses = null;
    let topSpacingRule = null;
    let topInnerClasses = null;
    let topInnerSpacingRule = null;
    let bottomClasses = null;
    let bottomSpacingRule = null;
    let bottomInnerClasses = null;
    let bottomInnerSpacingRule = null;

    if (spacing.top_spacing) {
        topSpacingRule = findSpacingRule(spacing.top_spacing, topStyles);
        topClasses = concatClassNames([baseStyles.topSpacing, topSpacingRule]);
    }
    if (spacing.top_inner_spacing) {
        topInnerSpacingRule = findSpacingRule(
            spacing.top_inner_spacing,
            topInnerStyles,
        );
        topInnerClasses = concatClassNames([
            baseStyles.topInnerSpacing,
            topInnerSpacingRule,
        ]);
    }
    if (spacing.bottom_spacing) {
        bottomSpacingRule = findSpacingRule(
            spacing.bottom_spacing,
            bottomStyles,
        );
        bottomClasses = concatClassNames([
            baseStyles.bottomSpacing,
            bottomSpacingRule,
        ]);
    }
    if (spacing.bottom_inner_spacing) {
        bottomInnerSpacingRule = findSpacingRule(
            spacing.bottom_inner_spacing,
            bottomInnerStyles,
        );
        bottomInnerClasses = concatClassNames([
            baseStyles.bottomInnerSpacing,
            bottomInnerSpacingRule,
        ]);
    }

    return concatClassNames([
        topClasses,
        topInnerClasses,
        bottomClasses,
        bottomInnerClasses,
    ]);
};
