import React from "react";

import { focusElement } from "../utils/keyboardFocus";

interface IProps {
    id?: string;
    name?: string;
    className?: string;
    onSubmit?: (event: React.FormEvent<HTMLFormElement>) => Promise<void>;
    noValidate?: boolean;
    children?: React.ReactNode;
}

interface IState {}

export class Form extends React.Component<IProps, IState> {
    private submitLock = false;

    public static defaultProps: Partial<IProps> = {
        className: "form",
    };

    // React Ref to HTML form element
    public formElem: HTMLFormElement | null = null;

    // Boolean latch used to prevent updating form field focus, except for once
    // after a form submit attempt.
    private shouldResetInputFocus = false;

    private readonly onSubmit = async (
        evnt: React.FormEvent<HTMLFormElement>,
    ) => {
        evnt.preventDefault();
        if (this.submitLock) {
            console.log(
                "Ignoring form submit event because submitLock is active.",
            );
            return;
        }

        // Set latch to true so that later on, when componentDidUpdate is called (due to
        // error messages being added to the form), we can set the appropriate input
        // focus. The latch is needed to revent changing focus after typing every character.
        this.shouldResetInputFocus = true;

        if (this.props.onSubmit) {
            // Prevent multiple form submission
            this.submitLock = true;
            try {
                await this.props.onSubmit(evnt);
            } catch (err) {
                console.error(err);
            }
            // Unlock form submission
            this.submitLock = false;
            this.setChildInputFocus();
        }
    };

    public componentDidUpdate() {
        if (this.shouldResetInputFocus) {
            this.setChildInputFocus();
            this.shouldResetInputFocus = false;
        }
    }

    private setChildInputFocus() {
        if (!this.formElem) {
            return;
        }
        // Look to see if any inputs in the form have errors. If they do, set
        // keyboard focus to the first input with an error.
        const errorFields = this.formElem.querySelectorAll<HTMLElement>(
            ".form__field.form__field--has-errors",
        );
        if (errorFields.length > 0) {
            const fieldInput = errorFields[0].querySelector<HTMLElement>(
                "input, select, textarea",
            );
            // focus on first input field with errors
            if (fieldInput) {
                focusElement(fieldInput);
                // if error no input elems in errored fields, focus on first input field in form
            } else {
                const firstInputField =
                    this.formElem.querySelector<HTMLElement>(
                        "input, select, textarea",
                    );
                if (firstInputField) {
                    focusElement(firstInputField);
                }
            }
        }
    }

    public render() {
        return (
            <form
                {...this.props}
                ref={(r) => {
                    this.formElem = r;
                }}
                onSubmit={this.onSubmit}
            >
                {this.props.children}
            </form>
        );
    }
}
