import React from "react";

import { FinancingPlan } from "../../../models/financing";
import { isoAPR } from "../../../models/nominals";
import { formatAPR, formatTermLength } from "../../../utils/format";

interface IProps {
    plan: {
        apr: FinancingPlan["apr"];
        term_months: FinancingPlan["term_months"];
    };
}

interface IState {}

export class TermLengthAd extends React.Component<IProps, IState> {
    render() {
        if (parseInt(isoAPR.unwrap(this.props.plan.apr), 10) !== 0) {
            return null;
        }
        return (
            <div className="financing-form__feature">
                <div className="financing-form__feature--apr">
                    {formatAPR(this.props.plan.apr)} APR
                </div>
                <div className="financing-form__feature--for">for</div>
                <div className="financing-form__feature--term">
                    {formatTermLength(this.props.plan.term_months)} Months
                </div>
            </div>
        );
    }
}
