import React from "react";
import SVG from "react-inlinesvg";
import { t } from "ttag";

import iconXClose from "../../../../img/icons/x-close.svg";
import { Image } from "../../../common/Image";
import { Link } from "../../../common/Link";
import { RichText } from "../../../common/RichText";
import {
    IBasketLine,
    IBasketLineAdvertistingContent,
    IProduct,
    IWishListLine,
} from "../../../models/catalogue.interfaces";
import { IPostDiscountAddons, IPrice } from "../../../models/prices.interfaces";
import {
    PriceType,
    getOptionValueDisplayName,
    getPostDiscountAddonsTotal,
    getProductPrice,
} from "../../../utils/catalogue";
import format from "../../../utils/format";
import { notEmpty } from "../../../utils/functional";
import { Dinero, ZERO, getDinero } from "../../../utils/money";
import { urls } from "../../../utils/urls";

import styles from "./BasketLineBase.module.scss";

export interface IBasketLineBaseProps<T = IBasketLine | IWishListLine> {
    line: T;
    shippingMethodCode?: string;
}

const listOptions = (product: IProduct) => {
    const attributes = product.attributes;
    const optionKeys = attributes.product_options?.value || [];
    return optionKeys
        .map((key) => {
            const attr = attributes[key];
            if (!attr) {
                return;
            }
            return {
                key: key,
                name: attr.name,
                value: attr.value,
            };
        })
        .filter(notEmpty);
};

interface IBasketLineImageProps {
    product: IProduct;
}

export const BasketLineImage = (props: IBasketLineImageProps) => {
    // Use the product image if one exists else try the parent image
    let image = props.product.images.slice().shift();
    if (!image && props.product.parent) {
        image = props.product.parent.images.slice().shift();
    }
    if (!image) {
        return null;
    }
    const onGoToProduct = () => {
        if (!props.product.link) {
            return;
        }
        urls.navigateToURL(props.product.link);
    };

    // Refs #22223 - Prevents rendering multiple link wrappers to the same location
    // to avoid ADA issues. If parent link exists, renders image link wrapper.
    if (props.product.parent?.link) {
        return (
            <Link
                className={styles.link}
                href={props.product.parent?.link}
                target="_top"
            >
                <Image
                    className={styles.image}
                    alt={t`Learn more about ${props.product.title}`}
                    src={image.original}
                />
            </Link>
        );
    }

    return (
        <div className={styles.link} onClick={onGoToProduct}>
            <Image className={styles.image} alt="" src={image.original} />
        </div>
    );
};

interface IBasketLineOfferDetailsProps {
    line: IBasketLine;
}

interface IBasketLineOfferDetailsState {
    isSavingsInfoExpanded: boolean;
}

export class BasketLineOfferDetails extends React.Component<
    IBasketLineOfferDetailsProps,
    IBasketLineOfferDetailsState
> {
    state: IBasketLineOfferDetailsState = {
        isSavingsInfoExpanded: false,
    };

    private readonly onAccordionClick = (
        event: React.MouseEvent<HTMLElement>,
    ) => {
        if (event) {
            event.preventDefault();
            event.currentTarget.classList.toggle(styles.buttonExpanded);
        }
        this.setState((s) => {
            return {
                isSavingsInfoExpanded: !s.isSavingsInfoExpanded,
            };
        });
    };

    private getTotalBeforeDiscounts() {
        let totalBeforeDiscount = getDinero(
            this.props.line.price_excl_tax_excl_discounts,
        );
        if (this.props.line.product.price && this.props.line.quantity) {
            totalBeforeDiscount = getProductPrice(
                this.props.line.product.price,
                {
                    priceType: PriceType.RETAIL,
                    includePostDiscountAddons: false,
                    quantity: this.props.line.quantity,
                },
            );
        }
        return totalBeforeDiscount;
    }

    private getTotalAfterDiscounts() {
        const linePriceExclTaxInclDiscounts = getDinero(
            this.props.line.line_price_excl_tax_incl_discounts,
        );
        // WE have to subtract the post-discount addons from the total line price
        // in order to display the total discount correctly (since
        // line_price_excl_tax_incl_discounts includes the add-on prices, but
        // the discount total should exclude the add-ons).
        const addonsTotal = getPostDiscountAddonsTotal(
            this.props.line.product.price,
            this.props.line.quantity,
        );
        return linePriceExclTaxInclDiscounts.subtract(addonsTotal);
    }

    private getDiscountDetailsTotal() {
        let detailedDiscounts = ZERO;
        if (this.props.line.discounts.length >= 0) {
            detailedDiscounts = this.props.line.discounts
                .map((discount) => {
                    return getDinero(discount.amount);
                })
                .reduce((total, amount) => {
                    return total.add(amount);
                }, ZERO);
        }
        return detailedDiscounts;
    }

    private getUnaccountedForDiscountAmount(
        totalEffectiveDiscount: ReturnType<typeof getDinero>,
    ) {
        const discountDetailsTotal = this.getDiscountDetailsTotal();
        return totalEffectiveDiscount.subtract(discountDetailsTotal);
    }

    render() {
        if (!this.props.line.price_excl_tax_excl_discounts) {
            return null;
        }
        const totalBeforeDiscount = this.getTotalBeforeDiscounts();
        const totalAfterDiscount = this.getTotalAfterDiscounts();
        const totalDiscount = totalBeforeDiscount.subtract(totalAfterDiscount);
        const difference = this.getUnaccountedForDiscountAmount(totalDiscount);
        if (totalDiscount.isZero() || totalDiscount.isNegative()) {
            return null;
        }
        const details = this.props.line.discounts.map((discount, i) => {
            return (
                <div key={i} className={styles.savingsDetailItem}>
                    <span>{discount.voucher_code || discount.offer_name}</span>
                    <div className={styles.priceDiscount}>
                        -{format.money(discount.amount)}
                    </div>
                </div>
            );
        });
        if (difference.isPositive() && !difference.isZero()) {
            details.unshift(
                <div key={details.length} className={styles.savingsDetailItem}>
                    {t`Discount`}-{format.money(difference)}
                </div>,
            );
        }
        const savingsDetailID = `basket-line-savings-details-${this.props.line.product.id}`;
        return (
            <div className={styles.basketLineSavings}>
                <div className={styles.savingsSummary}>
                    <button
                        className={styles.button}
                        aria-controls={savingsDetailID}
                        onClick={this.onAccordionClick}
                        aria-expanded={this.state.isSavingsInfoExpanded}
                    >
                        {t`Total Savings`}
                    </button>
                    <div className={styles.priceDiscount}>
                        -{format.money(totalDiscount)}
                    </div>
                </div>
                {this.state.isSavingsInfoExpanded && (
                    <div id={savingsDetailID} className={styles.savingsDetails}>
                        {details}
                    </div>
                )}
            </div>
        );
    }
}

interface IBasketLinePriceProps {
    line?: IBasketLine;
    unitPrice: IPrice;
    linePriceInclDiscounts: Dinero;
    postDiscountAddons: IPostDiscountAddons | undefined;
    quantity: number;
    isMinimal?: boolean;
}

export const BasketLinePrice = (props: IBasketLinePriceProps) => {
    const retailPrice = getProductPrice(props.unitPrice, {
        priceType: PriceType.RETAIL,
        includePostDiscountAddons: false,
        quantity: props.quantity,
    });
    if (retailPrice.equalsTo(props.linePriceInclDiscounts)) {
        return (
            <div className={styles.priceRegular}>
                {format.money(props.linePriceInclDiscounts)}
            </div>
        );
    }
    const postDiscountAddons = props.postDiscountAddons || [];
    const qty = props.line ? props.line.quantity : 1;
    return (
        <>
            <span className="ada-screenreader-only">{t`Original price:`}</span>
            <div
                className={
                    retailPrice.greaterThan(props.linePriceInclDiscounts)
                        ? styles.priceStrikeThru
                        : styles.priceRegular
                }
            >
                {format.money(retailPrice)}
            </div>
            {props.line && !props.isMinimal && (
                <BasketLineOfferDetails line={props.line} />
            )}
            {postDiscountAddons.map((addon, i) => (
                <div key={i} className={styles.pricePostDiscountAddon}>
                    <span>{addon.name}</span>
                    <span>
                        {"+"}
                        {format.money(
                            getDinero(addon.price_excl_tax).multiply(qty),
                        )}
                    </span>
                </div>
            ))}
            <span className="ada-screenreader-only">{t`Current price:`}</span>
            <div
                className={
                    retailPrice.greaterThan(props.linePriceInclDiscounts)
                        ? styles.price
                        : styles.priceRegular
                }
            >
                {format.money(props.linePriceInclDiscounts)}
            </div>
        </>
    );
};

interface IBasketLineTitleProps {
    product: IProduct;
    additionalCSSClass?: string;
}

export const BasketLineTitle = (props: IBasketLineTitleProps) => {
    let title = props.product.parent
        ? props.product.parent.title
        : props.product.title;
    if (props.product.parent) {
        const titleOptions = listOptions(props.product)
            .filter((option) => option.key !== "option_color")
            .map((option) =>
                getOptionValueDisplayName(option.name, option.value),
            )
            .filter((name) => !!name)
            .join(", ");
        if (titleOptions) {
            title = title + ", " + titleOptions;
        }
    }
    return (
        <h2 className={`${styles.productTitle} ${props.additionalCSSClass}`}>
            <Link href={props.product.link} target="_top">
                {title}
            </Link>
        </h2>
    );
};

interface IBasketLineQuantityProps {
    quantity: number;
}

export const BasketLineQuantity = (props: IBasketLineQuantityProps) => {
    return (
        <div className="basket-line__details__qty">
            <p>
                {t`Qty:`} {props.quantity}
            </p>
        </div>
    );
};

interface IBasketLineWarrantyInfoProps {
    product: IProduct;
}

export const BasketLineWarrantyInfo = (props: IBasketLineWarrantyInfoProps) => {
    let warrantyText = props.product.attributes.warranty
        ? props.product.attributes.warranty.value
        : null;
    if (
        !warrantyText &&
        props.product.parent &&
        props.product.parent.attributes.warranty
    ) {
        warrantyText = props.product.parent.attributes.warranty.value;
    }
    if (!warrantyText) {
        return null;
    }
    return (
        <li key={"warranty"}>
            {t`Warranty:`} <strong>{`${warrantyText}`}</strong>
        </li>
    );
};

interface IBasketLineColorProps {
    product: IProduct;
}

export const BasketLineColorOption = (props: IBasketLineColorProps) => {
    const colorOption = listOptions(props.product).find((option) => {
        return option.key === "option_color";
    });
    const productColor = colorOption ? (
        <li key={colorOption.key}>
            <span>{colorOption.name}</span>:{" "}
            <strong>{colorOption.value}</strong>
        </li>
    ) : null;
    return productColor;
};

interface IBasketLineOptionsProps {
    product: IProduct;
}

export const BasketLineOptions = (props: IBasketLineOptionsProps) => {
    return (
        <ul className={styles.optionsList}>
            <BasketLineColorOption product={props.product} />
            <BasketLineWarrantyInfo product={props.product} />
        </ul>
    );
};

interface IBasketLineUpsellMessageProps {
    content: IBasketLineAdvertistingContent;
}
interface IBasketLineUpsellMessageState {
    isHidden: boolean;
}
export class BasketLineUpsellMessage extends React.Component<
    IBasketLineUpsellMessageProps,
    IBasketLineUpsellMessageState
> {
    state: IBasketLineUpsellMessageState = {
        isHidden: false,
    };

    private readonly onClose = () => {
        this.setState({
            isHidden: true,
        });
    };

    render() {
        return (
            <div
                className={styles.offerUpsellMessageOuter}
                style={{ display: this.state.isHidden ? "none" : "block" }}
            >
                <div className={styles.offerUpsellMessage}>
                    <button
                        className={styles.offerUpsellCloseButton}
                        aria-label={t`close`}
                        onClick={this.onClose}
                    >
                        <SVG
                            aria-hidden="true"
                            className={styles.offerUpsellCloseIcon}
                            src={iconXClose}
                            title={t`Close Icon`}
                        />
                    </button>
                    <RichText html={this.props.content.content} />
                </div>
            </div>
        );
    }
}
