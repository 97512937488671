import React from "react";
import { connect } from "react-redux";

import { BundleGroupTypes } from "../../../constants";
import { IConcreteBundle } from "../../../models/catalogue.interfaces";
import { IProductID } from "../../../models/nominals";
import { ICompareTile } from "../../../models/product-compare.interfaces";
import { Dispatchers } from "../../configurator/dispatchers";
import {
    baseVariantSelector,
    rootProductSelector,
    upgradeBundlesSelector,
} from "../../configurator/selectors";
import { TDispatchMapper, TStateMapper } from "../../reducers.interfaces";
import { defaults } from "../defaults";
import { ProductCompareUpgradeTile as ProductCompareUpgradeTileComponent } from "../elements/ProductCompareUpgradeTile";

interface IOwnProps {
    tile: ICompareTile;
}

interface IReduxProps {
    pageRootProduct: IProductID | null;
    selectedUpgrade: IProductID | null;
    upgradeBundles: IConcreteBundle[];
}

interface IDispatchProps {
    onUpgradeSelect: (productID: IProductID | null) => void;
}

interface IProps extends IOwnProps, IDispatchProps, IReduxProps {}

interface IState {}

class ProductCompareUpgradeTileContainer extends React.Component<
    IProps,
    IState
> {
    render() {
        const isSelected = this.props.selectedUpgrade ? true : false;
        return (
            <ProductCompareUpgradeTileComponent
                product={this.props.tile.rootProducts[0]}
                selectedUpgrades={
                    this.props.selectedUpgrade
                        ? [
                              {
                                  productID: this.props.selectedUpgrade,
                                  quantity: 1,
                              },
                          ]
                        : []
                }
                isSelected={isSelected}
                pageRootProduct={this.props.pageRootProduct}
                onSelectionChange={(updates) => {
                    if (updates.length > 0) {
                        const update = updates[0];
                        this.props.onUpgradeSelect(
                            update.quantity > 0 ? update.productID : null,
                        );
                    }
                }}
                upgradeBundles={this.props.upgradeBundles}
            />
        );
    }
}

const mapStateToProps: TStateMapper<"configurator", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    const state = rootState.configurator || defaults;
    const rootProduct = rootProductSelector(rootState.configurator);
    const baseVariant = baseVariantSelector(state);

    return {
        ...ownProps,
        pageRootProduct: rootProduct ? rootProduct.id : null,
        selectedUpgrade: state.ui.selectedUpgrade,
        upgradeBundles: upgradeBundlesSelector(
            state.entities.concreteBundles,
            baseVariant,
            BundleGroupTypes.UPGRADE_PRODUCT,
        ),
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const dispatchers = new Dispatchers(dispatch);

    return {
        onUpgradeSelect: (productID) => {
            dispatchers.setSelectedUpgrade(productID);
        },
    };
};

export const ProductCompareUpgradeTile = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ProductCompareUpgradeTileContainer);
