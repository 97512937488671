import React from "react";
import { t } from "ttag";

import { interpolate } from "@thelabnyc/thelabui/src/utils/i18n";

import { RatingGraphic } from "../../../common/RatingGraphic";
import { formatNumber } from "../../../utils/format";
import { ReviewsComponentTypes } from "../constants";

interface IProps {
    numReviews: number;
    averageRating: number;
    roundedRating: number;
    reviewsComponentType: ReviewsComponentTypes;
    starHasStroke?: boolean;
}

interface IState {}

export class ReviewStarRating extends React.PureComponent<IProps, IState> {
    render() {
        if (!this.props.numReviews || this.props.numReviews <= 0) {
            return null;
        }
        const numReviews = `${formatNumber(this.props.numReviews)}+ ${
            this.props.numReviews === 1 ? "REVIEW" : "REVIEWS"
        }`;
        let reviewNumber: JSX.Element | null = null;
        if (
            this.props.reviewsComponentType !== ReviewsComponentTypes.CAROUSEL
        ) {
            reviewNumber = (
                <div className="customer-review__number">
                    {numReviews}
                    <br />
                    {interpolate(t`AVERAGE OF %s %s`, [
                        `${this.props.roundedRating}`,
                        `${this.props.roundedRating === 1 ? "STAR" : "STARS"}`,
                    ])}
                </div>
            );
        } else {
            reviewNumber = (
                <div className="customer-review__number">
                    {interpolate(t`(%s OUT OF 5) %s`, [
                        `${this.props.roundedRating}`,
                        numReviews,
                    ])}
                </div>
            );
        }
        return (
            <div className="customer-review__star-rating">
                <div className="customer-review__stars">
                    <RatingGraphic
                        cardClass="product-reviews-header"
                        cardSize="large"
                        rating={this.props.averageRating}
                        onlyStars={true}
                        starHasStroke={this.props.starHasStroke}
                    />
                </div>
                {reviewNumber}
            </div>
        );
    }
}
