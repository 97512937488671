import * as t from "io-ts";
import { withFallback } from "io-ts-types/lib/withFallback";

import {
    ImageURL,
    ProductCategoryID,
    ProductID,
    SafeHTML,
    WagtailPageID,
    WebPageURL,
} from "./nominals";
import { Link as LinkSnippet } from "./streamfield-snippets";
import { NullNumber, nullable } from "./utils";

type LiteralValue = string | number | boolean;

export const StreamValue = <T extends LiteralValue, V extends t.Mixed>(
    type: T,
    value: V,
) => {
    return t.interface({
        id: withFallback(t.string, ""),
        type: t.literal(type),
        value: value,
    });
};

/**
 * See streamfield block: tsicommon.cms.blocks.sub_blocks.abstract.BooleanBlock
 */
export const BooleanBlock = t.boolean;

/**
 * See streamfield block: tsicommon.cms.blocks.sub_blocks.abstract.CharBlock
 */
export const CharBlock = t.string;

/**
 * See streamfield block: tsicommon.cms.blocks.sub_blocks.abstract.ChoiceBlock
 */
export const ChoiceBlock = t.string;

/**
 * See streamfield block: tsicommon.cms.blocks.sub_blocks.abstract.DateBlock
 */
export const DateBlock = t.string;

/**
 * See streamfield block: tsicommon.cms.blocks.sub_blocks.abstract.DecimalBlock
 */
export const DecimalBlock = t.string;

/**
 * See streamfield block: tsicommon.cms.blocks.sub_blocks.abstract.FloatBlock
 */
export const FloatBlock = t.number;

/**
 * See streamfield block: tsicommon.cms.blocks.sub_blocks.abstract.EmbedBlock
 */
export const EmbedBlock = SafeHTML;

/**
 * See streamfield block: tsicommon.cms.blocks.sub_blocks.abstract.ImageChooserBlock
 */
export const ImageChooserBlock = t.intersection([
    t.interface({
        title: t.string,
        width: t.number,
        height: t.number,
        url: ImageURL,
    }),
    t.partial({
        renditions: t.record(
            t.string,
            t.union([
                t.undefined,
                t.interface({
                    spec: t.string,
                    width: t.number,
                    height: t.number,
                    url: ImageURL,
                }),
            ]),
        ),
    }),
]);
export type ImageChooserBlock = t.TypeOf<typeof ImageChooserBlock>;

/**
 * See streamfield block: tsicommon.cms.blocks.sub_blocks.abstract.IntegerBlock
 */
export const IntegerBlock = t.number;

/**
 * See streamfield block: tsicommon.cms.blocks.sub_blocks.abstract.ListBlock
 */
export const ListBlock = t.array;

/**
 * See streamfield block: tsicommon.cms.blocks.sub_blocks.abstract.PageChooserBlock
 */
export const PageChooserBlock = t.interface({
    id: WagtailPageID,
    url: WebPageURL,
});
export type PageChooserBlock = t.TypeOf<typeof PageChooserBlock>;

/**
 * See streamfield block: tsicommon.cms.blocks.sub_blocks.abstract.RawHTMLBlock
 */
export const RawHTMLBlock = SafeHTML;

/**
 * See streamfield block: tsicommon.cms.blocks.sub_blocks.abstract.RegexBlock
 */
export const RegexBlock = t.string;

/**
 * See streamfield block: tsicommon.cms.blocks.sub_blocks.abstract.RichTextBlock
 */
export const RichTextBlock = SafeHTML;

/**
 * See streamfield block: tsicommon.cms.blocks.sub_blocks.abstract.SnippetChooserBlock
 */
export const SnippetChooserBlock = <T extends t.Mixed>(snip: T) => snip;

/**
 * See streamfield block: tsicommon.cms.blocks.sub_blocks.abstract.StaticBlock
 */
export const StaticBlock = SafeHTML;

/**
 * See streamfield block: tsicommon.cms.blocks.sub_blocks.abstract.StructBlock
 */
export const StructBlock = <
    T extends Parameters<typeof t.interface>[0],
    U extends Parameters<typeof t.partial>[0],
>(
    required: T,
    optional: U,
) => {
    return t.intersection([t.interface(required), t.partial(optional)]);
};

/**
 * See streamfield block: tsicommon.cms.blocks.sub_blocks.abstract.StreamBlock
 */
export const StreamBlock = <
    T extends Parameters<typeof t.union>[0],
    U extends Parameters<typeof t.union>[1],
>(
    codecs: T,
    name?: U,
) => {
    return t.array(t.union(codecs, name));
};

/**
 * See streamfield block: tsicommon.cms.blocks.sub_blocks.abstract.TextBlock
 */
export const TextBlock = t.string;

/**
 * See streamfield block: tsicommon.cms.blocks.sub_blocks.abstract.URLBlock
 */
export const URLBlock = WebPageURL;

/**
 * See streamfield block: tsicommon.cms.blocks.sub_blocks.background_styles.BackgroundStylesSubBlock
 */
export const BackgroundStylesSubBlock = StructBlock(
    {},
    {
        style: nullable(ChoiceBlock),
        background_image: nullable(ImageChooserBlock),
    },
);

/**
 * See streamfield block: tsicommon.cms.blocks.sub_blocks.choosers.ProductAttributeChoiceBlock
 */
export const ProductAttributeChoiceBlock = t.string;

/**
 * See streamfield block: tsicommon.cms.blocks.sub_blocks.choosers.ProductOptionValueChoiceBlock
 */
export const ProductOptionValueChoiceBlock = t.string;

/**
 * See streamfield block: tsicommon.cms.blocks.sub_blocks.choosers.ProductChooserSubBlock
 */
export const ProductChooserSubBlock = ProductID;

/**
 * See streamfield block: tsicommon.cms.blocks.sub_blocks.choosers.CategoryChooserSubBlock
 */
export const CategoryChooserSubBlock = t.interface({
    id: ProductCategoryID,
    name: t.string,
});
export type CategoryChooserSubBlock = t.TypeOf<typeof CategoryChooserSubBlock>;

/**
 * See streamfield block: tsicommon.cms.blocks.sub_blocks.choosers.BundleGroupChooserSubBlock
 */
export const BundleGroupChooserSubBlock = t.interface({
    id: NullNumber,
    suggested_products_info: nullable(
        t.array(
            t.interface({
                product_id: ProductID,
                shipping_class_name: t.string,
            }),
        ),
    ),
});

/**
 * See streamfield block: tsicommon.cms.blocks.sub_blocks.spacing.SpacingSubBlock
 */
export const SpacingSubBlock = StructBlock(
    {},
    {
        top_spacing: nullable(ChoiceBlock),
        bottom_spacing: nullable(ChoiceBlock),
    },
);

/**
 * See streamfield block: tsicommon.cms.blocks.sub_blocks.flair.FlairSubBlock
 */
export const FlairSubBlock = StructBlock(
    {},
    {
        style: nullable(ChoiceBlock),
        content: nullable(RichTextBlock),
    },
);

/**
 * See streamfield block: tsicommon.cms.models.variants.VariantHeroBlock
 */
export const VariantHeroBlock = StructBlock(
    {
        attribute: ProductAttributeChoiceBlock,
        value: ProductOptionValueChoiceBlock,
        image: ImageChooserBlock,
    },
    {
        product: nullable(ProductChooserSubBlock),
        mobile_image: nullable(ImageChooserBlock),
    },
);
export type VariantHeroBlock = t.TypeOf<typeof VariantHeroBlock>;

/**
 * See streamfield block: tsicommon.cms.models.variants.VariantDescriptionBlock
 */
export const VariantDescriptionBlock = StructBlock(
    {
        product: ProductChooserSubBlock,
    },
    {
        description: nullable(CharBlock),
        preorder_shipping_description: nullable(CharBlock),
    },
);
export type VariantDescriptionBlock = t.TypeOf<typeof VariantDescriptionBlock>;

/**
 * See streamfield block: tsicommon.cms.blocks.tab_cards._ComparisonCardProduct
 */
export const ComparisonCardProduct = StructBlock(
    {
        show_flair: BooleanBlock,
        card_flair: FlairSubBlock,
        card_title: RichTextBlock,
        card_image: ImageChooserBlock,
        card_image_mobile: nullable(ImageChooserBlock),
        card_copy: RichTextBlock,
        card_product: ProductChooserSubBlock,
    },
    {},
);
export interface IComparisonCardProduct
    extends t.TypeOf<typeof ComparisonCardProduct> {}

/**
 * See streamfield block: tsicommon.cms.blocks.tab_cards._ComparisonCardCategory
 */
export const ComparisonCardCategory = StructBlock(
    {
        show_flair: BooleanBlock,
        card_flair: FlairSubBlock,
        card_title: RichTextBlock,
        card_image: ImageChooserBlock,
        card_image_mobile: nullable(ImageChooserBlock),
        card_copy: RichTextBlock,
        card_category: CategoryChooserSubBlock,
    },
    {},
);
export interface IComparisonCardCategory
    extends t.TypeOf<typeof ComparisonCardCategory> {}

/**
 * See streamfield block: tsicommon.cms.blocks.tab_cards._ComparisonCardAttribute
 */
export const ComparisonCardAttribute = StructBlock(
    {
        show_flair: BooleanBlock,
        card_flair: FlairSubBlock,
        card_title: RichTextBlock,
        card_image: ImageChooserBlock,
        card_image_mobile: nullable(ImageChooserBlock),
        card_copy: RichTextBlock,
        card_attribute: ProductAttributeChoiceBlock,
        card_attribute_value: ProductOptionValueChoiceBlock,
    },
    {},
);
export interface IComparisonCardAttribute
    extends t.TypeOf<typeof ComparisonCardAttribute> {}

/**
 * See streamfield block: tsicommon.cms.blocks.tab_cards._ComparisonTabProduct
 */
export const ComparisonTabProduct = StructBlock(
    {
        tab_name: RichTextBlock,
        tab_support_copy: CharBlock,
        tab_cards: ListBlock(ComparisonCardProduct),
    },
    {},
);
export interface IComparisonTabProduct
    extends t.TypeOf<typeof ComparisonTabProduct> {}

/**
 * See streamfield block: tsicommon.cms.blocks.tab_cards._ComparisonTabCategory
 */
export const ComparisonTabCategory = StructBlock(
    {
        tab_name: RichTextBlock,
        tab_support_copy: CharBlock,
        tab_cards: ListBlock(ComparisonCardCategory),
    },
    {},
);
export interface IComparisonTabCategory
    extends t.TypeOf<typeof ComparisonTabCategory> {}

/**
 * See streamfield block: tsicommon.cms.blocks.tab_cards._ComparisonTabAttribute
 */
export const ComparisonTabAttribute = StructBlock(
    {
        tab_name: RichTextBlock,
        tab_support_copy: CharBlock,
        tab_cards: ListBlock(ComparisonCardAttribute),
    },
    {},
);
export interface IComparisonTabAttribute
    extends t.TypeOf<typeof ComparisonTabAttribute> {}

/**
 * See streamfield block: tsicommon.cms.blocks.tab_cards.TabCardsBlock
 */
export const TabCardsBlock = StructBlock(
    {
        hide_tabs: BooleanBlock,
        theme: t.partial({
            theme: t.string,
        }),
        spacing: SpacingSubBlock,
        header_content_desktop: RichTextBlock,
        header_content_mobile: RichTextBlock,
        aria_label: CharBlock,
        tabs: StreamBlock([
            StreamValue("tab_product", ComparisonTabProduct),
            StreamValue("tab_category", ComparisonTabCategory),
            StreamValue("tab_attribute", ComparisonTabAttribute),
        ]),
    },
    {},
);
export interface ITabCardsBlock extends t.TypeOf<typeof TabCardsBlock> {}

/**
 * See streamfield block: tsicommon.cms.blocks.product_upsell_modal._UpsellSlide
 */
export const ProductUpsellModalBlockSlide = StructBlock(
    {
        image: ImageChooserBlock,
    },
    {
        slide_bundle: BundleGroupChooserSubBlock,
        slide_cta: CharBlock,
        slide_link: nullable(SnippetChooserBlock(LinkSnippet)),
        initial_mobile_slide: BooleanBlock,
    },
);
export type ProductUpsellModalBlockSlide = t.TypeOf<
    typeof ProductUpsellModalBlockSlide
>;

/**
 * See streamfield block: tsicommon.cms.blocks.product_upsell_modal.ProductUpsellModalBlock
 */
export const ProductUpsellModalBlock = StructBlock(
    {},
    {
        header_title: CharBlock,
        header_copy: CharBlock,
        header_title_mobile: CharBlock,
        header_copy_mobile: CharBlock,
        slides: ListBlock(ProductUpsellModalBlockSlide),
        cta_copy: CharBlock,
    },
);
export type ProductUpsellModalBlock = t.TypeOf<typeof ProductUpsellModalBlock>;

/**
 * See streamfield block: tsicommon.cms.blocks.sub_blocks.collection_name_styles.CollectionNameStylesSubBlock
 */
export const CollectionNameStylesSubBlock = StructBlock(
    {},
    {
        style: nullable(ChoiceBlock),
    },
);
