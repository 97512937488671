import { combineReducers } from "@reduxjs/toolkit";

import { IAddressCountry } from "../../models/address.interfaces";
import { guardReducer } from "../../utils/redux";
import { Action } from "./constants";
import {
    IBasketSearchResults,
    ICustomerSearchResults,
    ICustomerSummary,
    IViewStack,
} from "./models.interfaces";
import {
    IActionClearVoucherSearch,
    IActionSetBasketSearchErrors,
    IActionSetBasketSearchResults,
    IActionSetBasketSearchTerm,
    IActionSetCountries,
    IActionSetCurrentlyAssistedBasket,
    IActionSetCurrentlyAssistedCustomer,
    IActionSetCustomerSearchResults,
    IActionSetCustomerSearchTerm,
    IActionSetVoucherSearchErrors,
    IActionSetVoucherSearchResults,
    IActionViewStackClear,
    IActionViewStackPop,
    IActionViewStackPush,
    IActionViewStackReplaceTop,
    IActionViewStackSetRoot,
    IReduxState,
} from "./reducers.interfaces";

const countriesDefault: IAddressCountry[] = [];

const customerSearchDefault: ICustomerSearchResults = {
    total: 0,
    page: 1,
    hasNext: false,
    hasPrev: false,
    results: [],
    term: "",
};

const basketSearchDefault: IBasketSearchResults = {
    term: "",
    error: "",
    basket: null,
    owner: null,
};

const voucherSearchDefault: IReduxState["voucherSearch"] = {
    count: 0,
    next: null,
    previous: null,
    results: [],
};

const assistingDefault: IReduxState["assisting"] = {
    customer: null,
    basket: null,
};

const viewsDefault: IViewStack = {
    stack: [],
};

const countriesReducer = guardReducer(
    Action,
    countriesDefault,
    (state = countriesDefault, action: IActionSetCountries) => {
        switch (action.type) {
            case Action.SET_COUNTRIES:
                return action.countries;
        }
        return state;
    },
);

const customerSearchReducer = guardReducer(
    Action,
    customerSearchDefault,
    (
        state = customerSearchDefault,
        action: IActionSetCustomerSearchResults | IActionSetCustomerSearchTerm,
    ) => {
        let newState: ICustomerSearchResults;
        switch (action.type) {
            case Action.SET_CUSTOMER_SEARCH_RESULTS:
                newState = {
                    ...state,
                    total: action.total,
                    page: action.page,
                    hasNext: action.hasNext,
                    hasPrev: action.hasPrev,
                    results: ([] as ICustomerSummary[]).concat(
                        action.customers,
                    ),
                };
                return newState;
            case Action.SET_CUSTOMER_SEARCH_TERM:
                newState = { ...state, term: action.term };
                return newState;
        }
        return state;
    },
);

const basketSearchReducer = guardReducer(
    Action,
    basketSearchDefault,
    (
        state = basketSearchDefault,
        action:
            | IActionSetBasketSearchResults
            | IActionSetBasketSearchTerm
            | IActionSetBasketSearchErrors,
    ) => {
        switch (action.type) {
            case Action.SET_BASKET_SEARCH_RESULTS:
                return {
                    ...state,
                    basket: action.basket,
                    owner: action.owner,
                    error: "",
                };
            case Action.SET_BASKET_SEARCH_TERM:
                return { ...state, term: action.term };
            case Action.SET_BASKET_SEARCH_ERRORS:
                return {
                    ...state,
                    basket: null,
                    owner: null,
                    error: action.error,
                };
        }
        return state;
    },
);

const assistingReducer = guardReducer(
    Action,
    assistingDefault,
    (
        state: IReduxState["assisting"] = assistingDefault,
        action:
            | IActionSetCurrentlyAssistedCustomer
            | IActionSetCurrentlyAssistedBasket,
    ) => {
        switch (action.type) {
            case Action.SET_CURRENTLY_ASSISTED_CUSTOMER:
                return { ...state, customer: action.user };
            case Action.SET_CURRENTLY_ASSISTED_BASKET:
                return { ...state, basket: action.basket };
        }
        return state;
    },
);

const voucherSearchReducer = guardReducer(
    Action,
    voucherSearchDefault,
    (
        state: IReduxState["voucherSearch"] = voucherSearchDefault,
        action:
            | IActionSetVoucherSearchResults
            | IActionSetVoucherSearchErrors
            | IActionClearVoucherSearch,
    ): IReduxState["voucherSearch"] => {
        switch (action.type) {
            case Action.SET_VOUCHER_SEARCH_RESULTS:
            case Action.SET_VOUCHER_SEARCH_ERRORS:
                return action.payload;
            case Action.CLEAR_VOUCHER_SEARCH:
                return voucherSearchDefault;
        }
        return state;
    },
);

const viewsReducer = guardReducer(
    Action,
    viewsDefault,
    (
        state = viewsDefault,
        action:
            | IActionViewStackClear
            | IActionViewStackSetRoot
            | IActionViewStackPush
            | IActionViewStackPop
            | IActionViewStackReplaceTop,
    ) => {
        switch (action.type) {
            case Action.VIEW_STACK_CLEAR:
                return {
                    stack: [],
                };
            case Action.VIEW_STACK_SET_ROOT:
                return {
                    stack: [
                        {
                            name: action.name,
                            component: action.component,
                        },
                    ],
                };
            case Action.VIEW_STACK_PUSH:
                return {
                    stack: state.stack.concat([
                        {
                            name: action.name,
                            component: action.component,
                        },
                    ]),
                };
            case Action.VIEW_STACK_POP:
                return {
                    stack:
                        state.stack.length >= 1 ? state.stack.slice(0, -1) : [],
                };
            case Action.VIEW_STACK_REPLACE_TOP:
                return {
                    stack: (state.stack.length >= 1
                        ? state.stack.slice(0, -1)
                        : []
                    ).concat([
                        {
                            name: action.name,
                            component: action.component,
                        },
                    ]),
                };
        }

        return state;
    },
);

export const reducers = combineReducers({
    countries: countriesReducer,
    customerSearch: customerSearchReducer,
    basketSearch: basketSearchReducer,
    voucherSearch: voucherSearchReducer,
    assisting: assistingReducer,
    views: viewsReducer,
});
