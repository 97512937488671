import React from "react";
import SVG from "react-inlinesvg";
import Modal from "react-modal";
import { t } from "ttag";

import iconCircleClose from "../../../../img/icons/circle-close.svg";
import { IWebPageURL } from "../../../models/nominals";
import { ICompareTile } from "../../../models/product-compare.interfaces";
import { focusElement } from "../../../utils/keyboardFocus";

interface IProps {
    selectedTiles: ICompareTile[];
    isModalVisible: boolean;
    compareAllURI: string;
    productComparePageURL: IWebPageURL | null;
    onCloseModalClick: () => void;
}

interface IState {}

export class CompareAllModal extends React.Component<IProps, IState> {
    private buildCompareNowURI(baseURL: IWebPageURL) {
        const tileIDs = this.props.selectedTiles.map(
            (tile) => tile.product_compare_tile.id,
        );
        const returnURL = encodeURIComponent(window.location.pathname);
        const ids = encodeURIComponent(tileIDs.join(","));
        return `${baseURL}?tiles=${ids}&return_url=${returnURL}`;
    }

    render() {
        return (
            <Modal
                aria={{ modal: true }}
                contentLabel={t`Compare All Verification`}
                className="product-compare-modal"
                isOpen={this.props.isModalVisible}
                onAfterOpen={() => {
                    focusElement(".product-compare-modal button");
                }}
                role="dialog"
            >
                <button
                    aria-label="Close"
                    type="button"
                    onClick={this.props.onCloseModalClick}
                    className="al-comparisonpopup-closeoutX"
                >
                    <SVG
                        aria-hidden="true"
                        className=""
                        src={iconCircleClose}
                    />
                </button>
                <h2>{t`You have selected 4`}</h2>
                <h2>{t`mattresses to compare`}</h2>

                <p>{t`Would you like to compare all mattresses?`}</p>

                <nav>
                    {this.props.productComparePageURL && (
                        <a
                            href={this.props.compareAllURI}
                            className="button button--inverse al-comparisonpopup-compareall"
                        >
                            {t`Compare All`}
                        </a>
                    )}
                    {this.props.productComparePageURL && (
                        <a
                            href={this.buildCompareNowURI(
                                this.props.productComparePageURL,
                            )}
                            className="button al-comparisonpopup-compareselected"
                        >
                            {t`Compare Selected`}
                        </a>
                    )}
                </nav>
            </Modal>
        );
    }
}
