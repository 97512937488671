import { format as formatDate } from "date-fns";
import React from "react";
import { connect } from "react-redux";
import { t } from "ttag";

import { TStateMapper } from "../../reducers.interfaces";
import {
    PredictedDeliveryDateStatus,
    PreferredDeliveryDateDisplayType,
} from "../constants";
import { IDeliveryDatePredictionState } from "../reducers.interfaces";
import { PredictedDeliveryLoader } from "./PredictedDeliveryLoader";

import styles from "./PreferredDeliveryDate.module.scss";

const NO_PREFERENCE = "";

interface IOwnProps {
    type: PreferredDeliveryDateDisplayType;
    savePreferredDeliveryDate: (date: Date | null) => Promise<void>;
    fallbackDescription: string;
    preferredDeliveryContent?: React.ReactNode;
}

interface IReduxProps {
    prediction: IDeliveryDatePredictionState;
    preferredDate: Date | null;
}

interface IProps extends IOwnProps, IReduxProps {}

interface IState {}

const getSlots = (
    type: PreferredDeliveryDateDisplayType,
    prediction: IDeliveryDatePredictionState,
): Date[] => {
    if (prediction.status !== PredictedDeliveryDateStatus.LOADED) {
        return [];
    }
    const slots =
        type === PreferredDeliveryDateDisplayType.SHOW_AGGRESSIVE
            ? prediction.data.slots_aggressive.map((d) => new Date(d))
            : prediction.data.slots_conservative.map((d) => new Date(d));
    return slots;
};

const formatDateOptionLabel = (dt: Date) => {
    return formatDate(dt, "EEEE, MMM do, yyyy");
};

const formatDateOptionValue = (dt: Date) => {
    return dt.toISOString();
};

const PDDOption = (props: { option: Date }) => {
    const label = formatDateOptionLabel(props.option);
    const value = formatDateOptionValue(props.option);
    return <option value={value}>{label}</option>;
};

const PDDNullOption = () => {
    return (
        <option value={NO_PREFERENCE}>{t`Select Another Delivery Date`}</option>
    );
};

const PDDOptionList = (props: IProps) => {
    const slots = getSlots(props.type, props.prediction);
    const options = slots.map((option) => (
        <PDDOption key={option.toISOString()} option={option} />
    ));
    options.push(<PDDNullOption key={NO_PREFERENCE} />);
    return <>{options}</>;
};

class PreferredDeliveryDateComponent extends React.Component<IProps, IState> {
    private readonly onSelectChange = (
        e: React.FormEvent<HTMLSelectElement | HTMLInputElement>,
    ) => {
        e.preventDefault();
        const val = e.currentTarget.value;
        const pref = val === NO_PREFERENCE ? null : new Date(val);
        this.props.savePreferredDeliveryDate(pref);
    };

    componentDidMount() {
        if (
            this.props.prediction.status ===
                PredictedDeliveryDateStatus.LOADED &&
            this.props.type !== PreferredDeliveryDateDisplayType.DISABLED
        ) {
            // On first mount, default to the first delivery slot.
            this.setDefaultDeliveryDate();
        }
    }

    componentDidUpdate(prevProps: IProps) {
        // In case default delivery date isn't set on the first mount,
        // set it once the status is LOADED for the first time.
        if (
            this.props.type !== PreferredDeliveryDateDisplayType.DISABLED &&
            prevProps.prediction.status ===
                PredictedDeliveryDateStatus.LOADING &&
            this.props.prediction.status ===
                PredictedDeliveryDateStatus.LOADED &&
            !this.props.preferredDate
        ) {
            this.setDefaultDeliveryDate();
        }
    }

    private readonly setDefaultDeliveryDate = () => {
        const slots = getSlots(this.props.type, this.props.prediction);
        if (slots.length > 0) {
            this.props.savePreferredDeliveryDate(slots[0]);
        }
    };

    private readonly preferredDeliveryText = () => {
        if (this.props.preferredDeliveryContent) {
            return this.props.preferredDeliveryContent;
        } else if (!this.props.preferredDate) {
            return "Need a later date? A local delivery agent will call you to schedule your in-home delivery on a day that's convenient for you.";
        }
        return "Please Note: We will do our best to accommodate your request. A local delivery agent will call you to confirm your delivery date and time.";
    };

    render() {
        const isPredictedDataReady = !(
            this.props.type === PreferredDeliveryDateDisplayType.DISABLED ||
            this.props.prediction.status ===
                PredictedDeliveryDateStatus.INITIAL ||
            this.props.prediction.status ===
                PredictedDeliveryDateStatus.ERROR ||
            this.props.prediction.status === PredictedDeliveryDateStatus.LOADING
        );
        return (
            <>
                <PredictedDeliveryLoader />
                {isPredictedDataReady ? (
                    <div>
                        <label
                            className={styles.container}
                            htmlFor="preferred-delivery-date"
                        >
                            <span className={styles.label}>
                                Preferred Delivery Date
                            </span>
                            <select
                                id="preferred-delivery-date"
                                value={
                                    this.props.preferredDate
                                        ? formatDateOptionValue(
                                              this.props.preferredDate,
                                          )
                                        : NO_PREFERENCE
                                }
                                onChange={this.onSelectChange}
                                className={styles.select}
                            >
                                <PDDOptionList {...this.props} />
                            </select>
                        </label>
                        {this.preferredDeliveryText()}
                    </div>
                ) : (
                    <p>{this.props.fallbackDescription}</p>
                )}
            </>
        );
    }
}

const mapStateToProps: TStateMapper<"checkout", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    return {
        prediction: rootState.checkout.data.predicted_delivery_date,
        preferredDate: rootState.checkout.data.preferred_date
            ? new Date(rootState.checkout.data.preferred_date)
            : null,
        ...ownProps,
    };
};

export const PreferredDeliveryDate = connect(mapStateToProps)(
    PreferredDeliveryDateComponent,
);
