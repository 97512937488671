import { createRoot } from "react-dom/client";

import { IS_PUPPETEER } from "./environment";
import { markPerfTiming } from "./performance";

const nodeIsElemment = (node: Node): node is HTMLElement => {
    return node.nodeType === 1;
};

/**
 * Finds all current and future elements matching the given selector and calls the callback
 * function for each element found.
 */
export const decorateElements = async (
    selector: string,
    cb: (elemBatch: HTMLElement[]) => void,
) => {
    // Generate a unique ID for this decorator instance
    const yieldHistory: Set<HTMLElement> = new Set();

    const yieldBatch = (elemBatch: HTMLElement[]) => {
        // Filter out elements that have already been decorated
        elemBatch = elemBatch.filter((elem) => {
            return !yieldHistory.has(elem);
        });
        // Tag each element so that we don't decorate it again
        for (const elem of elemBatch) {
            yieldHistory.add(elem);
        }
        // Send the batch
        cb(elemBatch);
    };

    // Decorate all the elements that already exist in the DOM
    yieldBatch(Array.from(document.querySelectorAll<HTMLElement>(selector)));

    // Build a mutation observer to watch for any new elements added to the DOM
    const observer = new MutationObserver((mutationsList) => {
        for (const mutation of mutationsList) {
            const addedNodes = Array.from(mutation.addedNodes);
            for (const node of addedNodes) {
                if (nodeIsElemment(node)) {
                    const elem = node.parentElement || node;
                    yieldBatch(
                        Array.from(
                            elem.querySelectorAll<HTMLElement>(selector),
                        ),
                    );
                }
            }
        }
    });

    // Start observing the target node for configured mutations
    observer.observe(document.body, {
        attributes: true,
        childList: true,
        subtree: true,
    });
};

/**
 * Returns a function which can be called with a batch of elements. When one of
 * those elements becomes visible on the page, it calls a callback with the
 * visible element as a parameter.
 */
const whenVisible = (cb: (elem: HTMLElement) => void) => {
    const options: IntersectionObserverInit = {
        rootMargin: "100px 50px",
    };
    return (elems: HTMLElement[]) => {
        // Keep a history of elements we've already triggered so that we don't trigger them again.
        const yieldHistory: Set<HTMLElement> = new Set();
        const yieldElem = (elem: HTMLElement) => {
            if (yieldHistory.has(elem)) {
                return;
            }
            yieldHistory.add(elem);
            cb(elem);
        };
        // CB to be called when an elements visibility changes
        const onVisChange: IntersectionObserverCallback = (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    yieldElem(entry.target as HTMLElement);
                    observer.unobserve(entry.target);
                }
            });
        };
        // Observe each element's visibility
        const observer = new IntersectionObserver(onVisChange, options);
        elems.forEach((elem) => {
            // yield elem immediately upon becoming visible
            observer.observe(elem);
            // once the page is idle, slowly preload other not-yet-visible elements
            window.requestIdleCallback(() => {
                yieldElem(elem);
            });
        });
    };
};

/**
 * Render a react component on any match of a selector.
 */
export const placeComponent = (
    selector: string,
    component: JSX.Element,
    lazy = true,
) => {
    const mount = async (elem: HTMLElement) => {
        markPerfTiming("placeComponent.start", selector);
        const root = createRoot(elem);
        root.render(component);
        markPerfTiming("placeComponent.done", selector);
    };
    const mountBatch =
        lazy && !IS_PUPPETEER && window.IntersectionObserver
            ? whenVisible(mount)
            : (elems: HTMLElement[]) => {
                  elems.forEach(mount);
              };
    decorateElements(selector, mountBatch);
};

/**
 * Same as placeComponent, but instead of accepting a JSX fragment directly, it accepts
 * an asynchronous function which returns JSX. This allows placing a component whose code
 * is dynamically loaded if and only if the related DOM element exists.
 */
export const dynamicPlaceComponent = async (
    selector: string,
    renderJSX: (elem: HTMLElement) => Promise<JSX.Element | null>,
    lazy = true,
) => {
    const mount = async (elem: HTMLElement) => {
        markPerfTiming("dynamicPlaceComponent.start", selector);
        const component = await renderJSX(elem);
        if (component) {
            markPerfTiming("dynamicPlaceComponent.render", selector);
            const root = createRoot(elem);
            root.render(component);
            markPerfTiming("dynamicPlaceComponent.done", selector);
        }
    };
    const mountBatch =
        lazy && !IS_PUPPETEER && window.IntersectionObserver
            ? whenVisible(mount)
            : (elems: HTMLElement[]) => {
                  elems.forEach(mount);
              };
    decorateElements(selector, mountBatch);
};
