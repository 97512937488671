import * as NavigationMenu from "@radix-ui/react-navigation-menu";
import React, { useState } from "react";

import { RichNavigationBannerContentAssembly, templates } from "@reactivated";

import {
    StreamField,
    StreamFieldBlockMap,
} from "@thelabnyc/thelabui/src/components/StreamField";

import { BannerBlock } from "../../../blocks/BannerBlock";
import { useCurrentBreakpoint } from "../../../utils/hooks";
import { getPageSetting } from "../../../utils/settings";
import { useIsSSR } from "../../../utils/ssr";
import { RichNavigationLink } from "../RichNavigationLink";
import { RichNavigationLinkCollection } from "../RichNavigationLinkCollection";

import styles from "./index.module.scss";

export type LinkCollection = { id: number; isOpen: boolean };

export const bannerContentAssemblyComponents: StreamFieldBlockMap<RichNavigationBannerContentAssembly> =
    {
        banner: BannerBlock,
    };

export const RichNavigation = (
    props: templates.RichNavigationTemplate["rich_navigation"],
) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const viewport = useCurrentBreakpoint();
    const isSSR = useIsSSR();
    // Create an Array for open/close states of link collections on mobile
    const linkCollections: LinkCollection[] = props?.content
        ? props.content.value.map((_, i: number) => ({ id: i, isOpen: false }))
        : [];
    const [openMobileContent, setOpenMobileContent] =
        useState<LinkCollection[]>(linkCollections);
    // For now, the React-powered RichNavigation only appears on Desktop -@zlebar
    // Except for Cocoon nav header.
    // With the SSR migration of Cocoon SiteHeaderTemplate
    // first time, RichNavigation has been used and tested in mobile screens
    if (
        viewport.belowMobile &&
        !isSSR &&
        getPageSetting("app-slug") !== "cocoon"
    ) {
        return null;
    }
    if (!props || !props.content) {
        return null;
    }
    return (
        <NavigationMenu.Root
            className={styles.root}
            onValueChange={(val: string) =>
                val ? setIsOpen(true) : setIsOpen(false)
            }
        >
            <NavigationMenu.List
                data-orientation={`${viewport.belowMobile ? "vertical" : "horizontal"}`}
                className={styles.list}
            >
                {props.content.value.map((block, i: number) => {
                    switch (block.type) {
                        case "rich_nav_link_collection":
                            return (
                                <NavigationMenu.Item key={i}>
                                    <RichNavigationLinkCollection
                                        collection={block}
                                        banner_content={
                                            props.banner_content &&
                                            !viewport.belowMobile
                                                ? props.banner_content.value
                                                : null
                                        }
                                        value_props={props.value_props}
                                        openMobileContent={openMobileContent}
                                        setOpenMobileContent={
                                            setOpenMobileContent
                                        }
                                        mobileContentIndex={i}
                                    />
                                </NavigationMenu.Item>
                            );
                        case "link":
                            return (
                                <NavigationMenu.Item key={i}>
                                    <RichNavigationLink {...block.value} />
                                </NavigationMenu.Item>
                            );
                        default:
                            return <></>;
                    }
                })}
            </NavigationMenu.List>
            {props.banner_content && viewport.belowMobile && (
                <StreamField
                    components={bannerContentAssemblyComponents}
                    value={props.banner_content.value}
                />
            )}
            {isOpen && <div className={styles.overlay}></div>}
        </NavigationMenu.Root>
    );
};
