import { IRetailStoreWithDistance } from "../../models/location.interfaces";
import { ISyncStoreID } from "../../models/nominals";
import { IStoreSearchFilters } from "./models.interfaces";

export enum Action {
    CLEAR_STORES = "Retail/CLEAR_STORES",
    ADD_STORES = "Retail/ADD_STORES",
    SET_STORES = "Retail/SET_STORES",
    UPDATE_STORE_FILTERS = "Retail/UPDATE_STORE_FILTERS",
    SET_FOCUSED_STORE = "Retail/SET_FOCUSED_STORE",
}

export interface IReduxState {
    // Store location data from the retail locator API
    storesLoadedKey: string;
    stores: IRetailStoreWithDistance[];

    // Filters used to limit which stores are displayed in the Retail Locator
    storeFilters: IStoreSearchFilters;

    // `external_id` of the store which is currently focused
    focusedStoreID: ISyncStoreID | null;
}

export interface IBaseAction {
    type: Action;
}

export interface IActionClearStores extends IBaseAction {
    type: Action.CLEAR_STORES;
}

export interface IActionAddStores extends IBaseAction {
    type: Action.ADD_STORES;
    payload: IRetailStoreWithDistance[];
}

export interface IActionSetStores extends IBaseAction {
    type: Action.SET_STORES;
    payload: {
        loadKey: string;
        stores: IRetailStoreWithDistance[];
    };
}

export interface IActionUpdateStoreFilters extends IBaseAction {
    type: Action.UPDATE_STORE_FILTERS;
    payload: Partial<IStoreSearchFilters>;
}

export interface IActionSetFocusedStore extends IBaseAction {
    type: Action.SET_FOCUSED_STORE;
    payload: {
        externalID: ISyncStoreID | null;
    };
}

export type IAction =
    | IActionClearStores
    | IActionAddStores
    | IActionSetStores
    | IActionUpdateStoreFilters
    | IActionSetFocusedStore;
