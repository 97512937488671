import * as t from "io-ts";

import {
    ProductUUID,
    ReviewsBrandID,
    ReviewsProductID,
    ReviewsProductTypeID,
    ReviewsProductVariantID,
} from "./nominals";
import { nullable } from "./utils";

export const SearchFacetSubOption = t.interface({
    name: t.union([t.string, t.number]),
    option_id: t.number,
    quantity: t.number,
});

export const SearchFacetOption = t.intersection([
    SearchFacetSubOption,
    t.interface({
        group_id: nullable(t.string),
        group_name: nullable(t.string),
        group_descr: nullable(t.string),
        group_sort_order: nullable(t.number),
        description: t.string,
        sub_option_type: nullable(
            t.keyof({
                text: null,
                rating: null,
                product_id: null,
                source_id: null,
                product_variant_id: null,
            }),
        ),
        sub_options: t.array(SearchFacetSubOption),
    }),
]);

export const SearchFacet = t.interface({
    id: t.number,
    type: t.keyof({
        text: null,
        rating: null,
        product_id: null,
        source_id: null,
        product_variant_id: null,
    }),
    is_conjunctive: t.boolean,
    options: t.array(SearchFacetOption),
});

export const SearchFacets = t.array(SearchFacet);

export const ReviewsBrand = t.interface({
    id: ReviewsBrandID,
    name: t.string,
    facets: t.array(t.number),
    num_reviews: t.number,
    average_rating: nullable(t.number),
});

export const Review = t.intersection([
    t.interface({
        id: t.number,

        product_id: ReviewsProductID,
        product_name: t.string,
        product_short_name: t.string,

        product_category_name: nullable(t.string),
        product_category_slug: nullable(t.string),
        product_category_sort_order: nullable(t.number),

        brand_id: ReviewsBrandID,
        brand_name: t.string,

        product_type_id: nullable(ReviewsProductTypeID),
        product_type_name: nullable(t.string),
        product_type_sort_order: nullable(t.number),
        product_variant_id: nullable(ReviewsProductVariantID),

        name: t.string,
        location: t.string,
        rating: t.number,
        headline: t.string,
        text: t.string,
        sleep_style: nullable(t.string),
        is_recommended: nullable(t.boolean),
        source_id: t.number,
        source_name: t.string,
        created_datetime: t.string,
        modified_datetime: t.string,
        disclosure_code: t.string,
    }),
    t.partial({
        product_uuid: nullable(ProductUUID), // Product UUID field doesn't exist if no core product is mapped
        product_variant_slug: nullable(t.string),
        product_variant_display_name: nullable(t.string),

        highlighted: t.string,

        is_staff_reviewer: t.boolean,
        is_verified_buyer: t.boolean,
        is_verified_reviewer: t.boolean,
    }),
]);

export const ReviewResultsPage = t.interface({
    count: t.number,
    next: nullable(t.string),
    previous: nullable(t.string),
    results: t.array(Review),
});

const WriteReviewTemplateFieldResponseType = t.keyof({
    text: null,
    multiple: null,
    single: null,
    numeric: null,
    email: null,
});

const WriteReviewTemplateFieldInputType = t.keyof({
    TextBox: null,
    TextArea: null,
    Stars: null,
    CheckboxVertical: null,
    RadioVertical: null,
    RadioHorizontal: null,
    RadioHorizontalLinked: null,
    Dropdown: null,
    AgeDisclosure: null,
});

const WriteReviewTemplateFieldType = t.keyof({
    simple: null,
    collection: null,
    composite: null,
});

export const WriteReviewTemplateField = t.interface({
    type: WriteReviewTemplateFieldResponseType,
    response_type: WriteReviewTemplateFieldResponseType,
    input_type: WriteReviewTemplateFieldInputType,
    field_id: nullable(t.string),
    field_type: WriteReviewTemplateFieldType,
    key: t.string,
    name: t.string,
    is_required: t.boolean,
    max_length: nullable(t.number),
    options: t.array(t.string),
    is_tag: t.boolean,
    is_merchant_specific: t.boolean,
});

export const WriteReviewTemplate = t.record(t.string, WriteReviewTemplateField);

export const HistogramEntry = t.interface({
    rating: t.number,
    count: t.number,
});

export const WriteReviewBlackBox = t.interface({
    io: t.string,
    igloo: t.string,
});

export const ReviewsProductVariant = t.interface({
    id: ReviewsProductVariantID,
    slug: t.string,
    display_name: t.string,
    alt_product_id: t.string,
});

export const ReviewsProduct = t.intersection([
    t.interface({
        id: ReviewsProductID,
        uuid: nullable(ProductUUID),
        brand: ReviewsBrandID,
        product_type: nullable(ReviewsProductTypeID),
        product_type_name: nullable(t.string),
        product_type_sort_order: t.number,
        category: nullable(t.number),
        name: t.string,
        short_name: nullable(t.string),
        product_id: t.string,
        num_reviews: t.number,
        average_rating: nullable(t.number),
        histogram: t.array(HistogramEntry),
        variants: t.array(ReviewsProductVariant),
    }),
    t.partial({
        component_of_uuids: t.array(ProductUUID),
    }),
]);

export const ReviewsProducts = t.array(ReviewsProduct);

export const ReviewsInfoMap = t.interface({
    reviewsProductID: ReviewsProductID,
    reviewsProductVariantIDs: t.array(ReviewsProductVariantID),
});

export const UUIDReviewsInfoMap = t.record(ProductUUID, ReviewsInfoMap);
