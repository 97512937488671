import React from "react";

import { IWebPageURL, isoWebPageURL } from "../models/nominals";

type LinkProps = React.DetailedHTMLProps<
    React.AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
>;

interface IProps extends Omit<LinkProps, "href"> {
    href: IWebPageURL | null;
    children: React.ReactNode;
}

export const Link = (props: IProps) => {
    const href = props.href ? isoWebPageURL.unwrap(props.href) : undefined;
    return (
        <a {...props} href={href}>
            {props.children}
        </a>
    );
};

export default Link;
