import React from "react";

import { IImageURL } from "../models/nominals";
import { IS_PUPPETEER } from "../utils/environment";

type ImgProps = React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
>;

interface IProps extends Pick<ImgProps, "alt" | "className"> {
    "lazy"?: boolean;
    "data-src"?: IImageURL | string;
    "src"?: IImageURL | string;
}

const LAZY_CLASS = "lazyload";

export const Image = (props: IProps) => {
    const isLazy = props.lazy || props.lazy === undefined;
    type ImgElemProps = ImgProps & {
        "data-src"?: string;
        "lazy"?: boolean;
    };
    const changedProps: ImgElemProps = {};
    if (isLazy && !IS_PUPPETEER) {
        if (props.className) {
            changedProps.className = `${props.className} ${LAZY_CLASS}`;
        } else {
            changedProps.className = LAZY_CLASS;
        }
        if (props.src && !props["data-src"]) {
            changedProps["data-src"] = `${props.src}`;
            changedProps.src = undefined;
        }
    } else {
        if (props.className) {
            changedProps.className = props.className.replace("lazyload", "");
        }
        if (props["data-src"] && !props.src) {
            changedProps.src = `${props["data-src"]}`;
            changedProps["data-src"] = undefined;
        }
    }
    const imgProps: ImgElemProps = {
        alt: props.alt,
        className: props.className,
        src: props.src ? `${props.src}` : undefined,
        ["data-src"]: props["data-src"] ? `${props["data-src"]}` : undefined,
        ...changedProps,
        lazy: undefined,
    };
    return <img {...imgProps} />;
};
