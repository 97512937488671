import { t } from "ttag";

import { IConfig, default as config } from "./config";

type TStringKey =
    | // Checkout Localization
    "WEBSITE_SUPPORT_NUMBER"
    | "WEBSITE_SUPPORT_NUMBER_LINK"
    | "WEBSITE_SUPPORT_CONTACT_HOURS"
    | "EXPEDITED_SHIPPING_EXCEPTIONS"
    | "REJECT_MSG_FINANCING"
    | "REJECT_MSG_SYF"
    | "REJECT_MSG_CYBERSOURCE"
    | "REJECT_MSG_CASH"
    | "CHECKOUT_SECTION_TITLE_SHIPPING_ADDRESS"
    | "CHECKOUT_SECTION_TITLE_SHIPPING_METHOD"
    | "CHECKOUT_SECTION_TITLE_BILLING_ADDRESS"
    | "CHECKOUT_SECTION_TITLE_PAYMENT_METHODS"
    | "CONTINUE_BTN_LABEL_LOGIN"
    | "CONTINUE_BTN_LABEL_SHIPPING_ADDRESS"
    | "CONTINUE_BTN_LABEL_SHIPPING_METHOD"
    | "CONTINUE_BTN_LABEL_BILLING_ADDRESS"
    | "CONTINUE_BTN_LABEL_PAYMENT_METHODS"
    | "CHECKOUT_ERROR_SHIPPING_ADDRESS"
    | "CHECKOUT_ERROR_SHIPPING_METHOD"
    | "CHECKOUT_ERROR_PAYMENT_METHOD"
    | "CHECKOUT_ERROR_BILLING_ADDRESS"
    | "CHECKOUT_ERROR_FALLBACK"

    // Order Thank You Page
    | "ORDER_THANK_YOU_B1_CREATE_ACCT_COPY"
    | "ORDER_THANK_YOU_B1_CREATE_ACCT_SUCCESS"
    | "ORDER_THANK_YOU_B3_CREATE_ACCT_COPY"
    | "ORDER_THANK_YOU_B4_CREATE_ACCT_COPY"

    // Financing
    | "FINANCING_CARD_NAME"
    | "FINANCING_APPLY_CTA_HEAD"
    | "FINANCING_APPLY_CTA_SUBHEAD"
    | "FINANCING_APPROVAL_INTRO_TEXT"
    | "FINANCING_FORTIVA_OFFER_INTRO_TEXT"
    | "FINANCING_CONSENT_DELIVERY_HEAD"
    | "FINANCING_CONSENT_DELIVERY_BODY"
    | "FINANCING_CONSENT_ESIGN_HEAD"
    | "FINANCING_CONSENT_BODY"
    | "FINANCING_CONSENT_DISCLAIMER"
    | "FINANCING_ESIGN_AGREEEMENT"
    | "FINANCING_SHARE_CONTACT_INFO"
    | "FINANCING_PURCHASE_PRICE_NOTICE"
    | "FINANCING_PRINT_APPLY_FORM_HEAD"
    | "FINANCING_APP_INTRO_HEAD"
    | "FINANCING_APP_INTRO_BODY"
    | "FINANCING_APP_PENDING_MSG"
    | "FINANCING_APP_SUBMIT_ERROR"
    | "FINANCING_RETURN_TO_CHECKOUT"
    | "FINANCING_RETURN_TO_SITE"
    | "FINANCING_WELCOME_MSG"
    | "FINANCING_DEFAULT_PLAN_TERM_MONTHS"
    | "FINANCING_DEFAULT_PLAN_APR"
    | "FINANCING_DEFAULT_PLAN_THRESHOLD"
    | "FINANCING_DEFAULT_PLAN_SUPERSCRIPT"
    | "FINANCING_FINANCING_ERROR"
    | "FINANCING_MIN_AMOUNT"
    | "FINANCING_TERMS_PDF"
    | "FINANCING_TECHNICAL_REQS_LINK"
    | "FINANCING_BASKET_FINE_PRINT"
    | "FINANCING_CHECKOUT_FINE_PRINT"
    | "FINANCING_CHECKOUT_FINE_PRINT_MOBILE"
    | "FINANCING_PREQUAL_CONSENT_MSG"

    // Mattress Match
    | "MATTRESS_SELECTOR_BODY_TYPE_DEFAULT_DESCRIPTION"
    | "MATTRESS_SELECTOR_BODY_TYPE_OPTION_DESCRIPTION"
    | "MATTRESS_SELECTOR_FEEL_DEFAULT_DESCRIPTION"
    | "MATTRESS_SELECTOR_COOL_DEFAULT_DESCRIPTION"
    | "MATTRESS_SELECTOR_BUDGET_DEFAULT_DESCRIPTION"
    | "MATTRESS_SELECTOR_FEEL_HEADING"
    | "MATTRESS_SELECTOR_COOL_HEADING"
    | "MATTRESS_SELECTOR_BUDGET_HEADING"
    | "DISCOVER_YOUR_PERFECT_MATTRESS";

type TGetStringFn = (key: TStringKey) => string | null;

type TLazyString = (getString: TGetStringFn) => string | null;

type IStrings = {
    [key in TStringKey]: string | null | TLazyString;
};

/**
 * If the value of the given config name is truthy, return the first string, else, return the second string. This
 * is useful for choosing strings based on a feature flag setting.
 */
const featureFlagString = (
    configName: keyof IConfig,
    truthyString: string | null,
    falseyString: string | null,
): TLazyString => {
    return () => {
        if (config.get(configName)) {
            return truthyString;
        }
        return falseyString;
    };
};

const _strings: IStrings = {
    WEBSITE_SUPPORT_NUMBER: null,
    WEBSITE_SUPPORT_NUMBER_LINK: null,
    WEBSITE_SUPPORT_CONTACT_HOURS: null,
    EXPEDITED_SHIPPING_EXCEPTIONS: t`
        Select either next-day or 2-day shipping above. Orders placed by noon EST ship the same day (except
        Saturday and Sunday). Sorry, expedited shipping is not available for White Glove Delivery items or
        TEMPUR-Topper Supreme.
    `,

    CHECKOUT_SECTION_TITLE_SHIPPING_ADDRESS: t`Shipping`,
    CHECKOUT_SECTION_TITLE_SHIPPING_METHOD: t`Delivery Options`,
    CHECKOUT_SECTION_TITLE_BILLING_ADDRESS: t`Billing`,
    CHECKOUT_SECTION_TITLE_PAYMENT_METHODS: t`Payment`,

    CONTINUE_BTN_LABEL_LOGIN: t`Continue`,
    CONTINUE_BTN_LABEL_SHIPPING_ADDRESS: t`Continue to Delivery Options`,
    CONTINUE_BTN_LABEL_SHIPPING_METHOD: t`Continue to Billing`,
    CONTINUE_BTN_LABEL_BILLING_ADDRESS: t`Continue to Payment`,
    CONTINUE_BTN_LABEL_PAYMENT_METHODS: t`Place Order`,

    CHECKOUT_ERROR_SHIPPING_ADDRESS: t`An error occurred with the shipping address. Please verify the information is correct.`,
    CHECKOUT_ERROR_SHIPPING_METHOD: t`An error occurred with the selected shipping method. Please verify the information is correct.`,
    CHECKOUT_ERROR_PAYMENT_METHOD: t`An error occurred with the selected payment method. Please verify the information is correct.`,
    CHECKOUT_ERROR_BILLING_ADDRESS: t`An error occurred with the billing address. Please verify the information is correct.`,
    CHECKOUT_ERROR_FALLBACK: featureFlagString(
        "ENABLE_BASKET_ID",
        t`
            An error occurred while placing your order. Please verify the information is correct, and resubmit
            your order. If you are still experiencing issues, you may call us at %(websiteSupportNumber)s and provide
            your cart ID %(basketID)s.
        `,
        t`
            An error occurred while placing your order. Please verify the information is correct, and resubmit
            your order. If you are still experiencing issues, you may call us at %(websiteSupportNumber)s.
        `,
    ),

    ORDER_THANK_YOU_B1_CREATE_ACCT_COPY: t`Create an account to easily check order status and get updates on delivery from one convenient location.`,
    ORDER_THANK_YOU_B1_CREATE_ACCT_SUCCESS: t`Don't forget, you can easily check order status and get updates on delivery for all your orders from your <MyOrdersLink>My Orders</MyOrdersLink> page.`,
    ORDER_THANK_YOU_B3_CREATE_ACCT_COPY: t`The email address <UserEmail>EMAIL ADDRESS</UserEmail> is already associated with an account. <LoginLink>Log in</LoginLink> to easily check order status and get updates on delivery for all your orders from one convenient location.`,
    ORDER_THANK_YOU_B4_CREATE_ACCT_COPY: t`Don't forget, you can easily check order status and get updates on delivery from your <MyOrdersLink>My Orders</MyOrdersLink> page.`,

    FINANCING_CARD_NAME: t`Tempur-Pedic<SUP>®</SUP> Credit Card`,
    FINANCING_APPLY_CTA_HEAD: t`No account? Apply today.`,
    FINANCING_APPLY_CTA_SUBHEAD: t`Finance your purchase with a Tempur-Pedic Credit Card`,
    FINANCING_APPROVAL_INTRO_TEXT: t`You have been pre-qualified for a Tempur-Pedic Credit Card with a credit line of <B>up to:</B>`,
    FINANCING_FORTIVA_OFFER_INTRO_TEXT: t`Congratulations! You are pre-qualified for a Fortiva® Retail Credit Account with <br /> Tempur-Pedic.`,
    FINANCING_CONSENT_DELIVERY_HEAD: t`Consent to Electronic Delivery`,
    FINANCING_CONSENT_DELIVERY_BODY: t`
        In order to process your transaction, we need your consent to deliver disclosures for this transaction to you electronically. Please review the terms and conditions of our ESIGN Consent and indicate your willingness to receive your Special Terms and APR information and other disclosures that may be required for this transaction electronically by checking the box below. If you do not consent to receive these documents electronically, you will not be able to process this transaction online.
    `,
    FINANCING_CONSENT_ESIGN_HEAD: t`View ESIGN Consent`,
    FINANCING_CONSENT_BODY: t`
        Please read the Consent for Electronic Disclosure of Special Terms and APR Information below.
        <br /><br />
        If you consent to receive the Special Terms and APR Information disclosure electronically, please check the box below.
        <br /><br />
        Consent for Electronic Disclosure of Special Terms and APR Information
        <br /><br />
        Consent for Electronic Disclosure. The law requires that certain information be provided to you “in writing” in
        connection with your purchase under special terms. The law allows this information to be provided to you
        electronically, instead, with your prior consent. So before we provide these disclosures electronically,
        you must review the following information and consent to receive the Special Terms and APR Information disclosure
        electronically. Your consent applies only to this transaction. The length of the special terms period is the
        time period indicated for the special terms you have selected.
        <br /><br />
        Withdrawal of Consent. You have the right to withdraw your consent at any time prior to final submission of
        this purchase at no cost to you. If you withdraw your consent, you will be unable to make your purchase online
        using the special terms you have selected. You withdraw your consent by exiting your web browser without
        completing this purchase, selecting the back button, or by selecting some other method of payment.
        <br /><br />
        Hardware and Software Requirements for Access and Retention. To retain the Special Terms and APR Information
        disclosure, you will need an internet browser capable of saving or printing this web page. You will also need
        a printer if you wish to print out and retain the Special Terms and APR Information disclosure on paper, and
        a method of electronic storage if you wish to retain the disclosure in electronic form. If you would like a
        paper copy of the Special Terms and
        <br /><br />
        APR Information disclosure, you may contact Tempur-Pedic by calling <a data-phone-link-label="1-888-811-5053" data-phone-link="1-888-811-5053" href="tel:18888115053">1-888-811-5053</a> from 8am – 9pm Monday –
        Friday, 9am – 6pm Saturday – Sunday Eastern Time to request a paper version at no charge.
        <br /><br />
        Method of Consent. You consent to receive the Special Terms and APR Information disclosure electronically
        by checking the box below and clicking on the “Place Order” button.
        <br /><br />
    `,
    FINANCING_CONSENT_DISCLAIMER: t`
        <h2>Terms and Conditions</h2>
        <p>As part of your relationship with us, you are entitled by law to receive certain information "in writing." The federal E-SIGN Act and certain state laws allow us to provide this information to you electronically, instead, with your prior consent. We also need your general consent to use electronic records and signatures in our relationship with you. So, before you submit your application for a credit card account electronically, you must review and consent to the terms outlined below.</p>
        <p>In this consent, "we," "us," "our" and "Wells Fargo" are used to refer to Wells Fargo Bank, N.A. "You" and "your" refer to the person giving this consent.</p>
        <ol>
        <li><strong>Your consent to use electronic records and signatures.</strong> You consent to receiving your Credit Card Agreement (including Truth-in-Lending disclosures) the Arbitration Agreement and the Privacy Notice electronically ("Electronic Records"). We may also use and obtain from you electronic signatures.</li>
        <li><strong>Your option to receive paper copies.</strong> If you want a copy of your Credit Card Agreement (including Truth-in-Lending disclosures), the Arbitration Agreement or Privacy Notice on paper, you may contact our customer service unit and request a paper version at no charge. You can call our customer service unit toll-free at 1-877-805-7744, Monday - Friday, 8:00 am to 6:00 pm Central Time.</li>
        <li><strong>Your consent covers the Credit Card Agreement (including Truth-in-Lending disclosures) the Arbitration Agreement and the Privacy Notice.</strong> Your consent applies to the Credit Card Agreement (including Truth-in-Lending disclosures) for your account, the Arbitration Agreement and the Privacy Notice. Your consent does not apply to Truth-in-Lending disclosures provided after your account is opened, such as the disclosures given on your monthly periodic statements, or to any annual notice of our privacy policies that we may provide. Your monthly periodic statements and annual notice of our privacy policies will be sent in paper form.</li>
        <li><strong>You may withdraw your consent at any time before you submit your application.</strong> You have the right to withdraw your consent at any time prior to submitting your application at no cost to you. If you wish to withdraw your consent, you must exit your Web browser without clicking the Submit Application button at the end of the application. If you withdraw your consent you will not be able to apply for a credit card account electronically. If you do not wish to receive your Credit Card Agreement (including Truth-in-Lending disclosures), Arbitration Agreement and Privacy Notice electronically and would like to have them provided in paper form, you may apply by mail for a credit card. Please call the toll-free customer service number given in paragraph 2 above to request a paper application.</li>
        <li><strong>You must keep your email or electronic address current with us.</strong> In order to ensure that we are able to provide you with important notices and other information from time to time, you must notify us of any change in your email or other electronic address. You may change the email address on record for you by calling the toll-free customer service number given in paragraph 2 above.</li>
        <li><strong>Hardware and software you will need.</strong> To access and retain Electronic Records, you must have:
        <ul>
        <li>Current Version (defined below) of an Internet browser we support,</li>
        <li>a connection to the Internet,</li>
        <li>an active email account,</li>
        <li>a Current Version of a program that accurately reads and displays PDF files (such as Adobe Acrobat Reader), and</li>
        <li>a computer and an operating system capable of supporting all of the above. You will also need a printer if you wish to print out and retain records on paper, and electronic storage if you wish to retain records in electronic form.</li>
        </ul>
        </li>
        </ol>
        <p>By "Current Version", we mean a version of the software that is currently being supported by its publisher. The following link includes more information on the software we currently support and links for downloading the software and related system requirements: <a href='https://www.wellsfargo.com/help/online-banking/hardware-software-requirements' target='_blank'>Technical Requirements</a>.</p>
        <p>You should check the Technical Requirements page occasionally for updates on supported software. From time to time, we may offer services or features that require that your Internet browser be configured in a particular way, such as permitting the use of JavaScript. If we detect that your Internet Browser is not properly configured, we will provide you with a notice and advice on how to update your configuration.</p>
        <p>We reserve the right to discontinue support of a Current Version of software if, in our sole opinion, it suffers from a security or other flaw that makes it unsuitable for use with your account. And we always reserve the right, in our sole discretion, to communicate with you by U.S. Postal Service.</p>
        <p>Please indicate your consent to receive the Electronic Records and to use electronic records and signatures by checking the "I consent to receiving my agreements and disclosures electronically" box. By providing your consent, you are also confirming that you have the hardware and software described above, that you are able to receive and review electronic records, and that you have an active email account. You are also confirming that you are authorized to, and do, consent on behalf of all the co-owners of your accounts.</p>
    `,
    FINANCING_ESIGN_AGREEEMENT: t`You agree that this transaction is under your credit card agreement with Wells Fargo Bank, N.A.`,
    FINANCING_SHARE_CONTACT_INFO: t`
        By submitting your application, you acknowledge and agree that Tempur-Pedic may send, email, or call you with information regarding
        our financing program, as well as their products over the next 30 days.
    `,
    FINANCING_PURCHASE_PRICE_NOTICE: t`
        This is not a request for a specific credit limit. You agree that you may receive and accept a credit
        limit less than the purchase amount. Providing an amount helps ensure your purchase expectations are
        considered as part of your application for credit, when possible. For example, Tempur-Pedic mattress sets
        range from $1,500–$12,500.
    `,
    FINANCING_PRINT_APPLY_FORM_HEAD: t`Tempur-Pedic Online Credit Application - Printable Review`,
    FINANCING_APP_INTRO_HEAD: t`Important Information About Procedures For Opening A New Account`,
    FINANCING_APP_INTRO_BODY: t`
        <p>To help the government fight the funding of terrorism and money laundering activities, Federal law requires
        all financial institutions to obtain, verify, and record information that identifies each person who opens
        an account. What this means for you: When you open an account, we will ask for your name, address, date of
        birth, and other information that will allow us to identify you. We may also ask to see your driver's
        license or other identifying documents.</p>
        <p>You must have an address located within the United States, U.S. Territories, U.S. Commonwealths, or a U.S. Military Address to open an account.</p>
    `,
    FINANCING_APP_PENDING_MSG: t`
        The decision on your application is currently being reviewed. If approved for the Tempur-Pedic Credit
        Card, you will receive your card in the mail in the next 7–10 days. If denied, you will receive written
        notification with our reasons for denial in the mail within 30 days. If you have any questions about your
        application status, please call 1-877­-805­-7744, Monday – Friday 9 am – 7 pm Eastern Time.
    `,
    FINANCING_APP_SUBMIT_ERROR: t`An unexpected error occurred. Please review your information and try again in a few minutes. If the problem continues, please contact us at <a data-phone-link-label='1-800-459-8451' data-phone-link='1-800-459-8451' href='tel:18004598451'>1-800-459-8451</a>.`,
    FINANCING_RETURN_TO_CHECKOUT: t`Return to Checkout`,
    FINANCING_RETURN_TO_SITE: t`Return to Tempurpedic.com`,
    FINANCING_WELCOME_MSG: t`Your new credit card unlocks exclusive financing offers from Tempur-Pedic. Simply enter your new credit card number at checkout.`,

    FINANCING_DEFAULT_PLAN_TERM_MONTHS: null,
    FINANCING_DEFAULT_PLAN_APR: null,
    FINANCING_DEFAULT_PLAN_THRESHOLD: null,
    FINANCING_DEFAULT_PLAN_SUPERSCRIPT: null,
    FINANCING_FINANCING_ERROR:
        "That does not appear to be a valid Tempur-Pedic Credit Card number. Please verify the entered information is correct.",
    FINANCING_MIN_AMOUNT: "750.00",
    FINANCING_TERMS_PDF:
        "https://l.tempurpedic.com/wellsfargo-terms/web/viewer.html",
    FINANCING_TECHNICAL_REQS_LINK:
        "https://www.wellsfargo.com/help/wfonline/hardware_software_req.jhtml",
    FINANCING_BASKET_FINE_PRINT: null,
    FINANCING_CHECKOUT_FINE_PRINT: null,
    FINANCING_CHECKOUT_FINE_PRINT_MOBILE: null,
    FINANCING_PREQUAL_CONSENT_MSG: t`By checking this box, I understand this is not a
                            credit card application and is only being used to
                            see if any pre-qualified offers are available. I
                            also understand Wells Fargo Bank, N. A. will conduct
                            a soft pull of my credit and this will not affect
                            my credit score. I also understand that Wells Fargo
                            may share my name, address email address and
                            pre-qualification status with Tempur-Pedic so they
                            may send or email me information regarding my
                            pre-qualification status.`,
    REJECT_MSG_FINANCING: featureFlagString(
        "ENABLE_BASKET_ID",
        t`
            There was an issue processing your financing payment. Please take the following steps to complete your order:
            <ul>
                <li>Step 1 - Please verify your information is accurate, correct any errors, and resubmit your order.</li>
                <li>Step 2 - If you are still experiencing issues, please call us at %(websiteSupportNumber)s and provide reference number %(basketID)s. We’re here %(websiteSupportContactHours)s to assist you.</li>
            </ul>
        `,
        t`
            There was an issue processing your financing payment. Please take the following steps to complete your order:
            <ul>
                <li>Step 1 - Please verify your information is accurate, correct any errors, and resubmit your order.</li>
                <li>Step 2 - If you are still experiencing issues, please call us at %(websiteSupportNumber)s. We’re here %(websiteSupportContactHours)s to assist you.</li>
            </ul>
        `,
    ),
    REJECT_MSG_SYF: featureFlagString(
        "ENABLE_BASKET_ID",
        t`
            There was an issue processing your Synchrony financing payment. Please take the following steps to complete your order:
            <ul>
                <li>Step 1 - Please verify your information is accurate, correct any errors, and resubmit your order.</li>
                <li>Step 2 - If you are still experiencing issues, please call us at %(websiteSupportNumber)s and provide reference number %(basketID)s. We’re here %(websiteSupportContactHours)s to assist you.</li>
            </ul>
        `,
        t`
            There was an issue processing your Synchrony financing payment. Please take the following steps to complete your order:
            <ul>
                <li>Step 1 - Please verify your information is accurate, correct any errors, and resubmit your order.</li>
                <li>Step 2 - If you are still experiencing issues, please call us at %(websiteSupportNumber)s. We’re here %(websiteSupportContactHours)s to assist you.</li>
            </ul>
        `,
    ),
    REJECT_MSG_CYBERSOURCE: featureFlagString(
        "ENABLE_BASKET_ID",
        t`
            Your payment has been declined. Please take the following steps to complete your order:
            <ul>
                <li>Step 1 - Verify that your payment and billing information is accurate, correct any errors and resubmit your order</li>
                <li>Step 2 - If you believe your payment and billing information is correct, please contact your financial institution</li>
                <li>Step 3 - If you are still experiencing issues, you may call us at %(websiteSupportNumber)s and provide reference number %(basketID)s</li>
            </ul>
        `,
        t`
            Your payment has been declined. Please take the following steps to complete your order:
            <ul>
                <li>Step 1 - Verify that your payment and billing information is accurate, correct any errors and resubmit your order</li>
                <li>Step 2 - If you believe your payment and billing information is correct, please contact your financial institution</li>
                <li>Step 3 - If you are still experiencing issues, you may call us at %(websiteSupportNumber)s</li>
            </ul>
        `,
    ),
    REJECT_MSG_CASH: featureFlagString(
        "ENABLE_BASKET_ID",
        t`
            There was an issue processing your payment. Please take the following steps to complete your order:
            <ul>
                <li>Step 1 - Please verify your information is accurate, correct any errors, and resubmit your order.</li>
                <li>Step 2 - If you are still experiencing issues, please call us at %(websiteSupportNumber)s and provide reference number %(basketID)s. We’re here %(websiteSupportContactHours)s to assist you.</li>
            </ul>
        `,
        t`
            There was an issue processing your payment. Please take the following steps to complete your order:
            <ul>
                <li>Step 1 - Please verify your information is accurate, correct any errors, and resubmit your order.</li>
                <li>Step 2 - If you are still experiencing issues, please call us at %(websiteSupportNumber)s. We’re here %(websiteSupportContactHours)s to assist you.</li>
            </ul>
        `,
    ),
    MATTRESS_SELECTOR_BODY_TYPE_DEFAULT_DESCRIPTION: t`90-Night Trial</p><p>We know some sleepers adjust to a new mattress faster than others. We encourage you to give yourself three full months to adjust to your new Tempur-Pedic. Love your new mattress, or we'll make it right.`,
    MATTRESS_SELECTOR_BODY_TYPE_OPTION_DESCRIPTION: t`Knowing your approximate height and weight helps us predict the amount of pressure your body will place on the mattress — or how much you'll likely sink into it when you lie down. We use this information to help pinpoint the right feel for an optimal balance of comfort and support.`,
    MATTRESS_SELECTOR_FEEL_DEFAULT_DESCRIPTION: t`Soft, firm, or somewhere in the middle? Your favorite feel is simply your personal preference — and don't worry, there are no wrong answers. If you're unsure, rest easy knowing every Tempur-Pedic precisely adapts to your weight, shape, and temperature to relieve pressure points and reduce motion transfer.`,
    MATTRESS_SELECTOR_COOL_DEFAULT_DESCRIPTION: t`Did you know <strong>62%</strong> of households have at least one person who sleeps hot? If you feel uncomfortably warm at night or wake up covered in sweat, you may be a hot sleeper. We’ll build your personalized sleep solution to help you sleep cooler all night long.`,
    MATTRESS_SELECTOR_BUDGET_DEFAULT_DESCRIPTION: t`The average mattress lifespan is 6-8 years. Rest easy with our 10-Year Warranty and get 3,650 nights of great sleep.`,
    MATTRESS_SELECTOR_FEEL_HEADING: t`What is your preferred mattress feel?`,
    MATTRESS_SELECTOR_COOL_HEADING: t`Do you sleep hot?`,
    MATTRESS_SELECTOR_BUDGET_HEADING: t`How much do you value life changing sleep?`,
    DISCOVER_YOUR_PERFECT_MATTRESS: t`Discover Your Perfect Tempur-Pedic®!`,
};

const updateString = (newStrings: Partial<IStrings>) => {
    Object.assign(_strings, newStrings);
};

const getString: TGetStringFn = (key) => {
    const value = _strings[key];
    // If not set, output a warning and return the empty value
    if (!value) {
        console.warn(
            `WARNING: tsi-common-react translated string key ${key} is not set.`,
        );
        return value;
    }
    // If it's just a string, return it
    if (typeof value !== "function") {
        return value;
    }
    // If it's a lazy string, call the function to get the string results
    return value(getString);
};

export const strings = {
    update: updateString,
    get: getString,
};
