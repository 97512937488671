import * as t from "io-ts";

import { Months, ProductAPIURL } from "./nominals";
import { NullBoolean, NullString, nullable } from "./utils";

export const PostDiscountAddon = t.interface({
    name: t.string,
    price_excl_tax: t.string,
});

export const PostDiscountAddons = t.array(PostDiscountAddon);

export const Price = t.intersection([
    t.interface({
        currency: NullString,
        excl_tax: NullString,
        incl_tax: NullString,
        tax: NullString,
    }),
    t.partial({
        retail: NullString,
        cosmetic_excl_tax: NullString,
        cosmetic_incl_tax: NullString,
        enable_per_month_pricing: NullBoolean,
        per_month: NullString,
        per_month_term_length: nullable(Months),
        post_discount_addons: PostDiscountAddons,
    }),
]);

export const APIPrice = t.interface({
    product: ProductAPIURL,
    quantity: t.number,
    unit: Price,
    total: Price,
});
