import React from "react";

import { getOptionValueHash } from "../../../utils/catalogue";
import { notEmpty, unique } from "../../../utils/functional";
import { sortProductOptions } from "../../../utils/sorting";

interface IProps {
    sizeAttributeOptionGroupName: string;
    sizeAttributeOptions: string[];
    priceSelectValue: string;
    onSelectChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

interface IState {}

export class SizeSelector extends React.Component<IProps, IState> {
    private readonly onSelectChange = (
        e: React.ChangeEvent<HTMLSelectElement>,
    ) => {
        this.props.onSelectChange(e);
    };

    private readonly onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
    };

    render() {
        const label = this.props.sizeAttributeOptionGroupName.replace(
            " Options",
            "",
        );
        const cleanedOptions = this.props.sizeAttributeOptions
            .filter(notEmpty)
            .filter(unique);
        const sortedOptions = sortProductOptions("option_size", cleanedOptions);
        const optionElems = sortedOptions.map((option) => {
            return (
                <option key={getOptionValueHash(option)} value={option}>
                    {option}
                </option>
            );
        });
        const selectID =
            this.props.sizeAttributeOptionGroupName
                .toLowerCase()
                .replace(" ", "-") + "-select";
        return (
            <form onSubmit={this.onSubmit}>
                <label htmlFor={selectID}>Price by {label}</label>
                <select
                    id={selectID}
                    className="al-compareresults-selectsize"
                    onChange={this.onSelectChange}
                    value={this.props.priceSelectValue}
                >
                    {optionElems}
                </select>
            </form>
        );
    }
}
