import React from "react";

import { ITextBoxField } from "../models/formFields.interfaces";
import { AbstractFormField, IFormFieldState } from "./AbstractFormField";

export class FormTextbox<T extends string> extends AbstractFormField<
    T,
    HTMLTextAreaElement,
    ITextBoxField<T>,
    IFormFieldState
> {
    public static defaultProps = {
        type: "textbox",
    };

    public state: IFormFieldState = {
        focused: false,
    };

    protected updateValueAttribute(
        event: React.FormEvent<HTMLTextAreaElement>,
    ) {
        this.value = event.currentTarget.value;
    }

    protected getInputProps() {
        return Object.assign(super.getInputProps(), {
            "rows": this.props.rows,
            "cols": this.props.cols,
            "minLength": this.props.minLength,
            "maxLength": this.props.maxLength,
            "aria-expanded": this.props.ariaExpanded,
        });
    }

    protected buildControl() {
        const props = this.getInputProps();
        return (
            <textarea
                ref={(ref) => {
                    this.inputElem = ref;
                }}
                {...props}
            ></textarea>
        );
    }
}
