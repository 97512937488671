import { Store } from "@reduxjs/toolkit";

import { registerCascade } from "../../utils/cascade";
import format from "../../utils/format";
import { getDinero } from "../../utils/money";
import { IRootState } from "../reducers.interfaces";
import { Dispatchers as CheckoutDispatchers } from "./dispatchers";

type IRootCheckoutState = Pick<IRootState, "checkout">;

export const registerCommonCascades = (store: Store<IRootCheckoutState>) => {
    const checkoutDispatchers = new CheckoutDispatchers(store.dispatch);

    // Update grand_total
    registerCascade(store, {
        transformState: (storeState: IRootCheckoutState) => {
            return storeState.checkout;
        },

        getKey: (state) => {
            if (state.data.deferredPaymentOrder) {
                return format.decimal(
                    state.data.deferredPaymentOrder.total_incl_tax,
                );
            }
            let total = state.data.basket
                ? getDinero(state.data.basket.total_incl_tax)
                : getDinero(0.0);
            if (state.derived.shipping_method_cost_incl_tax) {
                total = total.add(
                    getDinero(state.derived.shipping_method_cost_incl_tax),
                );
            }
            return format.decimal(total);
        },

        doCascade: async (total: string, state) => {
            checkoutDispatchers.setDerivedFields({
                grand_total: total,
            });
            checkoutDispatchers.resetPaymentMethods(state.data.payment_methods);
            return true;
        },
    });

    // Calculate Total Discount Amount
    registerCascade(store, {
        transformState: (storeState: IRootCheckoutState) => {
            return storeState.checkout;
        },

        getKey: (state) => {
            if (state.data.deferredPaymentOrder) {
                const beforeDiscount =
                    state.data.deferredPaymentOrder
                        .basket_total_before_discounts_incl_tax;
                const afterDiscount =
                    state.data.deferredPaymentOrder.basket_total_incl_tax;
                const discount = beforeDiscount.subtract(afterDiscount);
                return format.decimal(discount);
            }
            const totalExclDiscounts = state.data.basket
                ? getDinero(state.data.basket.total_excl_tax_excl_discounts)
                : getDinero(0.0);
            const total = state.data.basket
                ? getDinero(state.data.basket.total_excl_tax)
                : getDinero(0.0);
            const basketDiscount = totalExclDiscounts.subtract(total);
            return format.decimal(basketDiscount);
        },

        doCascade: async (discount: string) => {
            checkoutDispatchers.setDerivedFields({
                total_discount: discount,
            });
            return true;
        },
    });

    // Update selected Financing Plan when the available plans change
    registerCascade(store, {
        transformState: (storeState: IRootCheckoutState) => {
            return storeState.checkout;
        },

        getKey: (state) => {
            const planIDs = state.data.financing_plans
                .map((plan) => {
                    return plan.id;
                })
                .sort();
            const paymentKeys =
                state.form && state.form.payment_methods
                    ? Object.keys(state.form.payment_methods).sort()
                    : [];
            const paymentTypes = paymentKeys.map((key) => {
                return state.form.payment_methods[key].method_type;
            });
            const data = {
                planIDs: planIDs,
                paymentKeys: paymentKeys,
                paymentTypes: paymentTypes,
            };
            return JSON.stringify(data);
        },

        doCascade: async (_, state) => {
            const planIDs = state.data.financing_plans.map((plan) => {
                return plan.id;
            });
            const keysToUpdate = Object.keys(state.form.payment_methods).filter(
                (key) => {
                    const method = state.form.payment_methods[key];
                    return (
                        method.method_type === "financing" &&
                        (!method.financing_plan ||
                            !planIDs.includes(method.financing_plan))
                    );
                },
            );
            keysToUpdate.forEach((methodKey) => {
                checkoutDispatchers.setPaymentMethodFields(methodKey, {
                    financing_plan: state.data.financing_plans[0]?.id ?? null,
                });
            });
            return true;
        },
    });
};
