import loadGoogleMapsApi from "load-google-maps-api";

import { config } from "../config";
import {
    ILatitude,
    ILongitude,
    IMeters,
    IMiles,
    isoLatitude,
    isoLongitude,
    isoMeters,
    isoMiles,
} from "../models/nominals";

const METERS_PER_MILE = 1_609.344;

let __loadingGMaps: ReturnType<typeof loadGoogleMapsApi> | undefined;
let numMaps = 0;

export const loadGMaps = () => {
    if (!__loadingGMaps) {
        __loadingGMaps = loadGoogleMapsApi({
            key: config.get("GOOGLE_MAPS_API"),
            libraries: ["places"],
        });
    }
    return __loadingGMaps;
};

export const metersToMiles = (meters: IMeters): IMiles => {
    return isoMiles.wrap(isoMeters.unwrap(meters) / METERS_PER_MILE);
};

export const milesToMeters = (miles: IMiles): IMeters => {
    return isoMeters.wrap(isoMiles.unwrap(miles) * METERS_PER_MILE);
};

export const getGoogleLatLng = ({
    lat,
    lng,
}: {
    lat: ILatitude;
    lng: ILongitude;
}): google.maps.LatLngLiteral => {
    return {
        lat: isoLatitude.unwrap(lat),
        lng: isoLongitude.unwrap(lng),
    };
};

export const newMap = (
    mapDiv: HTMLElement,
    opts?: google.maps.MapOptions,
): google.maps.Map => {
    // Build the map
    const map = new google.maps.Map(mapDiv, opts);
    // Keep track of how many maps are on the page (since each call instantiation costs money).
    numMaps++;
    if (numMaps > 1) {
        console.trace(
            `Total of ${numMaps} have been loaded on this page. To reduce costs, aim for no more than 1 map load per page.`,
        );
    }
    // Return the map object to the caller
    return map;
};

export const newAutocomplete = <T extends HTMLInputElement>(
    inputElem: T,
    opts?: google.maps.places.AutocompleteOptions,
): google.maps.places.Autocomplete => {
    const autocomplete = new google.maps.places.Autocomplete(inputElem, {
        types: ["geocode"],
        componentRestrictions: {
            country: "us",
        },
        ...opts,
    });
    // Select all basic data fields: https://developers.google.com/maps/billing/gmp-billing#basic-data
    autocomplete.setFields([
        "address_component",
        "adr_address",
        "business_status",
        "formatted_address",
        "geometry",
        "icon",
        "name",
        "photo",
        "type",
        "url",
        "vicinity",
    ]);
    return autocomplete;
};

export const geocode = async (
    request: google.maps.GeocoderRequest,
): Promise<google.maps.GeocoderResult[]> => {
    await loadGMaps();
    const geocoder = new google.maps.Geocoder();
    return new Promise((resolve, reject) => {
        geocoder.geocode(request, (results, status) => {
            if (status !== google.maps.GeocoderStatus.OK || !results) {
                reject(new Error(status));
                return;
            }
            resolve(results);
        });
    });
};
