import React from "react";

import { getOptionValueByIdx } from "../apps/configurator/utils";
import { getProductVariantsForOptionCodes } from "../apps/selectors";
import { ConfiguratorTypes } from "../constants";
import {
    IOptionCode,
    IOptionSingleValue,
    IOptionValues,
    IProduct,
} from "../models/catalogue.interfaces";
import { range } from "../utils/functional";
import { ProductOptionSelector } from "./ProductOptionSelector";

interface IProps {
    selectorID: string;
    rootProduct: IProduct | null;
    variant: IProduct | null;
    code: IOptionCode;
    values: IOptionValues;
    onOptionChange: (
        namespace: string,
        code: IOptionCode,
        index: number,
        totalNumValues: number,
        value: IOptionSingleValue,
    ) => void;
    configuratorType?: ConfiguratorTypes;
    style?: "full" | "mini";
    chatLinkComponent?: JSX.Element | null;
    liveChatHeader?: string;
    enableChat?: boolean;
    isPlpPanelStyle?: boolean;
}

interface IState {}

export class ProductOptionSelectorSet extends React.PureComponent<
    IProps,
    IState
> {
    private getSelectorCount(): number {
        if (!this.props.rootProduct) {
            return 0;
        }
        // Get the codes which are before this selector in the drill-down precedence
        const allCodes =
            this.props.rootProduct.attributes.product_options?.value || [];
        const prevCodes = allCodes.slice(0, allCodes.indexOf(this.props.code));
        // Filter the variants down to those possible for the previous selectors
        const variants =
            prevCodes.length > 0
                ? getProductVariantsForOptionCodes(
                      [this.props.rootProduct],
                      prevCodes,
                      this.props.values,
                  )
                : this.props.rootProduct.children;
        // Figure out how many selectors to show from the filtered variant list
        // Example of goal: show 1 option_feel when option_size is Queen, but 2 option_size is Split King.
        const maxValueCount = variants.reduce<number>((memo, variant) => {
            const val = variant.attributes[this.props.code]?.value || [];
            const len = Array.isArray(val) ? val.length : 1;
            return Math.max(len, memo);
        }, 0);
        // As long as there's a root product, always show at least 1 selector
        return Math.max(maxValueCount, 1);
    }

    render() {
        const numSelectors = this.getSelectorCount();
        return range(numSelectors).map((num) => {
            const i = num - 1;
            return (
                <ProductOptionSelector
                    key={i}
                    {...this.props}
                    selectorID={`${this.props.selectorID}-${i}`}
                    value={getOptionValueByIdx(
                        this.props.values[this.props.code],
                        i,
                    )}
                    onOptionChange={(namespace, code, value) => {
                        this.props.onOptionChange(
                            namespace,
                            code,
                            i,
                            numSelectors,
                            value,
                        );
                    }}
                />
            );
        });
    }
}
