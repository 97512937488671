import classNames from "classnames";
import { format as formatDate } from "date-fns";
import React from "react";
import { connect } from "react-redux";
import { t } from "ttag";

import { Trans } from "../../../common/Trans";
import { ILocation } from "../../../models/location.interfaces";
import {
    ABTest,
    ABTestVariant,
    getDesiredABTestVariant,
} from "../../../utils/abTesting";
import { isZipLocation } from "../../../utils/guards";
import { EditablePostalCode } from "../../common/containers/EditablePostalCode";
import { preferredLocationSelector } from "../../common/selectors";
import { TStateMapper } from "../../reducers.interfaces";
import {
    PredictedDeliveryDateDisplayType,
    PredictedDeliveryDateStatus,
    PredictedDeliveryDateStyle,
} from "../constants";
import { IDeliveryDatePredictionState } from "../reducers.interfaces";
import { PredictedDeliveryLoader } from "./PredictedDeliveryLoader";

import styles from "./PredictedDeliveryDate.module.scss";

interface IOwnProps {
    type?: PredictedDeliveryDateDisplayType;
    isReadOnly?: boolean;
    style: PredictedDeliveryDateStyle;
    deliveryIsFree?: boolean;
}

interface IReduxProps {
    location: ILocation | null;
    prediction: IDeliveryDatePredictionState;
}

interface IProps extends IOwnProps, IReduxProps {}

interface IState {}

class PredictedDeliveryDateComponent extends React.Component<IProps, IState> {
    render() {
        // If AB test 17966 is set to B, don't show anything. If it's either A or not set, continue.
        if (
            getDesiredABTestVariant(ABTest.PREDICTED_DELIVERY_DATES) ===
            ABTestVariant.B
        ) {
            return null;
        }

        // If PDDs are disabled, still load the data.
        // NOTE:
        // This is temporary to assist with tailoring the Convey predictions
        // Should be removed once feature is used more heavily in production
        if (this.props.type === PredictedDeliveryDateDisplayType.DISABLED) {
            return <PredictedDeliveryLoader />;
        }

        // If data status is initial, load data
        if (
            this.props.prediction.status === PredictedDeliveryDateStatus.INITIAL
        ) {
            return <PredictedDeliveryLoader />;
        }

        // If the loaded data indicates a convey error, but the detected
        // location has changed since the Convey data was loaded, load the
        // Convey data again for the newly detected location.
        if (
            this.props.prediction.status ===
                PredictedDeliveryDateStatus.ERROR &&
            this.props.location &&
            isZipLocation(this.props.location) &&
            this.props.prediction.data.postcode !== this.props.location.zip
        ) {
            return <PredictedDeliveryLoader />;
        }

        // If the loaded data indicates that the convey API is offline, show nothing.
        if (
            this.props.prediction.status ===
                PredictedDeliveryDateStatus.ERROR &&
            this.props.prediction.data.status_code ===
                "deliverydates-api-offline-error"
        ) {
            return null;
        }

        // If the loaded data indicates that the detected location can not be
        // predicted by the deliverydates API, show an error message.
        if (
            this.props.prediction.status ===
                PredictedDeliveryDateStatus.ERROR &&
            this.props.prediction.data.status_code ===
                "unpredictable-location-error"
        ) {
            return (
                // Hide the message for now when the detected location can not be predicted
                // https://thelabnyc.plan.io/issues/22478#note-6
                <div className={styles.root} hidden={true}>
                    <PredictedDeliveryLoader />
                    {t`Due to your location, we’re unable to provide an estimated
                        delivery date. However, after you place your order, a local
                        shipping agent will contact you to schedule delivery.`}
                </div>
            );
        }

        // For any other errors, show an error message.
        if (
            this.props.prediction.status === PredictedDeliveryDateStatus.ERROR
        ) {
            return (
                <div className={styles.root}>
                    {t`Please enter a valid ZIP Code:`}
                    <EditablePostalCode isReadOnly={this.props.isReadOnly} />
                </div>
            );
        }

        // If the PDD is loading, render an empty div. This prevents page reflows/jumps since it
        // will take up the same vertical space as the fully-rendered component.
        if (
            this.props.prediction.status === PredictedDeliveryDateStatus.LOADING
        ) {
            return <div className={styles.root}>&nbsp;</div>;
        }

        // We have loaded data, to render the PDD.
        const displayDate =
            this.props.type === PredictedDeliveryDateDisplayType.SHOW_AGGRESSIVE
                ? this.props.prediction.data.date_aggressive
                : this.props.prediction.data.date_conservative;

        const deliveryTextClasses = classNames({
            [styles.oneLine]:
                this.props.style === PredictedDeliveryDateStyle.ONE_LINE,
            [styles.twoLine]:
                this.props.style === PredictedDeliveryDateStyle.TWO_LINE,
        });

        const deliveryTextFormat: string =
            this.props.deliveryIsFree === false
                ? t`Estimated Delivery to<Location></Location>as soon as <Date></Date>`
                : t`Free Delivery to<Location></Location>as soon as <Date></Date>`;

        return (
            <div className={styles.root}>
                <PredictedDeliveryLoader />
                <div className={deliveryTextClasses}>
                    <span>
                        <Trans
                            fmtString={deliveryTextFormat}
                            data={{
                                Location: (_, idx) => (
                                    <EditablePostalCode
                                        key={idx}
                                        isReadOnly={this.props.isReadOnly}
                                    />
                                ),
                                Date: (_, idx) => (
                                    <span key={idx}>
                                        {formatDate(displayDate, "MMMM do")}
                                    </span>
                                ),
                            }}
                        />
                    </span>
                </div>
            </div>
        );
    }
}

const mapStateToProps: TStateMapper<"checkout", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    return {
        location: preferredLocationSelector(rootState),
        prediction: rootState.checkout.data.predicted_delivery_date,
        ...ownProps,
    };
};

export const PredictedDeliveryDate = connect(mapStateToProps)(
    PredictedDeliveryDateComponent,
);
