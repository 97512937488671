import { Dispatch } from "@reduxjs/toolkit";
import { default as creditCardType } from "credit-card-type";

import {
    IAddress,
    IAddressCountry,
    IAddressState,
} from "../../models/address.interfaces";
import {
    IBasket,
    IConcreteBundle,
    IOptionCode,
    IOptionSingleValue,
    IProduct,
    IUserConfigurableBundle,
    IWishListLine,
} from "../../models/catalogue.interfaces";
import {
    IOrder,
    IOrderResponse,
    IPaymentStates,
} from "../../models/checkout.interfaces";
import {
    IDeliveryDatePrediction,
    IPredictionResponseStatusCode,
} from "../../models/deliverydates";
import { FinancingPlan } from "../../models/financing";
import { IShippingMethod } from "../../models/shipping.interfaces";
import { ICSRAssistedUser } from "../../models/user.interfaces";
import format from "../../utils/format";
import { clamp } from "../../utils/math";
import { guardUnhandledAction } from "../../utils/never";
import {
    Action,
    CARD_TYPE_AMEX,
    CARD_TYPE_DISCOVER,
    CARD_TYPE_MASTERCARD,
    CARD_TYPE_VISA,
    ConfigureGiftStatus,
    DEFAULT_COUNTRY,
    DEFAULT_PHONE_NUMBER,
    PRIMARY_PAYMENT_METHOD_KEY,
    SECONDARY_PAYMENT_METHOD_KEY,
    Step,
} from "./constants";
import { defaultPaymentMethod } from "./defaults";
import {
    IAction,
    IActionAcknowledgeMergedBasket,
    IActionAddPaymentMethod,
    IActionBeginCompleteDeferredPayment,
    IActionPredictedDeliveryDateLoaded,
    IActionPredictedDeliveryDateLoading,
    IActionRemovePaymentMethod,
    IActionResetPaymentMethods,
    IActionResetPaymentStates,
    IActionSetAddToBasketErrorModalState,
    IActionSetConfigurableBundles,
    IActionSetConfigureGiftStatus,
    IActionSetDerivedFields,
    IActionSetFields,
    IActionSetGiftOptionValue,
    IActionSetGiftRootProduct,
    IActionSetPaymentMethodFields,
    IActionSetPreferredDeliveryDate,
    IActionUpdateAssistedUser,
    IActionUpdateBasket,
    IActionUpdateBillingStates,
    IActionUpdateBundle,
    IActionUpdateCountries,
    IActionUpdateFinancingPlans,
    IActionUpdateOrder,
    IActionUpdatePaymentError,
    IActionUpdatePaymentMethods,
    IActionUpdatePaymentStates,
    IActionUpdateShippingMethods,
    IActionUpdateShippingStates,
    IActionUpdateUserAddresses,
    IActionUpdateWishlist,
    IActionUseBillingAsShipping,
    IPayment,
    IReduxFormState,
} from "./reducers.interfaces";

export class Dispatchers {
    dispatch: Dispatch<IAction>;

    constructor(dispatch: Dispatch<IAction>) {
        this.dispatch = dispatch;
    }

    /**
     * Update a form field's value in Redux
     */
    public readonly changeFormField = (
        changedFields: IActionSetFields["fields"],
    ) => {
        this.dispatch<IActionSetFields>({
            type: Action.SET_FIELDS,
            fields: changedFields,
        });
    };

    /**
     * Update an address field's value in Redux
     */
    public readonly changeAddressField = (
        currentState: IReduxFormState,
        savedAddresses: IAddress[],
        changedFields: Partial<IReduxFormState>,
    ) => {
        const enteredAddress = {
            shipping_first_name: currentState.shipping_first_name,
            shipping_last_name: currentState.shipping_last_name,
            shipping_line1: currentState.shipping_line1,
            shipping_line2: currentState.shipping_line2,
            shipping_line4: currentState.shipping_line4,
            shipping_state: currentState.shipping_state,
            shipping_postcode: currentState.shipping_postcode,
            shipping_phone_number: currentState.shipping_phone_number,
            shipping_country: currentState.shipping_country,
            ...changedFields,
        };

        const defaultAddr: Partial<IAddress> = {};
        const matchingSavedAddress =
            savedAddresses.filter((addr) => {
                return (
                    enteredAddress.shipping_first_name === addr.first_name &&
                    enteredAddress.shipping_last_name === addr.last_name &&
                    enteredAddress.shipping_line1 === addr.line1 &&
                    enteredAddress.shipping_line2 === addr.line2 &&
                    enteredAddress.shipping_line4 === addr.line4 &&
                    enteredAddress.shipping_state === addr.state &&
                    enteredAddress.shipping_postcode === addr.postcode &&
                    enteredAddress.shipping_phone_number ===
                        addr.phone_number &&
                    enteredAddress.shipping_country === addr.country
                );
            })[0] || defaultAddr;

        const fieldsToUpdate = {
            ...changedFields,
            shipping_address_url: matchingSavedAddress.url || "",
        };

        this.dispatch<IActionSetFields>({
            type: Action.SET_FIELDS,
            fields: fieldsToUpdate,
        });
    };

    /**
     * Update Redux's address fields to match the given pre-saved address.
     */
    public readonly selectSavedAddress = (
        prefix: "shipping" | "billing",
        savedAddresses: IAddress[],
        selectedAddressURL: string,
    ) => {
        const defaultAddr: Partial<IAddress> = {};
        const selectedAddress =
            savedAddresses.filter((a) => {
                return a.url === selectedAddressURL;
            })[0] || defaultAddr;
        this.dispatch<IActionSetFields>({
            type: Action.SET_FIELDS,
            fields: {
                [`${prefix}_address_url`]: selectedAddressURL || "",
                [`${prefix}_first_name`]: selectedAddress.first_name || "",
                [`${prefix}_last_name`]: selectedAddress.last_name || "",
                [`${prefix}_line1`]: selectedAddress.line1 || "",
                [`${prefix}_line2`]: selectedAddress.line2 || "",
                [`${prefix}_line4`]: selectedAddress.line4 || "",
                [`${prefix}_state`]: selectedAddress.state || "",
                [`${prefix}_postcode`]: selectedAddress.postcode || "",
                [`${prefix}_phone_number`]:
                    selectedAddress.phone_number || DEFAULT_PHONE_NUMBER,
                [`${prefix}_country`]:
                    selectedAddress.country || DEFAULT_COUNTRY,
            },
        });
    };

    /**
     * Update user's address book
     */
    public readonly updateUserAddresses = (addresses: IAddress[]) => {
        this.dispatch<IActionUpdateUserAddresses>({
            type: Action.UPDATE_USER_ADDRESSES,
            addresses: addresses,
        });
    };

    /**
     * Signal start of loading PDD data
     */
    public readonly predictedDeliveryDateLoading = () => {
        this.dispatch<IActionPredictedDeliveryDateLoading>({
            type: Action.PREDICTED_DELIVERY_DATE_LOADING,
        });
    };

    /**
     * Signal loading PDD data finished
     */
    public readonly predictedDeliveryDateLoaded = (
        data: IDeliveryDatePrediction,
    ) => {
        this.dispatch<IActionPredictedDeliveryDateLoaded>({
            type: Action.PREDICTED_DELIVERY_DATE_LOADED,
            payload: data,
            error: false,
        });
    };

    /**
     * Signal loading PDD data failed
     */
    public readonly predictedDeliveryDateLoadFailed = (
        postcode: string,
        status_code: IPredictionResponseStatusCode,
    ) => {
        this.dispatch<IActionPredictedDeliveryDateLoaded>({
            type: Action.PREDICTED_DELIVERY_DATE_LOADED,
            payload: {
                postcode: postcode,
                status_code: status_code,
                created_datetime: null,
            },
            error: true,
        });
    };

    public readonly beginCompleteDeferredPayment = (order: IOrder) => {
        this.dispatch<IActionBeginCompleteDeferredPayment>({
            type: Action.BEGIN_COMPLETE_DEFERRED_PAYMENT,
            payload: {
                order: order,
            },
        });
    };

    /**
     * Set if or not the billing address is the same as the shipping address.
     */
    public readonly setBillingAddressIsSameAsShipping = (isSame: boolean) => {
        this.dispatch<IActionUseBillingAsShipping>({
            type: Action.USE_SHIPPING_ADDR_AS_BILLING_ADDR,
            billing_addr_is_shipping_addr: isSame,
        });
    };

    /**
     * Reset all payment method data to the default state
     */
    public readonly resetPaymentMethods = (
        methods: IPayment["method_type"][],
    ) => {
        this.dispatch<IActionResetPaymentMethods>({
            type: Action.RESET_PAYMENT_METHODS,
            payload: {
                methods,
            },
        });
        this.dispatch<IActionResetPaymentStates>({
            type: Action.RESET_PAYMENT_STATES,
        });
    };

    /**
     * Enable a payment method
     */
    public readonly addPaymentMethod = (
        methodKey: string,
        methodType: IPayment["method_type"],
        amount = "0.00",
        payBalance = true,
        account?: string,
    ) => {
        let methodData: IPayment;
        switch (methodType) {
            case "default":
                methodData = defaultPaymentMethod;
                break;
            case "cash":
                methodData = {
                    method_type: "cash",
                    amount: amount,
                    pay_balance: payBalance,
                };
                break;
            case "pay-later":
                methodData = {
                    method_type: "pay-later",
                    amount: amount,
                    pay_balance: payBalance,
                };
                break;
            case "cybersource":
                methodData = {
                    method_type: "cybersource",
                    amount: amount,
                    pay_balance: payBalance,
                    card_type: CARD_TYPE_VISA,
                    card_number: "",
                    card_expiration: "",
                    card_cvc: "",
                };
                break;
            case "bluefin":
                methodData = {
                    method_type: "bluefin",
                    amount: amount,
                    pay_balance: payBalance,
                    payment_data: "",
                };
                break;
            case "financing":
                methodData = {
                    method_type: "financing",
                    amount: amount,
                    pay_balance: payBalance,
                    new_financing_account: null,
                    financing_account: account === undefined ? "" : account,
                    financing_plan: null,
                    has_agreed: false,
                    has_esigned: false,
                };
                break;
            case "synchrony":
                methodData = {
                    method_type: "synchrony",
                    amount: amount,
                    pay_balance: payBalance,
                    dpos_token: null,
                    transaction_code: null,
                };
                break;
            default:
                guardUnhandledAction(methodType);
                throw new Error(`Unknown payment method type: ${methodType}`);
        }
        this.dispatch<IActionAddPaymentMethod>({
            type: Action.ADD_PAYMENT_METHOD,
            method_key: methodKey,
            method: methodData,
        });

        // switching payment methods, so let's forget payment state
        this.dispatch<IActionResetPaymentStates>({
            type: Action.RESET_PAYMENT_STATES,
        });
    };

    /**
     * Remove a payment method
     */
    public readonly removePaymentMethod = (methodKey: string) => {
        this.dispatch<IActionRemovePaymentMethod>({
            type: Action.REMOVE_PAYMENT_METHOD,
            method_key: methodKey,
        });
    };

    /**
     * Set the value of form field for a payment method
     */
    public readonly setPaymentMethodFields = (
        methodKey: string,
        fields: IActionSetPaymentMethodFields["fields"],
    ) => {
        this.dispatch<IActionSetPaymentMethodFields>({
            type: Action.SET_PAYMENT_METHOD_FIELDS,
            method_key: methodKey,
            fields: fields,
        });
    };

    /**
     * Update the available payment methods
     */
    public readonly updatePaymentMethods = (
        methods: IPayment["method_type"][],
    ) => {
        this.dispatch<IActionUpdatePaymentMethods>({
            type: Action.UPDATE_PAYMENT_METHODS,
            methods: methods,
        });
    };

    /**
     * Update the available payment methods
     */
    public readonly updatePaymentStates = (states: IPaymentStates) => {
        this.dispatch<IActionUpdatePaymentStates>({
            type: Action.UPDATE_PAYMENT_STATES,
            states: states,
        });
    };

    /**
     * Update the payment errors
     */
    public readonly updatePaymentError = (error: string) => {
        this.dispatch<IActionUpdatePaymentError>({
            type: Action.UPDATE_PAYMENT_ERROR,
            error: error,
        });
    };

    /**
     * Set the Credit Card number
     */
    public readonly changeCreditCardNumber = (
        methodKey: string,
        cardNumber: string,
    ) => {
        const typeCodes: { [t: string]: string } = {
            [creditCardType.types.VISA]: CARD_TYPE_VISA,
            [creditCardType.types.MASTERCARD]: CARD_TYPE_MASTERCARD,
            [creditCardType.types.AMERICAN_EXPRESS]: CARD_TYPE_AMEX,
            [creditCardType.types.DISCOVER]: CARD_TYPE_DISCOVER,
        };

        const fields = {
            card_number: cardNumber.replace(/[^0-9]+/g, ""),
            card_type: "",
        };

        // If we can, set the card type and format the card number
        const types = creditCardType(fields.card_number);
        const ccType = types[0];
        if (ccType && ccType.type) {
            fields.card_number = format.creditCardNumber(
                fields.card_number,
                ccType.type,
            );
            fields.card_type = typeCodes[ccType.type] || "";
        } else {
            fields.card_number = cardNumber;
        }

        this.setPaymentMethodFields(methodKey, fields);
    };

    /**
     * Set the Credit Card expiration date
     */
    public readonly changeCreditCardExpDate = (
        methodKey: string,
        oldValue: string,
        newValue: string,
    ) => {
        const value = newValue.replace(/[^0-9]+/g, "");
        const month = value.slice(0, 2);
        const year = value.length >= 3 ? value.slice(2, 4) : "";
        const showSlash =
            !!year || (oldValue.length === 1 && month.length === 2);
        const formatted = showSlash ? `${month}/${year}` : month;
        this.setPaymentMethodFields(methodKey, {
            card_expiration: formatted,
        });
    };

    /**
     * Set the payment amount in the case of split pay
     */
    public readonly changeAmount = (
        methodKey: string,
        grand_total: string | null,
        newValue: string,
    ) => {
        let value: string = newValue.replace(/[^0-9.]+/g, "");
        const n_value = Number(value);
        const n_grand_total = Number(grand_total);
        let amount: number = clamp(n_value, 0, n_grand_total);
        if (isNaN(amount)) {
            amount = 0;
        }
        // we want to preserve `value` as a string in the case of trailing . or 0
        // but in the case of value being too high, we'll use the number version
        if (n_value > n_grand_total) {
            value = String(amount);
        }

        const fields = {
            amount: value,
        };
        this.setPaymentMethodFields(methodKey, fields);

        // update the other split pay amount
        const otherMethodKey =
            methodKey === PRIMARY_PAYMENT_METHOD_KEY
                ? SECONDARY_PAYMENT_METHOD_KEY
                : PRIMARY_PAYMENT_METHOD_KEY;
        const otherFields = {
            amount: (n_grand_total - amount).toFixed(2),
        };
        this.setPaymentMethodFields(otherMethodKey, otherFields);
    };

    /**
     * Set the Wells Fargo account number
     */
    public readonly changeFinancingAccountNumber = (
        methodKey: string,
        account_number: string,
    ) => {
        this.setPaymentMethodFields(methodKey, {
            financing_account: account_number.replace(/[^0-9]+/g, ""),
        });
    };

    /**
     * Set the list of Wells Fargo accounts
     */
    public readonly setFinancingPlans = (plans: FinancingPlan[]) => {
        this.dispatch<IActionUpdateFinancingPlans>({
            type: Action.UPDATE_FINANCING_PLANS,
            plans: plans,
        });
    };

    /**
     * Update the basket in Redux
     */
    public readonly updateBasket = (basket: IBasket) => {
        this.dispatch<IActionUpdateBasket>({
            type: Action.UPDATE_BASKET,
            basket: basket,
        });
    };

    /**
     * Update the order in Redux
     */
    public readonly updateOrder = (order: IOrderResponse) => {
        this.dispatch<IActionUpdateOrder>({
            type: Action.UPDATE_ORDER,
            order: order,
        });
    };

    /**
     * Update the given wishlist in Redux with the given lines.
     */
    public readonly updateWishlist = (
        listName: string,
        lines: IWishListLine[],
    ) => {
        this.dispatch<IActionUpdateWishlist>({
            type: Action.UPDATE_WISHLIST,
            code: listName,
            lines: lines,
        });
    };

    /**
     * Update Redux with the given product bundle data
     */
    public readonly updateBundle = (bundle: IConcreteBundle) => {
        this.dispatch<IActionUpdateBundle>({
            type: Action.UPDATE_BUNDLE,
            bundle: bundle,
        });
    };

    /**
     * Update Redux with the given shipping methods
     */
    public readonly updateShippingMethods = (
        methods: IShippingMethod[],
        errors: string[],
    ) => {
        if (methods.length > 0 && errors.length > 0) {
            throw new Error(
                "Something went wrong! Can't send action with both shipping methods and shipping method errors.",
            );
        }

        // Update Redux
        this.dispatch<IActionUpdateShippingMethods>({
            type: Action.UPDATE_SHIPPING_METHODS,
            methods: methods,
            errors: errors,
        });

        // If there are any errors, set the current checkout step to Step.SHIPPING_ADDRESS so that the error is displayed
        if (errors.length > 0) {
            this.changeFormField({
                current_step: Step.SHIPPING_ADDRESS,
            });
        }
    };

    /**
     * Update Redux with the given countries
     */
    public readonly updateCountries = (countries: IAddressCountry[]) => {
        this.dispatch<IActionUpdateCountries>({
            type: Action.UPDATE_COUNTRIES,
            countries: countries,
        });
    };

    /**
     * Update Redux with the given countries
     */
    public readonly updateStates = (
        type: "shipping" | "billing",
        states: IAddressState[],
    ) => {
        if (type === "shipping") {
            this.dispatch<IActionUpdateShippingStates>({
                type: Action.UPDATE_SHIPPING_STATES,
                states: states,
            });
        } else {
            this.dispatch<IActionUpdateBillingStates>({
                type: Action.UPDATE_BILLING_STATES,
                states: states,
            });
        }
    };

    /**
     * Update Redux with the current CSR assisted user
     */
    public readonly updateAssistedUser = (user: ICSRAssistedUser) => {
        this.dispatch<IActionUpdateAssistedUser>({
            type: Action.UPDATE_ASSISTED_USER,
            user: user,
        });
    };

    /**
     * Set a derived data field in Redux
     */
    public readonly setDerivedFields = (
        fields: IActionSetDerivedFields["fields"],
    ) => {
        this.dispatch<IActionSetDerivedFields>({
            type: Action.SET_DERIVED_FIELDS,
            fields: fields,
        });
    };

    /**
     * Acknowledge having viewed a merged basket
     */
    public readonly acknowledgeMergedBasket = (basketID: number) => {
        this.dispatch<IActionAcknowledgeMergedBasket>({
            type: Action.ACKNOWLEDGE_MERGED_BASKET,
            basketID: basketID,
        });
    };

    public readonly setConfigureGiftStatus = (status: ConfigureGiftStatus) => {
        this.dispatch<IActionSetConfigureGiftStatus>({
            type: Action.SET_CONFIGURE_GIFT_STATUS,
            payload: status,
        });
    };

    public readonly setConfigurableBundles = (
        bundles: IUserConfigurableBundle[],
    ) => {
        this.dispatch<IActionSetConfigurableBundles>({
            type: Action.SET_CONFIGURABLE_BUNDLES,
            payload: bundles,
        });
    };

    public readonly setGiftRootProduct = (
        giftID: string,
        bundleID: number,
        rootProduct: IProduct,
    ) => {
        this.dispatch<IActionSetGiftRootProduct>({
            type: Action.SET_GIFT_ROOT_PRODUCT,
            payload: {
                giftID: giftID,
                bundleID: bundleID,
                rootProduct: rootProduct,
            },
        });
    };

    public readonly setGiftOptionValue = (
        giftID: string,
        namespace: string,
        code: IOptionCode,
        index: number,
        totalNumValues: number,
        value: IOptionSingleValue,
    ) => {
        this.dispatch<IActionSetGiftOptionValue>({
            type: Action.SET_GIFT_OPTION_VALUE,
            payload: {
                giftID: giftID,
                namespace: namespace,
                code: code,
                index: index,
                totalNumValues: totalNumValues,
                value: value,
            },
        });
    };

    public readonly setAddToBasketErrorModalState = (
        isOpen: boolean,
        reason = "",
    ) => {
        this.dispatch<IActionSetAddToBasketErrorModalState>({
            type: Action.SET_ADD_TO_BASKET_ERROR_MODAL_STATE,
            payload: {
                isOpen: isOpen,
                reason: reason,
            },
        });
    };

    public readonly setPreferredDeliveryDate = (date: Date | null) => {
        this.dispatch<IActionSetPreferredDeliveryDate>({
            type: Action.SET_PREFERRED_DELIVERY_DATE,
            payload: {
                preferred_date: date,
            },
        });
    };
}
