import { Action, Store } from "@reduxjs/toolkit";

export interface ICascade<IStoreState, IWorkState> {
    /**
     * If IStoreState and IWorkState aren't the same, define this function to transform
     * the store state into the work state.
     */
    transformState: (storeState: IStoreState) => IWorkState;

    /**
     * Given the store's current state, get the equivalence comparison key. If the key
     * returned is different than what was returned last time getKey was called, then
     * doCascade will get called.
     */
    getKey: (state: IWorkState) => string;

    /**
     * Upon store state change, perform an action, and return a promise with `true` if
     * the action was successful.
     */
    doCascade: (newKey: string, state: IWorkState) => Promise<boolean>;

    /**
     * Optional custom function to use for equivalence key comparison.
     */
    isKeyEquivalent?: (
        prevKey: string | undefined,
        newKey: string | undefined,
    ) => boolean;

    /**
     * Minimum number of milliseconds to wait between calling doCascade.
     */
    debounce?: number;
}

/**
 * Register a new cascade function for the given store.
 */
export const registerCascade = <
    IStoreState,
    IAction extends Action,
    IWorkState = IStoreState,
>(
    store: Store<IStoreState, IAction>,
    cascade: ICascade<IStoreState, IWorkState>,
) => {
    let _prevKey: string | undefined;

    const isKeyEquivalent =
        cascade.isKeyEquivalent ||
        ((prevKey, newKey) => {
            return !newKey || prevKey === newKey;
        });

    const handler = () => {
        const storeState = store.getState();
        const state = cascade.transformState(storeState);
        const newKey = cascade.getKey(state);
        if (isKeyEquivalent(_prevKey, newKey)) {
            return;
        }

        _prevKey = newKey;

        Promise.resolve(true)
            .then(() => {
                return cascade.doCascade(newKey, state);
            })
            .then((success) => {
                if (!success) {
                    _prevKey = undefined;
                }
            });
    };

    const debounce = cascade.debounce || 0;
    let timer: number;
    store.subscribe(() => {
        window.clearTimeout(timer);
        timer = window.setTimeout(handler, debounce);
    });
};
