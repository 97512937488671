import React from "react";
import SVG from "react-inlinesvg";
import Modal from "react-modal";
import { t } from "ttag";

import iconCircleClose from "../../../../img/icons/circle-close.svg";
import {
    IAddress,
    IBillingAddress,
    IShippingAddress,
} from "../../../models/address.interfaces";
import { focusElement } from "../../../utils/keyboardFocus";
import { IReduxFormState } from "../reducers.interfaces";

import styles from "./AddressSuggestionModal.module.scss";

interface IProps {
    form: IReduxFormState;
    suggestedAddress: IAddress | null;
    onContinue: (shouldImport: boolean) => void;
    onRequestClose: () => void;
    onAfterClose: () => void;
    showAddressSuggestionModal: boolean;
    addressPrefix: string;
}

interface IState {
    acceptAddressSuggestion: boolean;
}

export class AddressSuggestionModal extends React.Component<IProps, IState> {
    public state: IState = {
        acceptAddressSuggestion: true,
    };

    render() {
        if (!this.props.suggestedAddress) {
            return null;
        }
        const modalStyle = {
            content: {
                top: "5%",
                bottom: "auto",
                left: "50%",
                right: "auto",
                width: "400px",
                padding: "30px",
                marginLeft: "-200px",
                overflow: "auto",
            },
        };

        const formAddress: Partial<IAddress> = [
            "first_name",
            "last_name",
            "line1",
            "line2",
            "line4",
            "state",
            "postcode",
        ].reduce((address, field) => {
            const key = (this.props.addressPrefix + "_" + field) as keyof (
                | IShippingAddress
                | IBillingAddress
            );
            return { ...address, [field]: this.props.form[key] };
        }, {});

        const setAcceptAddressSuggestion = (
            acceptAddressSuggestion: boolean,
        ) => {
            this.setState({
                acceptAddressSuggestion: acceptAddressSuggestion,
            });
        };

        const onContinue = () => {
            this.props.onContinue(this.state.acceptAddressSuggestion);
        };

        const onClose = () => {
            this.props.onRequestClose();
        };

        let suggestedLine2: JSX.Element | null = null;
        if (this.props.suggestedAddress && this.props.suggestedAddress.line2) {
            suggestedLine2 = (
                <span className={styles.addressLine}>
                    {this.props.suggestedAddress.line2}
                </span>
            );
        }

        let givenLine2: JSX.Element | null = null;
        if (formAddress.line2) {
            givenLine2 = (
                <span className={styles.addressLine}>{formAddress.line2}</span>
            );
        }

        return (
            <Modal
                style={modalStyle}
                isOpen={this.props.showAddressSuggestionModal}
                onRequestClose={onClose}
                onAfterClose={this.props.onAfterClose}
                role="dialog"
                aria={{
                    labelledby: "address-verification",
                    modal: true,
                }}
                onAfterOpen={() => {
                    focusElement(`.${styles.closeButton}`);
                }}
            >
                <button
                    className={styles.closeButton}
                    aria-label={t`close`}
                    onClick={onClose}
                >
                    <SVG
                        aria-hidden="true"
                        className={styles.closeIcon}
                        src={iconCircleClose}
                        title={t`Close Icon`}
                    />
                </button>
                <div className={styles.root}>
                    <h1
                        className="ada-screenreader-only"
                        id="address-verification"
                    >
                        {t`Address Verification`}
                    </h1>
                    <h2 className={styles.header}>
                        {t`Please verify your address`}
                    </h2>
                    <fieldset className={styles.form}>
                        <legend>
                            <p>{t`USPS recommends using this address.`}</p>
                        </legend>
                        <label htmlFor="use_suggested_address">
                            <input
                                type="radio"
                                name="address_group"
                                id="use_suggested_address"
                                className={styles.radioInput}
                                checked={this.state.acceptAddressSuggestion}
                                onChange={setAcceptAddressSuggestion.bind(
                                    this,
                                    true,
                                )}
                            />{" "}
                            <span className={styles.radioTitle}>
                                {t`Use the address suggested by USPS:`}
                            </span>
                            <p className="address-suggestion-model__address">
                                <span className={styles.addressLine}>
                                    {this.props.suggestedAddress.first_name}{" "}
                                    {this.props.suggestedAddress.last_name}
                                </span>
                                <span className={styles.addressLine}>
                                    {this.props.suggestedAddress.line1}
                                </span>
                                {suggestedLine2}
                                <span className={styles.addressLine}>
                                    {this.props.suggestedAddress.line4},{" "}
                                    {this.props.suggestedAddress.state}{" "}
                                    {this.props.suggestedAddress.postcode}
                                </span>
                            </p>
                        </label>
                        <label htmlFor="use_input_address">
                            <input
                                type="radio"
                                name="address_group"
                                id="use_input_address"
                                className={styles.radioInput}
                                checked={!this.state.acceptAddressSuggestion}
                                onChange={setAcceptAddressSuggestion.bind(
                                    this,
                                    false,
                                )}
                            />{" "}
                            <span className={styles.radioTitle}>
                                {t`Use the address you entered:`}
                            </span>
                            <p className="address-suggestion-model__address">
                                <span className={styles.addressLine}>
                                    {formAddress.first_name}{" "}
                                    {formAddress.last_name}
                                </span>
                                <span className={styles.addressLine}>
                                    {formAddress.line1}
                                </span>
                                {givenLine2}
                                <span className={styles.addressLine}>
                                    {formAddress.line4}, {formAddress.state}{" "}
                                    {formAddress.postcode}
                                </span>
                            </p>
                        </label>
                    </fieldset>
                    <button
                        className={`${styles.continueButton} button`}
                        onClick={onContinue}
                    >
                        <span>{t`CONTINUE`}</span>
                    </button>
                </div>
            </Modal>
        );
    }
}
