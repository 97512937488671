import { IOptionValues, IProduct } from "../../models/catalogue.interfaces";
import { SortType, sortProductVariants } from "../../utils/sorting";
import { getProductVariant } from "../selectors";
import { getPreferredOptionSelections } from "./persistence";

/**
 * Given a root product, return the variant which should be initially selected.
 * This is based on the last known state of the option selectors and
 * the ordering of the selector options.
 */
export const getInitialVariant = (rootProduct: IProduct): IProduct => {
    // If there's no children, the parent is selected
    if (!rootProduct.children || rootProduct.children.length <= 0) {
        return rootProduct;
    }

    // Try and select the child which matches the most possible number of previously selected option values
    const namespace = rootProduct.product_class_slug;
    const codes = [...(rootProduct.attributes.product_options?.value || [])];
    const initialVariant = codes.reduce(
        (memo: IProduct | null | undefined, _, i) => {
            if (memo) {
                return memo;
            }
            const codeSubset = i === 0 ? codes : codes.slice(0, -i);
            const optionSet = getPreferredOptionSelections(
                namespace,
                codeSubset,
            );
            return getProductVariant([rootProduct], optionSet);
        },
        null,
    );
    if (initialVariant) {
        return initialVariant;
    }

    // Sort the children using the order of the select options.
    const sortedChildren = sortProductVariants(rootProduct, SortType.PRESELECT);
    const availableChildren = sortedChildren.filter((p) => {
        return p.availability.is_available_to_buy;
    });

    // Try to select the first available child.
    if (availableChildren.length > 0) {
        return availableChildren[0];
    }

    // Finally, just select the first child.
    return sortedChildren[0];
};

export const getInitialOptionValues = (
    rootProduct: IProduct,
): IOptionValues => {
    const initialVariant = getInitialVariant(rootProduct);
    const options: IOptionValues = {};
    (rootProduct.attributes.product_options?.value || []).forEach((optName) => {
        options[optName] = initialVariant.attributes[optName]?.value;
    });
    return options;
};
