// sort-imports-ignore
/* eslint-disable import/first */
// Import polyfills and setup error reporting system
import "./src/_bootstrap";
import { Provider, getServerData, getTemplate } from "@reactivated";
import { initPage } from "@thelabnyc/thelabui/src/index";

// Initialize the rest of the application
import Modal from "react-modal";
import {
    sendGTMUserHash,
    kuidListeners,
} from "tsi-common-react/src/utils/analytics";
import { initWidgets } from "tsi-common-react/src/widgets";

// Remove no-js class from HTML tag
const htmlElem = document.querySelector("html");
if (htmlElem) {
    htmlElem.classList.remove("no-js");
}

// Load CSS
import "./src/styles/main.scss";

// Update the user hash in GTM on page load
window.onload = () => {
    sendGTMUserHash();
};
kuidListeners();

// Set tsi-common-react configuration
import "./src/localization";

// This is the main entry point for new-style thelab-ui / reactivated
// components (which support SSR)
initPage({
    Provider,
    getServerData,
    getTemplate,
}).catch((e) => {
    console.error(e);
});

// Set the root app element for react-modal
if (document.querySelector("body > .layout")) {
    Modal.setAppElement("body > .layout");
} else if (document.querySelector("#root")) {
    Modal.setAppElement("#root");
}

// Load Site Components
initWidgets();

import "./src/ui/main-nav";
import "./src/ui/helpers";
import "./src/ui/reviews";
import "./src/ui/lazyloading";
import "./src/ui/homepage";
import "./src/ui/pdp-features";
import "./src/ui/pdp-comparison";
import "./src/ui/landing-page";
import "./src/ui/landing-page-value-props";
import "./src/ui/landing-page-customer-review";
import "./src/ui/landing-page-promo";
import "./src/ui/contact-us-page";
import "./src/ui/basket";

import "tsi-common-react/src/blocks/comparison-carousel";

import "./src/Common/index";
import "./src/Checkout/index";
import "./src/ProductCompare/index";
import "./src/ProductDetail/index";
import "./src/Reviews/index";
