import React from "react";
import { t } from "ttag";

import { IPaymentMethodFormProps } from "./PaymentMethod.interfaces";

interface IProps extends IPaymentMethodFormProps {}

interface IState {}

export class Cash extends React.Component<IProps, IState> {
    render() {
        return (
            <div className="form__error">
                <p>
                    <strong>
                        {t`Please enter payment information directly into the ERP.`}
                    </strong>
                </p>
            </div>
        );
    }
}
