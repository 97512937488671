import {
    APIConcreteBundles,
    ConciseProducts,
    MattressMatchResults,
    Product,
    ProductClasses,
    Products,
    VerboseProductClass,
} from "../models/catalogue";
import {
    IConciseProduct,
    IConcreteBundle,
    IMattressMatchPreferenceSet,
    IMattressMatchResults,
    IProduct,
    IProductClass,
    IVerboseProductClass,
} from "../models/catalogue.interfaces";
import {
    IProductAPIURL,
    IProductCategoryID,
    IProductID,
    IProductUUID,
    isoProductAPIURL,
    isoProductID,
    isoProductUUID,
} from "../models/nominals";
import { APIPrice } from "../models/prices";
import { IAPIPrice } from "../models/prices.interfaces";
import { ProductShippingMethods } from "../models/shipping";
import { IProductShippingMethod } from "../models/shipping.interfaces";
import { check } from "../models/utils";
import { CSRF_HEADER, ajax, getCSRFToken } from "../utils/ajax";
import { PromiseMutex } from "../utils/mutex";

export const getProductURL = (productID: IProductID): IProductAPIURL => {
    return isoProductAPIURL.wrap(`/api/products/${productID}/`);
};

export const getProductID = (productURL: IProductAPIURL): IProductID | null => {
    const groups = isoProductAPIURL.unwrap(productURL).match(/\/(\d+)\/$/);
    return groups ? isoProductID.wrap(parseInt(groups[1], 10)) : null;
};

export const getProductShippingMethods = async (
    productID: IProductID,
    postCode: string,
) => {
    const mutex = new PromiseMutex<IProductShippingMethod[]>(
        `product-shipping-methods-get-${productID}`,
    );
    let loading = mutex.getPromise();
    if (!loading) {
        loading = ajax
            .get(`/api/products/${productID}/shipping-methods/`)
            .query({ postcode: postCode })
            .set("Accept", "application/json")
            .then((resp) => {
                return check(ProductShippingMethods.decode(resp.body));
            });
        mutex.setPromise(loading);
    }
    return loading;
};

export const getProductShippingMethodsByLocation = async (
    productID: IProductID,
    postCode: string | null | undefined,
) => {
    const mutex = new PromiseMutex<IProductShippingMethod[]>(
        `product-shipping-methods-by-location-get-${productID}`,
    );
    let loading = mutex.getPromise();
    if (!loading) {
        loading = ajax
            .get(`/api/products/${productID}/shipping-methods-by-location/`)
            .query({ postcode: postCode })
            .set("Accept", "application/json")
            .then((resp) => {
                return check(ProductShippingMethods.decode(resp.body));
            });
        mutex.setPromise(loading);
    }
    return loading;
};

export const listProducts = async (listURL: string) => {
    const mutex = new PromiseMutex<IConciseProduct[]>(`product-get-${listURL}`);
    let loading = mutex.getPromise();
    if (!loading) {
        loading = ajax
            .get(listURL)
            .set("Accept", "application/json")
            .then((resp) => {
                return check(ConciseProducts.decode(resp.body));
            });
        mutex.setPromise(loading);
    }
    return loading;
};

export const getProduct = async (productURL: IProductAPIURL) => {
    const mutex = new PromiseMutex<IProduct>(`product-get-${productURL}`);
    let loading = mutex.getPromise();
    if (!loading) {
        loading = ajax
            .get(isoProductAPIURL.unwrap(productURL))
            .set("Accept", "application/json")
            .then((resp) => {
                return check(Product.decode(resp.body));
            });
        mutex.setPromise(loading);
    }
    return loading;
};

export const getProductPrice = async (
    productURL: IProductAPIURL,
    quantity: number,
) => {
    const mutex = new PromiseMutex<IAPIPrice>(
        `product-price-get-${productURL}`,
    );
    let loading = mutex.getPromise();
    if (!loading) {
        loading = ajax
            .get(`${productURL}price/`)
            .query({
                quantity: quantity,
            })
            .set("Accept", "application/json")
            .then((resp) => {
                return check(APIPrice.decode(resp.body));
            });
        mutex.setPromise(loading);
    }
    return loading;
};

export const loadCategory = async (categoryID: IProductCategoryID) => {
    const mutex = new PromiseMutex<IProduct[]>(
        `products-category-list-${categoryID}`,
    );
    let loading = mutex.getPromise();
    if (!loading) {
        loading = ajax
            .get(`/api/categories/${categoryID}/products/`)
            .set("Accept", "application/json")
            .then((resp) => {
                return check(Products.decode(resp.body));
            });
        mutex.setPromise(loading);
    }
    return loading;
};

export const listConcreteBundles = async (productURL: IProductAPIURL) => {
    const mutex = new PromiseMutex<IConcreteBundle[]>(
        `product-concrete-bundle-list-${productURL}`,
    );
    let loading = mutex.getPromise();
    if (!loading) {
        loading = ajax
            .get(`${productURL}concretebundles/`)
            .set("Accept", "application/json")
            .then((resp) => {
                return check(APIConcreteBundles.decode(resp.body));
            })
            .then((bundles) => {
                const loadingSuggestedProducts = bundles.map(
                    async (inputBundle) => {
                        // Load the details of each suggested product
                        const products = await Promise.all(
                            inputBundle.suggested_products.map((pID) => {
                                return getProduct(getProductURL(pID));
                            }),
                        );
                        // Filter out product which are out of stock
                        const availableProducts = products.filter(
                            (p) => p.availability.is_available_to_buy,
                        );
                        // Inject the remaining product details into the bundle data
                        const bundle: IConcreteBundle = {
                            ...inputBundle,
                            suggested_products: availableProducts,
                        };
                        return bundle;
                    },
                );
                return Promise.all(loadingSuggestedProducts);
            });
        mutex.setPromise(loading);
    }
    return loading;
};

export const getProductByUUID = async (productUUID: IProductUUID) => {
    const UUIDRegex =
        /^[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}$/;
    if (!productUUID || !isoProductUUID.unwrap(productUUID).match(UUIDRegex)) {
        return null;
    }
    const mutex = new PromiseMutex<IProduct | null>(
        `product-get-${productUUID}`,
    );
    let loading = mutex.getPromise();
    if (!loading) {
        loading = ajax
            .get(`/api/products/?core_product__uuid=${productUUID}`)
            .set("Accept", "application/json")
            .then((resp) => {
                if (!resp.body || !resp.body[0]) {
                    return null;
                }
                const product: IProduct = resp.body[0];
                const url = getProductURL(product.id);
                return getProduct(url);
            });
        mutex.setPromise(loading);
    }
    return loading;
};

export const listProductClasses = async (): Promise<IProductClass[]> => {
    const mutex = new PromiseMutex<IProductClass[]>(`product-class-list`);
    let loading = mutex.getPromise();
    if (!loading) {
        loading = ajax
            .get("/api/product-classes/")
            .set("Accept", "application/json")
            .then((resp) => {
                return check(ProductClasses.decode(resp.body));
            });
        mutex.setPromise(loading);
    }
    return loading;
};

export const listProductsForClass = async (productClassSlug: string) => {
    const mutex = new PromiseMutex<IProduct[]>(
        `product-class-products-${productClassSlug}`,
    );
    let loading = mutex.getPromise();
    if (!loading) {
        loading = ajax
            .get(`/api/product-classes/${productClassSlug}/products`)
            .set("Accept", "application/json")
            .then((resp) => {
                return check(Products.decode(resp.body));
            });
        mutex.setPromise(loading);
    }
    return loading;
};

export const getProductClass = async (
    productClassSlug: string,
): Promise<IVerboseProductClass> => {
    const mutex = new PromiseMutex<IVerboseProductClass>(
        `product-class-${productClassSlug}`,
    );
    let loading = mutex.getPromise();
    if (!loading) {
        loading = ajax
            .get(`/api/product-classes/${productClassSlug}/`)
            .set("Accept", "application/json")
            .then((resp) => {
                return check(VerboseProductClass.decode(resp.body));
            });
        mutex.setPromise(loading);
    }
    return loading;
};

export const submitMattressMatchPreferences = async (
    data: IMattressMatchPreferenceSet,
): Promise<IMattressMatchResults> => {
    return ajax
        .post("/api/mattress-match/")
        .set("Accept", "application/json")
        .set(CSRF_HEADER, await getCSRFToken())
        .send(data)
        .then((resp): IMattressMatchResults => {
            return check(MattressMatchResults.decode(resp.body));
        });
};
