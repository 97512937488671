import React from "react";
import SVG from "react-inlinesvg";

import iconQuestionMarkCircleFilled from "../../img/icons/question-mark-circle-filled.svg";
import { dynamicPlaceComponent } from "../utils/react";

/**
 * Activate tooltips. HTML should be in the format:
 *
 *     <span data-tooltip="This is the content of my fancy new tooltip."></span>
 */
export const initTooltips = async (lazy = true) => {
    let index = 0;
    dynamicPlaceComponent(
        "[data-tooltip]",
        async (elem) => {
            const text = elem.dataset.tooltip;
            if (!text) {
                return null;
            }
            const ReactTooltip = (await import("react-tooltip")).default;
            const tipID = `tooltip-${index++}`;
            return (
                <>
                    <span data-tip={true} data-for={tipID} tabIndex={0}>
                        <SVG
                            className="tooltip__handle"
                            src={iconQuestionMarkCircleFilled}
                        />
                    </span>
                    <ReactTooltip
                        id={tipID}
                        className="tooltip__content"
                        event={"mouseover focus"}
                        eventOff="scroll mousewheel mouseleave mouseout blur"
                        isCapture={true}
                        role="tooltip"
                        overridePosition={(
                            { left, top },
                            _currentEvent,
                            _currentTarget,
                            node,
                        ) => {
                            const d = document.documentElement;
                            left = Math.min(
                                d.clientWidth - node!.clientWidth,
                                left,
                            );
                            top = Math.min(
                                d.clientHeight - node!.clientHeight,
                                top,
                            );
                            left = Math.max(0, left);
                            top = Math.max(0, top);
                            return { top, left };
                        }}
                    >
                        {text}
                    </ReactTooltip>
                </>
            );
        },
        lazy,
    );
};
