import React from "react";

import { getProductByUUID } from "../../../api/products";
import { Link } from "../../../common/Link";
import { RatingGraphic } from "../../../common/RatingGraphic";
import { IWebPageURL } from "../../../models/nominals";
import { IReview } from "../../../models/reviews.interfaces";
import { formatDateString } from "../../../utils/format";
import { DisclosureCopy } from "./DisclosureCopy";
import { ReviewRecommended } from "./ReviewRecommended";
import { ReviewSource } from "./ReviewSource";
import { ReviewUserInfo } from "./ReviewUserInfo";

interface IProps {
    review: IReview;
    showSideDate: boolean;
    enablePDPLinks: boolean;
    showRecommended: boolean;
    showVerboseUserInfo: boolean;
    starHasStroke?: boolean;
}

interface IState {
    productLink: IWebPageURL | null;
}

export class Review extends React.PureComponent<IProps, IState> {
    state: IState = {
        productLink: null,
    };

    async componentDidMount() {
        if (!this.props.review.product_uuid) {
            return;
        }
        const product = await getProductByUUID(this.props.review.product_uuid);
        if (product && product.link && product.show_in_site_search) {
            this.setState({
                productLink: product.link,
            });
        }
    }

    private buildProductTitle() {
        const title = this.props.review.product_variant_display_name
            ? `${this.props.review.product_name} – ${this.props.review.product_variant_display_name}`
            : this.props.review.product_name;
        if (this.props.enablePDPLinks && this.state.productLink) {
            return (
                <Link
                    className="customer-review__review-item__product-link"
                    href={this.state.productLink}
                >
                    {title}
                </Link>
            );
        }
        return <div>{title}</div>;
    }

    render() {
        const formattedDate = formatDateString(
            this.props.review.created_datetime,
            "MMM d, yyyy",
        );
        const formattedReviewDate = this.props.showSideDate
            ? formattedDate
            : null;
        const content = this.props.review.highlighted
            ? this.props.review.highlighted
            : this.props.review.text;
        return (
            <div
                className="customer-review__review-item"
                role="group"
                aria-label="Product Review"
                tabIndex={0}
            >
                <div className="customer-review__review-item__star-rating">
                    <div className="customer-review__review-item__stars">
                        <RatingGraphic
                            cardClass="product-reviews-filter"
                            cardSize="standard"
                            rating={this.props.review.rating}
                            starHasStroke={this.props.starHasStroke}
                        />
                    </div>
                    <div className="customer-review__review-item__product">
                        {this.buildProductTitle()}
                        <span className="customer-review__review-item__date customer-review__review-item__date--mobile">
                            {formattedReviewDate}
                        </span>
                    </div>
                    <ReviewSource review={this.props.review} />
                    <DisclosureCopy review={this.props.review} />
                </div>
                <div className="customer-review__review-item__content">
                    <div className="customer-review__review-item__header">
                        <h2 className="customer-review__review-item__title">
                            {this.props.review.headline}
                        </h2>
                        <div className="customer-review__review-item__date">
                            {formattedReviewDate}
                        </div>
                    </div>
                    <ReviewUserInfo
                        showVerboseUserInfo={this.props.showVerboseUserInfo}
                        user={this.props.review.name}
                        location={this.props.review.location}
                        formattedDate={formattedDate}
                    />
                    <div
                        className="customer-review__review-item__copy"
                        dangerouslySetInnerHTML={{ __html: content }}
                    ></div>
                    <ReviewRecommended
                        showRecommended={this.props.showRecommended}
                        recommended={this.props.review.is_recommended}
                    />
                </div>
            </div>
        );
    }
}
