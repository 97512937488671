import classNames from "classnames";
import React from "react";
import { t } from "ttag";

import { interpolate } from "@thelabnyc/thelabui/src/utils/i18n";

import creditCards from "../../../../img/checkout/credit-cards.png";
import { Image } from "../../../common/Image";
import { FormNumber } from "../../../forms/FormNumber";
import { FormTextbox } from "../../../forms/FormTextbox";
import format from "../../../utils/format";
import { focusElement } from "../../../utils/keyboardFocus";
import { PRIMARY_PAYMENT_METHOD_KEY } from "../constants";
import { Dispatchers } from "../dispatchers";
import { IBluefinPayment } from "../reducers.interfaces";
import { IPaymentMethodFormProps } from "./PaymentMethod.interfaces";

interface IProps extends IPaymentMethodFormProps {
    setPaymentMethodFields: Dispatchers["setPaymentMethodFields"];
    changeAmount: Dispatchers["changeAmount"];
}

interface IState {}

export class Bluefin extends React.Component<IProps, IState> {
    textBox: FormTextbox<"payment_data"> | undefined | null;

    private focusTextBox() {
        // Set focus to the input field, so Bluefin entry will not be ignored
        // Only try to focus the TextBox if it is visible
        const inputElem = this.textBox ? this.textBox.getInputElem() : null;
        if (
            inputElem &&
            inputElem.offsetHeight > 0 &&
            this.props.methodKey === PRIMARY_PAYMENT_METHOD_KEY
        ) {
            focusElement(inputElem);
        }
    }

    componentDidMount() {
        this.focusTextBox();
    }

    private readonly onChange = (
        event: React.FormEvent<HTMLTextAreaElement>,
    ) => {
        this.props.setPaymentMethodFields(this.props.methodKey, {
            [event.currentTarget.name]: event.currentTarget.value,
        });
    };

    private readonly onAmountChange = (
        event: React.FormEvent<HTMLInputElement>,
    ) => {
        this.props.changeAmount(
            this.props.methodKey,
            this.props.derived.grand_total,
            event.currentTarget.value,
        );
    };

    private readonly onValidStateChange = (
        fieldName: string,
        errorMessages: string[],
    ) => {
        this.props.onValidStateChange(
            fieldName,
            errorMessages,
            this.props.methodKey,
        );
    };

    private getMethodData(): IBluefinPayment {
        const data = this.props.form.payment_methods[this.props.methodKey];
        let cybData: IBluefinPayment | null = null;
        if (data.method_type === "bluefin") {
            cybData = data;
        }
        const baseFields: IBluefinPayment = {
            method_type: "bluefin",
            amount: "",
            pay_balance: false,
            payment_data: "",
        };
        return {
            ...baseFields,
            ...cybData,
        };
    }

    render() {
        const methodData = this.getMethodData();

        const paymentDataClasses = classNames({
            ["form__card-number"]: true,
        });

        const errors =
            this.props.methodKey in this.props.errors
                ? this.props.errors[this.props.methodKey]
                : {};

        const creditCardImage = creditCards;
        return (
            <div>
                <h4 className="checkout-step__bar-heading">
                    {this.props.header}
                </h4>
                <div className="checkout-step__form-container checkout-step__form-container--cc checkout-step__form-container--blue">
                    <div className="checkout-step__form-header">
                        <div className="checkout-step__form-header--cc-copy">
                            <h5 className="checkout-step__copy--bold">
                                {t`Secure Credit Card Payment`}
                            </h5>
                            <div>
                                {t`Place your cursor in the field below and type customer credit card info into the keypad.`}
                            </div>
                        </div>
                        <div className="checkout-step__form-header--cc-image">
                            <Image
                                src={creditCardImage}
                                className="checkout-step__form-image--cc responsive-img"
                                alt="Accepted Credit Cards: VISA, MasterCard, American Express or Discover"
                            />
                        </div>
                    </div>
                    <fieldset
                        disabled={this.props.disabled}
                        style={{ border: 0 }}
                    >
                        <div className="checkout-step__copy--bold">
                            Encrypted Payment Data
                        </div>
                        <FormTextbox
                            ref={(ref) => {
                                this.textBox = ref;
                            }}
                            rows={3}
                            cols={40}
                            name="payment_data"
                            id="payment_data"
                            validation={["required"]}
                            showErrorMessages={this.props.showErrorMessages}
                            onValidStateChange={this.onValidStateChange}
                            autoComplete="payment-data"
                            className={paymentDataClasses}
                            value={methodData.payment_data}
                            errors={errors.payment_data}
                            onChange={this.onChange}
                            maxLength={200}
                        />
                        {this.props.isSplitPay && (
                            <div className="checkout-step__payment-amount">
                                <div className="checkout-step__copy--bold">
                                    Amount
                                </div>
                                <FormNumber
                                    name="amount"
                                    id="amount"
                                    step={0.01}
                                    autoComplete=""
                                    validation={[
                                        "required",
                                        "amount",
                                        "nonzero",
                                    ]}
                                    showErrorMessages={
                                        this.props.showErrorMessages
                                    }
                                    onValidStateChange={this.onValidStateChange}
                                    value={methodData.amount || undefined}
                                    errors={errors.amount}
                                    onChange={this.onAmountChange}
                                    disabled={this.props.shouldFreezeAmount}
                                />
                            </div>
                        )}
                        {this.props.disabled && (
                            <div>
                                <h5>{t`Pre-Authorized`}</h5>
                                <p>
                                    {interpolate(
                                        t`This card has been pre-authorized for the amount of %s`,
                                        [
                                            format.money(
                                                methodData.amount || "0.00",
                                            ),
                                        ],
                                    )}
                                    .
                                </p>
                            </div>
                        )}
                    </fieldset>
                </div>
            </div>
        );
    }
}
