import React from "react";

import { IStringField } from "../models/formFields.interfaces";
import { IFormFieldState } from "./AbstractFormField";
import { AbstractInput } from "./AbstractInput";

interface IState extends IFormFieldState {
    isRevealed: boolean;
}

export class FormPasswordInput<T extends string> extends AbstractInput<
    T,
    IStringField<T>,
    IState
> {
    public static defaultProps = {
        type: "password",
    };

    public state: IState = {
        focused: false,
        isRevealed: false,
    };

    private readonly onTogglePasswordVisibility = (
        e: React.MouseEvent<HTMLElement>,
    ) => {
        e.preventDefault();
        this.setState((state) => {
            return {
                ...state,
                isRevealed: !state.isRevealed,
            };
        });
    };

    protected getInputProps() {
        return Object.assign(super.getInputProps(), {
            minLength: this.props.minLength,
            maxLength: this.props.maxLength,
        });
    }

    protected buildControl() {
        const props = this.getInputProps();
        props.className ??= "form__field__input";
        return (
            <div className="form__field__password-input-group">
                <input
                    ref={(ref) => {
                        this.inputElem = ref;
                    }}
                    {...props}
                    type={this.state.isRevealed ? "text" : "password"}
                    autoComplete="new-password"
                />
                <button
                    className="form__field__password-reveal-toggle"
                    onClick={this.onTogglePasswordVisibility}
                    aria-label={
                        this.state.isRevealed
                            ? "Hide password"
                            : "Show password"
                    }
                >
                    {this.state.isRevealed ? "Hide" : "Show"}
                </button>
            </div>
        );
    }
}
