import { Middleware } from "@reduxjs/toolkit";

import { isKnownAction } from "../../utils/redux";
// Integrate with the configurator's preference system
import {
    getPreferredOptionSelections,
    persistOptionValue,
} from "../configurator/persistence";
import {
    Action,
    IActionSetOptionValue,
    IActionUpdateSelectManyFilterValue,
    IActionUpdateSelectOneFilterValue,
} from "./reducers.interfaces";

export { getPreferredOptionSelections };

/**
 * Persist option selection values to localStorage so that they can be
 * automatically selected upon the next page load.
 */
export const persistOptionSelections: Middleware = () => {
    return (next) => {
        return (action) => {
            if (
                isKnownAction<IActionSetOptionValue>(
                    Action.SET_OPTION_VALUE,
                    action,
                )
            ) {
                persistOptionValue(
                    action.payload.namespace,
                    action.payload.code,
                    action.payload.value,
                );
            }
            if (
                isKnownAction<IActionUpdateSelectOneFilterValue>(
                    Action.UPDATE_SELECT_ONE_FILTER_VALUE,
                    action,
                ) &&
                action.payload.optionID
            ) {
                persistOptionValue(
                    action.payload.optionNamespace,
                    action.payload.filterID,
                    action.payload.optionID,
                );
            }
            if (
                isKnownAction<IActionUpdateSelectManyFilterValue>(
                    Action.UPDATE_SELECT_MANY_FILTER_VALUE,
                    action,
                ) &&
                action.payload.isSelected
            ) {
                persistOptionValue(
                    action.payload.optionNamespace,
                    action.payload.filterID,
                    action.payload.optionID,
                );
            }
            return next(action);
        };
    };
};
