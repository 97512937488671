import React from "react";
import SVG from "react-inlinesvg";
import { t } from "ttag";

import iconQuestionMarkCircle from "../../../../img/icons/question-mark-circle.svg";
import {
    IDiscount,
    IOfferDiscount,
    IVoucherDiscount,
} from "../../../models/catalogue.interfaces";
import { format } from "../../../utils/format";
import { discountIsVoucher } from "../../../utils/guards";
import { Dinero, ZERO, getDinero } from "../../../utils/money";

import styles from "./BasketAllSavings.module.scss";

interface IProps {
    offerDiscounts: IOfferDiscount[];
    voucherDiscounts: IVoucherDiscount[];
    offerPostOrderActions: IOfferDiscount[];
    voucherPostOrderActions: IVoucherDiscount[];
    removeVoucherCode: (code: string) => void;
    totalOscarDiscount: Dinero;
    hasOscarAdjustment: boolean;
}

interface IState {
    savingsInfoExpanded: boolean;
}

export class BasketAllSavings extends React.Component<IProps, IState> {
    public state: IState = {
        savingsInfoExpanded: false,
    };

    private infoClick(event: React.MouseEvent<HTMLElement>) {
        if (event) {
            event.preventDefault();
        }

        this.setState((s) => {
            return {
                savingsInfoExpanded: !s.savingsInfoExpanded,
            };
        });
    }

    /**
     * Figure out the proper name to display for the given discount using the following rules:
     *   1. If the discount is from a voucher (coupon), use the voucher name.
     *   2. If the discount is from an offer:
     *     1. If there is a `short_name`, use it.
     *     2. Lastly, use the long name.
     */
    private getDiscountName(discount: IDiscount) {
        if (discountIsVoucher(discount)) {
            return discount.voucher.name;
        }
        return discount.short_name ? discount.short_name : discount.name;
    }

    private buildSavingsItem(discount: IDiscount, index: number) {
        if (discount.is_hidden) {
            return null;
        }
        const discountName = this.getDiscountName(discount);
        let voucher: JSX.Element | null = null;
        if (discountIsVoucher(discount)) {
            const onRemoveSaving = (
                event: React.MouseEvent<HTMLSpanElement>,
            ) => {
                event.preventDefault();
                this.props.removeVoucherCode(discount.voucher.code);
            };
            voucher = (
                <span>
                    <div className="basket-savings__code">
                        {t`Code:`} {discount.voucher.code}{" "}
                    </div>
                    <a
                        onClick={onRemoveSaving}
                        className="basket-savings__detail-remove"
                    >
                        [{t`Remove`}]
                    </a>
                </span>
            );
        }
        let amount: JSX.Element | null = null;
        if (parseFloat(discount.amount) !== 0) {
            amount = (
                <li className={styles.accentText}>
                    <strong>-{format.money(discount.amount)}</strong>
                </li>
            );
        }
        return (
            <ul key={index} className={styles.line}>
                <li className="basket-savings__title basket-savings__detail-title">
                    <span>{discountName}</span>
                    {voucher}
                </li>
                {amount}
            </ul>
        );
    }

    private buildOscarDiscount(index: number) {
        return (
            <React.Fragment key={index}>
                <li className="basket-savings__title basket-savings__detail-title">
                    <span>{t`Discount`}</span>
                </li>
                <li className="basket-savings__total basket-savings__detail-total">
                    -{format.money(this.props.totalOscarDiscount)}
                </li>
            </React.Fragment>
        );
    }

    render() {
        const onInfoClick = (event: React.MouseEvent<HTMLElement>) => {
            this.infoClick(event);
        };

        // Combine the offers and vouchers and sort them based on their index property (to ensure proper application order)
        const allSavings: IDiscount[] = this.props.offerDiscounts
            .concat(this.props.voucherDiscounts)
            .concat(this.props.offerPostOrderActions)
            .concat(this.props.voucherPostOrderActions)
            .sort((a, b) => {
                return a.index - b.index;
            });

        // Build display of discounts
        const savingsItems = allSavings.map((discount, i) => {
            return this.buildSavingsItem(discount, i);
        });

        // Calculate total savings
        const totalSavings = allSavings.reduce(
            (memo, savingsItem) => memo.add(getDinero(savingsItem.amount)),
            ZERO,
        );

        if (
            savingsItems.every((item) => item === null) &&
            totalSavings.isZero()
        ) {
            return null;
        }

        if (this.props.hasOscarAdjustment) {
            const oscarDiscount = this.buildOscarDiscount(savingsItems.length);
            savingsItems.unshift(oscarDiscount);
        }

        const basketSavingsAccordion = this.state.savingsInfoExpanded && (
            <div
                id="basket-savings-accordion"
                className={styles.accordionContent}
            >
                <p>{t`Promotional codes apply towards retail value only.`}</p>
                <p>
                    {t`To maximize your savings, site wide offers will automatically apply to any remaining balance.`}
                </p>
            </div>
        );

        return (
            <div className={styles.root}>
                <ul className={styles.line}>
                    <li>
                        <strong>{t`Total Savings`}</strong>
                        <button
                            className={`${styles.button} accordion`}
                            onClick={onInfoClick}
                            aria-controls="basket-savings-accordion"
                            aria-expanded={this.state.savingsInfoExpanded}
                            aria-label={t`More info`}
                        >
                            <SVG
                                className="basket-savings__title-icon"
                                src={iconQuestionMarkCircle}
                                role="img"
                                aria-hidden="true"
                                title={t`what is basket savings?`}
                            />
                        </button>
                    </li>
                    <li>
                        {/* Hide total savings amount if totalSavings is zero */}
                        {!totalSavings.isZero() && (
                            <strong className={styles.accentText}>
                                -{format.money(totalSavings)}
                            </strong>
                        )}
                    </li>
                </ul>
                {basketSavingsAccordion}
                <div>{savingsItems}</div>
            </div>
        );
    }
}
