import { useEffect, useId } from "react";

import { getPageSetting } from "../utils/settings";

/**
 * React Component to add the Google reCaptcha script to the page.
 */
export const CaptchaLibrary = (props: { onLoad?: () => void }) => {
    const scriptID = useId();

    useEffect(() => {
        const sitekey = getPageSetting("google-recaptcha-sitekey");
        const script = document.createElement("script");
        script.id = scriptID;
        script.type = "text/javascript";
        script.async = true;
        script.src = `https://www.google.com/recaptcha/api.js?render=${sitekey}`;
        if (props.onLoad) {
            script.onload = props.onLoad;
        }
        // Inject script into DOM
        const scriptSibling = document.getElementsByTagName("script")[0];
        scriptSibling.parentNode?.insertBefore(script, scriptSibling);
    }, []);

    return null;
};

/**
 * Call this on form submit to get the captcha token value
 */
export const getCaptchaToken = async (
    action: string,
): Promise<string | null> => {
    if (!window.grecaptcha) {
        return null;
    }
    const sitekey = getPageSetting("google-recaptcha-sitekey");
    return window.grecaptcha.execute(sitekey, { action: action });
};
