import { FinancingPlan } from "../models/financing";
import { IAPR, IMonths, isoAPR, isoMonths } from "../models/nominals";
import { strToBool } from "./format";
import { Dinero, getDinero } from "./money";

export interface FinancingPlanMeta {
    threshold: Dinero;
    length: IMonths;
    apr: IAPR;
    superscript: string;
    description: string;
    default?: boolean;
}

/**
 * List the currently available Financing financing plans.
 */
export const listFinancingPlans = (): FinancingPlanMeta[] => {
    const plansHTML = Array.from<HTMLElement>(
        document.querySelectorAll(".financing-plan-table__plan"),
    );
    const plans = plansHTML.map((elem): FinancingPlanMeta => {
        return {
            threshold: getDinero(elem.dataset.threshold || "0"),
            length: isoMonths.wrap(parseInt(elem.dataset.length || "0", 10)),
            apr: isoAPR.wrap(elem.dataset.apr || "0"),
            superscript: elem.dataset.superscript || "",
            description: elem.dataset.description || "",
            default: strToBool(elem.dataset.default || "no"),
        };
    });
    plans.sort((a, b) => {
        if (a.threshold === b.threshold) {
            return 0;
        }
        return a.threshold < b.threshold ? -1 : 1;
    });
    return plans;
};

export const getDefaultFinancingPlan = (): FinancingPlanMeta | null => {
    const plans = listFinancingPlans();
    return plans.find((plan) => plan.default) ?? null;
};

export const getLowestThresholdPlan = (): FinancingPlanMeta | null => {
    const plans = listFinancingPlans();
    return plans.length > 0 ? plans[0] : null;
};

export const getBestEligiblePlan = (
    amount: Dinero,
): FinancingPlanMeta | null => {
    const plans = listFinancingPlans().filter((plan) => {
        return plan.threshold.lessThanOrEqual(amount);
    });
    plans.sort((a, b) => {
        if (a.length === b.length) {
            return 0;
        }
        return a.length < b.length ? 1 : -1;
    });
    return plans.length > 0 ? plans[0] : null;
};

export const getLongestActivePlan = async (
    financingPlans: FinancingPlan[],
): Promise<FinancingPlanMeta | null> => {
    // Make sure to load them properly to avoid indirectly triggering an uninitialized import
    const { filterFinancingPlans, getLongestFinancingPlan } = await import(
        "../apps/checkout/utils"
    );
    if (financingPlans) {
        // Before retrieving the longest active financing plan, narrow down the selection to include only plans with a 0% APR
        const filteredFinancingPlans = filterFinancingPlans(financingPlans);
        const longestPlan = getLongestFinancingPlan(filteredFinancingPlans);
        return longestPlan && isoMonths.unwrap(longestPlan.term_months) > 0
            ? {
                  threshold: getDinero(
                      longestPlan.product_price_threshold || "0",
                  ),
                  length: longestPlan.term_months,
                  apr: longestPlan.apr || isoAPR.wrap("0"),
                  superscript: longestPlan.fine_print_superscript || "",
                  description: longestPlan.description || "",
              }
            : null;
    }
    return null;
};
