import React from "react";
import { connect } from "react-redux";

import { IFormUUID, IReviewsProductTypeID } from "../../../models/nominals";
import { TDispatchMapper, TStateMapper } from "../../reducers.interfaces";
import { WriteReviewFormMode } from "../constants";
import { Dispatchers } from "../dispatchers";
import { WriteReviewButton } from "../elements/WriteReviewButton";
import { WriteReviewForm } from "../elements/WriteReviewForm";
import { WriteReviewThankYou } from "../elements/WriteReviewThankYou";

interface IOwnProps {
    formUUID: IFormUUID;
    brand?: string;
    displayedProductTypeIDWhitelist?: IReviewsProductTypeID[];
}

interface IReduxProps {}

interface IDispatchProps {
    dispatchers: Dispatchers;
}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

interface IState {}

class SingleProductCollapsibleWriteReviewFormComponent extends React.Component<
    IProps,
    IState
> {
    componentDidMount() {
        this.props.dispatchers.registerNewWriteReviewForm(
            this.props.formUUID,
            WriteReviewFormMode.CLOSED,
        );
    }

    private readonly onReviewSubmit = () => {
        const reviewModuleTitle = document.querySelector(
            ".write-review-button__container",
        );
        reviewModuleTitle?.scrollIntoView({ behavior: "smooth" });
    };

    render() {
        return (
            <div className="write-review-button__container">
                <WriteReviewButton formUUID={this.props.formUUID} />
                <WriteReviewForm
                    formUUID={this.props.formUUID}
                    formTitle={"Choose your product"}
                    brand={this.props.brand}
                    displayedProductTypeIDWhitelist={
                        this.props.displayedProductTypeIDWhitelist
                    }
                    onReviewSubmit={this.onReviewSubmit}
                />
                <WriteReviewThankYou formUUID={this.props.formUUID} />
            </div>
        );
    }
}

const mapStateToProps: TStateMapper<"reviews", IReduxProps, IOwnProps> = (
    _rootState,
    ownProps,
) => {
    return {
        ...ownProps,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const dispatchers = new Dispatchers(dispatch);
    return {
        dispatchers: dispatchers,
    };
};

export const SingleProductCollapsibleWriteReviewForm = connect(
    mapStateToProps,
    mapDispatchToProps,
)(SingleProductCollapsibleWriteReviewFormComponent);
