import * as t from "io-ts";

import { constants } from "@reactivated/constants";

import { codecFromConstMap } from "../models/utils";

export const SpacingRules =
    constants["tsicommon.cms.blocks.sub_blocks.spacing.SpacingRules"];

export const SpacingRulesMap = codecFromConstMap("SpacingRules", SpacingRules);

export type TSpacingRules = t.TypeOf<typeof SpacingRulesMap>;

export const ResponsiveVisibilityRules =
    constants[
        "tsicommon.cms.blocks.sub_blocks.responsive_visibility.ResponsiveVisibilityRules"
    ];

export const ResponsiveVisibilityRulesMap = codecFromConstMap(
    "ResponsiveVisibilityRules",
    ResponsiveVisibilityRules,
);

export type TResponsiveVisibilityRules = t.TypeOf<
    typeof ResponsiveVisibilityRulesMap
>;
